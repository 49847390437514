import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { NotificationEntity } from './notifications.types';

const DEFAULT_NOTIFICATIONS_PAGE_SIZE = 20;

export const getNotifications = async (
	page: number,
	pageSize: number = DEFAULT_NOTIFICATIONS_PAGE_SIZE,
): Promise<NotificationEntity[]> => {
	try {
		const { data } = await privateAxios.get<NotificationEntity[]>(
			`/notifications`,
			{
				params: {
					page,
					limit: pageSize,
				},
			},
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getUnreadNotificationsCount = async (): Promise<number> => {
	try {
		const { data } = await privateAxios.get<number>(
			`/notifications/unread-count`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
