import { Button } from '@/components/ui/button';
import { MyPasswordField } from '@/components/ui/form/password-form-field';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { PasswordRules } from '../../../shared/components/ui/PasswordRules';
import {
	ResetPasswordZodSchema,
	ResetPasswordZodType,
} from './validators/ResetPasswordValidator';

type Props = {
	onSubmit: (data: ResetPasswordZodType) => void;
	isLoading: boolean;
};

export function ResetPasswordForm({ onSubmit, isLoading }: Props) {
	const methods = useForm<ResetPasswordZodType>({
		resolver: zodResolver(ResetPasswordZodSchema),
	});

	const {
		handleSubmit,
		formState: { errors },
	} = methods;

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={handleSubmit(onSubmit)}
				noValidate
				className="flex flex-col gap-12"
			>
				<div className="flex flex-col gap-4">
					<div className="flex flex-col gap-2">
						<MyPasswordField
							name="password"
							required={true}
							label="Nouveau mot de passe"
							placeholder="Entrez votre nouveau mot de passe"
							error={errors.password?.message}
							labelClass="text-black"
							inputClass="text-black"
						/>
						<PasswordRules />
					</div>

					<MyPasswordField
						name="confirmPassword"
						required={true}
						label="Confirmation du mot de passe"
						placeholder="Confirmez votre nouveau mot de passe"
						error={errors.confirmPassword?.message}
						labelClass="text-black"
						inputClass="text-black"
						onPaste={(event) => {
							event.preventDefault();
						}}
					/>
				</div>

				<Button
					disabled={isLoading}
					type="submit"
					className="flex justify-center"
				>
					Réinitialiser le mot de passe
				</Button>
			</form>
		</FormProvider>
	);
}
