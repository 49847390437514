import classes from '@/components/ui/modal/modal.module.scss';
import { Thread } from '@/features/messages/api/messages.types';
import { filterBySearchValue } from '@/features/messages/utils/messages';
import { useCurrentResearch } from '@/features/researches/contexts/researches.context';
import { SearchBar } from '@/shared/components/inputs/SearchBar';
import { Modal } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { ThreadItem } from '../../atoms/ThreadItem';

type MobileSearchThreadsProps = {
	handleClose: () => void;
	threads: Thread[];
	onThreadClick: (threadId: number) => void;
};

export const MobileSearchThreads = (props: MobileSearchThreadsProps) => {
	const { userOnResearch: user } = useCurrentResearch();
	const [searchValue, setSearchValue] = useState('');
	const [debouncedSearchText] = useDebounce(searchValue, 500);
	const [filteredThreads, setFilteredThreads] = useState<Thread[]>([]);

	useEffect(() => {
		const filteredThreads = debouncedSearchText
			? filterBySearchValue(props.threads, user.role, debouncedSearchText)
			: props.threads;
		setFilteredThreads(filteredThreads);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.threads, debouncedSearchText]);

	const handleThreadClick = (threadId: number) => {
		props.onThreadClick(threadId);
		props.handleClose();
	};
	return (
		<Modal
			title="Rechercher un fil de discussion"
			fullScreen
			opened
			classNames={classes}
			onClose={props.handleClose}
		>
			<div className="flex h-full flex-col gap-4 overflow-hidden">
				<div className="flex flex-col gap-1">
					<SearchBar setSearchValue={setSearchValue} />
					<p className="text-sm font-semibold text-muted-foreground">
						{filteredThreads.length} résultats trouvés
					</p>
				</div>
				<div className="flex flex-col divide-y divide-gray-50 overflow-y-auto">
					{filteredThreads.map((thread) => (
						<ThreadItem
							thread={thread}
							key={thread.id}
							isActive={false}
							onThreadClick={() => handleThreadClick(thread.id)}
						/>
					))}
				</div>
			</div>
		</Modal>
	);
};
