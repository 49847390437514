import { useIsMobile } from '@/components/hooks/useIsMobile.ts';
import { Avatar } from '@mantine/core';
import OffsiteAvatar from '../../../../assets/icons/offsite-avatar.svg';
import OnsiteAvatar from '../../../../assets/icons/onsite-avatar.svg';
import ParticipantAvatar from '../../../../assets/icons/participant-avatar.svg';
import { isEnumKey } from '../../../../shared/utils/enums.ts';
import { CreateParticipantDto } from '../../api/dto/participants.dto.ts.ts';
import { CreateResearchMemberDto } from '../../api/dto/research-members.dto.ts.ts';
import {
	InvestigatorRole,
	InvestigatorRoleEnum,
	OffsiteResearchMemberRole,
	OffsiteResearchMemberRoleEnum,
	OnsiteResearchMemberRole,
	OnsiteResearchMemberRoleEnum,
} from '../../api/types/research-members.types.ts';
import { UserOnResearch } from '../../api/types/users-on-research.types.ts';
import { getResearchMemberInfo } from '../../utils/researchMemberInfo.ts';

type Props = {
	user: UserOnResearch | CreateResearchMemberDto | CreateParticipantDto;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isUserOnResearch = (user: any): user is UserOnResearch =>
	user.userOnResearchId !== undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isCreateParticipantDto = (user: any): user is CreateParticipantDto =>
	user.userOnResearchId === undefined && user.code !== undefined;

const isCreateResearchMemberDto = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	user: any,
): user is CreateResearchMemberDto =>
	user.userOnResearchId === undefined && user.roleOnResearch !== undefined;

export function ResearchMemberCard({ user }: Props) {
	let avatar = '';
	let displayTitle = '';
	let displayRole = '';
	const isMobile = useIsMobile();

	if (isUserOnResearch(user))
		({ avatar, displayTitle, displayRole } = getResearchMemberInfo(user));

	if (isCreateParticipantDto(user)) {
		(avatar = ParticipantAvatar),
			(displayTitle = user.code),
			(displayRole = user.email);
	}

	if (isCreateResearchMemberDto(user)) {
		if (isEnumKey(user.roleOnResearch, OnsiteResearchMemberRoleEnum)) {
			avatar = OnsiteAvatar;
			displayTitle =
				OnsiteResearchMemberRoleEnum[
					user.roleOnResearch as OnsiteResearchMemberRole
				];
		}

		if (isEnumKey(user.roleOnResearch, OffsiteResearchMemberRoleEnum)) {
			avatar = OffsiteAvatar;
			if (user.roleOnResearch === 'OTHER')
				displayTitle = user.roleAdditionalInfo || '';
			else if (user.roleOnResearch === 'DOCTOR')
				displayTitle =
					OffsiteResearchMemberRoleEnum.DOCTOR +
					' - ' +
					user.roleAdditionalInfo;
			else {
				displayTitle =
					OffsiteResearchMemberRoleEnum[
						user.roleOnResearch as OffsiteResearchMemberRole
					];
			}
		}

		if (isEnumKey(user.roleOnResearch, InvestigatorRoleEnum)) {
			avatar = OnsiteAvatar;
			displayTitle =
				InvestigatorRoleEnum[user.roleOnResearch as InvestigatorRole];
		}

		displayRole = user.email;
	}

	return (
		<div className="flex w-full items-center gap-2 ">
			<Avatar src={avatar} size={isMobile ? 'sm' : 'md'} />
			<div className="flex flex-col">
				<p className="truncate text-md">{displayTitle}</p>
				<p className="truncate text-sm text-muted-foreground">{displayRole}</p>
			</div>
		</div>
	);
}
