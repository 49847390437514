import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { handleExportData } from '../../../../shared/utils/files';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { getVisitsResults } from '../../actions/api/actions.queries';
import { formatVisitResultsData } from '../../utils/formatVisitResultsData';

export const useExportVisitsData = (exportTitle: string) => {
	const { research } = useCurrentResearch();

	const exportResultsMutation = useMutation({
		mutationFn: async () => getVisitsResults(research.id),
		onSuccess: (data) => {
			const result = data.map((result) => formatVisitResultsData(result));
			handleExportData(result, exportTitle);
			notifications.show({
				message: 'Résultats des visites exportés avec succès',
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
		},
		onError: () => {
			notifications.show({
				message: `Une erreur est survenue`,
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		},
	});

	return { exportResultsMutation };
};
