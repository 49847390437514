import { useQuery } from '@tanstack/react-query';
import { calendarQueryKeys } from './calendar.query-keys';
import { getVisitsSchedules } from './visit-schedule.queries';
import { VisitScheduleQueryParams } from './visit-schedule.types';
import { useCurrentResearch } from '../../researches/contexts/researches.context';

export const useVisitsSchedules = (params: VisitScheduleQueryParams) => {
	const { research } = useCurrentResearch();
	const {
		data: visitsSchedules,
		error,
		isLoading,
	} = useQuery({
		queryKey: calendarQueryKeys.list(research.id, params),
		queryFn: () => getVisitsSchedules(research.id, params),
	});

	return { visitsSchedules, error, isLoading };
};
