import { useIsMobile } from '@/components/hooks/useIsMobile';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { ConfirmPasswordForm } from '../forms/validators/ConfirmPasswordForm';

type ConfirmExportParticipantsDataModalProps = {
	onClose: () => void;
	onPasswordConfirmed: () => void;
};
export function ConfirmExportParticipantsDataModal({
	onClose: handleClose,
	onPasswordConfirmed: handleConfirmPassword,
}: ConfirmExportParticipantsDataModalProps) {
	const isMobile = useIsMobile();
	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			title="Exporter la liste de correspondance"
			size="lg"
			fullScreen={isMobile}
		>
			<div className="flex flex-col gap-6">
				<p className="text-center">
					Entrez votre mot de passe pour confirmer l'exportation des données.
				</p>
				<ConfirmPasswordForm
					onClose={handleClose}
					onPasswordConfirmed={handleConfirmPassword}
				/>
			</div>
		</Modal>
	);
}
