import { isParticipant } from '../../../../usersOnResearch/utils/validateRoles';
import { ResponseDto } from '../api/questionnaires.dto';
import { QuestionnaireResponse } from '../api/questionnaires.types';

export const exportQuestionnaireResults = (data: QuestionnaireResponse) => {
	const { questions, responses } = data;

	const result: {
		'Code participant': string;
		Question: string;
		Réponse: string;
	}[] = [];

	responses.forEach((response) => {
		const question = questions.find((q) => q.id === response.questionId);
		if (!question) return null;

		result.push({
			'Code participant': isParticipant(response.participant)
				? response.participant.participantOnResearch.code
				: '',
			Question: question.label,
			Réponse:
				response.value +
				(question.type === 'RANGE' ? ` / ${question.maxRange}` : ''),
		});
	});
	return result.sort((a, b) =>
		a['Code participant'].localeCompare(b['Code participant']),
	);
};

export const formatQuestionnaireReponses = (data: {
	[key: string]: unknown;
}): ResponseDto[] => {
	const responses = [];

	for (const [key, value] of Object.entries(data)) {
		const questionId = parseInt(key);
		if (typeof value === 'string') responses.push({ questionId, value });
		if (typeof value === 'number')
			responses.push({ questionId, value: value.toString() });
		if (Array.isArray(value))
			value.forEach((element) => {
				responses.push({ questionId, value: element });
			});
	}

	return responses;
};
