import { ActionIcon, Paper } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { S3File } from '../../types/S3File';
import { FileTile } from './FileTile';

type Props = {
	file: S3File;
	onRemoveFile: (name: string) => void;
};

export function FileOption({ file, onRemoveFile }: Props) {
	const {
		file: { name, size },
	} = file;
	return (
		<Paper
			shadow="sm"
			radius="md"
			className="flex w-full items-center justify-between gap-2 p-2"
		>
			<div className="flex max-w-[80%] grow">
				<FileTile fileName={name} fileSize={size} />
			</div>
			<ActionIcon
				variant="transparent"
				onClick={() => onRemoveFile(name ?? '')}
			>
				<IconTrash color="red" />
			</ActionIcon>
		</Paper>
	);
}
