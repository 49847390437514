import { Select } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { paths } from '../../../../routes/paths';
import classes from '../../../../shared/styles/ResearchPicker.module.scss';
import { useSession } from '../../../auth/shared/hooks/useSession';
import { useGetResearches } from '../../api/researches.hooks';

export function ResearchPicker() {
	const [currentResearch, setCurrentResearch] = useState<string | null>(null);
	const { researchId } = useParams();
	const { user } = useSession();
	const navigate = useNavigate();
	const { researches } = useGetResearches();

	useEffect(() => {
		if (researchId) setCurrentResearch(researchId);
		else setCurrentResearch(null);
	}, [researchId]);

	const handleChange = (selectedResearchId: string | null) => {
		if (!selectedResearchId) {
			setCurrentResearch((curr) => curr && null);
			navigate(`${paths.INDEX_PATH}`);
			return;
		}
		setCurrentResearch(selectedResearchId);
		let route = `${paths.RESEARCH_PATH}/${selectedResearchId}/`;
		if (user?.role === 'PARTICIPANT')
			route += `${paths.PARTICIPANT_TAB}/${user.userId}/${paths.VISITS_TAB}`;
		else if (user?.role === 'OFFSITE_RESEARCH_MEMBER')
			route = `${paths.RESEARCH_PATH}/${selectedResearchId}/${paths.PARTICIPANTS_TAB}`;
		else
			route = `${paths.RESEARCH_PATH}/${selectedResearchId}/${paths.DASHBOARD_TAB}`;
		navigate(route);
	};

	return (
		<Select
			value={currentResearch}
			allowDeselect={false}
			withCheckIcon={false}
			onChange={handleChange}
			data={researches?.map((research) => {
				return { value: research.id.toString(), label: research.name };
			})}
			limit={10}
			placeholder="Sélectionner une recherche"
			w="100%"
			radius="lg"
			size="lg"
			checkIconPosition="right"
			withScrollArea={true}
			comboboxProps={{
				position: 'top',
				middlewares: { flip: false, shift: false },
			}}
			classNames={classes}
		/>
	);
}
