import { AxiosError } from 'axios';
import { privateAxios } from '../../../../shared/services/privateAxios';
import { JoinResearchResponse } from '../../../auth/shared/types/JoinResearchResponse';
import { CreateResearchMemberDto } from '../dto/research-members.dto.ts';

export const addInvestigatorOnResearch = async (
	payload: Omit<CreateResearchMemberDto, 'role'>,
) => {
	try {
		const { data } = await privateAxios.post<JoinResearchResponse>(
			`/investigators/create-on-research`,
			payload,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const addOffsiteResearchMemberOnResearch = async (
	payload: Omit<CreateResearchMemberDto, 'role'>,
) => {
	try {
		const { data } = await privateAxios.post<JoinResearchResponse>(
			`/offsite-research-members/create-on-research`,
			payload,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const addOnsiteResearchMemberOnResearch = async (
	payload: Omit<CreateResearchMemberDto, 'role'>,
) => {
	try {
		const { data } = await privateAxios.post<JoinResearchResponse>(
			`/onsite-research-members/create-on-research`,
			payload,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
