import { Navigate } from 'react-router-dom';
import { useSession } from '../features/auth/shared/hooks/useSession';
import { Role } from '../features/profile/api/types/user.types';
import { validateRoles } from '../features/usersOnResearch/utils/validateRoles';
import { paths } from './paths';

type Props = {
	allowedRoles?: Role[];
	children: React.ReactNode;
};

export const PrivateRoute = ({ allowedRoles, children }: Props) => {
	const { isAuthenticated, user } = useSession();

	if (!isAuthenticated || !user)
		return <Navigate to={paths.SIGNIN_PATH} replace={true} />;

	if (!validateRoles(user.role, allowedRoles))
		return (
			<Navigate
				to={paths.SIGNIN_PATH}
				replace={true}
				state={{
					error: "Vous n'êtes pas autorisé à accéder à cette page",
				}}
			/>
		);

	return children;
};
