import { Button } from '@/components/ui/button';
import { notifications } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from '../../../../routes/paths';
import { SignInForm } from '../components/forms/SignInForm';
import { SignInDto } from '../queries/dto/SignInDto';
import { sendSignInOtpQuery } from '../queries/sendSignInOtpQuery';

export function SignInPage() {
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (location.search.includes('error=session_expired')) {
			notifications.show({
				message: 'Votre session a expiré, veuillez vous reconnecter',
				color: 'red',
				icon: <IconX style={{ width: '20rem', height: '20rem' }} />,
			});
			location.search = '';
		}
		if (location.state?.error) {
			notifications.show({
				message: location.state.error,
				color: 'red',
				icon: <IconX style={{ width: '20rem', height: '20rem' }} />,
			});
			location.state.error = undefined;
		}
	}, [location]);

	const mutation = useMutation({
		mutationFn: async (payload: SignInDto) => {
			return await sendSignInOtpQuery(payload);
		},
		onSuccess: async (_, params) => {
			navigate(paths.VERIFY_SIGNIN_PATH, {
				state: { email: params.email },
				replace: true,
			});
		},
		onError: (error: AxiosError) => {
			if (error.response?.status.toString().startsWith('4')) return;
			notifications.show({
				message:
					'Une erreur est survenue lors de la connexion. Veuillez réessayer.',
				color: 'red',
				icon: <IconX style={{ width: '20rem', height: '20rem' }} />,
			});
		},
	});

	const handleSubmit = (data: SignInDto) => {
		mutation.mutate(data);
	};

	return (
		<div className="flex max-w-xl flex-col justify-center gap-12">
			<div className="flex flex-col gap-3">
				<h1 className="text-center">Connectez-vous à votre compte</h1>
				<p className="text-center text-md font-normal text-muted-foreground">
					Entrez vos informations pour accéder à votre espace.
				</p>
			</div>

			<div className="flex flex-col items-center gap-4">
				<SignInForm onSubmit={handleSubmit} isLoading={mutation.isPending} />
				{mutation.isError &&
					mutation.error.response?.status.toString().startsWith('4') && (
						<p className="text-center text-sm text-error-500">
							Identifiants incorrects
						</p>
					)}
			</div>

			<Button
				variant={'link'}
				size={'sm'}
				onClick={() => navigate(paths.FORGOT_PASSWORD_PATH)}
				className="flex justify-center"
			>
				Mot de passe oublié ?
			</Button>
		</div>
	);
}
