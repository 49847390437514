import { Center, Checkbox } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useSession } from '../../../../auth/shared/hooks/useSession';
import { useCurrentParticipant } from '../../../../usersOnResearch/contexts/participants.context';
import { getActionPermissions } from '../../../utils/actionPermissions';
import { Action, ActionWithResult } from '../../api/actions.types';
import { useSetMiscellaneousActionResultMutation } from '../api/miscellaneous-actions.hooks';

type Props = {
	action: ActionWithResult;
};
export const ActionCheckbox = ({ action }: Props) => {
	const { participant } = useCurrentParticipant();
	const { user } = useSession();
	const [checked, setChecked] = useState(action.result?.completed);

	useEffect(() => {
		setChecked(action.result?.completed);
	}, [action.result?.completed]);

	const isDisabled = !getActionPermissions(action as Action, user?.role)
		.canWrite;

	const { setMiscellaneousActionResultMutation } =
		useSetMiscellaneousActionResultMutation(
			action.id,
			participant.userId,
			() => {
				setChecked((prev) => !prev);
			},
		);

	return (
		<Center h="100%">
			<Checkbox
				radius="sm"
				color="teal"
				checked={checked}
				disabled={isDisabled}
				onChange={(event) => {
					setMiscellaneousActionResultMutation.mutate(
						event.currentTarget.checked,
					);
				}}
			/>
		</Center>
	);
};
