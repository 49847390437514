import { Button, Modal, Stack } from '@mantine/core';
import classes from '../../../../../shared/styles/Modal.module.scss';
import { useCurrentParticipant } from '../../../../usersOnResearch/contexts/participants.context';
import { ActionType } from '../../api/actions.types';
import { DynamicTableResult } from '../../dynamic-tables/components/cards/DynamicTableResult';
import { QuestionnaireResponse } from '../../questionnaires/components/cards/QuestionnaireResponse';

type Props = {
	handleClose: () => void;
	actionId: number;
	actionType: ActionType;
};

export function ViewResponsesModal({
	handleClose,
	actionId,
	actionType,
}: Props) {
	const { participant } = useCurrentParticipant();
	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			radius="sm"
			classNames={classes}
			styles={{
				root: {
					'--modal-size': '90%',
				},
				header: {
					marginBottom: '2rem',
				},
			}}
		>
			<Stack align="center" w="90%" mx="auto">
				{actionType === 'QUESTIONNAIRE' && (
					<QuestionnaireResponse
						userId={participant.userId}
						actionId={actionId}
					/>
				)}
				{actionType === 'TABLE' && (
					<DynamicTableResult userId={participant.userId} actionId={actionId} />
				)}
				<Button size="md" fullWidth w="90%" onClick={handleClose}>
					Fermer
				</Button>
			</Stack>
		</Modal>
	);
}
