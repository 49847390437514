import MyField from '@/components/ui/form/form-field.tsx';
import { zodResolver } from '@hookform/resolvers/zod';
import { ActionIcon } from '@mantine/core';
import { IconPlaylistAdd } from '@tabler/icons-react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useCurrentResearch } from '../../../../researches/contexts/researches.context.tsx';
import { CreateParticipantDto } from '../../../api/dto/participants.dto.ts.ts';
import {
	AddParticipantZodSchema,
	AddParticipantZodType,
} from './AddParticipantZodSchema.ts';

type Props = {
	participants: CreateParticipantDto[];
	setParticipants: React.Dispatch<React.SetStateAction<CreateParticipantDto[]>>;
};

export function AddParticipantForm({ participants, setParticipants }: Props) {
	const { research } = useCurrentResearch();
	const [isDuplicate, setIsDuplicate] = useState(false);
	const methods = useForm<AddParticipantZodType>({
		resolver: zodResolver(AddParticipantZodSchema),
	});
	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = methods;

	const submitForm = (data: AddParticipantZodType) => {
		setIsDuplicate(false);
		const participant = {
			...data,
			researchId: research.id,
		};
		if (
			participants.some(
				(p) => p.email === participant.email || p.code === participant.code,
			)
		) {
			setIsDuplicate(true);
			return;
		}
		setParticipants((currParticipants) => [...currParticipants, participant]);
		reset({ email: '', code: '' });
	};

	return (
		<FormProvider {...methods}>
			<div className="flex flex-col gap-4">
				<MyField
					type="email"
					name="email"
					label="Email"
					placeholder="Email du participant"
					error={errors.email?.message}
					labelClass="hidden"
					inputClass="text-black"
					additionalClass="space-y-0"
				/>
				<div className="flex flex-col items-center gap-4 md:flex-row">
					<MyField
						type="text"
						name="code"
						label="Identifiant pour la recherche"
						placeholder="Code du participant"
						error={errors.code?.message}
						labelClass="hidden"
						inputClass="text-black"
						additionalClass="space-y-0"
					/>
					<ActionIcon onClick={handleSubmit(submitForm)} size="xl">
						<IconPlaylistAdd stroke={2} />
					</ActionIcon>
				</div>
				{isDuplicate && (
					<p className="text-center text-sm text-error-500">
						Cette adresse mail ou cet identifiant a déjà été attribué
					</p>
				)}
			</div>
		</FormProvider>
	);
}
