import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import { Avatar, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMemo } from 'react';
import { useCurrentResearch } from '../../../../researches/contexts/researches.context';
import { getActionIcon } from '../../../utils/actionType';
import { Action, ActionWithResult } from '../../api/actions.types';
import { useDownloadActionDocument } from '../../api/hooks/useDownloadActionDocument';
import { useDownloadActionDocuments } from '../../api/hooks/useDownloadActionDocuments';
import { PreviewActionModal } from '../modals/PreviewActionModal';

type Props = {
	action: Action | ActionWithResult;
};
export const ActionHeader = ({ action }: Props) => {
	const { research } = useCurrentResearch();
	const isMobile = useIsMobile();

	const zipTitle = `${research.name}-${action.visit.name}-${action.name}-${new Date().toLocaleDateString()}-info-documents.zip`;

	const { downloadDocumentMutation } = useDownloadActionDocument(action.id);
	const { downloadDocumentsMutation } = useDownloadActionDocuments(
		action.id,
		zipTitle,
	);

	const handleOnDownload = () => {
		if (action?.actionDocuments.length === 1)
			downloadDocumentMutation.mutate(action.actionDocuments[0]);
		else downloadDocumentsMutation.mutate();
	};

	const [
		previewModalOpened,
		{ open: previewModalOpen, close: previewModalClose },
	] = useDisclosure();

	const { icon, color } = useMemo(
		() => getActionIcon(action.actionType),
		[action.actionType],
	);

	const shouldPreviewItem =
		!isMobile &&
		(action.actionType === 'QUESTIONNAIRE' || action.actionType === 'TABLE');

	return (
		<>
			<div className="flex items-center gap-3">
				<Avatar size={32} color={color} variant="filled">
					{icon}
				</Avatar>
				<div className="flex flex-col">
					{shouldPreviewItem ? (
						<Tooltip label="Prévisualiser" radius={'md'} position={'top-start'}>
							<Button
								variant={'link'}
								className="justify-start p-0"
								onClick={previewModalOpen}
							>
								{action.name}
							</Button>
						</Tooltip>
					) : (
						<p className="line-clamp-1 font-semibold">{action.name}</p>
					)}
					<p className="line-clamp-2 text-sm text-muted-foreground">
						{action.description}
					</p>
					{!isMobile && action?.actionDocuments.length > 0 && (
						<Tooltip label="Télécharger" radius={'md'} position={'top-start'}>
							<Button
								variant={'link'}
								size={'sm'}
								className="justify-start p-0 underline"
								onClick={handleOnDownload}
							>
								{action.actionDocuments.length === 1
									? '1 fichier disponible'
									: action.actionDocuments.length + ' fichiers disponibles'}
							</Button>
						</Tooltip>
					)}
				</div>
			</div>
			{previewModalOpened && (
				<PreviewActionModal
					handleClose={previewModalClose}
					actionId={action.id}
					actionType={action.actionType}
				/>
			)}
		</>
	);
};
