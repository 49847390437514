import { useState } from 'react';
import { ParticipantContactsInfo } from './ParticipantContactsInfo';
import { ConfirmPasswordForm } from '@/features/usersOnResearch/components/forms/validators/ConfirmPasswordForm';

export function ParticipantContactsTab() {
	const [passwordConfirmed, setPasswordConfirmed] = useState(false);
	const handleConfirmPassword = () => setPasswordConfirmed(true);

	return (
		<div className="flex h-full w-full flex-col gap-4">
			<div className="flex flex-col gap-1">
				<h4>Coordonnées</h4>
				<p className="text-muted-foreground">
					Contactez le participant de la recherche
				</p>
			</div>

			{passwordConfirmed ? (
				<ParticipantContactsInfo />
			) : (
				<div className="w-70% mt-4 self-center">
					<p className="text-center font-semibold">
						Entrez votre mot de passe pour afficher les coordonnées du
						participant
					</p>
					<ConfirmPasswordForm onPasswordConfirmed={handleConfirmPassword} />
				</div>
			)}
		</div>
	);
}
