import { MyTabsWithScroll } from '@/components/ui/tabs/tabs-with-scroll';
import { paths } from '../../routes/paths';

export function ParticipantsTabs() {
	const tabItems = [
		{
			link: paths.VISITS_TAB,
			label: 'Visites',
		},
		{
			link: paths.DOCUMENTS_TAB,
			label: 'Documents',
		},
		{
			link: paths.MEETS_TAB,
			label: 'Visioconférences',
		},
		{
			link: paths.MESSAGING_TAB,
			label: 'Messagerie',
		},
		{
			link: paths.NOTES_TAB,
			label: 'Notes',
		},
		{
			link: paths.CONSENT_TAB,
			label: 'Consentements',
		},
		{
			link: paths.CONTACTS_TAB,
			label: 'Contacts',
		},
	];

	return <MyTabsWithScroll tabItems={tabItems} />;
}
