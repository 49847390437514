import { AxiosError } from 'axios';
import { publicAxios } from '../../../../shared/services/publicAxios';
import { LoginResponse } from '../../shared/types/AuthToken';
import { JoinResearchResponse } from '../../shared/types/JoinResearchResponse';
import { OffsiteResearchMemberSignUpDto } from './offsite-research-member.dto';

export const offsiteResearchMemberJoinResearchQuery = async (
	key: string,
): Promise<JoinResearchResponse> => {
	try {
		const response = await publicAxios.post<JoinResearchResponse>(
			`/offsite-research-members/confirm-join-research`,
			undefined,
			{ headers: { Authorization: `Bearer ${key}` } },
		);
		return response.data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const OffsiteResearchMemberSignUpQuery = async (
	body: OffsiteResearchMemberSignUpDto,
	key: string,
): Promise<LoginResponse> => {
	try {
		const response = await publicAxios.post<LoginResponse>(
			`/offsite-research-members/signup`,
			body,
			{
				headers: { Authorization: `Bearer ${key}` },
			},
		);
		return response.data;
	} catch (error) {
		throw error as AxiosError;
	}
};
