import { z } from 'zod';
import { passwordValidator } from '../../../../../shared/components/validators/commonValidators';

export type ChangePasswordZodType = z.infer<typeof ChangePasswordZodSchema>;

export const ChangePasswordZodSchema = z
	.object({
		currentPassword: z
			.string()
			.min(1, { message: 'Vous devez renseigner votre mot de passe actuel' }),
		newPassword: passwordValidator,
		confirmNewPassword: passwordValidator,
	})
	.refine((data) => data.newPassword === data.confirmNewPassword, {
		path: ['confirmNewPassword'],
		message: 'Les mots de passe sont différents',
	})
	.refine((data) => data.currentPassword !== data.newPassword, {
		path: ['newPassword'],
		message:
			'Le nouveau mot de passe doit être différent du mot de passe actuel',
	});
