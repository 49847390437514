import { Divider, Slider, Stack, Text, rem } from '@mantine/core';
import { useMemo } from 'react';
import { Question, Response } from '../../api/questionnaires.types';
import { getSliderMarksFromRange } from '../../utils/slider';

type Props = {
	question: Question;
	response: Response;
};

export function RangeResponseCard({ question, response }: Props) {
	const maxRange = question.maxRange ?? 0;

	const sliderMarks = useMemo(
		() => getSliderMarksFromRange(maxRange),
		[maxRange],
	);

	return (
		<Stack gap="xl" w="90%">
			<Stack gap="sm">
				<Text fw={500}>
					{question.label}{' '}
					{question.required && (
						<Text component="span" c="red">
							*
						</Text>
					)}
				</Text>
				<Slider
					size="xl"
					mb="xl"
					label={null}
					value={+response.value}
					disabled
					max={maxRange}
					marks={sliderMarks}
					thumbSize={rem(25)}
					styles={{
						thumb: {
							borderWidth: rem(2),
							padding: rem(3),
							display: 'flex',
						},
						mark: { borderColor: 'var(--mantine-primary-color-6)' },
						bar: { backgroundColor: 'var(--mantine-primary-color-6)' },
					}}
				/>
			</Stack>
			<Divider />
		</Stack>
	);
}
