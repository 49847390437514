import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../../../App';
import { useCurrentResearch } from '../contexts/researches.context';
import { updateResearch } from './researches.mutations';
import { getResearch, getResearches } from './researches.queries';
import { researchesQueryKeys } from './researches.query-keys';
import { Research } from './researches.types';

export const useGetResearches = () => {
	const {
		data: researches,
		error,
		isLoading,
	} = useQuery<Research[]>({
		queryKey: researchesQueryKeys.list(['ACTIVE']),
		queryFn: () => getResearches(['ACTIVE']),
	});

	return { researches, error, isLoading };
};

export const useGetResearch = (researchId: number) => {
	const {
		data: research,
		error,
		isLoading,
	} = useQuery<Research>({
		queryKey: researchesQueryKeys.detail(researchId),
		queryFn: () => getResearch(researchId),
	});

	return { research, error, isLoading };
};

export const useUpdateResearch = () => {
	const { research } = useCurrentResearch();

	const showSuccessNotification = (params: {
		name?: string;
		forecastParticipantsCount?: number;
	}) => {
		const message = params.name
			? 'Nom de la recherche modifée avec succès'
			: `Nombre d'inclusions prévues modifié avec succès`;
		notifications.show({
			message,
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const updateResearchMutation = useMutation({
		mutationFn: async ({
			name,
			forecastParticipantsCount,
		}: {
			name?: string;
			forecastParticipantsCount?: number;
		}) => updateResearch(research.id, name, forecastParticipantsCount),
		onSuccess: (_, params) => {
			queryClient.invalidateQueries({
				queryKey: researchesQueryKeys.lists(),
			});
			showSuccessNotification(params);
		},
		onError: showErrorNotification,
	});

	return { updateResearchMutation };
};
