import { Divider, Stack, Textarea } from '@mantine/core';
import { Question, Response } from '../../api/questionnaires.types';
import { MAX_RESPONSE_LENGTH } from '../../utils/constants';

type Props = {
	question: Question;
	response: Response;
};

export function TextResponseCard({ question, response }: Props) {
	return (
		<Stack gap="xl" w="90%">
			<Textarea
				label={question.label}
				description={`${MAX_RESPONSE_LENGTH} caractères maximum`}
				size="md"
				autosize
				minRows={2}
				maxRows={10}
				resize="vertical"
				withAsterisk={question.required}
				radius="md"
				value={response.value}
				disabled
				styles={{
					input: { marginTop: '3%', color: 'black' },
				}}
			/>
			<Divider />
		</Stack>
	);
}
