import { BarChart } from '@mantine/charts';
import { Box } from '@mantine/core';
import { useState } from 'react';
import { VisitCategory } from '../../../visits/api/visits.types';
import { VisitCompletionData } from '../../types/DashboardData';
import { FigureCard } from '../atoms/FigureCard';
import { ToggleVisitCategory } from '../atoms/ToggleVisitCategory';

type VisitStatusReportBarChartProps = {
	visits: VisitCompletionData[];
};
export const VisitStatusReportBarChart = ({
	visits,
}: VisitStatusReportBarChartProps) => {
	const [visitCategory, setVisitCategory] =
		useState<VisitCategory>('Participants');

	const filteredVisits = visits.filter((visit) =>
		visit.isForCaregiver
			? visitCategory === 'Aidants'
			: visitCategory === 'Participants',
	);

	return (
		<FigureCard title={'Taux de complétion des visites'}>
			<div className="flex flex-col gap-4">
				<ToggleVisitCategory
					visitCategory={visitCategory}
					setVisitCategory={setVisitCategory}
				/>
				<Box w="95%" mx="auto" h="fit">
					<BarChart
						data={filteredVisits}
						h={300}
						dataKey="visit"
						type="stacked"
						series={[
							{
								name: 'Participants',
								label: 'Participants actifs ayant complété la visite',
								color: 'var(--mantine-color-custom-green-6)',
							},
							{
								name: 'Dont figés',
								label: 'Participants figés ayant complété la visite',
								color: 'var(--mantine-color-custom-orange-6)',
							},
						]}
						tickLine="x"
						gridAxis="y"
						barProps={{
							barSize: 32,
						}}
						yAxisProps={{
							tickMargin: 10,
							tick: {
								fontSize: 'var(--mantine-font-size-sm)',
								fill: 'var(--mantine-color-gray-7)',
							},
							label: {
								value: 'Nombre de participants',
								position: 'insideBottomLeft',
								angle: -90,
								offset: 10,
							},
							allowDecimals: false,
						}}
						xAxisProps={{
							tickMargin: 10,
							tick: {
								fontSize: 'var(--mantine-font-size-sm)',
								fill: 'var(--mantine-color-gray-7)',
							},
							tickFormatter(value: string) {
								return value.length < 30 ? value : value.slice(0, 30) + '...';
							},
						}}
						withLegend
						legendProps={{ verticalAlign: 'bottom', align: 'center' }}
						style={{ overflow: 'auto' }}
					/>
				</Box>
			</div>
		</FigureCard>
	);
};
