import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import MyField from '@/components/ui/form/form-field';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/components/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { Checkbox, Fieldset } from '@mantine/core';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { formatDate } from '../../../../shared/utils/dates';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';
import { useUpdateParticipantContactInfo } from '../../api/participant-contact.hooks';
import { AddSecondaryFieldInfoButton } from '../atoms/AddSecondaryFieldInfoButton';
import { FieldInfo } from '../atoms/FieldInfo';
import {
	ParticipantPersonalInfoZodSchema,
	ParticipantPersonalInfoZodType,
} from '../forms/ParticipantPersonalInfoZodSchema';

export const ParticipantPersonalInfo = () => {
	const isMobile = useIsMobile();
	const { participant } = useCurrentParticipant();
	const { firstName, lastName, phoneNumber, email } = participant;
	const { birthDate, address, zipCode, city, isUnableToConsent, isVulnerable } =
		participant.participantProfile;

	const emailBis = participant.participantOnResearch.emailBis;
	const phoneNumberBis = participant.participantOnResearch.phoneNumberBis;

	const [showEmailBis, setShowEmailBis] = useState(Boolean(emailBis));
	const [showPhoneNumberBis, setShowPhoneNumberBis] = useState(
		Boolean(phoneNumberBis),
	);

	const { updateUserInfoMutation } = useUpdateParticipantContactInfo();

	const location = `${address}, ${zipCode} ${city}`;

	const methods = useForm<ParticipantPersonalInfoZodType>({
		resolver: zodResolver(ParticipantPersonalInfoZodSchema),
		defaultValues: {
			emailBis,
			phoneNumberBis,
		},
	});
	const {
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = methods;

	const handleFormSubmit = (data: ParticipantPersonalInfoZodType) => {
		updateUserInfoMutation.mutate(data);
	};

	return (
		<div className="flex gap-4 max-md:flex-col">
			<h5 className={cn(isMobile ? 'w-full' : 'w-1/4')}>
				Informations personnelles
			</h5>
			<div className="flex flex-1 flex-col">
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(handleFormSubmit)}>
						<Fieldset
							p="lg"
							styles={{
								root: {
									backgroundColor: 'white',
								},
							}}
						>
							<div className="flex flex-col gap-12">
								<div className="flex flex-col gap-4">
									<div className="flex grow gap-4 max-md:flex-col">
										<FieldInfo label="Prénom" value={firstName} />
										<FieldInfo label="Nom" value={lastName} />
									</div>

									<div className="flex items-start gap-4 max-md:flex-col md:items-center">
										<FieldInfo label="Téléphone" value={phoneNumber} />
										{showPhoneNumberBis ? (
											<div className="md:w-1/2">
												<MyField
													type="text"
													name="phoneNumberBis"
													label="Numéro de téléphone secondaire"
													placeholder={
														watch().phoneNumberBis && phoneNumberBis
															? phoneNumberBis
															: 'Numéro de téléphone secondaire'
													}
													error={errors.phoneNumberBis?.message}
													labelClass="text-muted-foreground"
													inputClass="text-black"
												/>
											</div>
										) : (
											<div className="md:w-1/2">
												<AddSecondaryFieldInfoButton
													label="Ajouter un numéro de téléphone secondaire"
													onClick={() => setShowPhoneNumberBis(true)}
												/>
											</div>
										)}
									</div>

									<FieldInfo
										label="Date de naissance"
										value={formatDate(birthDate, 'DD/MM/YYYY')}
									/>
									<FieldInfo label="Adresse postale" value={location} />

									<div className="flex items-start gap-4 max-md:flex-col md:items-center">
										<FieldInfo label="Adresse email" value={email} />
										{showEmailBis ? (
											<div className="md:w-1/2">
												<MyField
													type="text"
													name="emailBis"
													label="Adresse email secondaire"
													placeholder={
														watch().emailBis && emailBis
															? emailBis
															: 'Adresse email secondaire'
													}
													error={errors.emailBis?.message}
													labelClass="text-muted-foreground"
													inputClass="text-black"
												/>
											</div>
										) : (
											<div className="md:w-1/2">
												<AddSecondaryFieldInfoButton
													label="Ajouter une adresse mail secondaire"
													onClick={() => setShowEmailBis(true)}
												/>
											</div>
										)}
									</div>

									<Checkbox
										checked={isVulnerable}
										onChange={() => {}}
										label="Personne vulnérable"
										radius="xs"
										size="md"
										disabled
									/>
									<Checkbox
										checked={isUnableToConsent}
										onChange={() => {}}
										label="Personne dans l'incapacité physique de signer"
										radius="xs"
										size="md"
										disabled
									/>
								</div>

								<Separator />

								<div className="flex gap-4 max-md:flex-col">
									<Button
										onClick={() =>
											reset({
												emailBis,
												phoneNumberBis,
											})
										}
										variant="outline"
										className="w-full"
										type="button"
									>
										Annuler
									</Button>

									<Button
										type="submit"
										disabled={updateUserInfoMutation.isPending}
										className="w-full"
									>
										Enregistrer
									</Button>
								</div>
							</div>
						</Fieldset>
					</form>
				</FormProvider>
			</div>
		</div>
	);
};
