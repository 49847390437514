import { SegmentedControl } from '@mantine/core';
import { DayTimeScale } from '../../types/TimeScale';

export type ToggleXAxisTimeScaleProps = {
	timeScale: DayTimeScale;
	setTimeScale: (timeScale: DayTimeScale) => void;
};

export const ToggleXAxisTimeScale = ({
	timeScale,
	setTimeScale,
}: ToggleXAxisTimeScaleProps) => {
	return (
		<SegmentedControl
			data={[
				{ label: 'Semaine', value: 'week' },
				{ label: 'Mois', value: 'month' },
				{ label: 'Année', value: 'year' },
			]}
			value={timeScale}
			onChange={(value: string) => setTimeScale(value as DayTimeScale)}
			radius="md"
			w="fit-content"
		/>
	);
};
