import { isMatch } from '../../../shared/utils/filters';
import { Role } from '../../profile/api/types/user.types';
import { UserOnResearch } from '../../usersOnResearch/api/types/users-on-research.types';
import { Thread } from '../api/messages.types';

export const getThreadContact = (
	thread: Thread,
	userRole: Role,
): 'RESEARCH_CENTER' | UserOnResearch => {
	if (thread.participant && userRole !== 'PARTICIPANT')
		return thread.participant;
	if (thread.offsiteResearchMember && userRole !== 'OFFSITE_RESEARCH_MEMBER')
		return thread.offsiteResearchMember;
	return 'RESEARCH_CENTER';
};

export const filterBySearchValue = (
	threads: Thread[],
	userRole: Role,
	searchText: string,
) => {
	return threads.filter((thread) => {
		const contact = getThreadContact(thread, userRole);
		if (contact === 'RESEARCH_CENTER')
			return isMatch('Centre de recherche', searchText);

		return (
			isMatch(contact.firstName, searchText) ||
			isMatch(contact.lastName, searchText) ||
			(contact.participantOnResearch &&
				isMatch(contact.participantOnResearch.code, searchText)) ||
			(contact.investigatorOnResearch &&
				isMatch(contact.investigatorOnResearch.roleOnResearch, searchText)) ||
			(contact.onsiteResearchMemberOnResearch &&
				isMatch(
					contact.onsiteResearchMemberOnResearch.roleOnResearch,
					searchText,
				)) ||
			(contact.offsiteResearchMemberOnResearch &&
				isMatch(
					contact.offsiteResearchMemberOnResearch.roleOnResearch,
					searchText,
				)) ||
			isMatch(
				contact.offsiteResearchMemberOnResearch?.roleAdditionalInfo || '',
				searchText,
			)
		);
	});
};
