import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSession } from '../../../auth/shared/hooks/useSession';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { joinMeet } from '../meets.queries';
import { meetsQueryKeys } from '../meets.query-keys';
import { EyesonMeet } from '../meets.types';

export const useJoinMeetMutation = (meetId: number) => {
	const queryClient = useQueryClient();
	const { research } = useCurrentResearch();
	const { user } = useSession();
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const joinMeetMutation = useMutation({
		mutationFn: async () => joinMeet(meetId),
		onSuccess: (data: EyesonMeet) => {
			user &&
				queryClient.invalidateQueries({
					queryKey: meetsQueryKeys.listAllByUser(user.userId),
				});
			queryClient.invalidateQueries({
				queryKey: meetsQueryKeys.listByResearch(research.id),
			});
			window.open(data.joinUrl, '_blank');
		},
		onError: showErrorNotification,
	});

	return { joinMeetMutation };
};
