import { useQuery } from '@tanstack/react-query';
import { DashboardData } from '../types/DashboardData';
import { getDashboardData } from './dashbord.queries';
import { dashboardQueryKeys } from './dashbord.query-keys';
import { useCurrentResearch } from '../../researches/contexts/researches.context';

export const useGetDashboardData = () => {
	const { research } = useCurrentResearch();
	const {
		data: dashboardData,
		isLoading,
		error,
	} = useQuery<DashboardData>({
		queryKey: dashboardQueryKeys.detail(research.id),
		queryFn: () => getDashboardData(research.id),
	});

	return { dashboardData, isLoading, error };
};
