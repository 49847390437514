import { CaregiverBadge } from '../../../researches/components/ui/CaregiverBadge';
import { ParticipantOnResearch } from '../../api/types/participants.types';
import { UserOnResearch } from '../../api/types/users-on-research.types';
import { ResearchMemberCard } from '../cards/ResearchMemberCard';

export type Props = {
	participant: UserOnResearch & {
		participantOnResearch: ParticipantOnResearch;
	};
};
export function ParticipantCell({ participant }: Props) {
	const caredForByCode =
		participant.participantOnResearch.caredForBy &&
		participant.participantOnResearch.caredForBy.participantOnResearch.code;
	const caresForCode =
		participant.participantOnResearch.caresFor &&
		participant.participantOnResearch.caresFor.participantOnResearch.code;
	const hasCaregiver = caredForByCode || caresForCode;

	return (
		<div className="flex w-min items-center justify-start gap-4">
			<ResearchMemberCard user={participant} />
			{hasCaregiver && <CaregiverBadge participant={participant} />}
		</div>
	);
}
