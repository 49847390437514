import { ActionIcon } from '@mantine/core';
import { IconMessageCircle2Filled } from '@tabler/icons-react';

export const NewThreadItem = () => {
	return (
		<div className="flex min-h-[70px] items-center gap-2 bg-muted px-2 py-5">
			<ActionIcon size="40" radius="xl">
				<IconMessageCircle2Filled />
			</ActionIcon>
			<p className="font-semibold">Nouveau message</p>
		</div>
	);
};
