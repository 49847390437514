import { z } from 'zod';

export const RangeQuestionZodSchema = (maxRange: number) =>
	z
		.number({
			required_error: 'La réponse à cette question est requise',
		})
		.int()
		.min(0)
		.max(maxRange, {
			message: `La valeur doit être comprise entre 0 et ${maxRange}`,
		});
