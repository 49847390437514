import { ActionIcon } from '@mantine/core';
import { IconCalendarPlus } from '@tabler/icons-react';
import { atcb_action } from 'add-to-calendar-button';
import { formatDate } from '../../../../shared/utils/dates';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { ParticipantVisit } from '../../../visits/api/visits.types';

export type AddToCalendarButtonProps = {
	visit: ParticipantVisit;
};

export const AddToCalendarCustomButton = ({
	visit,
}: AddToCalendarButtonProps) => {
	const { research } = useCurrentResearch();

	const getLocation = () => {
		let location = `${visit?.address} - ${visit?.city} ${visit?.zipCode}`;
		if (visit?.addressDetails)
			location += `\nComplément d'adresse: ${visit?.addressDetails}`;
		return location;
	};

	return (
		<ActionIcon
			size="lg"
			variant="light"
			onClick={(e) => {
				if (visit.startDate && visit.endDate)
					atcb_action(
						{
							name: `[${research.name} - ${visit.name}] Participant ${visit.participant?.participantOnResearch?.code}`,
							description: visit.description,
							startDate: formatDate(visit.startDate, 'YYYY-MM-DD'),
							endDate: formatDate(visit.endDate, 'YYYY-MM-DD'),
							startTime: formatDate(visit.startDate, 'HH:mm'),
							endTime: formatDate(visit.endDate, 'HH:mm'),
							options: ['Apple', 'Google', 'Outlook.com', 'iCal'],
							iCalFileName: 'cosy-trials-invitation',
							location: getLocation(),
							language: 'fr',
							listStyle: 'modal',
							hideBackground: true,
							timeZone: 'Europe/Paris',
						},
						e?.currentTarget,
					);
			}}
		>
			<IconCalendarPlus />
		</ActionIcon>
	);
};
