import {
	IconCalendar,
	IconFile,
	IconMessage,
	IconNotification,
	IconUser,
	IconVideo,
	IconWritingSign,
} from '@tabler/icons-react';
import { NotificationCategoryType } from './api/notifications.types';

export const getNotificationIconAndTitle = (
	category: NotificationCategoryType,
) => {
	switch (category) {
		case 'SCHEDULE_VISIT_REMINDER':
		case 'VISIT_REMINDER':
		case 'NEW_VISIT_ALERT':
		case 'UPDATE_VISIT_ALERT':
			return {
				icon: <IconCalendar />,
				title: 'Visite',
			};
		case 'NEW_MEET_ALERT':
		case 'UPDATE_MEET_ALERT':
		case 'DELETE_MEET_ALERT':
			return {
				icon: <IconVideo />,
				title: 'Visioconférence',
			};
		case 'NEW_DOCUMENT_ALERT':
			return {
				icon: <IconFile />,
				title: 'Nouveau document',
			};
		case 'NEW_MESSAGE_ALERT':
			return {
				icon: <IconMessage />,
				title: 'Nouveau message',
			};
		case 'ADULT_REMINDER':
			return {
				icon: <IconUser />,
				title: 'Participant',
			};
		case 'NEW_CONSENT_ALERT':
		case 'SIGNED_CONSENT_ALERT':
		case 'EXPIRED_CONSENT_ALERT':
		case 'DECLINED_CONSENT_ALERT':
			return {
				icon: <IconWritingSign />,
				title: 'Consentement',
			};

		default:
			return {
				icon: <IconNotification />,
				title: 'Notification',
			};
	}
};
