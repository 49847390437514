import { useIsMobile } from '@/components/hooks/useIsMobile.ts';
import { Button } from '@/components/ui/button.tsx';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { useState } from 'react';
import { CreateResearchMemberDto } from '../../api/dto/research-members.dto.ts.ts';
import { useAddResearchMembers } from '../../api/hooks/research-members.hooks.tsx';
import { AddResearchMemberForm } from '../forms/AddResearchMemberForm.tsx';
import { ResearchMembersControlPanel } from '../inputs/ResearchMembersControlPanel.tsx';

type Props = {
	handleClose: () => void;
};
export function AddResearchMembersModal({ handleClose }: Props) {
	const [researchMembers, setResearchMembers] = useState<
		CreateResearchMemberDto[]
	>([]);
	const { addResearchMembersMutation, isLoading } =
		useAddResearchMembers(handleClose);
	const isMobile = useIsMobile();

	const submitForm = async () => {
		await addResearchMembersMutation(researchMembers);
		setResearchMembers([]);
		handleClose();
	};

	return (
		<Modal
			opened={true}
			onClose={() => {
				setResearchMembers([]);
				handleClose();
			}}
			fullScreen={isMobile}
			centered
			classNames={classes}
			title="Ajouter un ou plusieurs membres à cette recherche"
			size="xl"
		>
			<div className="flex flex-col gap-6">
				<AddResearchMemberForm
					setResearchMembers={setResearchMembers}
					researchMembers={researchMembers}
				/>
				<ResearchMembersControlPanel
					researchMembers={researchMembers}
					setResearchMembers={setResearchMembers}
				/>
			</div>

			<div className="flex gap-4">
				{!isMobile && (
					<Button
						onClick={() => {
							setResearchMembers([]);
							handleClose();
						}}
						variant="outline"
						className="w-full"
					>
						Annuler
					</Button>
				)}
				<Button
					onClick={submitForm}
					disabled={isLoading || researchMembers.length === 0}
					className="w-full"
				>
					Ajouter
				</Button>
			</div>
		</Modal>
	);
}
