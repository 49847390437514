import { useIsMobile } from '@/components/hooks/useIsMobile';
import { cn } from '@/components/utils';
import { useDisclosure } from '@mantine/hooks';
import { IconFileExport, IconHandStop, IconPencil } from '@tabler/icons-react';
import { useCurrentResearch } from '../../researches/contexts/researches.context';
import { useCurrentParticipant } from '../../usersOnResearch/contexts/participants.context';
import { AddConsentModal } from '../components/modals/AddConsentModal';
import { DeleteGlobalConsentModal } from '../components/modals/DeleteGlobalConsentModal';
import { ConsentTable } from '../components/tables/ConsentTable';
import { useExportConsents } from '../hooks/useExportConsents';
import { Button } from '@/components/ui/button';

export const ConsentTab = () => {
	const { participant } = useCurrentParticipant();
	const { userOnResearch } = useCurrentResearch();
	const isMobile = useIsMobile();

	const [
		addConsentModalOpened,
		{ open: openAddConsentModal, close: closeAddConsentModal },
	] = useDisclosure();
	const [
		deleteGlobalConsentModalOpened,
		{
			open: openDeleteGlobalConsentModal,
			close: closeDeleteGlobalConsentModal,
		},
	] = useDisclosure();

	const exportConsentsMutation = useExportConsents();

	const canRemoveGlobalConsent = () => {
		if (userOnResearch.status !== 'ACTIVE') return false;
		if (
			!['INVESTIGATOR', 'ONSITE_RESEARCH_MEMBER'].includes(userOnResearch.role)
		)
			return false;
		if (!participant || participant.status !== 'ACTIVE') return false;
		return true;
	};
	const canAddGlobalConsent = () => {
		if (userOnResearch.status !== 'ACTIVE') return false;
		if (
			!['INVESTIGATOR', 'ONSITE_RESEARCH_MEMBER'].includes(userOnResearch.role)
		)
			return false;
		if (participant && participant.status !== 'ACTIVE') return false;
		return true;
	};
	const canExportConsents =
		!participant &&
		['INVESTIGATOR', 'ONSITE_RESEARCH_MEMBER'].includes(userOnResearch.role);

	return (
		<>
			<div className="flex h-full w-full flex-col gap-4">
				<div className="flex flex-col justify-start gap-2 md:flex-row md:justify-between">
					<h4>Consentements</h4>
					<div className="flex items-end gap-2 max-lg:flex-col max-md:items-start">
						{canRemoveGlobalConsent() && (
							<Button
								size={isMobile ? 'xs' : 'sm'}
								className="w-fit"
								variant="outline"
								onClick={openDeleteGlobalConsentModal}
							>
								<div className="flex flex-row items-center gap-1">
									{
										<IconHandStop
											className={cn(isMobile ? 'h-4 w-4' : 'h-5 w-5')}
										/>
									}
									<p>Retirer le consentement</p>
								</div>
							</Button>
						)}
						{canAddGlobalConsent() && (
							<Button
								size={isMobile ? 'xs' : 'sm'}
								className="w-fit"
								onClick={openAddConsentModal}
							>
								<div className="flex flex-row items-center gap-1">
									{
										<IconPencil
											className={cn(isMobile ? 'h-4 w-4' : 'h-5 w-5')}
										/>
									}
									<p>Faire signer un consentement</p>
								</div>
							</Button>
						)}
						{canExportConsents && (
							<Button
								size={isMobile ? 'xs' : 'sm'}
								className="w-fit"
								variant="outline"
								onClick={() => exportConsentsMutation.mutate()}
								disabled={exportConsentsMutation.isPending}
							>
								<div className="flex flex-row items-center gap-1">
									{
										<IconFileExport
											className={cn(isMobile ? 'h-4 w-4' : 'h-5 w-5')}
										/>
									}
									<p>Exporter</p>
								</div>
							</Button>
						)}
					</div>
				</div>
				<ConsentTable />
			</div>
			{deleteGlobalConsentModalOpened && (
				<DeleteGlobalConsentModal handleClose={closeDeleteGlobalConsentModal} />
			)}
			{addConsentModalOpened && (
				<AddConsentModal handleClose={closeAddConsentModal} />
			)}
		</>
	);
};
