import {
	Question,
	Response as ResponseType,
} from '../../api/questionnaires.types';
import { MultipleSelectResponseCard } from './MultipleSelectResponseCard';
import { RangeResponseCard } from './RangeResponseCard';
import { SingleSelectResponseCard } from './SingleSelectResponseCard';
import { TextResponseCard } from './TextResponseCard';

type ResponseCardProps = {
	question: Question;
	response: ResponseType | ResponseType[] | undefined;
};

export function ResponseCard({ question, response }: ResponseCardProps) {
	if (!response) return null;

	if (question.type === 'TEXT' && !Array.isArray(response))
		return <TextResponseCard question={question} response={response} />;

	if (question.type === 'RANGE' && !Array.isArray(response))
		return <RangeResponseCard question={question} response={response} />;

	if (question.type === 'SINGLE_CHOICE' && !Array.isArray(response))
		return <SingleSelectResponseCard question={question} response={response} />;

	if (question.type === 'MULTIPLE_CHOICE' && Array.isArray(response))
		return (
			<MultipleSelectResponseCard question={question} responses={response} />
		);

	return null;
}
