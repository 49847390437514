import { CustomLoader } from '@/components/ui/loader';
import classes from '@/components/ui/modal/modal.module.scss';
import { useCurrentResearch } from '@/features/researches/contexts/researches.context';
import { isSameDay } from '@/shared/utils/dates';
import { Modal } from '@mantine/core';
import { useEffect, useMemo, useRef } from 'react';
import { useGetThread } from '../../../api/messages.hooks';
import { MessageItem } from '../../atoms/MessageItem';
import { MessageSeparator } from '../../atoms/MessageSeparator';
import { MobileMessageListHeader } from '../atoms/MobileMessageListHeader';
import { MobileSendNewMessageForm } from '../forms/MobileSendNewMessageForm';

type MobileMessageListProps = {
	threadId: number;
	resetCurrentThread: () => void;
};

export const MobileMessageList = (props: MobileMessageListProps) => {
	const { userOnResearch: user } = useCurrentResearch();
	const { thread, isLoading, error } = useGetThread(props.threadId ?? 0);

	const containerRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (!containerRef.current) return;
		containerRef.current.scroll({
			top: containerRef.current.scrollHeight,
			left: 0,
		});
	}, [thread, containerRef]);

	const formattedMessages = useMemo(() => {
		if (!thread) return null;
		let previousDate: Date;

		const messages = thread?.messages;
		if (!messages) return null;

		return messages.map((message, index) => {
			const currentDate = new Date(message.createdAt);
			if (index > 0) previousDate = new Date(messages[index - 1].createdAt);
			const isNewDay = index === 0 || !isSameDay(previousDate, currentDate);
			return (
				<div key={message.id} className="flex flex-col gap-4">
					{isNewDay && <MessageSeparator date={currentDate} />}
					{
						<MessageItem
							message={message}
							sent={message.emitter.userId === user?.userId}
						/>
					}
				</div>
			);
		});
	}, [thread, user.userId]);

	return (
		<Modal
			fullScreen
			opened
			classNames={classes}
			withCloseButton={false}
			onClose={props.resetCurrentThread}
			styles={{
				content: { padding: 0, margin: 0 },
				body: { padding: 0, margin: 0 },
			}}
		>
			{error && (
				<p className="h-full w-full bg-white text-sm text-error-500">
					Erreur lors de la récupération de la conversation.
				</p>
			)}
			{isLoading && <CustomLoader additionalClasses="bg-white" />}
			{
				<div className="flex h-full w-full flex-col gap-4 bg-white pb-4">
					{thread && (
						<MobileMessageListHeader
							thread={thread}
							handleClose={props.resetCurrentThread}
						/>
					)}
					<div
						className="flex w-full flex-1 flex-col gap-4 overflow-y-auto px-2"
						ref={containerRef}
					>
						{formattedMessages ?? (
							<p className="text-sm text-muted-foreground">
								Aucun message pour le moment
							</p>
						)}
					</div>
					<MobileSendNewMessageForm threadId={props.threadId} />
				</div>
			}
		</Modal>
	);
};
