import { Badge } from '@/components/ui/badge';
import { ConsentStatus, ConsentStatusEnum } from '../../api/consents.entities';
import { getConsentStatusBadgeColor } from '../../consent.utils';

export type ConsentStatusBadgeProps = {
	status: ConsentStatus;
};

export const ConsentStatusBadge = ({ status }: ConsentStatusBadgeProps) => {
	const variant = getConsentStatusBadgeColor(status);

	return <Badge variant={variant}>{ConsentStatusEnum[status]}</Badge>;
};
