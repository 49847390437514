export const InvestigatorRoleEnum = {
	MAIN_INVESTIGATOR: 'Investigateur principal',
	CO_INVESTIGATOR: 'Co-investigateur',
};

export type InvestigatorRole = keyof typeof InvestigatorRoleEnum;

export type InvestigatorOnResearch = {
	roleOnResearch: InvestigatorRole;
};

export const OnsiteResearchMemberRoleEnum = {
	RESEARCH_NURSE: 'Infirmier(e) de recherche',
	ARC: 'ARC de site ou TEC',
	ADVANCED_PRACTICE_NURSE: 'Infirmier(e) en pratique avancée',
	COORDINATING_NURSE: 'Infirmier(e) de coordination',
	PHARMACIST: 'Pharmacien',
};

export type OnsiteResearchMemberRole =
	keyof typeof OnsiteResearchMemberRoleEnum;

export const OnsiteResearchMemberRoleArray = Object.entries(
	OnsiteResearchMemberRoleEnum,
).map(([key, value]) => ({
	[key]: value,
}));

export type OnsiteResearchMemberOnResearch = {
	roleOnResearch: OnsiteResearchMemberRole;
};

export const OffsiteResearchMemberRoleEnum = {
	NURSE: 'Infirmier(e)',
	DOCTOR: 'Médecin',
	PHARMACIST: "Pharmacie d'officine",
	RESEARCH_COORDINATOR: "Coordinateur d'étude",
	OTHER: 'Autre',
};

export type OffsiteResearchMemberRole =
	keyof typeof OffsiteResearchMemberRoleEnum;

export type OffsiteResearchMemberOnResearch = {
	roleOnResearch: OffsiteResearchMemberRole;
	roleAdditionalInfo?: string;
};
