import { useIsMobile } from '@/components/hooks/useIsMobile';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { ParticipantVisit } from '../../api/visits.types';
import { ScheduleVisitForm } from './ScheduleVisitForm';

export type ScheduleVisitModalProps = {
	visit: ParticipantVisit;
	handleClose: () => void;
};

export const ScheduleVisitModal = ({
	visit,
	handleClose: onClose,
}: ScheduleVisitModalProps) => {
	const isMobile = useIsMobile();

	return (
		<Modal
			opened={true}
			onClose={onClose}
			centered
			classNames={classes}
			onClick={(e) => e.stopPropagation()}
			fullScreen={isMobile}
			title={'Planifier la visite'}
			size="xl"
		>
			<ScheduleVisitForm visit={visit} handleClose={onClose} />
		</Modal>
	);
};
