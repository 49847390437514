import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { getZip } from '../documents.queries';

export const useDownloadDocumentsAsZip = () => {
	const { research } = useCurrentResearch();
	const handleDownload = (blobData: Blob, fileName: string) => {
		const blobUrl = URL.createObjectURL(blobData);
		const downloadLink = document.createElement('a');
		downloadLink.href = blobUrl;
		downloadLink.download = fileName;
		downloadLink.click();
		URL.revokeObjectURL(blobUrl);
	};
	const showSuccessNotification = () => {
		notifications.show({
			message: 'Documents téléchargés',
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const downloadDocumentsMutation = useMutation({
		mutationFn: async (ids: number[]) => getZip(ids),
		onSuccess: (blobData) => {
			handleDownload(
				blobData,
				`${research.name}-${new Date().toLocaleDateString()}-documents.zip`,
			);
			showSuccessNotification();
		},
		onError: showErrorNotification,
	});

	return { downloadDocumentsMutation };
};
