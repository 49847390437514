import { useDragScroll } from '@/components/hooks/useDragScroll';
import { Tabs } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './tabs.module.scss';

type MyTabsProps = {
	tabItems: {
		link: string;
		label: string;
	}[];
};

export const MyTabsWithScroll = ({ tabItems }: MyTabsProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [tab, setTab] = useState<string>(tabItems[0].link);
	const dragRef = useDragScroll();
	const [areTabsOverflowing, setAreTabsOverflowing] = useState(false);

	const handleScroll = (direction: 'left' | 'right') => {
		if (!dragRef.current) return;
		const scrollValue = direction === 'left' ? -100 : 100;
		dragRef.current.scrollTo({
			left: dragRef.current.scrollLeft + scrollValue,
			behavior: 'smooth',
		});
	};
	const handleTabChange = (value: string) => {
		setTab(value);
		const path = location.pathname.split('/');
		path[path.length - 1] = value;
		const newPath = path.join('/');
		navigate(newPath);
	};

	useEffect(() => {
		let resizeObserver: ResizeObserver;
		const contentElement = dragRef.current;

		const checkOverflow = (observerElement: HTMLDivElement) => {
			resizeObserver = new ResizeObserver((entries) => {
				const element = entries[0].target;
				setAreTabsOverflowing(element.scrollWidth > element.clientWidth);
			});

			if (observerElement) resizeObserver.observe(observerElement);
		};

		if (contentElement) {
			checkOverflow(contentElement);
		}

		return () => {
			if (resizeObserver && contentElement) {
				resizeObserver.unobserve(contentElement);
			}
		};
	}, [areTabsOverflowing, dragRef]);

	useEffect(() => {
		const path = location.pathname.split('/');
		const currentTab = path[path.length - 1];

		if (tabItems.map((item) => item.link).includes(currentTab)) {
			setTab(currentTab);
		}
	}, [location.pathname, tabItems]);

	return (
		<div className="flex w-full items-center justify-between gap-1">
			{areTabsOverflowing && (
				<IconChevronLeft
					onClick={() => handleScroll('left')}
					className="cursor-pointer justify-self-start p-0 text-primary"
				/>
			)}
			<div className="w-[90%]">
				<Tabs
					classNames={classes}
					value={tab}
					onChange={(value) => {
						value && handleTabChange(value);
					}}
					ref={dragRef}
				>
					<Tabs.List grow>
						{tabItems.map((item, index) => (
							<Tabs.Tab key={index} value={item.link} className={classes.tab}>
								{item.label}
							</Tabs.Tab>
						))}
					</Tabs.List>
				</Tabs>
			</div>
			{areTabsOverflowing && (
				<IconChevronRight
					onClick={() => handleScroll('right')}
					className="cursor-pointer justify-self-end p-0 text-primary"
				/>
			)}
		</div>
	);
};
