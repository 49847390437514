import { Badge } from '@/components/ui/badge';
import { MeetStatusEnum, MeetStatusType } from '../../api/meets.types';

type MeetStatusBadgeProps = {
	status: MeetStatusType;
};

export function MeetStatusBadge({ status }: MeetStatusBadgeProps) {
	const variant =
		status === 'UPCOMING' ? 'success' : status === 'ENDED' ? 'info' : 'warning';

	return <Badge variant={variant}>{MeetStatusEnum[status]}</Badge>;
}
