import { Badge } from '@/components/ui/badge';
import {
	DocumentCustomType,
	DocumentTypeEnum,
} from '../../api/documents.types';

export type DocumentTypeBadgeProps = {
	type: DocumentCustomType;
};

export const DocumentTypeBadge = ({ type }: DocumentTypeBadgeProps) => {
	const getBadgeVariant = () => {
		switch (type) {
			case 'RESEARCH_DOCUMENT':
				return 'blue';
			case 'TEMPLATE':
				return 'green';
			case 'ORGANIZATION':
				return 'yellow';
			case 'PARTICIPANT_DOCUMENT':
				return 'red';
			default:
				return 'info';
		}
	};
	return <Badge variant={getBadgeVariant()}>{DocumentTypeEnum[type]}</Badge>;
};
