import { Button } from '@/components/ui/button';
import { UserOnResearch } from '@/features/usersOnResearch/api/types/users-on-research.types';
import { zodResolver } from '@hookform/resolvers/zod';
import { Textarea } from '@mantine/core';
import { useForm } from 'react-hook-form';
import { useSendMessage } from '../../api/messages.hooks';
import { MAX_MESSAGE_LENGTH } from '../../utils/constants';
import { CreateMessageZodSchema } from './validators/CreateMessageValidator';

type SendNewMessageFormProps = {
	threadId?: number;
	recipients?: UserOnResearch[];
	onMessageSent?: () => void;
};

export const SendNewMessageForm = ({
	threadId,
	recipients,
	onMessageSent: handleMessageSent,
}: SendNewMessageFormProps) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<{ content: string }>({
		resolver: zodResolver(CreateMessageZodSchema),
	});

	const onMessageSent = () => {
		reset();
		handleMessageSent && handleMessageSent();
	};
	const { sendMessageMutation } = useSendMessage(
		onMessageSent,
		threadId,
		recipients,
	);

	return (
		<div className="mx-6 mb-4">
			<form
				onSubmit={handleSubmit((data) =>
					sendMessageMutation.mutate(data.content),
				)}
			>
				<Textarea
					placeholder={`Envoyer un message (${MAX_MESSAGE_LENGTH} caractères maximum)`}
					rows={3}
					radius="sm"
					{...register('content')}
					error={errors.content?.message}
					rightSection={
						<Button size="sm" className="mb-2 mr-4 self-end px-6" type="submit">
							Envoyer
						</Button>
					}
					rightSectionWidth={100}
				/>
			</form>
		</div>
	);
};
