import { useIsMobile } from '@/components/hooks/useIsMobile';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal, TypographyStylesProvider } from '@mantine/core';
import DOMPurify from 'dompurify';
import { CustomLoader } from '../../../../components/ui/loader';
import { useGetNote } from '../../api/hooks/useGetNote';

export type Props = {
	noteId: number;
	handleClose: () => void;
};

export const ViewNoteModal = ({ noteId, handleClose }: Props) => {
	const isMobile = useIsMobile();
	const { note, isLoading, error } = useGetNote(noteId);

	const cleanedHtml = DOMPurify.sanitize(note?.htmlContent ?? '');

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			fullScreen={isMobile}
			title={note?.title}
			size="xl"
			overlayProps={{
				backgroundOpacity: 0.7,
				blur: 2,
			}}
		>
			<div className="flex flex-col gap-6">
				{error && (
					<p className="text-error-00 text-center text-sm">
						Une erreur est survenue lors du chargement de la note
					</p>
				)}
				{isLoading && <CustomLoader />}
				{!error && note && (
					<TypographyStylesProvider>
						<div dangerouslySetInnerHTML={{ __html: cleanedHtml }} />
					</TypographyStylesProvider>
				)}
			</div>
		</Modal>
	);
};
