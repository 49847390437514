import { Button } from '@/components/ui/button';
import { cn } from '@/components/utils';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { useCurrentResearch } from '../../researches/contexts/researches.context';
import { useCurrentParticipant } from '../../usersOnResearch/contexts/participants.context';
import { AddMeetModal } from '../components/modals/AddMeetModal';
import { MeetsTable } from '../components/tables/MeetsTable';
import { useIsMobile } from '@/components/hooks/useIsMobile';

export function MeetsTab() {
	const isMobile = useIsMobile();
	const [opened, { open, close }] = useDisclosure();
	const { userOnResearch } = useCurrentResearch();
	const { participant } = useCurrentParticipant();

	const canCreateMeet =
		!participant &&
		userOnResearch.status === 'ACTIVE' &&
		['INVESTIGATOR', 'ONSITE_RESEARCH_MEMBER'].includes(userOnResearch.role);

	return (
		<>
			<div className="flex h-full w-full flex-col gap-4">
				<div className="flex flex-col justify-start gap-2 md:flex-row md:justify-between">
					<h4>Visioconférences</h4>
					{canCreateMeet && (
						<Button
							size={isMobile ? 'xs' : 'sm'}
							className="w-fit"
							onClick={open}
						>
							<div className="flex flex-row items-center gap-1">
								{<IconPlus className={cn(isMobile ? 'h-4 w-4' : 'h-5 w-5')} />}
								<p>Créer une visioconférence</p>
							</div>
						</Button>
					)}
				</div>
				<MeetsTable />
			</div>
			{opened && <AddMeetModal handleClose={close} />}
		</>
	);
}
