export const visitsQueryKeys = {
	all: ['visits'] as const,
	byResearch: (researchId: number) =>
		[...visitsQueryKeys.all, 'research', researchId] as const,
	results: (researchId: number) => {
		return [...visitsQueryKeys.byResearch(researchId), 'results'] as const;
	},
	resultsByParticipant: (researchId: number, participantId: number) =>
		[
			...visitsQueryKeys.results(researchId),
			'participant',
			participantId,
		] as const,
};
