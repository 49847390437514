import { useQuery } from '@tanstack/react-query';
import { getNoteContent } from '../notes.queries';
import { notesQueryKeys } from '../notes.query-keys';

export const useGetNote = (noteId: number) => {
	const {
		data: note,
		isLoading,
		error,
	} = useQuery({
		queryKey: notesQueryKeys.detail(noteId),
		queryFn: async () => getNoteContent(noteId),
		enabled: !!noteId,
	});

	return { note, isLoading, error };
};
