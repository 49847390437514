import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useInvalidateQueries } from '../../../../../shared/hooks/useInvalidateQueries';
import { handleExportData } from '../../../../../shared/utils/files';
import { dashboardQueryKeys } from '../../../../dashboard/api/dashbord.query-keys';
import { useCurrentResearch } from '../../../../researches/contexts/researches.context';
import { visitsQueryKeys } from '../../../api/visits.query-keys';
import { actionsQueryKeys } from '../../api/actions.query-keys';
import { exportQuestionnaireResults } from '../utils/exportQuestionnaireResults';
import { ResponseDto } from './questionnaires.dto';
import {
	resetQuestionnaire,
	submitQuestionnaire,
} from './questionnaires.mutations';
import {
	getQuestionnairePreview,
	getQuestionnaireResponse,
} from './questionnaires.queries';

export const useGetQuestionnaireResponse = (
	actionId: number,
	userId: number,
) => {
	const {
		data: questionnaire,
		error,
		isLoading,
	} = useQuery({
		queryKey: actionsQueryKeys.result(actionId, userId),
		queryFn: () => getQuestionnaireResponse(actionId, userId),
	});

	return { questionnaire, error, isLoading };
};

export const useGetQuestionnairePreview = (actionId: number) => {
	const {
		data: questionnaire,
		error,
		isLoading,
	} = useQuery({
		queryKey: actionsQueryKeys.detail(actionId),
		queryFn: () => getQuestionnairePreview(actionId),
	});

	return { questionnaire, error, isLoading };
};

export const useExportQuestionnaireResponse = (
	actionId: number,
	exportTitle: string,
) => {
	const exportResultsMutation = useMutation({
		mutationFn: async () => getQuestionnaireResponse(actionId),
		onSuccess: (data) => {
			const result = exportQuestionnaireResults(data);
			handleExportData(result, exportTitle);
			notifications.show({
				message: 'Résultats des tableaux exportés avec succès',
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
		},
		onError: () => {
			notifications.show({
				message: `Une erreur est survenue`,
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		},
	});

	return { exportResultsMutation };
};

export const useResetQuestionnaire = (
	actionId: number,
	userId: number,
	successCallback?: () => void,
) => {
	const invalidateQueries = useInvalidateQueries();
	const { research } = useCurrentResearch();

	const resetResultsMutation = useMutation({
		mutationFn: async () => resetQuestionnaire(actionId, userId),
		onSuccess: () => {
			invalidateQueries([
				dashboardQueryKeys.detail(research.id),
				visitsQueryKeys.byResearch(research.id),
				actionsQueryKeys.results(actionId),
			]);
			notifications.show({
				message: 'Questionnaire réinitialisé avec succès',
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
			successCallback?.();
		},
		onError: () => {
			notifications.show({
				message: `Une erreur est survenue`,
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		},
	});

	return { resetResultsMutation };
};

export const useSendQuestionnaireResponse = (
	actionId: number,
	userId: number,
	successCallback: () => void,
) => {
	const { research } = useCurrentResearch();
	const invalidateQueries = useInvalidateQueries();

	const sendResultsMutation = useMutation({
		mutationFn: async (responses: ResponseDto[]) =>
			submitQuestionnaire(actionId, userId, responses),
		onSuccess: () => {
			notifications.show({
				message: 'Questionnaire rempli avec succès',
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
			invalidateQueries([
				dashboardQueryKeys.detail(research.id),
				visitsQueryKeys.byResearch(research.id),
				actionsQueryKeys.results(actionId),
			]);
			successCallback();
		},
		onError: () => {
			notifications.show({
				message: `Une erreur est survenue`,
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		},
	});

	return { sendResultsMutation };
};
