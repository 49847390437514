import { AxiosError } from 'axios';
import { privateAxios } from '../../../../../shared/services/privateAxios';
import { DynamicTableCellDto } from './dynamic-tables.dto';

export const resetDynamicTableData = async (
	actionId: number,
	participantId: number,
) => {
	try {
		const { data } = await privateAxios.delete(
			`visits/action/${actionId}/participant/${participantId}/reset-table-data`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const sendDynamicTableData = async (
	actionId: number,
	participantId: number,
	cells: DynamicTableCellDto[],
) => {
	try {
		const { data } = await privateAxios.post(
			`/visits/action/${actionId}/participant/${participantId}/table-data`,
			{
				cells,
			},
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
