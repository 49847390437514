import { z } from 'zod';
import { emailValidator } from '../../../../../../shared/components/validators/commonValidators';

export type RequestPasswordResetZodType = z.infer<
	typeof RequestPasswordResetZodSchema
>;

export const RequestPasswordResetZodSchema = z.object({
	email: emailValidator,
});
