import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { useDeleteMeetMutation } from '../../api/hooks/useDeleteMeet';

type Props = {
	meetId: number;
	handleClose: () => void;
};
export function DeleteMeetModal({ meetId, handleClose }: Props) {
	const { deleteMeetMutation } = useDeleteMeetMutation(meetId);
	const isMobile = useIsMobile();

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			title="Supprimer la visioconférence"
			size="lg"
			fullScreen={isMobile}
		>
			<div className="flex flex-col gap-6">
				<p className="text-center">
					Êtes-vous sûr(e) de vouloir supprimer cette visioconférence ?
				</p>
				<div className="flex gap-4">
					{!isMobile && (
						<Button onClick={handleClose} variant="outline" className="w-full">
							Annuler
						</Button>
					)}
					<Button
						disabled={deleteMeetMutation.isPending}
						className="w-full"
						variant="destructive"
						onClick={() => {
							deleteMeetMutation.mutate();
							handleClose();
						}}
					>
						Supprimer
					</Button>
				</div>
			</div>
		</Modal>
	);
}
