import { SegmentedControl } from '@mantine/core';
import classes from '../../../../shared/styles/SegmentedControl.module.scss';

export type ToggleCalendarViewProps = {
	view: string | undefined;
	setCalendarView: ((calendarView: string) => void) | undefined;
};

export const ToggleCalendarView = ({
	view,
	setCalendarView,
}: ToggleCalendarViewProps) => {
	return (
		<SegmentedControl
			data={[
				{ label: 'Semaine', value: 'timeGridWeek' },
				{ label: 'Mois', value: 'dayGridMonth' },
				{ label: 'Journée', value: 'listWeek' },
			]}
			value={view}
			onChange={setCalendarView}
			radius="md"
			w="fit-content"
			classNames={classes}
		/>
	);
};
