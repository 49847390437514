import { AxiosError } from 'axios';
import { publicAxios } from '../../../../shared/services/publicAxios';
import { SignInDto } from './dto/SignInDto';

export const sendSignInOtpQuery = async (body: SignInDto) => {
	try {
		const response = await publicAxios.post(`/auth/login`, body);
		return response.data;
	} catch (error) {
		throw error as AxiosError;
	}
};
