import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { UserProfile } from './types/user.types';

export const getUserInfo = async (): Promise<UserProfile> => {
	try {
		const { data } = await privateAxios.get<UserProfile>(`/users/me`);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
