import { UploadActionItem } from '../action-documents/components/UploadActionItem';
import { Action, ActionWithResult } from '../api/actions.types';
import { DynamicTableActionItem } from '../dynamic-tables/components/DynamicTableActionItem';
import { MiscellaneousActionItem } from '../miscellaneous/components/MiscellaneousActionItem';
import { QuestionnaireActionItem } from '../questionnaires/components/QuestionnaireActionItem';

type VisitActionItemProps = {
	action: Action | ActionWithResult;
};

export const VisitActionItem = ({ action }: VisitActionItemProps) => {
	switch (action.actionType) {
		case 'QUESTIONNAIRE':
			return <QuestionnaireActionItem action={action} />;
		case 'DOCUMENT':
			return <UploadActionItem action={action} />;
		case 'TABLE':
			return <DynamicTableActionItem action={action} />;
		default:
			return <MiscellaneousActionItem action={action} />;
	}
};
