import { UserOnResearch } from '../../usersOnResearch/api/types/users-on-research.types';

export const ConsentStatusEnum = {
	DRAFT: 'Brouillon',
	ONGOING: 'Envoyé',
	DONE: 'Signé',
	DECLINED: 'Refusé',
	EXPIRED: 'Expiré',
};

export type ConsentStatus = keyof typeof ConsentStatusEnum;

export enum SignerRoleEnum {
	PARTICIPANT = 'Participant',
	PARENT1 = 'Parent 1',
	PARENT2 = 'Parent 2',
	LEGAL_REPRESENTATIVE = 'Représentant légal',
	GUARDIAN = 'Tuteur',
	CUSTODIAN = 'Curateur',
	TRUSTED_PERSON = 'Personne de confiance',
	FAMILY_MEMBER = 'Membre de la famille',
	RELATIVE = 'Proche',
	WITNESS = 'Témoin impartial',
}

export type SignerRole = keyof typeof SignerRoleEnum;

export type Signers = {
	role: SignerRole;
};

export type Consent = {
	consentId: number;
	fileName: string;
	s3Key: string;
	status: ConsentStatus;
	participant: UserOnResearch;
	signers: Signers[];
	expiresAt: Date;
	createdAt: Date;
	updatedAt: Date;
};
