import { Button } from '@/components/ui/button';
import { MyPasswordField } from '@/components/ui/form/password-form-field';
import { zodResolver } from '@hookform/resolvers/zod';
import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { confirmPasswordQuery } from '../../../../auth/shared/queries/confirmPasswordQuery';
import {
	ConfirmPasswordZodSchema,
	ConfirmPasswordZodType,
} from './ConfirmPasswordZodSchema';

type ConfirmPasswordFormProps = {
	onPasswordConfirmed: () => void;
	onClose?: () => void;
};

export function ConfirmPasswordForm({
	onPasswordConfirmed: handleConfirmPassword,
	onClose: handleClose,
}: ConfirmPasswordFormProps) {
	const [isLoading, setIsLoading] = useState(false);
	const methods = useForm<ConfirmPasswordZodType>({
		resolver: zodResolver(ConfirmPasswordZodSchema),
	});
	const {
		handleSubmit,
		watch,
		formState: { errors },
	} = methods;
	const confirmPasswordMutation = useMutation({
		mutationFn: async (payload: ConfirmPasswordZodType) =>
			confirmPasswordQuery(payload.password),
		onSuccess: () => {
			handleClose?.();
			handleConfirmPassword();
		},
		onError: () =>
			notifications.show({
				message: `Une erreur est survenue lors de la confirmation du mot de passe, veuillez réessayer.`,
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			}),
		onSettled: () => setIsLoading(false),
	});

	const submitForm = (data: ConfirmPasswordZodType) => {
		setIsLoading(true);
		confirmPasswordMutation.mutate(data);
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className="flex flex-col gap-6">
					<MyPasswordField
						name="password"
						label="Mot de passe"
						placeholder="Mot de passe"
						error={errors.password?.message}
						labelClass="hidden"
						inputClass="text-black"
						additionalClass="space-y-0"
					/>

					<div className="flex gap-4">
						<Button onClick={handleClose} variant="outline" className="w-full">
							Annuler
						</Button>
						<Button
							type="submit"
							disabled={isLoading || !watch().password}
							className="w-full"
						>
							Confirmer
						</Button>
					</div>
				</div>
			</form>
		</FormProvider>
	);
}
