import { Button } from '@/components/ui/button';
import { notifications } from '@mantine/notifications';
import { IconArrowLeft, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { paths } from '../../../../routes/paths';
import { ResetPasswordForm } from '../components/forms/ResetPasswordForm';
import { ResetPasswordZodType } from '../components/forms/validators/ResetPasswordValidator';
import { ResetPasswordDto } from '../queries/dto/ResetPasswordDto';
import { resetPasswordQuery } from '../queries/resetPasswordQuery';

export function ResetPasswordPage() {
	const navigate = useNavigate();
	const { key } = useParams();
	const mutation = useMutation({
		mutationFn: async (payload: ResetPasswordDto) => {
			return await resetPasswordQuery(key ?? '', payload);
		},
		onSuccess: async () => {
			navigate(`../${paths.RESET_PASSWORD_SUCCESS_PATH}`, { replace: true });
		},
		onError: (error: AxiosError) => {
			if (error.response?.status.toString().startsWith('4')) return error;
			notifications.show({
				message:
					'Une erreur est survenue lors de la réinitialisation du mot de passe. Veuillez réessayer.',
				color: 'red',
				icon: <IconX style={{ width: '20rem', height: '20rem' }} />,
			});
		},
	});

	const handleSubmit = (data: ResetPasswordZodType) => {
		mutation.mutate(data);
	};

	return (
		<div className="flex max-w-xl flex-col justify-center gap-12">
			<div className="flex flex-col gap-3">
				<h1 className="text-center">Nouveau mot de passe</h1>
				<p className="text-center text-md font-normal text-muted-foreground">
					Votre nouveau mot de passe doit être différent du précédent mot de
					passe.
				</p>
			</div>

			<div className="flex flex-col items-center gap-4">
				<ResetPasswordForm
					onSubmit={handleSubmit}
					isLoading={mutation.isPending}
				/>
				{mutation.isError && mutation.error.response?.status === 400 && (
					<p className="text-center text-sm text-error-500">
						Le nouveau mot de passe doit être différent du précédent
					</p>
				)}
				{mutation.isError && mutation.error.response?.status === 401 && (
					<p className="text-center text-sm text-error-500">
						Le lien de réinitialisation a expiré
					</p>
				)}
			</div>

			<Button
				variant="link"
				size={'sm'}
				onClick={() => navigate(paths.SIGNIN_PATH)}
				className="flex justify-center"
			>
				<div className="flex space-x-2">
					<IconArrowLeft />
					<p>Retour à la connexion</p>
				</div>
			</Button>
		</div>
	);
}
