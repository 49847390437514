import { useIsMobile } from '@/components/hooks/useIsMobile';
import { cn } from '@/components/utils';
import { useUpdatePassword } from '../api/profile.hooks';
import { ChangePasswordForm } from '../components/forms/ChangePasswordForm';
import { ChangePasswordZodType } from '../components/forms/validators/ChangePasswordValidator';

export function LoginInformation() {
	const isMobile = useIsMobile();
	const { updatePasswordMutation } = useUpdatePassword();

	const handleSubmit = (data: ChangePasswordZodType) => {
		updatePasswordMutation.mutate(data);
	};

	return (
		<div className="flex gap-4 max-md:flex-col">
			<h5 className={cn(isMobile ? 'w-full' : 'w-1/4')}>Mot de passe</h5>
			<div className="flex flex-1">
				<ChangePasswordForm
					onSubmit={handleSubmit}
					isLoading={updatePasswordMutation.isPending}
				/>
			</div>
		</div>
	);
}
