import { z } from 'zod';
import { emailValidator } from '../../../../../shared/components/validators/commonValidators';

export type AddParticipantZodType = z.infer<typeof AddParticipantZodSchema>;

export const AddParticipantZodSchema = z.object({
	email: emailValidator,
	code: z.string().min(1, {
		message: "L'identifiant pour la recherche ne peut pas être vide",
	}),
});
