import classes from '@/components/ui/form/mantine-select-field.module.scss';
import { ComboboxItem, OptionsFilter, Select } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import React from 'react';
import { UserOnResearch } from '../../../features/usersOnResearch/api/types/users-on-research.types';
import { getResearchMemberInfo } from '../../../features/usersOnResearch/utils/researchMemberInfo';

type MultiSelectUsersOnResearchProps = {
	selectedUsers: UserOnResearch[];
	setSelectedUsers: React.Dispatch<React.SetStateAction<UserOnResearch[]>>;
	allUsers: UserOnResearch[];
	label?: string;
};
export function MultiSelectUsersOnResearch({
	selectedUsers,
	setSelectedUsers,
	allUsers,
	label,
}: MultiSelectUsersOnResearchProps) {
	const handleChange = (email: string) => {
		const user = allUsers.find((v) => v.email === email);
		const isAlreadyAdded = !!selectedUsers?.find(
			(v) => v.userOnResearchId === user?.userOnResearchId,
		);
		if (!user || isAlreadyAdded) return;

		setSelectedUsers((selectedUsers) => [...selectedUsers, user]);
	};

	const filterOptions: OptionsFilter = ({ options, search }) => {
		const filtered = (options as ComboboxItem[]).filter((option) =>
			option.label.toLowerCase().includes(search.toLowerCase()),
		);

		filtered.sort((a, b) => {
			if (a.disabled !== b.disabled) return a.disabled ? 1 : -1;
			return a.label.localeCompare(b.label);
		});
		return filtered;
	};

	const getLabel = (user: UserOnResearch) => {
		const { displayTitle, displayRole } = getResearchMemberInfo(user);
		return displayRole ? `${displayTitle} (${displayRole})` : displayTitle;
	};

	return (
		<Select
			label={label ?? null}
			withCheckIcon={false}
			classNames={classes}
			searchable
			leftSection={<IconSearch className="h-5 w-5" />}
			size="md"
			filter={filterOptions}
			maxDropdownHeight={200}
			data={
				allUsers &&
				allUsers.map((user) => ({
					label: getLabel(user),
					value: user.email,
					disabled: !!selectedUsers?.some(
						(v) => v.userOnResearchId === user.userOnResearchId,
					),
				}))
			}
			onChange={(value) => handleChange(value || '')}
		/>
	);
}
