import { Badge } from '@/components/ui/badge';
import { cn } from '@/components/utils';
import { Group, Indicator, Stack, Text } from '@mantine/core';
import { formatDate } from '../../../shared/utils/dates';
import { CalendarEvent, CalendarView } from './calendar.utils';

type EventContentProps = {
	event: CalendarEvent;
	view: CalendarView;
	el?: HTMLElement;
};

export const renderEventContent = ({ event, view }: EventContentProps) => {
	const { isRemote, participantCode } = event.extendedProps;

	if (view.type === 'dayGridMonth')
		return (
			<Group
				gap="xs"
				justify="flex-start"
				ml="sm"
				preventGrowOverflow={true}
				w="100%"
				style={{
					cursor: 'pointer',
				}}
			>
				<Indicator color={isRemote ? 'blue' : 'green'} />
				{event.start && (
					<Stack gap={0} maw="85%" align="left">
						<Text
							fw={600}
							size="sm"
							style={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{formatDate(event.start, 'HH:mm')} - {event.title}
						</Text>
						<Text
							size="xs"
							style={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							Part. : {participantCode}
						</Text>
					</Stack>
				)}
			</Group>
		);

	if (view.type === 'timeGridWeek')
		return (
			<>
				{event.start && event.end && (
					<Stack
						gap={0}
						w="100%"
						h="100%"
						p={4}
						align="right"
						justify="start"
						bg={isRemote ? 'blue' : 'green'}
						style={{
							cursor: 'pointer',
						}}
					>
						<Text fw={600} size="sm">
							{formatDate(event.start, 'HH:mm')} -{' '}
							{formatDate(event.end, 'HH:mm')}
						</Text>
						<Text
							fw={600}
							size="sm"
							style={{
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							{event.title}{' '}
							<Text component="span" fs="italic">
								({participantCode})
							</Text>
						</Text>
					</Stack>
				)}
			</>
		);

	if (view.type === 'listWeek') {
		return (
			<div className="hover: flex cursor-pointer flex-col items-start">
				<Badge
					className={cn(
						'flex items-center gap-0',
						{ 'bg-blue-100 text-blue-600 hover:bg-blue-100': isRemote },
						{ 'bg-green-100 text-green-600 hover:bg-green-100': !isRemote },
					)}
				>
					{isRemote ? 'A distance' : 'Sur centre'}
				</Badge>
				{event.start && (
					<p className={'text-sm font-semibold md:text-base'}>{event.title}</p>
				)}
				<div className="inline">
					<p className="hidden text-sm md:inline-block">Participant : </p>
					<p className="inline-block text-sm">{participantCode}</p>
				</div>
			</div>
		);
	}
};
