import { z } from 'zod';

export type FilterVisitsSchedulesZodType = z.infer<
	typeof FilterVisitsSchedulesZodSchema
>;

export const FilterVisitsSchedulesZodSchema = z.object({
	participantIds: z.array(z.coerce.number().positive()).optional(),
	isRemote: z.boolean(),
	isInCenter: z.boolean(),
});
