import { z } from 'zod';
import {
	addressValidator,
	cityValidator,
	zipCodeValidator,
} from '../../../../../shared/components/validators/commonValidators';

export type ScheduleVisitZodType = z.infer<typeof ScheduleVisitZodSchema>;

export const ScheduleVisitZodSchema = z.object({
	date: z.date({
		required_error: 'La date est requise',
		invalid_type_error: 'Date invalide',
	}),
	startTime: z
		.string({
			required_error: "L'horaire est requis",
		})
		.min(1, { message: "L'horaire est requis" }),
	endTime: z
		.string({
			required_error: "L'horaire est requis",
		})
		.min(1, { message: "L'horaire est requis" }),
	address: addressValidator,
	zipCode: zipCodeValidator,
	city: cityValidator,
	addressDetails: z.string().optional(),
});
