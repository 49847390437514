import { Button, Group, Modal, Stack, Text, Title } from '@mantine/core';
import classes from '../../../../shared/styles/Modal.module.scss';

type Props = {
	handleCancel: () => void;
	handleClose: () => void;
};

export function ConfirmCancelModal({ handleClose, handleCancel }: Props) {
	return (
		<Modal opened={true} onClose={handleClose} centered classNames={classes}>
			<Stack gap="lg" m="lg">
				<Title order={3} ta="center">
					Etes-vous sûr de vouloir annuler vos réponses ?
				</Title>
				<Text size="md" ta="center">
					Toutes vos réponses seront perdues le cas échéant.
				</Text>
			</Stack>

			<Group grow gap="5%" mx="5%">
				<Button onClick={handleClose} variant="outline" size="md">
					Retour
				</Button>
				<Button
					variant="filled"
					mx="5%"
					size="md"
					color="red"
					onClick={handleCancel}
				>
					Confirmer
				</Button>
			</Group>
		</Modal>
	);
}
