import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import { cn } from '@/components/utils';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { useCurrentResearch } from '../../researches/contexts/researches.context';
import { useCurrentParticipant } from '../../usersOnResearch/contexts/participants.context';
import { AddParticipantDocumentsModal } from '../components/modals/AddParticipantDocumentsModal';
import { AddParticipantDocumentsWithCustomRecipientsModal } from '../components/modals/AddParticipantDocumentsWithCustomRecipientsModal';
import { AddResearchDocumentsModal } from '../components/modals/AddResearchDocumentsModal';
import { DocumentsTable } from '../components/tables/DocumentsTable';

export function DocumentsTab() {
	const [opened, { open, close }] = useDisclosure();
	const { userOnResearch } = useCurrentResearch();
	const { participant } = useCurrentParticipant();
	const isMobile = useIsMobile();

	const shouldDisplayAddDocumentButton = () => {
		if (userOnResearch.status !== 'ACTIVE') return false;
		if (participant) return true;
		if (
			['INVESTIGATOR', 'ONSITE_RESEARCH_MEMBER'].includes(userOnResearch.role)
		)
			return true;
		return false;
	};

	const addDocumentModalToDisplay = () => {
		if (!participant) return <AddResearchDocumentsModal handleClose={close} />;
		if (userOnResearch.role === 'PARTICIPANT')
			return <AddParticipantDocumentsModal handleClose={close} />;
		return (
			<AddParticipantDocumentsWithCustomRecipientsModal handleClose={close} />
		);
	};

	return (
		<>
			<div className="flex h-full w-full flex-col gap-4">
				<div className="flex flex-col justify-start gap-2 md:flex-row md:justify-between">
					<h4>Documents</h4>
					<div className="flex items-end gap-2">
						{shouldDisplayAddDocumentButton() && (
							<Button
								size={isMobile ? 'xs' : 'sm'}
								className="w-fit"
								onClick={open}
							>
								<div className="flex flex-row items-center gap-1">
									{
										<IconPlus
											className={cn(isMobile ? 'h-4 w-4' : 'h-5 w-5')}
										/>
									}
									<p>Ajouter un document</p>
								</div>
							</Button>
						)}
					</div>
				</div>
				<DocumentsTable />
			</div>
			{opened && addDocumentModalToDisplay()}
		</>
	);
}
