import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomLoader } from '../../../../components/ui/loader';
import { paths } from '../../../../routes/paths';
import { JoinResearchResponse } from '../../shared/types/JoinResearchResponse';
import { offsiteResearchMemberJoinResearchQuery } from '../api/offsite-research-member.mutations';

export function OffsiteResearchMemberJoinResearchPage() {
	const navigate = useNavigate();
	const { key } = useParams();

	const mutation = useMutation({
		mutationFn: async () => {
			return await offsiteResearchMemberJoinResearchQuery(key ?? '');
		},
		onSuccess: async (data: JoinResearchResponse) => {
			const { email, hasSignedUp } = data;
			if (!hasSignedUp) {
				navigate(paths.OFFSITE_RESEARCH_MEMBER_SIGNUP_PATH, {
					replace: true,
					state: { email, key },
				});
				return;
			}
			navigate(paths.SIGNIN_PATH, { replace: true });
			notifications.show({
				message: 'Vous avez été ajouté à la recherche',
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
		},
		onError: (error: AxiosError) => {
			const errorMessage = error.response?.status.toString().startsWith('4')
				? "Lien d'invitation invalide"
				: 'Une erreur est survenue';
			notifications.show({
				message: errorMessage,
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
			navigate(paths.SIGNIN_PATH, { replace: true });
		},
	});

	useEffect(() => {
		mutation.mutate();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return <>{mutation.isPending && <CustomLoader />}</>;
}
