import { Button } from '@/components/ui/button';
import MyField from '@/components/ui/form/form-field';
import { zodResolver } from '@hookform/resolvers/zod';
import { Fieldset } from '@mantine/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { PasswordRules } from '../../../shared/components/ui/PasswordRules';
import {
	InvestigatorSignUpZodSchema,
	InvestigatorSignUpZodType,
} from './validators/InvestigatorSignUpZodSchema';
import { MyPasswordField } from '@/components/ui/form/password-form-field';

type Props = {
	onSubmit: (data: InvestigatorSignUpZodType) => void;
	isLoading: boolean;
};

export function InvestigatorSignUpForm({ onSubmit, isLoading }: Props) {
	const location = useLocation();
	const email = location.state?.email ?? '';

	const methods = useForm<InvestigatorSignUpZodType>({
		resolver: zodResolver(InvestigatorSignUpZodSchema),
		defaultValues: {
			email,
		},
	});
	const {
		handleSubmit,
		formState: { errors },
	} = methods;

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-12">
				<div className="flex flex-col gap-4">
					<div className="flex flex-col justify-between gap-4 md:flex-row">
						<MyField
							type="text"
							name="lastName"
							required={true}
							label="Nom"
							placeholder="Entrez votre nom"
							error={errors.lastName?.message}
							labelClass="text-black"
							inputClass="text-black"
							additionalClass="md:w-1/2"
						/>

						<MyField
							type="text"
							name="firstName"
							required={true}
							label="Prénom"
							placeholder="Entrez votre prénom"
							error={errors.firstName?.message}
							labelClass="text-black"
							inputClass="text-black"
							additionalClass="md:w-1/2"
						/>
					</div>

					<MyField
						type="tel"
						name="phoneNumber"
						required={true}
						label="Téléphone"
						placeholder="Entrez votre numéro de téléphone"
						error={errors.phoneNumber?.message}
						labelClass="text-black"
						inputClass="text-black"
					/>

					<Fieldset legend="Lieu de travail" variant="filled">
						<div className="flex flex-col gap-4">
							<MyField
								type="text"
								name="workplaceName"
								required={true}
								label="Établissement"
								placeholder="Établissement"
								error={errors.workplaceName?.message}
								labelClass="text-black"
								inputClass="text-black"
							/>
							<MyField
								type="text"
								name="medicalService"
								label="Service"
								placeholder="Service"
								error={errors.medicalService?.message}
								labelClass="text-black"
								inputClass="text-black"
							/>
							<MyField
								type="text"
								name="workplaceAddress"
								required={true}
								label="Adresse"
								placeholder="Adresse"
								error={errors.workplaceAddress?.message}
								labelClass="text-black"
								inputClass="text-black"
							/>

							<div className="flex flex-col justify-between gap-4 md:flex-row">
								<MyField
									type="text"
									name="workplaceCity"
									required={true}
									label="Ville"
									placeholder="Ville"
									error={errors.workplaceCity?.message?.toString()}
									labelClass="text-black"
									inputClass="text-black"
									additionalClass="md:w-1/2"
								/>
								<MyField
									type="number"
									name="workplaceZipCode"
									required={true}
									label="Code postal"
									placeholder="Code postal"
									error={errors.workplaceZipCode?.message?.toString()}
									labelClass="text-black"
									inputClass="text-black"
									additionalClass="md:w-1/2"
								/>
							</div>
						</div>
					</Fieldset>

					<MyField
						type="number"
						name="rpps"
						label="Identifiant RPPS"
						placeholder="Entrez votre identifiant RPPS"
						labelClass="text-black"
						inputClass="text-black"
						error={errors.rpps?.message}
					/>

					<MyField
						type="email"
						name="email"
						label="Email"
						placeholder="Entrez votre adresse email"
						disabled={true}
						defaultValue={email}
						error={errors.email?.message}
						labelClass="text-black"
						inputClass="text-black"
					/>

					<div className="flex flex-col gap-2">
						<MyPasswordField
							name="password"
							required={true}
							label="Mot de passe"
							placeholder="Entrez votre mot de passe"
							error={errors.password?.message?.toString()}
							labelClass="text-black"
							inputClass="text-black"
							autoComplete="new-password"
						/>
						<PasswordRules />
					</div>

					<MyPasswordField
						name="confirmPassword"
						required={true}
						label="Confirmation du mot de passe"
						placeholder="Confirmez votre mot de passe"
						error={errors.confirmPassword?.message?.toString()}
						labelClass="text-black"
						autoComplete="new-password"
						inputClass="text-black"
						onPaste={(event) => {
							event.preventDefault();
						}}
					/>
				</div>

				<Button
					disabled={isLoading}
					type="submit"
					className="flex justify-center"
				>
					S'inscrire
				</Button>
			</form>
		</FormProvider>
	);
}
