import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { ParticipantVisit, Visit } from './visits.types';

export const getVisits = async (researchId: number): Promise<Visit[]> => {
	try {
		const { data } = await privateAxios.get<Visit[]>(
			`visits/research/${researchId}`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getParticipantVisits = async (
	researchId: number,
	participantId: number,
): Promise<ParticipantVisit[]> => {
	try {
		const { data } = await privateAxios.get<ParticipantVisit[]>(
			`visits/research/${researchId}/participant/${participantId}`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getVisitSchedule = async (
	researchId: number,
	participantVisitId: number,
): Promise<ParticipantVisit> => {
	try {
		const { data } = await privateAxios.get<ParticipantVisit>(
			`visits/research/${researchId}/schedule/${participantVisitId}`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
