import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { NEXT_RATING_DATE } from '../../../ratings/constants';
import {
	REFRESH_TOKEN_STORAGE_KEY,
	TOKEN_STORAGE_KEY,
	decodeJwtAccessToken,
} from '../../utils/authTokens';
import { AuthContext } from '../contexts/AuthContext';
import { LoginResponse } from '../types/AuthToken';
import { AuthUser } from '../types/AuthUser';
import { useQueryClient } from '@tanstack/react-query';

type Props = {
	children: ReactNode;
};

const getInitialState = () => {
	const token = localStorage.getItem(TOKEN_STORAGE_KEY);
	if (!token) return { token: null, user: null };
	const user = decodeJwtAccessToken(token) as AuthUser;
	return { token, user };
};

export function AuthProvider({ children }: Props) {
	const [accessToken, setAccessToken] = useState(getInitialState().token ?? '');
	const [user, setUser] = useState<AuthUser | null>(
		getInitialState().user ?? null,
	);
	const isAuthenticated = useMemo(() => Boolean(accessToken), [accessToken]);
	const queryClient = useQueryClient();

	useEffect(() => {
		if (!isAuthenticated) {
			const token = localStorage.getItem(TOKEN_STORAGE_KEY);
			if (token) {
				const decodedToken = decodeJwtAccessToken(token);
				if (decodedToken) setUser(decodedToken);
				setAccessToken(token);
			}
		}
	}, [isAuthenticated]);

	const createSession = useCallback(
		({ accessToken, refreshToken }: LoginResponse) => {
			localStorage.setItem(TOKEN_STORAGE_KEY, accessToken);
			localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
			if (!localStorage.getItem(NEXT_RATING_DATE)) {
				const nextRatingDate = new Date();
				nextRatingDate.setMonth(nextRatingDate.getMonth() + 3);
				localStorage.setItem(NEXT_RATING_DATE, nextRatingDate.toISOString());
			}

			const decodedToken = decodeJwtAccessToken(accessToken);
			if (decodedToken) setUser(decodedToken);

			setAccessToken(accessToken);
		},
		[setAccessToken, setUser],
	);

	const signOut = useCallback(() => {
		localStorage.removeItem(TOKEN_STORAGE_KEY);
		localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
		setAccessToken('');
		setUser(null);
		queryClient.clear();
	}, [queryClient]);

	return (
		<AuthContext.Provider
			value={{
				user,
				isAuthenticated,
				createSession,
				signOut,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
}
