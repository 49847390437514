import classes from '@/components/ui/tabs/tabs.module.scss';
import { Tabs } from '@mantine/core';
import { LoginInformation } from './LoginInformation';
import { PersonalInformation } from './PersonalInformation';
export function SettingsTabs() {
	const tabItems = [
		{
			label: 'Informations personnelles',
			value: 'informations',
			component: <PersonalInformation />,
		},
		{
			label: 'Mot de passe',
			value: 'connexion',
			component: <LoginInformation />,
		},
	];

	return (
		<Tabs classNames={classes} defaultValue={tabItems[0].value}>
			<div className="flex flex-col gap-6">
				<Tabs.List>
					{tabItems.map((item, index) => (
						<Tabs.Tab key={index} value={item.value}>
							{item.label}
						</Tabs.Tab>
					))}
				</Tabs.List>

				{tabItems.map((item, index) => (
					<Tabs.Panel key={index} value={item.value}>
						{item.component}
					</Tabs.Panel>
				))}
			</div>
		</Tabs>
	);
}
