import { AxiosError } from 'axios';
import { privateAxios } from '../../../../../shared/services/privateAxios';
import { CreateActionDocumentDto } from './action-documents.dto';

export const resetResultDocuments = async (
	actionId: number,
	participantId: number,
) => {
	try {
		const { data } = await privateAxios.delete(
			`visits/action/${actionId}/participant/${participantId}/reset-result-documents`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const uploadActionResultDocuments = async (
	actionId: number,
	participantId: number,
	documents: CreateActionDocumentDto[],
) => {
	try {
		const { data } = await privateAxios.post(
			`/visits/action/${actionId}/participant/${participantId}/result-documents`,
			{
				documents,
			},
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
