import { Navigate, Outlet } from 'react-router-dom';
import { paths } from '../../../../routes/paths';
import { useSession } from '../hooks/useSession';

export function AuthLayout() {
	const { isAuthenticated } = useSession();

	if (isAuthenticated) {
		return <Navigate to={paths.INDEX_PATH} replace={true} />;
	}

	return (
		<div className="flex h-screen w-screen flex-row justify-start py-4 align-middle lg:gap-4">
			<div className="mx-auto my-auto flex h-full w-full min-w-fit flex-1 justify-center overflow-y-auto px-6 py-8">
				<Outlet />
			</div>
			<div className="my-auto flex h-full">
				<img
					src="/auth.svg"
					alt="Image de connexion"
					className="hidden object-contain lg:block"
				/>
			</div>
			<img
				src="/logo.png"
				className="absolute right-4 top-4 hidden h-24 object-contain md:block"
				alt="Logo CosyTrials"
			/>
		</div>
	);
}
