import { useQuery } from '@tanstack/react-query';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';
import { getNotesByParticipant, getNotesByResearch } from '../notes.queries';
import { notesQueryKeys } from '../notes.query-keys';
import { NoteSummary } from '../notes.types';

export const useGetNotes = () => {
	const { research } = useCurrentResearch();
	const { participant } = useCurrentParticipant();

	const { queryKey, queryFn } = participant
		? {
				queryKey: notesQueryKeys.listByParticipant(
					research.id,
					participant.userId,
				),
				queryFn: () => getNotesByParticipant(research.id, participant.userId),
			}
		: {
				queryKey: notesQueryKeys.list(research.id),
				queryFn: () => getNotesByResearch(research.id),
			};

	const {
		data: notes,
		isLoading,
		error,
	} = useQuery<NoteSummary[]>({
		queryKey,
		queryFn,
	});

	return { notes, isLoading, error };
};
