import { AxiosError, AxiosResponse } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { CreateMeetDto, UpdateMeetDto } from './meets.dto';

export const createMeet = async (
	payload: CreateMeetDto,
	researchId: number,
) => {
	try {
		const { data } = await privateAxios.post(
			`/meets/researches/${researchId}/create-meet`,
			payload,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const deleteMeet = async (meetId: number) => {
	try {
		const { data } = await privateAxios.delete(`/meets/${meetId}/delete-meet`);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const updateMeet = async (meetId: number, payload: UpdateMeetDto) => {
	try {
		const { data } = await privateAxios.put(
			`/meets/${meetId}/update-meet`,
			payload,
		);
		return data;
	} catch (error) {
		throw error as AxiosResponse;
	}
};
