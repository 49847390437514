import '@mantine/charts/styles.css';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/dropzone/styles.css';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import '@mantine/tiptap/styles.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'mantine-react-table/styles.css';
import { RouterProvider } from 'react-router-dom';
import { AuthProvider } from './features/auth/shared/providers/AuthProvider';
import { router } from './routes/router';
import { theme } from './shared/styles/theme';

// eslint-disable-next-line react-refresh/only-export-components
export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: 1,
			staleTime: 1000 * 60 * 5,
		},
	},
});

export default function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<ReactQueryDevtools initialIsOpen={true} />
			<MantineProvider theme={theme}>
				<ModalsProvider>
					<Notifications />
					<AuthProvider>
						<RouterProvider router={router} />
					</AuthProvider>
				</ModalsProvider>
			</MantineProvider>
		</QueryClientProvider>
	);
}
