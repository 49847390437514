import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { CreateConsentDto } from './consents.dto';
import { Consent } from './consents.entities';

export const createConsent = async (
	researchId: number,
	payload: CreateConsentDto,
) => {
	try {
		const { data } = await privateAxios.post<Consent>(
			`consents/research/${researchId}/create-consent`,
			payload,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const reactivateConsent = async (
	researchId: number,
	consentId: number,
) => {
	try {
		const { data } = await privateAxios.post<number>(
			`consents/research/${researchId}/reactivate-consent/${consentId}`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const deleteConsent = async (researchId: number, consentId: number) => {
	try {
		const { data } = await privateAxios.delete<number>(
			`consents/research/${researchId}/delete-consent/${consentId}`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
