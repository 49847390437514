import { useCurrentParticipant } from '@/features/usersOnResearch/contexts/participants.context';
import { useScheduleVisit } from '../../api/hooks/useScheduleVisit';
import { ParticipantVisit } from '../../api/visits.types';
import { ScheduleOnsiteVisitForm } from '../forms/ScheduleOnsiteVisitForm';
import { ScheduleRemoteVisitForm } from '../forms/ScheduleRemoteVisitForm';

type ScheduleVisitFormProps = {
	visit: ParticipantVisit;
	handleClose: () => void;
};

export const ScheduleVisitForm = ({
	visit,
	handleClose: onClose,
}: ScheduleVisitFormProps) => {
	const { participant } = useCurrentParticipant();

	const { scheduleVisitMutation } = useScheduleVisit(
		visit.id,
		visit.participant?.userId ?? participant.userId,
		onClose,
	);

	return (
		<div className="max-w-90% m-auto w-[90%]">
			{visit.isRemote ? (
				<ScheduleRemoteVisitForm
					visit={visit}
					handleClose={onClose}
					handleSubmit={(payload) => scheduleVisitMutation.mutate(payload)}
				/>
			) : (
				<ScheduleOnsiteVisitForm
					visit={visit}
					handleClose={onClose}
					handleSubmit={(payload) => scheduleVisitMutation.mutate(payload)}
				/>
			)}
		</div>
	);
};
