import { DonutChart } from '@mantine/charts';
import { Text } from '@mantine/core';
import { Legend } from 'recharts';
import { FigureCard } from '../atoms/FigureCard';

export type ParticipantEnrollmentPieChartProps = {
	activeParticipantsCount: number;
	inactiveParticipantsCount: number;
	pendingParticipantsCount: number;
	participantsWithCompletedVisitsCount: number;
	ongoingParticipantsCount: number;
};
export const ParticipantEnrollmentPieChart = ({
	inactiveParticipantsCount,
	pendingParticipantsCount,
	participantsWithCompletedVisitsCount,
	ongoingParticipantsCount,
}: ParticipantEnrollmentPieChartProps) => {
	const formattedData = [
		{
			name: 'Inclus et actif',
			value: ongoingParticipantsCount,
			color: 'var(--mantine-primary-color-6)',
		},

		{
			name: 'Terminé',
			value: participantsWithCompletedVisitsCount,
			color: 'var(--mantine-color-custom-green-6)',
		},
		{
			name: 'Figé',
			value: inactiveParticipantsCount,
			color: 'var(--mantine-color-custom-orange-6)',
		},
		{
			name: "En attente d'inclusion",
			value: pendingParticipantsCount,
			color: 'var(--mantine-color-gray-3)',
		},
	].filter((data) => data.value > 0);

	const renderColorfulLegendText = (value: string) => {
		return (
			<Text component="span" pl="sm" c="var(--mantine-color-gray-7)">
				{value}
			</Text>
		);
	};

	const pieChart = (
		<DonutChart
			data={formattedData}
			size={140}
			thickness={30}
			withTooltip
			tooltipDataSource="segment"
			w="100%"
			mih={230}
		>
			<Legend
				layout="vertical"
				align="center"
				verticalAlign="bottom"
				iconSize={10}
				iconType="circle"
				formatter={renderColorfulLegendText}
			/>
		</DonutChart>
	);

	return (
		<FigureCard title="Distribution des participants inclus">
			{pieChart}
		</FigureCard>
	);
};
