import { Button, Stack, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconDownload, IconTrash } from '@tabler/icons-react';
import dayjs from 'dayjs';
import {
	MRT_Cell,
	MRT_ColumnDef,
	MantineReactTable,
	useMantineReactTable,
} from 'mantine-react-table';
import { useMemo, useState } from 'react';
import { getDefaultMRTOptions } from '../../../../components/ui/table/table-options';
import { FileTile } from '../../../../shared/components/files/FileTile';
import {
	customDateFilterFn,
	customResearchMemberFilterFn,
	isMatch,
} from '../../../../shared/utils/filters';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';
import { getResearchMemberInfo } from '../../../usersOnResearch/utils/researchMemberInfo';
import {
	DocumentAccessLevelEnum,
	DocumentAccessLevelType,
	DocumentCustomType,
	DocumentInfo,
	DocumentTypeEnum,
} from '../../api/documents.types';
import { useDownloadDocument } from '../../api/hooks/useDownloadDocument';
import { useDownloadDocumentsAsZip } from '../../api/hooks/useDownloadDocumentsAsZip';
import { useGetDocuments } from '../../api/hooks/useGetDocuments';
import { DocumentRecipients } from '../atoms/DocumentRecipients';
import { DocumentTypeBadge } from '../atoms/DocumentTypeBadge';
import { DeleteDocumentModal } from '../modals/DeleteDocumentModal';
import { UpdateDocumentRecipientsModal } from '../modals/UpdateDocumentRecipientsModal';

export function DocumentsTable() {
	const [currentDocumentId, setCurrentDocumentId] = useState<number>();

	const { documents, isLoading, error } = useGetDocuments();
	const { mutation: downloadDocumentMutation } = useDownloadDocument();
	const { downloadDocumentsMutation } = useDownloadDocumentsAsZip();
	const { participant } = useCurrentParticipant();
	const { userOnResearch } = useCurrentResearch();

	const canDeleteDocument = (document: DocumentInfo) => {
		if (userOnResearch.status !== 'ACTIVE') return false;
		if (
			['INVESTIGATOR', 'ONSITE_RESEARCH_MEMBER'].includes(userOnResearch.role)
		) {
			if (participant) return document.scope === 'PRIVATE';
			return true;
		}
		return document.uploadedBy.userId === userOnResearch.userId;
	};

	const [
		deleteModalOpened,
		{ open: openDeleteModal, close: closeDeleteModal },
	] = useDisclosure();
	const handleOnDeleteModalClick = (documentId: number) => {
		setCurrentDocumentId(documentId);
		openDeleteModal();
	};
	const [
		documentRecipientsModalOpened,
		{ open: openDocumentRecipientsModal, close: closeDocumentRecipientsModal },
	] = useDisclosure();
	const handleOnRecipientsUpdate = (documentId: number) => {
		setCurrentDocumentId(documentId);
		openDocumentRecipientsModal();
	};

	const columns = useMemo<MRT_ColumnDef<DocumentInfo>[]>(() => {
		const result: MRT_ColumnDef<DocumentInfo>[] = [
			{
				header: 'Nom du fichier',
				id: 'fileName',
				accessorKey: 'fileName',
				size: 250,
				Cell: ({ cell }: { cell: MRT_Cell<DocumentInfo> }) => (
					<FileTile fileName={cell.getValue<string>()} />
				),
				enableSorting: false,
				mantineTableBodyCellProps: {
					style: {
						paddingLeft: 0,
					},
				},
				mantineTableHeadCellProps: {
					style: {
						paddingLeft: 0,
					},
				},
				filterFn: 'customDocumentFilterFn',
			},
			{
				header: 'Type',
				id: 'type',
				accessorKey: 'type',
				size: 1,
				Cell: ({ cell }: { cell: MRT_Cell<DocumentInfo> }) => (
					<DocumentTypeBadge type={cell.getValue<DocumentCustomType>()} />
				),
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
				filterFn: 'customDocumentTypeFilterFn',
			},
			{
				header: 'Partagé à',
				id: 'accessLevel',
				accessorKey: 'accessLevel',
				size: 100,
				Cell: ({ cell }: { cell: MRT_Cell<DocumentInfo> }) => (
					<DocumentRecipients
						accessLevel={cell.getValue<DocumentAccessLevelType>()}
						onRecipientsUpdate={() =>
							handleOnRecipientsUpdate(cell.row.original.id)
						}
					/>
				),

				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
				filterFn: 'customRecipientsFilterFn',
			},
			{
				header: "Date d'ajout",
				id: 'createdAt',
				accessorKey: 'createdAt',
				size: 10,
				Cell: ({ cell }: { cell: MRT_Cell<DocumentInfo> }) => (
					<p className="text-center text-sm">
						{dayjs(cell.getValue<Date>()).format('DD/MM/YY')}
					</p>
				),
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
				filterFn: 'customDateFilterFn',
			},
			{
				id: 'actions-download',
				header: '',
				accessorKey: 'id',
				columnDefType: 'display', //turns off data column features like sorting, filtering, etc.
				size: 1,
				Cell: ({ cell }: { cell: MRT_Cell<DocumentInfo> }) => {
					const { id } = cell.row.original;
					return (
						<IconDownload
							size={22}
							className="text-primary"
							onClick={() => downloadDocumentMutation.mutate(id)}
						/>
					);
				},
			},
			{
				id: 'actions-delete',
				header: '',
				accessorKey: 'permission',
				columnDefType: 'display', //turns off data column features like sorting, filtering, etc.
				size: 1,
				Cell: ({ cell }: { cell: MRT_Cell<DocumentInfo> }) => {
					return (
						canDeleteDocument(cell.row.original) && (
							<IconTrash
								size={22}
								onClick={() => handleOnDeleteModalClick(cell.row.original.id)}
								className="text-destructive"
							/>
						)
					);
				},
				mantineTableBodyCellProps: {
					style: {
						padding: 0,
					},
				},
			},
		];

		if (participant || userOnResearch.role === 'OFFSITE_RESEARCH_MEMBER')
			result.splice(2, 1, {
				header: 'Créateur',
				id: 'uploadedBy',
				accessorKey: 'uploadedBy',
				size: 100,
				Cell: ({ cell }: { cell: MRT_Cell<DocumentInfo> }) => {
					const { displayTitle, displayRole } = getResearchMemberInfo(
						cell.getValue<UserOnResearch>(),
					);
					return (
						<Stack gap={0}>
							<Text size="sm">{displayTitle}</Text>
							<Text size="xs" c="gray">
								{displayRole}
							</Text>
						</Stack>
					);
				},
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
				filterFn: 'customResearchMemberFilterFn',
			});
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [participant]);

	const defaultTableOptions = getDefaultMRTOptions<DocumentInfo>(
		Boolean(error),
	);
	const table = useMantineReactTable({
		data: documents || [],
		columns,
		...defaultTableOptions,
		state: { isLoading },
		enableRowSelection: true,
		enableSelectAll: true,
		positionToolbarAlertBanner: 'bottom',
		filterFns: {
			customDocumentFilterFn: (row, _id, filterValue) =>
				isMatch(row.original.fileName, filterValue),
			customDocumentTypeFilterFn: (row, _id, filterValue) =>
				isMatch(
					DocumentTypeEnum[row.original.type as DocumentCustomType],
					filterValue,
				),
			customRecipientsFilterFn: (row, _id, filterValue) =>
				isMatch(
					DocumentAccessLevelEnum[
						row.original.accessLevel as DocumentAccessLevelType
					],
					filterValue,
				),
			customResearchMemberFilterFn: (row, _id, filterValue) =>
				customResearchMemberFilterFn(row.original.uploadedBy, filterValue),
			customDateFilterFn: (row, _id, filterValue) =>
				customDateFilterFn(row.original.createdAt, filterValue),
		},
		renderTopToolbarCustomActions: ({ table }) => {
			const selectedDocumentIds = table
				.getSelectedRowModel()
				.rows.map((row) => row.original.id);
			return (
				selectedDocumentIds &&
				selectedDocumentIds.length > 0 && (
					<Button
						variant="outline"
						bg="#f1f3f5"
						leftSection={<IconDownload />}
						onClick={() =>
							downloadDocumentsMutation.mutate(selectedDocumentIds)
						}
						ml="auto"
					>
						Télécharger la sélection
					</Button>
				)
			);
		},
	});

	return (
		<>
			<MantineReactTable table={table} />
			{deleteModalOpened && currentDocumentId && (
				<DeleteDocumentModal
					documentId={currentDocumentId}
					handleClose={closeDeleteModal}
				/>
			)}
			{documentRecipientsModalOpened && currentDocumentId && (
				<UpdateDocumentRecipientsModal
					documentId={currentDocumentId}
					handleClose={closeDocumentRecipientsModal}
				/>
			)}
		</>
	);
}
