import { ActionIcon, Paper } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { CreateParticipantDto } from '../../api/dto/participants.dto.ts.ts';
import { CreateResearchMemberDto } from '../../api/dto/research-members.dto.ts.ts';
import { UserOnResearch } from '../../api/types/users-on-research.types.ts';
import { ResearchMemberCard } from '../cards/ResearchMemberCard.tsx';

type Props = {
	user: UserOnResearch | CreateResearchMemberDto | CreateParticipantDto;
	onRemoveUser: (email: string) => void;
};

export function ResearchMemberPicker({ user, onRemoveUser }: Props) {
	return (
		<Paper
			shadow="sm"
			radius="md"
			className="flex w-full items-center justify-between gap-2 p-2"
		>
			<div className="flex max-w-[80%] grow">
				<ResearchMemberCard user={user} />
			</div>
			<ActionIcon
				variant="transparent"
				onClick={() => onRemoveUser(user.email)}
			>
				<IconTrash color="red" />
			</ActionIcon>
		</Paper>
	);
}
