import { useQuery } from '@tanstack/react-query';
import { useSession } from '../../auth/shared/hooks/useSession';
import { useCurrentResearch } from '../../researches/contexts/researches.context';
import { getParticipantsByOffsiteResearchMember } from '../../usersOnResearch/api/queries/participants.queries';
import { getOffsiteResearchMembersByParticipant } from '../../usersOnResearch/api/queries/research-members.queries';
import { getUsersOnResearch } from '../../usersOnResearch/api/queries/user-on-research.queries';
import { messagesQueryKeys } from '../api/messages.query-keys';

export const useGetContacts = () => {
	const { user } = useSession();
	const { research } = useCurrentResearch();

	const getContactsList = async () => {
		switch (user?.role) {
			case 'PARTICIPANT':
				return getOffsiteResearchMembersByParticipant(
					research.id,
					user.userId,
					['ACTIVE'],
				);
			case 'OFFSITE_RESEARCH_MEMBER':
				return getParticipantsByOffsiteResearchMember(
					research.id,
					user.userId,
					['ACTIVE'],
				);
			case 'ONSITE_RESEARCH_MEMBER':
			case 'INVESTIGATOR':
				return getUsersOnResearch(
					research.id,
					['OFFSITE_RESEARCH_MEMBER', 'PARTICIPANT'],
					['ACTIVE'],
				);
			default:
				return null;
		}
	};

	const { data: contacts } = useQuery({
		queryKey: messagesQueryKeys.contacts(research.id, user?.userId || 0),
		queryFn: getContactsList,
	});

	return { contacts };
};
