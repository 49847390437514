import { z } from 'zod';
import { MAX_RESPONSE_LENGTH } from '../../../utils/constants';

export const TextQuestionZodSchema = (isRequired: boolean) =>
	z
		.string()
		.trim()
		.min(isRequired ? 1 : 0, {
			message: 'La réponse à cette question est requise',
		})
		.max(MAX_RESPONSE_LENGTH, {
			message: `La réponse à cette question doit faire moins de ${MAX_RESPONSE_LENGTH} caractères`,
		});
