import {
	CustomNavigationMenuLink,
	NavigationMenu,
	NavigationMenuItem,
	NavigationMenuList,
} from '@/components/ui/menu/navigation-menu';
import { useSession } from '@/features/auth/shared/hooks/useSession';
import { NotificationsCountBadge } from '@/features/notifications/components/atoms/NotificationsCountBadge';
import { ResearchPicker } from '@/features/researches/components/pickers/ResearchPicker';
import { paths } from '@/routes/paths';
import {
	IconHelp,
	IconLayoutGrid,
	IconLogout,
	IconMail,
	IconTool,
} from '@tabler/icons-react';
import { Button } from '../button';
import { Separator } from '../separator';
import { NavigationMenuHeader } from './navigation-menu-header';

export const HomeNavigationMenu = () => {
	const { signOut } = useSession();

	const items = [
		{
			label: 'Mon espace',
			link: paths.INDEX_PATH,
			icon: <IconLayoutGrid />,
		},
		{
			label: 'Aide',
			link: paths.HELP_PATH,
			icon: <IconHelp />,
		},
		{
			label: 'Paramètres',
			link: paths.SETTINGS_PATH,
			icon: <IconTool />,
		},
	];

	return (
		<NavigationMenu className="flex h-full w-full flex-col gap-12 overflow-y-scroll px-4 py-4">
			<NavigationMenuHeader />

			<NavigationMenuList className="w-full">
				<NavigationMenuItem className="w-full">
					<CustomNavigationMenuLink
						href={items[0].link}
						icon={items[0].icon}
						label={items[0].label}
					/>
				</NavigationMenuItem>

				<NavigationMenuItem className="w-full">
					<NotificationsCountBadge />
				</NavigationMenuItem>

				{items.slice(1).map((item, index) => (
					<NavigationMenuItem key={index} className="w-full">
						<CustomNavigationMenuLink
							href={item.link}
							icon={item.icon}
							label={item.label}
						/>
					</NavigationMenuItem>
				))}
				<NavigationMenuItem className="w-full">
					<Button
						variant={'ghost'}
						size={'sm'}
						className="w-full justify-start"
						onClick={signOut}
					>
						<div className="flex space-x-2">
							<IconLogout />
							<p className="text-sm">Déconnexion</p>
						</div>
					</Button>
				</NavigationMenuItem>
			</NavigationMenuList>
			<Separator className="mx-auto w-5/6" />
			<Button
				variant={'secondary'}
				size={'default'}
				className="w-full justify-center px-2"
				onClick={() => (window.location.href = 'mailto:contact@cosytrials.com')}
			>
				<div className="flex items-center space-x-1 px-2">
					<IconMail className="h-6 w-6" />
					<p className="text-bold text-xs">Contacter CosyTrials</p>
				</div>
			</Button>

			<div className="mt-auto">
				<ResearchPicker />
			</div>
		</NavigationMenu>
	);
};
