import dayjs from 'dayjs';
import { UserOnResearchStatusEnum } from '../../usersOnResearch/api/types/users-on-research.types';
import { ActionResultExportData } from '../actions/api/actions.types';

export const formatVisitResultsData = (action: ActionResultExportData) => {
	return {
		Participant: action.participantCode,
		Statut: UserOnResearchStatusEnum[action.participantStatus],
		"Date d'inclusion": action.activatedAt
			? dayjs(action.activatedAt).format('DD/MM/YYYY')
			: '',
		Visite: action.visitName,
		'Visite prévue le': action.visitForecastDate
			? dayjs(action.visitForecastDate).format('DD/MM/YYYY')
			: '',
		'Visite planifiée le': action.visitScheduledDate
			? dayjs(action.visitScheduledDate).format('DD/MM/YYYY')
			: '',
		Action: action.actionName,
		"Statut de l'action": action.completed ? 'Complété' : 'Non complété',
		'Date de modification': action.updatedAt
			? dayjs(action.updatedAt).format('DD/MM/YYYY')
			: '',
		'Modifié par': action.updatedBy ?? '',
	};
};
