import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { useUpdateUserOnResearchStatus } from '../../../usersOnResearch/api/hooks/users-on-research.hooks';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';

type DeleteGlobalConsentModalProps = {
	handleClose: () => void;
};

export function DeleteGlobalConsentModal({
	handleClose,
}: DeleteGlobalConsentModalProps) {
	const isMobile = useIsMobile();
	const { participant } = useCurrentParticipant();
	const { research } = useCurrentResearch();
	const mutation = useUpdateUserOnResearchStatus(
		participant.userId,
		'INACTIVE',
		handleClose,
	);

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			title="Retirer le consentement"
			size="md"
			fullScreen={isMobile}
		>
			<div className="flex flex-col gap-6">
				<p className="text-center">
					Êtes-vous sûr(e) de vouloir retirer le consentement du participant{' '}
					{participant.participantOnResearch.code} pour la recherche{' '}
					{research.name} ? Une fois le consentement retiré, le participant aura
					un accès restreint à la recherche.
				</p>
				<div className="flex gap-4">
					{!isMobile && (
						<Button onClick={handleClose} variant="outline" className="w-full">
							Annuler
						</Button>
					)}
					<Button
						disabled={mutation.isPending}
						className="w-full"
						variant="destructive"
						onClick={() => mutation.mutate()}
					>
						Retirer
					</Button>
				</div>
			</div>
		</Modal>
	);
}
