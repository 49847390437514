import { Input } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';

type SearchBarProps = {
	setSearchValue: (searchValue: string) => void;
	label?: string;
	size?: 'sm' | 'md' | 'lg';
};
export const SearchBar = ({
	setSearchValue,
	label = 'Rechercher',
	size = 'sm',
}: SearchBarProps) => {
	return (
		<Input
			leftSection={<IconSearch />}
			placeholder={label}
			size={size}
			w={'100%'}
			onChange={(e) => {
				setSearchValue(e.target.value);
			}}
		/>
	);
};
