import { Avatar } from '@mantine/core';
import ParticipantAvatar from '../../../../assets/icons/participant-avatar-black.svg';

import { IconClock, IconLock } from '@tabler/icons-react';
import { ParticipantOnResearch } from '../../../usersOnResearch/api/types/participants.types';
import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';
import { CaregiverBadge } from './CaregiverBadge';

type ParticipantBadgeProps = {
	participant: UserOnResearch & {
		participantOnResearch: ParticipantOnResearch;
	};
};

export function ParticipantBadge({ participant }: ParticipantBadgeProps) {
	const caredForByCode =
		participant.participantOnResearch.caredForBy &&
		participant.participantOnResearch.caredForBy.participantOnResearch.code;
	const caresForCode =
		participant.participantOnResearch.caresFor &&
		participant.participantOnResearch.caresFor.participantOnResearch.code;
	const hasCaregiver = caredForByCode || caresForCode;

	return (
		<div className="flex items-center gap-4">
			<div className="flex items-center gap-2">
				<Avatar src={ParticipantAvatar} size={24} />
				<p className="truncate text-lg font-semibold">
					{participant.participantOnResearch.code}
				</p>
				{participant.status === 'INACTIVE' && (
					<IconLock className="h-10 w-10 text-red-500" />
				)}
				{participant.status === 'PENDING' && (
					<IconClock className="h-10 w-10 text-orange-500" />
				)}
			</div>
			{hasCaregiver && <CaregiverBadge participant={participant} />}
		</div>
	);
}
