export const messagesQueryKeys = {
	all: ['messages'] as const,
	lists: () => [...messagesQueryKeys.all, 'list'] as const,
	list: (researchId: number) =>
		[...messagesQueryKeys.lists(), researchId] as const,
	threads: (researchId: number) =>
		[...messagesQueryKeys.list(researchId), 'threads'] as const,
	thread: (threadId: number) =>
		[...messagesQueryKeys.lists(), 'thread', threadId] as const,
	contacts: (researchId: number, userId: number) => [
		'contacts',
		researchId,
		userId,
	],
} as const;
