import dayjs from 'dayjs';
import { Role } from '../../features/profile/api/types/user.types';
import { UserOnResearch } from '../../features/usersOnResearch/api/types/users-on-research.types';
import { getResearchMemberInfo } from '../../features/usersOnResearch/utils/researchMemberInfo';

export const isMatch = (value: string, filterValue: string) =>
	value.toLowerCase().trim().includes(filterValue.trim().toLowerCase());

export const customLocalisationFilterFn = (role: Role, filterValue: string) => {
	if (role === 'OFFSITE_RESEARCH_MEMBER')
		return isMatch('A distance', filterValue);
	return isMatch('Sur centre', filterValue);
};

export const customResearchMemberFilterFn = (
	user: UserOnResearch,
	filterValue: string,
) => {
	const { displayRole, displayTitle } = getResearchMemberInfo(user);
	return (
		isMatch(displayTitle ?? '', filterValue) ||
		isMatch(displayRole ?? '', filterValue)
	);
};

export const customDateFilterFn = (date: string | Date, filterValue: string) =>
	isMatch(dayjs(date).format('DD/MM/YYYY'), filterValue);

export const customNameFilterFn = (
	lastName: string,
	firstName: string,
	filterValue: string,
) => isMatch(lastName, filterValue) || isMatch(firstName, filterValue);

export const customStatusFilterFn = (
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	status: any,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	statusEnum: any,
	filterValue: string,
) => isMatch(statusEnum[status].toLowerCase(), filterValue);
