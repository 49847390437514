import { AxiosError } from 'axios';
import { publicAxios } from '../../../../shared/services/publicAxios';

export const resendSignInOtpQuery = async (email: string) => {
	try {
		const response = await publicAxios.post(
			`/auth/login/resend-email-verification`,
			{
				email,
			},
		);
		return response.data;
	} catch (error) {
		throw error as AxiosError;
	}
};
