import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import MyField from '@/components/ui/form/form-field';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/components/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { Fieldset } from '@mantine/core';
import { FormProvider, useForm } from 'react-hook-form';
import {
	useGetUserProfile,
	useUpdateUserProfile,
} from '../../api/profile.hooks';
import {
	OnsiteResearchMemberProfileZodSchema,
	OnsiteResearchMemberProfileZodType,
} from './validators/OnsiteResearchMemberProfileZodSchema';

export function OnsiteResearchMemberProfileForm2() {
	const isMobile = useIsMobile();
	const { profile } = useGetUserProfile();
	const { updateUserInfoMutation } = useUpdateUserProfile();

	const methods = useForm<OnsiteResearchMemberProfileZodType>({
		resolver: zodResolver(OnsiteResearchMemberProfileZodSchema),
		defaultValues: profile,
	});
	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = methods;

	const submitForm = (data: OnsiteResearchMemberProfileZodType) =>
		updateUserInfoMutation.mutate(data);

	return (
		<div className="flex gap-4 max-md:flex-col">
			<h5 className={cn(isMobile ? 'w-full' : 'w-1/4')}>Lieu de travail</h5>
			<div className="flex flex-1">
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(submitForm)} className="w-full">
						<Fieldset
							p="lg"
							styles={{
								root: {
									backgroundColor: 'white',
								},
							}}
						>
							<div className="flex flex-col gap-12">
								<div className="flex flex-col gap-4">
									<MyField
										type="text"
										name="onsiteResearchMemberProfile.workplaceName"
										required={true}
										label="Établissement"
										placeholder="Établissement"
										error={
											errors.onsiteResearchMemberProfile?.workplaceName?.message
										}
										labelClass="text-black"
										inputClass="text-black"
									/>

									<MyField
										type="text"
										name="onsiteResearchMemberProfile.medicalService?"
										label="Service"
										placeholder="Service"
										error={
											errors.onsiteResearchMemberProfile?.medicalService
												?.message
										}
										labelClass="text-black"
										inputClass="text-black"
									/>

									<MyField
										type="text"
										name="onsiteResearchMemberProfile.workplaceAddress"
										label="Adresse"
										placeholder="Adresse"
										required={true}
										error={
											errors.onsiteResearchMemberProfile?.workplaceAddress
												?.message
										}
										labelClass="text-black"
										inputClass="text-black"
									/>

									<div className="flex flex-col justify-between gap-4 md:flex-row">
										<MyField
											type="text"
											name="onsiteResearchMemberProfile.workplaceCity"
											label="Ville"
											placeholder="Ville"
											required={true}
											error={errors.onsiteResearchMemberProfile?.workplaceCity?.message?.toString()}
											labelClass="text-black"
											inputClass="text-black"
											additionalClass="md:w-1/2"
										/>
										<MyField
											type="number"
											name="onsiteResearchMemberProfile.workplaceZipCode"
											label="Code postal"
											placeholder="Code postal"
											required={true}
											error={errors.onsiteResearchMemberProfile?.workplaceZipCode?.message?.toString()}
											labelClass="text-black"
											inputClass="text-black"
											additionalClass="md:w-1/2"
										/>
									</div>
								</div>
								<Separator />

								<div className="flex gap-4 max-md:flex-col">
									<Button
										onClick={() => reset(profile)}
										variant="outline"
										className="w-full"
										type="button"
									>
										Annuler
									</Button>

									<Button
										type="submit"
										disabled={updateUserInfoMutation.isPending}
										className="w-full"
									>
										Enregistrer
									</Button>
								</div>
							</div>
						</Fieldset>
					</form>
				</FormProvider>
			</div>
		</div>
	);
}
