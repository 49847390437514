import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { Thread } from './messages.types';
import { DEFAULT_PAGE_SIZE } from '../utils/constants';

export const getThreadsLatestMessages = async (
	researchId: number,
	page: number,
	pageSize: number = DEFAULT_PAGE_SIZE,
) => {
	try {
		const { data } = await privateAxios.get<Thread[]>(
			`/chat/research/${researchId}`,
			{
				params: {
					page,
					limit: pageSize,
				},
			},
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getThread = async (threadId: number) => {
	try {
		const { data } = await privateAxios.get<Thread>(`/chat/thread/${threadId}`);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
