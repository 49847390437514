export const helpQueryKeys = {
	allQuestions: ['help-questions'] as const,
	allDocuments: ['help-documents'] as const,
	lists: () => [...helpQueryKeys.allDocuments, 'list'] as const,
	questionsLists: () => [...helpQueryKeys.allQuestions, 'list'] as const,
	documentsLists: () => [...helpQueryKeys.allDocuments, 'list'] as const,
	questionsList: (researchId: number) => [
		...helpQueryKeys.questionsLists(),
		researchId,
	],
	documentsList: (researchId: number) => [
		...helpQueryKeys.documentsLists(),
		researchId,
	],
};
