import { Anchor, Avatar, Group, Stack, Text, Tooltip } from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import ParticipantAvatar from '../../../../../assets/icons/participant-avatar.svg';
import { paths } from '../../../../../routes/paths';
import { UserOnResearch } from '../../../../usersOnResearch/api/types/users-on-research.types';
import { getResearchMemberInfo } from '../../../../usersOnResearch/utils/researchMemberInfo';
import { ActionResult } from '../../api/actions.types';

type Props = {
	actionResult: ActionResult;
};
export const ActionResultCard = ({ actionResult }: Props) => {
	const completedByOther =
		actionResult.completed &&
		actionResult.updatedBy &&
		actionResult.updatedBy.userOnResearchId !==
			actionResult.participant.userOnResearchId;
	const visitDate =
		actionResult.visit?.scheduledDate || actionResult.visit?.forecastDate;

	const isCompleted = actionResult.completed;
	const navigate = useNavigate();

	const { displayTitle: participantDisplayTitle } = getResearchMemberInfo(
		actionResult.participant as UserOnResearch,
	);
	const {
		displayTitle: updatedByUserDisplayTitle,
		displayRole: updatedByUserDisplayRole,
	} =
		(completedByOther &&
			getResearchMemberInfo(actionResult.updatedBy as UserOnResearch)) ||
		{};

	return (
		<Group justify="space-between">
			<Group>
				<Avatar src={ParticipantAvatar} />
				<Stack gap={0}>
					<Tooltip label="Accéder à l'espace" radius="md">
						<Text>
							<Anchor
								fw={600}
								onClick={() =>
									navigate(
										`../${paths.PARTICIPANT_TAB}/${actionResult.participant.userId}`,
									)
								}
							>
								{participantDisplayTitle}
							</Anchor>
						</Text>
					</Tooltip>
					{completedByOther && (
						<Text size="sm">
							Rempli par{' '}
							<Text span fw={600}>
								{updatedByUserDisplayTitle} ({updatedByUserDisplayRole})
							</Text>
						</Text>
					)}
				</Stack>
			</Group>
			<Group>
				<Text size="sm" c="gray">
					{visitDate
						? `Visite prévue le ${dayjs(visitDate).format('DD/MM/YYYY')}`
						: 'Visite non planifiée'}
				</Text>
				{isCompleted ? (
					<Avatar color="teal">
						<IconCheck size={20} color="teal" />
					</Avatar>
				) : (
					<Avatar color="red">
						<IconX size={20} color="red" />
					</Avatar>
				)}
			</Group>
		</Group>
	);
};
