import { Button } from '@/components/ui/button';
import { Text, Tooltip, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconEdit, IconTrash, IconVideo } from '@tabler/icons-react';
import dayjs from 'dayjs';
import {
	MRT_Cell,
	MRT_ColumnDef,
	MantineReactTable,
	useMantineReactTable,
} from 'mantine-react-table';
import { useMemo, useState } from 'react';
import { getDefaultMRTOptions } from '../../../../components/ui/table/table-options';
import {
	customDateFilterFn,
	customStatusFilterFn,
	isMatch,
} from '../../../../shared/utils/filters';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { useGetAllMeets } from '../../api/hooks/useGetAllMeets';
import {
	MeetStatusEnum,
	MeetStatusType,
	MeetWithParticipants,
	UserOnMeet,
} from '../../api/meets.types';
import { MeetStatusBadge } from '../badges/MeetStatusBadge';
import { ParticipantsBadge } from '../badges/ParticipantsBadge';
import { DeleteMeetModal } from '../modals/DeleteMeetModal';
import { JoinMeetModal } from '../modals/JoinMeetModal';
import { ShowMeetParticipantsModal } from '../modals/ShowMeetParticipantsModal';
import { UpdateMeetModal } from '../modals/UpdateMeetModal';

export function MeetsTable() {
	const { userOnResearch } = useCurrentResearch();

	const { meets, isLoading, error } = useGetAllMeets();
	const [currentMeet, setCurrentMeet] = useState<MeetWithParticipants>();

	const canUpdateOrDeleteMeet = (users: UserOnMeet[]) =>
		userOnResearch.status === 'ACTIVE' &&
		!!users.some(
			(u) => u.userId === userOnResearch.userId && u.roleOnMeet === 'ORGANIZER',
		);
	const canJoinMeet = (users: UserOnMeet[]) =>
		userOnResearch.status === 'ACTIVE' &&
		!!users.some((u) => u.userId === userOnResearch.userId);

	const [
		deleteModalOpened,
		{ open: openDeleteModal, close: closeDeleteModal },
	] = useDisclosure();
	const handleOnDeleteModalClick = (meet: MeetWithParticipants) => {
		setCurrentMeet(meet);
		openDeleteModal();
	};
	const [
		updateModalOpened,
		{ open: openUpdateModal, close: closeUpdateModal },
	] = useDisclosure();
	const handleOnUpdateModalClick = (meet: MeetWithParticipants) => {
		setCurrentMeet(meet);
		openUpdateModal();
	};
	const [
		showParticipantsModalOpened,
		{ open: openShowParticipantsModal, close: closeShowParticipantsModal },
	] = useDisclosure();
	const handleOnShowParticipantsModalClick = (meet: MeetWithParticipants) => {
		setCurrentMeet(meet);
		openShowParticipantsModal();
	};
	const [joinModalOpened, { open: openJoinModal, close: closeJoinModal }] =
		useDisclosure();
	const handleOnJoinModalClick = (meet: MeetWithParticipants) => {
		setCurrentMeet(meet);
		openJoinModal();
	};

	const columns = useMemo<MRT_ColumnDef<MeetWithParticipants>[]>(
		() => [
			{
				header: 'Nom de la visioconférence',
				id: 'name',
				accessorKey: 'name',
				size: 250,
				Cell: ({ cell }: { cell: MRT_Cell<MeetWithParticipants> }) => (
					<div className="flex items-center gap-1">
						<IconVideo className="h-6 w-6 text-orange-500" />
						<p className="word-break line-clamp-2 text-md font-semibold">
							{cell.getValue<string>()}
						</p>
					</div>
				),
				enableSorting: false,
				filterFn: 'customMeetFilterFn',
			},
			{
				header: 'Date',
				id: 'startDate',
				accessorFn: (row) => row.startDate,
				size: 60,
				Cell: ({ cell }: { cell: MRT_Cell<MeetWithParticipants> }) => (
					<div className="flex flex-col gap-0">
						<p>{dayjs(cell.getValue<Date>()).format('HH:mm')}</p>
						<p>le {dayjs(cell.getValue<Date>()).format('DD MMM')}</p>
					</div>
				),
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
				filterFn: 'customDateFilterFn',
			},
			{
				header: 'Durée',
				id: 'duration',
				accessorFn: (row) =>
					dayjs
						.duration(dayjs(row.endDate).diff(dayjs(row.startDate)))
						.format('H[h] m[min]'),
				size: 60,
				Cell: ({ cell }: { cell: MRT_Cell<MeetWithParticipants> }) => (
					<p className="text-sm">{cell.getValue<string>()}</p>
				),
				enableColumnFilter: false,
				enableSorting: false,
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
			},
			{
				header: 'Participants',
				id: 'participants',
				accessorKey: 'participants',
				size: 70,
				Cell: ({ cell }: { cell: MRT_Cell<MeetWithParticipants> }) => {
					return cell.getValue<UserOnMeet[]>().length > 0 ? (
						<Tooltip label="Afficher les participants">
							<UnstyledButton
								onClick={() => {
									handleOnShowParticipantsModalClick(cell.row.original);
								}}
							>
								<ParticipantsBadge
									participants={cell.getValue<UserOnMeet[]>()}
								/>
							</UnstyledButton>
						</Tooltip>
					) : (
						<Text size="sm" fs="italic" c="dimmed">
							Aucun participant
						</Text>
					);
				},
				enableColumnFilter: false,
				enableSorting: false,
				mantineTableHeadCellProps: {
					align: 'center',
					padding: 'var(--mantine-spacing-xs)',
				},
				mantineTableBodyCellProps: {
					align: 'center',
					padding: 'var(--mantine-spacing-xs)',
				},
			},
			{
				header: 'Statut',
				id: 'status',
				accessorKey: 'status',
				size: 70,
				Cell: ({ cell }: { cell: MRT_Cell<MeetWithParticipants> }) => (
					<MeetStatusBadge status={cell.getValue<MeetStatusType>()} />
				),
				filterFn: 'customStatusFilterFn',
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
			},
			{
				id: 'actions-join',
				header: '',
				columnDefType: 'display',
				size: 1,
				accessorKey: 'status',
				Cell: ({ cell }: { cell: MRT_Cell<MeetWithParticipants> }) =>
					canJoinMeet(cell.row.original.participants) &&
					cell.getValue<MeetStatusType>() !== 'ENDED' && (
						<Button
							onClick={() => handleOnJoinModalClick(cell.row.original)}
							variant={'link'}
							size={'xs'}
						>
							Rejoindre
						</Button>
					),
			},
			{
				id: 'actions-modify',
				header: '',
				columnDefType: 'display',
				accessorKey: 'status',
				size: 1,
				Cell: ({ cell }: { cell: MRT_Cell<MeetWithParticipants> }) =>
					canUpdateOrDeleteMeet(cell.row.original.participants) &&
					cell.getValue<MeetStatusType>() === 'UPCOMING' && (
						<IconEdit
							className="h-6 w-6 cursor-pointer text-gray-500 hover:text-gray-700"
							onClick={() => handleOnUpdateModalClick(cell.row.original)}
						/>
					),
			},
			{
				id: 'actions-delete',
				header: '',
				columnDefType: 'display', //turns off data column features like sorting, filtering, etc.
				size: 1,
				accessorKey: 'status',
				Cell: ({ cell }: { cell: MRT_Cell<MeetWithParticipants> }) =>
					canUpdateOrDeleteMeet(cell.row.original.participants) &&
					cell.getValue<MeetStatusType>() === 'UPCOMING' && (
						<IconTrash
							className="h-6 w-6 cursor-pointer text-error-500 hover:text-error-600"
							onClick={() => handleOnDeleteModalClick(cell.row.original)}
						/>
					),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const defaultTableOptions = getDefaultMRTOptions<MeetWithParticipants>(
		Boolean(error),
	);

	const table = useMantineReactTable({
		data: meets ?? [],
		columns,
		state: {
			isLoading,
		},
		...defaultTableOptions,
		filterFns: {
			customStatusFilterFn: (row, _id, filterValue) =>
				customStatusFilterFn(row.original.status, MeetStatusEnum, filterValue),
			customDateFilterFn: (row, _id, filterValue) =>
				customDateFilterFn(row.original.startDate, filterValue),
			customMeetFilterFn: (row, _id, filterValue) =>
				isMatch(row.original.name, filterValue),
		},
	});

	return (
		<>
			<MantineReactTable table={table} />
			{deleteModalOpened && currentMeet && (
				<DeleteMeetModal
					handleClose={closeDeleteModal}
					meetId={currentMeet.id}
				/>
			)}
			{updateModalOpened && currentMeet && (
				<UpdateMeetModal handleClose={closeUpdateModal} meet={currentMeet} />
			)}
			{showParticipantsModalOpened && currentMeet && (
				<ShowMeetParticipantsModal
					handleClose={closeShowParticipantsModal}
					participants={currentMeet.participants ?? []}
					meetName={currentMeet.name}
					startDate={currentMeet.startDate}
				/>
			)}

			{joinModalOpened && currentMeet && (
				<JoinMeetModal handleClose={closeJoinModal} meet={currentMeet} />
			)}
		</>
	);
}
