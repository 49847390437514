import { Select } from '@mantine/core';
import { useGetResearches } from '../../../researches/api/researches.hooks';

type NotificationsResearchFilterProps = {
	researchId: number | null;
	setResearchId: (researchId: number | null) => void;
};

export const NotificationsResearchFilter = ({
	researchId,
	setResearchId,
}: NotificationsResearchFilterProps) => {
	const { researches } = useGetResearches();

	return (
		<Select
			value={researchId?.toString() ?? null}
			placeholder="Filtrer par recherche"
			size="md"
			w="auto"
			withCheckIcon={false}
			allowDeselect={false}
			clearable
			radius="lg"
			onChange={(value) => setResearchId(value ? parseInt(value) : null)}
			data={researches?.map((research) => {
				return { value: research.id.toString(), label: research.name };
			})}
		/>
	);
};
