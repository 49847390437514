import { useIsMobile } from '@/components/hooks/useIsMobile';
import { cn } from '@/components/utils';
import { Fieldset } from '@mantine/core';
import {
	LegalRepresentative,
	LegalRepresentativeRoleEnum,
} from '../../../profile/api/types/participants.types';
import { FieldInfo } from '../atoms/FieldInfo';

type LegalRepresentativeInfoProps = {
	legalRepresentative: LegalRepresentative;
};

export const LegalRepresentativeInfo = ({
	legalRepresentative,
}: LegalRepresentativeInfoProps) => {
	const isMobile = useIsMobile();
	const {
		firstName,
		lastName,
		email,
		phoneNumber,
		address,
		zipCode,
		city,
		role,
		additionalInfo,
	} = legalRepresentative;

	const location = `${address}, ${zipCode} ${city}`;

	return (
		<div className="flex gap-4 max-md:flex-col">
			<h5 className={cn(isMobile ? 'w-full' : 'w-1/4')}>
				{LegalRepresentativeRoleEnum[role]}
			</h5>
			<div className="flex flex-1 flex-col">
				<Fieldset
					p="lg"
					styles={{
						root: {
							backgroundColor: 'white',
						},
					}}
				>
					<div className="flex flex-col gap-12">
						<div className="flex flex-col gap-4">
							<div className="flex grow gap-4 max-md:flex-col">
								<FieldInfo label="Prénom" value={firstName} />
								<FieldInfo label="Nom" value={lastName} />
							</div>

							{role === 'FAMILY_MEMBER' && additionalInfo && (
								<FieldInfo label="Relation de parenté" value={additionalInfo} />
							)}

							<FieldInfo label="Téléphone" value={phoneNumber} />
							<FieldInfo label="Adresse postale" value={location} />
							<FieldInfo label="Adresse email" value={email} />
						</div>
					</div>
				</Fieldset>
			</div>
		</div>
	);
};
