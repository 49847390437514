import { ReactNode, createContext, useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { CustomLoader } from '../../../components/ui/loader';
import { paths } from '../../../routes/paths';
import { useGetMyselfOnResearch } from '../../usersOnResearch/api/hooks/users-on-research.hooks';
import { UserOnResearch } from '../../usersOnResearch/api/types/users-on-research.types';
import { useGetResearch } from '../api/researches.hooks';
import { Research } from '../api/researches.types';

export type ResearchContextData = {
	research: Research;
	userOnResearch: UserOnResearch;
};
export const ResearchContext = createContext({} as ResearchContextData);

type ResearchProviderProps = {
	children: ReactNode;
};
export const ResearchProvider = (props: ResearchProviderProps) => {
	const { researchId } = useParams();
	const { children } = props;

	const researchIdNumber = Number(researchId);
	const {
		research,
		isLoading: isResearchLoading,
		error: researchError,
	} = useGetResearch(researchIdNumber);

	const {
		userOnResearch,
		isLoading: isUserOnResearchLoading,
		error: userOnResearchError,
	} = useGetMyselfOnResearch(researchIdNumber);

	const isLoading = isResearchLoading || isUserOnResearchLoading;
	const error = researchError || userOnResearchError;
	if (isLoading) return <CustomLoader />;
	if (error || !research || !userOnResearch)
		return <Navigate to={`/${paths.ERROR_PATH}`} />;

	return (
		<ResearchContext.Provider
			value={{
				research: research,
				userOnResearch: userOnResearch,
			}}
		>
			{children}
		</ResearchContext.Provider>
	);
};

// eslint-disable-next-line react-refresh/only-export-components
export const useCurrentResearch = () => {
	return useContext(ResearchContext);
};
