import { z } from 'zod';
import { emailValidator } from '../../../../../shared/components/validators/commonValidators';

export type AddResearchMemberZodType = z.infer<
	typeof AddResearchMemberZodSchema
>;

export const AddResearchMemberZodSchema = z
	.object({
		email: emailValidator,
		role: z
			.string()
			.optional()
			.refine((value) => value, {
				message:
					"Veuillez saisir la fonction du membre de l'équipe de recherche",
			}),
		roleOnResearch: z
			.string()
			.optional()
			.refine((value) => value, {
				message: "Veuillez saisir le rôle du membre de l'équipe de recherche",
			}),
		roleAdditionalInfo: z.string().optional(),
	})
	.refine(
		(data) => {
			if (
				(data.roleOnResearch === 'OTHER' || data.roleOnResearch === 'DOCTOR') &&
				!data.roleAdditionalInfo
			)
				return false;
			return true;
		},
		{
			path: ['roleAdditionalInfo'],
			message: "Veuillez préciser le rôle du membre de l'équipe de recherche",
		},
	);
