import { z } from 'zod';
import { MAX_MESSAGE_LENGTH } from '../../../utils/constants';

export type CreateMessageZodType = z.infer<typeof CreateMessageZodSchema>;

export const CreateMessageZodSchema = z.object({
	content: z
		.string()
		.min(1, { message: 'Le contenu est requis' })
		.max(MAX_MESSAGE_LENGTH, {
			message: `Le contenu ne doit pas dépasser ${MAX_MESSAGE_LENGTH} caractères`,
		}),
});
