import { Button, Group, Modal, Stack, Text, Title } from '@mantine/core';
import classes from '../../../../../shared/styles/Modal.module.scss';

type Props = {
	handleSubmit: () => void;
	handleClose: () => void;
	isLoading: boolean;
};

export function ConfirmResponsesModal({
	handleClose,
	handleSubmit,
	isLoading,
}: Props) {
	return (
		<Modal opened={true} onClose={handleClose} centered classNames={classes}>
			<Stack gap="lg" m="lg">
				<Title order={3} ta="center">
					Etes-vous sûr de vouloir envoyer vos réponses ?
				</Title>
				<Text size="md" ta="center">
					Vous ne pourrez pas modifier vos réponses une fois celles-ci envoyées.
				</Text>
			</Stack>

			<Group grow gap="5%" mx="5%">
				<Button onClick={handleClose} variant="outline" size="md">
					Retour
				</Button>
				<Button
					variant="filled"
					size="md"
					loading={isLoading}
					onClick={handleSubmit}
				>
					Envoyer
				</Button>
			</Group>
		</Modal>
	);
}
