import { useIsMobile } from '@/components/hooks/useIsMobile';
import classes from '@/components/ui/modal/modal.module.scss';
import { ResearchMemberCard } from '@/features/usersOnResearch/components/cards/ResearchMemberCard';
import { Modal } from '@mantine/core';
import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';

type Props = {
	contacts: UserOnResearch[];
	handleClose: () => void;
};

export function ShowContactsModal({ contacts, handleClose }: Props) {
	const isMobile = useIsMobile();

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			title="Membres de l'équipe sur centre"
			size="lg"
			fullScreen={isMobile}
		>
			<div className="flex flex-col gap-6">
				<div className="flex flex-col items-center justify-center gap-2">
					{contacts.map((contact) => (
						<ResearchMemberCard key={contact.userId} user={contact} />
					))}
				</div>
			</div>
		</Modal>
	);
}
