import { useIsMobile } from '@/components/hooks/useIsMobile';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { useState } from 'react';
import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';
import { CreateDocumentWithoutKeyDto } from '../../api/documents.dto';
import { DocumentAccessLevelType } from '../../api/documents.types';
import { useUploadDocuments } from '../../api/hooks/useUploadDocuments';
import { DocumentRecipientsForm } from '../forms/DocumentRecipientsForm';
import { UploadDocumentsForm } from '../forms/UploadDocumentsForm';
import { Button } from '@/components/ui/button';

type AddResearchDocumentsModalProps = {
	handleClose: () => void;
};

export function AddResearchDocumentsModal({
	handleClose,
}: AddResearchDocumentsModalProps) {
	const isMobile = useIsMobile();
	const [stepForm, setStepForm] = useState<1 | 2>(1);
	const isFirstStep = stepForm === 1;

	const [accessLevel, setAccessLevel] =
		useState<DocumentAccessLevelType>('RESEARCH_CENTER');
	const [recipients, setRecipients] = useState<UserOnResearch[]>([]);
	const [documents, setDocuments] = useState<
		(CreateDocumentWithoutKeyDto & { file: File })[]
	>([]);

	const { uploadDocuments, isLoading } = useUploadDocuments();

	const handleCancel = () => {
		if (isFirstStep) handleClose();
		setStepForm(1);
	};
	const handleValidate = () => {
		if (isFirstStep) setStepForm(2);
		else handleSubmit();
	};
	const handleSubmit = async () => {
		await uploadDocuments(documents);
		handleClose();
	};

	const submitButtonText = isFirstStep
		? 'Suivant'
		: `Importer ${documents.length} fichier${documents.length > 1 ? 's' : ''} `;
	const cancelButtonText = isFirstStep ? 'Annuler' : 'Retour';

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			title="Importer des documents"
			size="lg"
			fullScreen={isMobile}
		>
			{isFirstStep && (
				<DocumentRecipientsForm
					accessLevel={accessLevel}
					setAccessLevel={setAccessLevel}
					recipients={recipients}
					setRecipients={setRecipients}
				/>
			)}
			{stepForm === 2 && (
				<UploadDocumentsForm
					accessLevel={accessLevel}
					recipients={recipients}
					documents={documents}
					setDocuments={setDocuments}
				/>
			)}

			<div className="flex gap-4">
				{!isMobile && (
					<Button onClick={handleCancel} variant="outline" className="w-full">
						{cancelButtonText}
					</Button>
				)}
				<Button
					onClick={handleValidate}
					disabled={(stepForm === 2 && documents.length === 0) || isLoading}
					className="w-full"
				>
					{submitButtonText}
				</Button>
			</div>
		</Modal>
	);
}
