import { z } from 'zod';
import {
	addressValidator,
	cityValidator,
	emailValidator,
	firstNameValidator,
	lastNameValidator,
	phoneNumberValidator,
	zipCodeValidator,
} from '../../../../../../shared/components/validators/commonValidators';

export const LegalRepresentativeZodSchema = z
	.object({
		email: emailValidator,
		firstName: firstNameValidator,
		role: z.enum([
			'PARENT1',
			'PARENT2',
			'LEGAL_REPRESENTATIVE',
			'GUARDIAN',
			'CUSTODIAN',
			'TRUSTED_PERSON',
			'FAMILY_MEMBER',
			'RELATIVE',
			'WITNESS',
		]),
		additionalInfo: z.string().trim().nullable().optional(),
		lastName: lastNameValidator,
		phoneNumber: phoneNumberValidator,
		address: addressValidator,
		zipCode: zipCodeValidator,
		city: cityValidator,
	})
	.refine(
		(data) => {
			if (data.role === 'FAMILY_MEMBER') return !!data.additionalInfo;
			return true;
		},
		{
			path: ['additionalInfo'],
			message: 'Veuillez préciser le lien de parenté',
		},
	);
export type LegalRepresentativeZodType = z.infer<
	typeof LegalRepresentativeZodSchema
>;
