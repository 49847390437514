import { ActionIcon, Tooltip } from '@mantine/core';
import { IconArrowRight, IconUsersPlus } from '@tabler/icons-react';
import {
	MRT_Cell,
	MRT_ColumnDef,
	MantineReactTable,
	useMantineReactTable,
} from 'mantine-react-table';
import { useMemo, useState } from 'react';
import { getDefaultMRTOptions } from '../../../../components/ui/table/table-options';

import { useDisclosure } from '@mantine/hooks';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../../routes/paths';
import { customDateFilterFn, isMatch } from '../../../../shared/utils/filters';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { getParticipantsByOffsiteResearchMember } from '../../api/queries/participants.queries';
import { getUsersOnResearch } from '../../api/queries/user-on-research.queries';
import { ParticipantOnResearch } from '../../api/types/participants.types';
import {
	UserOnResearch,
	UserOnResearchStatusEnum,
	UserOnResearchStatusType,
} from '../../api/types/users-on-research.types';
import { usersOnResearchQueryKeys } from '../../api/users-on-research.query-keys';
import { ResearchMemberOnResearchStatusBadge } from '../badges/ResearchMemberOnResearchStatusBadge';
import { AddCaregiverModal } from '../modals/AddCaregiverModal';
import { ParticipantCell } from './ParticipantCell';

export function ParticipantsTable() {
	const { research, userOnResearch } = useCurrentResearch();
	const navigate = useNavigate();

	const [currentParticipant, setCurrentParticipant] =
		useState<UserOnResearch>();

	const { queryKey, queryFn } =
		userOnResearch.role === 'OFFSITE_RESEARCH_MEMBER'
			? {
					queryKey: [
						usersOnResearchQueryKeys.listParticipantsByOffsiteResearchMember(
							research.id,
							userOnResearch.userId,
						),
					],
					queryFn: () =>
						getParticipantsByOffsiteResearchMember(
							research.id,
							userOnResearch.userId,
						),
				}
			: {
					queryKey: usersOnResearchQueryKeys.list(research.id, {
						roles: ['PARTICIPANT'],
					}),
					queryFn: () =>
						getUsersOnResearch(research.id, ['PARTICIPANT'], undefined),
				};

	const {
		data: participants,
		isLoading,
		error,
	} = useQuery<UserOnResearch[]>({
		queryKey,
		queryFn,
	});

	const canAddCaregiver = (participant: UserOnResearch) =>
		(userOnResearch.role === 'INVESTIGATOR' ||
			userOnResearch.role === 'ONSITE_RESEARCH_MEMBER') &&
		participant.status !== 'INACTIVE' &&
		!participant.participantOnResearch?.caresFor &&
		!participant.participantOnResearch?.caredForBy;
	const [
		caregiverModalOpened,
		{ open: openCaregiverModal, close: closeCaregiverModal },
	] = useDisclosure();
	const handleOnAddCaregiverButtonClick = (participant: UserOnResearch) => {
		setCurrentParticipant(participant);
		openCaregiverModal();
	};

	const handleOnParticipantClick = (participantId: number) => {
		navigate(
			`/${paths.RESEARCH_PATH}/${research.id}/${paths.PARTICIPANT_TAB}/${participantId}`,
		);
	};

	const columns = useMemo<MRT_ColumnDef<UserOnResearch>[]>(
		() => [
			{
				header: 'Participant',
				accessorFn(originalRow) {
					return originalRow;
				},
				size: 400,
				Cell: ({ cell }: { cell: MRT_Cell<UserOnResearch> }) => (
					<ParticipantCell
						participant={
							cell.row.original as UserOnResearch & {
								participantOnResearch: ParticipantOnResearch;
							}
						}
					/>
				),
				filterFn: 'participantFilterFn',
			},
			{
				header: 'Statut',
				accessorKey: 'status',
				size: 20,
				Cell: ({ cell }: { cell: MRT_Cell<UserOnResearch> }) => (
					<ResearchMemberOnResearchStatusBadge
						status={cell.getValue<UserOnResearchStatusType>()}
						userId={cell.row.original.userId}
						userRole={'PARTICIPANT'}
					/>
				),
				filterFn: (row, _id, filterValue) =>
					isMatch(UserOnResearchStatusEnum[row.original.status], filterValue),
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
			},
			{
				header: "Date d'ajout",
				accessorKey: 'createdAt',
				size: 5,
				Cell: ({ cell }: { cell: MRT_Cell<UserOnResearch> }) => (
					<p className="text-center text-sm">
						{dayjs(cell.getValue<Date>()).format('DD/MM/YY')}
					</p>
				),
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
				filterFn: (row, _id, filterValue) =>
					customDateFilterFn(row.original.createdAt, filterValue),
			},
			{
				id: 'action-add-caregiver',
				header: '',
				columnDefType: 'display',
				size: 1,
				Cell: ({ cell }: { cell: MRT_Cell<UserOnResearch> }) => (
					<>
						{canAddCaregiver(cell.row.original) && (
							<Tooltip label="Ajouter un aidant" radius="md">
								<ActionIcon
									variant="transparent"
									onClick={(e) => {
										e.stopPropagation();
										handleOnAddCaregiverButtonClick(cell.row.original);
									}}
								>
									<IconUsersPlus size={22} stroke={2} />
								</ActionIcon>
							</Tooltip>
						)}
					</>
				),
			},
			{
				id: 'actions',
				header: '',
				columnDefType: 'display',
				size: 1,
				Cell: () => <IconArrowRight className="text-primary" />,
			},
		],
		[], // eslint-disable-line react-hooks/exhaustive-deps
	);

	const defaultTableOptions = getDefaultMRTOptions<UserOnResearch>(
		Boolean(error),
	);
	const table = useMantineReactTable({
		data:
			(participants as (UserOnResearch & {
				participantOnResearch: ParticipantOnResearch;
			})[]) ?? [],
		columns,
		...defaultTableOptions,
		state: { isLoading },
		filterFns: {
			participantFilterFn: (row, _id, filterValue) =>
				isMatch(row.original.participantOnResearch?.code, filterValue),
		},
		globalFilterFn: 'participantFilterFn',
		enableSorting: false,
		mantineTableBodyRowProps: ({ row }) => ({
			onClick: () => handleOnParticipantClick(row.original.userId),
			style: { cursor: 'pointer' },
		}),
	});

	return (
		<>
			<MantineReactTable table={table} />
			{caregiverModalOpened && currentParticipant && (
				<AddCaregiverModal
					participant={currentParticipant}
					handleClose={closeCaregiverModal}
				/>
			)}
		</>
	);
}
