import { ParticipantProfile } from './participants.types';
import {
	InvestigatorProfile,
	OffsiteResearchMemberProfile,
	OnsiteResearchMemberProfile,
} from './research-member-profile.types';

export const RoleEnum = {
	PARTICIPANT: 'Participant',
	INVESTIGATOR: 'Investigateur',
	ONSITE_RESEARCH_MEMBER: "Membre de l'équipe de recherche sur centre",
	OFFSITE_RESEARCH_MEMBER: "Membre de l'équipe de recherche à distance",
};

export type Role = keyof typeof RoleEnum;

export type BaseUser = {
	userId: number;
	email: string;
	firstName: string;
	lastName: string;
	role: Role;
	phoneNumber: string;
	hasSignedUp: boolean;
};

export type UserProfile = BaseUser & {
	participantProfile?: ParticipantProfile;
	investigatorProfile?: InvestigatorProfile;
	onsiteResearchMemberProfile?: OnsiteResearchMemberProfile;
	offsiteResearchMemberProfile?: OffsiteResearchMemberProfile;
};
