import { Badge } from '@/components/ui/badge';
import {
	ResearchStatusEnum,
	ResearchStatusType,
} from '../../api/researches.types';

type ResearchStatusBadgeProps = {
	status: ResearchStatusType;
};

export const ResearchStatusBadge = ({ status }: ResearchStatusBadgeProps) => {
	const variant = status === 'ACTIVE' ? 'success' : 'info';
	return <Badge variant={variant}>{ResearchStatusEnum[status]}</Badge>;
};
