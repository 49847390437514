import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import { cn } from '@/components/utils';
import { useDisclosure } from '@mantine/hooks';
import { IconFileExport, IconPlus } from '@tabler/icons-react';
import { useSession } from '../../auth/shared/hooks/useSession';
import { useExportParticipantData } from '../api/hooks/participants.hooks';
import { AddParticipantModal } from '../components/modals/AddParticipantModal';
import { ParticipantsTable } from '../components/tables/ParticipantsTable';
import { ConfirmExportParticipantsDataModal } from '../components/modals/ConfirmExportParticipantsDataModal';

export function ParticipantsTab() {
	const [opened, { open, close }] = useDisclosure();
	const { user } = useSession();
	const isMobile = useIsMobile();

	const canAdd =
		user?.role === 'INVESTIGATOR' || user?.role === 'ONSITE_RESEARCH_MEMBER';

	const [
		confirmPasswordModalOpened,
		{ open: openConfirmPasswordModal, close: closeConfirmPasswordModal },
	] = useDisclosure();
	const { canExportParticipantData, exportParticipantData, isLoadingExport } =
		useExportParticipantData();

	return (
		<>
			<div className="flex h-full w-full flex-col gap-4">
				<div className="flex flex-col justify-start gap-2 md:flex-row md:justify-between">
					<h4>Suivi des participants</h4>
					<div className="flex gap-2 max-md:flex-col">
						{canAdd && (
							<Button
								size={isMobile ? 'xs' : 'sm'}
								className="w-fit"
								onClick={open}
							>
								<div className="flex flex-row items-center gap-1">
									{
										<IconPlus
											className={cn(isMobile ? 'h-4 w-4' : 'h-5 w-5')}
										/>
									}
									<p>Ajouter un participant</p>
								</div>
							</Button>
						)}
						{canExportParticipantData && (
							<Button
								size={isMobile ? 'xs' : 'sm'}
								className="w-fit"
								variant="outline"
								onClick={openConfirmPasswordModal}
								disabled={isLoadingExport}
							>
								<div className="flex flex-row items-center gap-1">
									{
										<IconFileExport
											className={cn(isMobile ? 'h-4 w-4' : 'h-5 w-5')}
										/>
									}
									<p>Exporter</p>
								</div>
							</Button>
						)}
					</div>
				</div>
				<ParticipantsTable />
			</div>
			{opened && <AddParticipantModal handleClose={close} />}
			{confirmPasswordModalOpened && (
				<ConfirmExportParticipantsDataModal
					onClose={closeConfirmPasswordModal}
					onPasswordConfirmed={exportParticipantData}
				/>
			)}
		</>
	);
}
