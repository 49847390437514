export const ResearchStatusEnum = {
	ACTIVE: 'En cours',
	CLOSED: 'Terminée',
};

export type ResearchStatusType = keyof typeof ResearchStatusEnum;

export type Research = {
	id: number;
	code: string;
	name: string;
	description: string;
	email: string;
	phoneNumber: string;
	address: string;
	zipCode: string;
	city: string;
	status: ResearchStatusType;
	forecastParticipantsCount: number;
	createdAt: Date;
	updatedAt: Date;
};
