import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { downloadActionDocument } from '../actions.queries';

export const useDownloadActionDocument = (actionId: number) => {
	const handleDownload = (signedUrl: string) => {
		const downloadLink = document.createElement('a');
		downloadLink.href = signedUrl;
		downloadLink.click();
	};

	const showSuccessNotification = () => {
		notifications.show({
			message: 'Document téléchargé',
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const downloadDocumentMutation = useMutation({
		mutationFn: async (documentId: number) => {
			return await downloadActionDocument(actionId, documentId);
		},
		onSuccess: (data) => {
			handleDownload(data.signedUrl);
			showSuccessNotification();
		},
		onError: showErrorNotification,
	});

	return { downloadDocumentMutation };
};
