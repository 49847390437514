import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteNote } from '../notes.mutations';
import { notesQueryKeys } from '../notes.query-keys';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';

export const useDeleteNote = (noteId: number) => {
	const { research } = useCurrentResearch();
	const queryClient = useQueryClient();

	const showSuccessNotification = () => {
		notifications.show({
			message: 'Note supprimée',
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const deleteNoteMutation = useMutation({
		mutationFn: async () => deleteNote(noteId),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: notesQueryKeys.list(research.id),
			});
			showSuccessNotification();
		},
		onError: () => {
			showErrorNotification();
		},
	});

	return { deleteNoteMutation };
};
