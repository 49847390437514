import { z } from 'zod';
import {
	addressValidator,
	cityValidator,
	firstNameValidator,
	medicalServiceValidator,
	phoneNumberValidator,
	rppsValidator,
	workplaceValidator,
	zipCodeValidator,
} from '../../../../../shared/components/validators/commonValidators';

export type OnsiteResearchMemberProfileZodType = z.infer<
	typeof OnsiteResearchMemberProfileZodSchema
>;

export const OnsiteResearchMemberProfileZodSchema = z.object({
	firstName: firstNameValidator.optional(),
	lastName: firstNameValidator.optional(),
	phoneNumber: phoneNumberValidator.optional(),
	onsiteResearchMemberProfile: z.object({
		workplaceName: workplaceValidator,
		workplaceAddress: addressValidator,
		workplaceCity: cityValidator,
		workplaceZipCode: zipCodeValidator,
		medicalService: medicalServiceValidator,
		rpps: rppsValidator.transform((rpps) => rpps ?? ''),
	}),
});
