import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { UpdateParticipantContactInfoDto } from './participant-contact.dto';

export const updateParticipantContactInfo = async (
	researchId: number,
	participantId: number,
	payload: UpdateParticipantContactInfoDto,
) => {
	try {
		const { data } = await privateAxios.patch(
			`participants/${participantId}/update-profile/research/${researchId}`,
			{ ...payload },
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
