import { CreateParticipantDto } from '../../api/dto/participants.dto.ts.ts';
import { UserOnResearch } from '../../api/types/users-on-research.types.ts';
import { ResearchMemberPicker } from './ResearchMemberPicker.tsx';

type Props<T> = {
	participants: T[];
	setParticipants: React.Dispatch<React.SetStateAction<T[]>>;
};

export function ParticipantControlPanel<
	T extends CreateParticipantDto | UserOnResearch,
>({ participants, setParticipants }: Props<T>) {
	const handleRemoveParticipant = (email: string) => {
		setParticipants((currParticipants) =>
			currParticipants.filter((participant) => participant.email !== email),
		);
	};

	return participants.length > 0 ? (
		<div className="flex flex-col gap-2">
			{participants.map((participant) => (
				<ResearchMemberPicker
					key={participant.email}
					user={participant}
					onRemoveUser={handleRemoveParticipant}
				/>
			))}
		</div>
	) : (
		<p className="text-center text-sm text-muted-foreground">
			Aucun participant sélectionné
		</p>
	);
}
