import { cn } from '@/components/utils';
import { Avatar, Group, Text } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import CaregiverAvatar from '../../../../assets/icons/caregiver-avatar.svg';
import ParticipantAvatar from '../../../../assets/icons/participant-avatar.svg';
import { paths } from '../../../../routes/paths';
import { useSession } from '../../../auth/shared/hooks/useSession';
import { BaseUser } from '../../../profile/api/types/user.types';
import { useGetParticipantsByOffsiteResearchMember } from '../../../usersOnResearch/api/hooks/participants.hooks';
import { ParticipantOnResearch } from '../../../usersOnResearch/api/types/participants.types';
import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';
import { useCurrentResearch } from '../../contexts/researches.context';

type ParticipantBadgeProps = {
	participant: UserOnResearch & {
		participantOnResearch: ParticipantOnResearch;
	};
};

export function CaregiverBadge({ participant }: ParticipantBadgeProps) {
	const { research } = useCurrentResearch();
	const { user } = useSession();
	const navigate = useNavigate();

	const getCaregiverInfo = (): {
		isCaregiver: boolean;
		caregiverInfo?: BaseUser & UserOnResearch;
	} => ({
		isCaregiver: Boolean(participant.participantOnResearch.caredForBy),
		caregiverInfo:
			participant.participantOnResearch.caredForBy ??
			participant.participantOnResearch.caresFor,
	});

	const { participants: linkedParticipants } =
		useGetParticipantsByOffsiteResearchMember(
			user?.userId ?? 0,
			undefined,
			user?.role === 'OFFSITE_RESEARCH_MEMBER',
		);

	const shouldActivateBadgeLink = () => {
		if (
			user?.role === 'INVESTIGATOR' ||
			user?.role === 'ONSITE_RESEARCH_MEMBER'
		)
			return true;
		if (user?.role !== 'OFFSITE_RESEARCH_MEMBER') return false;
		return linkedParticipants?.find(
			(p) =>
				p.participantOnResearch?.code ===
				getCaregiverInfo().caregiverInfo?.participantOnResearch?.code,
		);
	};
	const cursor = shouldActivateBadgeLink() ? 'pointer' : 'default';

	return (
		<div
			className="flex h-8 w-full cursor-pointer items-center gap-2 rounded-sm border border-solid border-gray-300 pr-1"
			onClick={(e) => {
				if (!shouldActivateBadgeLink()) return;
				e.stopPropagation();
				const caregiverParam = getCaregiverInfo().caregiverInfo?.userId;
				navigate(
					`/${paths.RESEARCH_PATH}/${research.id}/${paths.PARTICIPANT_TAB}/${caregiverParam}`,
				);
			}}
		>
			<div
				className={cn(
					'flex h-full items-center gap-0 rounded-sm pr-2',
					getCaregiverInfo().isCaregiver ? 'bg-primary' : 'bg-orange-500',
				)}
			>
				<Avatar
					src={
						getCaregiverInfo().isCaregiver ? CaregiverAvatar : ParticipantAvatar
					}
					size={24}
				/>
				<p className="text-xs text-primary-foreground">
					{getCaregiverInfo().isCaregiver ? 'Aidant' : 'Aidé'}
				</p>
			</div>
			<div className="flex items-center gap-1">
				<p className="truncate text-xs">
					{getCaregiverInfo().caregiverInfo?.participantOnResearch?.code}
				</p>
				{shouldActivateBadgeLink() && (
					<IconArrowRight className="h-4 w-4 text-primary" />
				)}
			</div>
		</div>
	);

	return (
		<Group
			bg="white"
			py="2px"
			px="2px"
			style={{
				border: '1px solid var(--mantine-color-gray-3)',
				borderRadius: 'var(--mantine-radius-xl)',
				cursor,
			}}
			onClick={(e) => {
				if (!shouldActivateBadgeLink()) return;
				e.stopPropagation();
				const caregiverParam = getCaregiverInfo().caregiverInfo?.userId;
				navigate(
					`/${paths.RESEARCH_PATH}/${research.id}/${paths.PARTICIPANT_TAB}/${caregiverParam}`,
				);
			}}
		>
			<Group
				bg={
					getCaregiverInfo().isCaregiver
						? 'var(--mantine-primary-color-6)'
						: 'var(--mantine-color-custom-orange-6)'
				}
				style={{
					border: '1px solid var(--mantine-color-gray-3)',
					borderRadius: 'var(--mantine-radius-xl)',
				}}
				gap={0}
				py="4px"
				px="4px"
			>
				<Avatar
					src={
						getCaregiverInfo().isCaregiver ? CaregiverAvatar : ParticipantAvatar
					}
					size={24}
				/>
				<Text size="sm" c="white" fw={600} mr="xs">
					{getCaregiverInfo().isCaregiver ? 'Aidant' : 'Aidé'}
				</Text>
			</Group>
			<Group gap="xs" mr="xs">
				<Text size="sm" fw={500} c="var(--mantine-primary-color-6)">
					{getCaregiverInfo().caregiverInfo?.participantOnResearch?.code}
				</Text>
				{shouldActivateBadgeLink() && (
					<IconArrowRight size={16} color="var(--mantine-primary-color-6)" />
				)}
			</Group>
		</Group>
	);
}
