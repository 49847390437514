import { Button, Group, Modal, Stack, Text, Title } from '@mantine/core';
import { useState } from 'react';
import { FileDropzone } from '../../../../../shared/components/files/FileDropzone';
import FilesControlPanel from '../../../../../shared/components/files/FilesControlPanel';
import classes from '../../../../../shared/styles/Modal.module.scss';
import { S3File } from '../../../../../shared/types/S3File';
import { useUploadActionResultsDocuments } from '../api/hooks/useUploadActionResultDocuments';

type Props = {
	actionId: number;
	handleClose: () => void;
};

export function UploadActionDocumentsModal({ actionId, handleClose }: Props) {
	const [files, setFiles] = useState<S3File[]>([]);

	const { uploadActionDocuments, isLoading } =
		useUploadActionResultsDocuments();

	const handleSubmit = async () => {
		await uploadActionDocuments(actionId, files);
		setFiles([]);
		handleClose();
	};

	const handleFilesUpload = (files: File[]) => {
		const formattedFiles = files.map((file) => ({
			fileName: file.name,
			file,
		}));
		setFiles((currFiles) => [...currFiles, ...formattedFiles]);
	};

	const handleRemoveFile = (name: string) => {
		setFiles(files.filter(({ file }) => file.name !== name));
	};

	return (
		<Modal
			opened={true}
			onClose={() => {
				setFiles([]);
				handleClose();
			}}
			centered
			classNames={classes}
		>
			<Stack gap="lg" m="lg">
				<Stack gap="xs">
					<Title order={3} ta="center">
						Choisir un ou pluiseurs fichiers
					</Title>
					<Text ta="center">
						Ces documents seront partagés à l'équipe de recherche
					</Text>
				</Stack>

				<Stack>
					<FileDropzone onFilesUpload={handleFilesUpload} />
					<FilesControlPanel files={files} onRemoveFile={handleRemoveFile} />
				</Stack>

				<Group grow mx="1%" gap="5%" mb={0}>
					<Button
						onClick={() => {
							setFiles([]);
							handleClose();
						}}
						variant="outline"
						size="md"
					>
						Annuler
					</Button>
					<Button
						variant="filled"
						size="md"
						onClick={handleSubmit}
						disabled={files.length === 0}
						loading={isLoading}
					>
						Importer{' '}
						{files.length > 0
							? files.length === 1
								? '1 fichier'
								: `${files.length} fichiers`
							: ''}
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
