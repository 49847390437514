import { useQuery } from '@tanstack/react-query';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';
import { getMeetsByParticipant, getResearchMeets } from '../meets.queries';
import { meetsQueryKeys } from '../meets.query-keys';
import { MeetWithParticipants } from '../meets.types';

export const useGetAllMeets = () => {
	const { research } = useCurrentResearch();
	const { participant } = useCurrentParticipant();

	const { queryKey, queryFn } = participant
		? {
				queryKey: meetsQueryKeys.listByUser(research.id, participant.userId),
				queryFn: () => getMeetsByParticipant(research.id, participant.userId),
			}
		: {
				queryKey: meetsQueryKeys.listByResearch(research.id),
				queryFn: () => getResearchMeets(research.id),
			};

	const {
		data: meets,
		isLoading,
		error,
	} = useQuery<MeetWithParticipants[]>({
		queryKey,
		queryFn,
	});

	return { meets, isLoading, error };
};
