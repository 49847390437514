import { FileIcon } from './FileIcon';

type Props = {
	fileName: string;
	fileSize?: number;
};

export function FileTile({ fileName, fileSize }: Props) {
	return (
		<div className="flex items-center gap-2">
			<FileIcon fileName={fileName} />
			<div className="flex flex-col">
				<p className="line-clamp-2">{fileName}</p>
				{fileSize && (
					<p className="text-sm text-muted-foreground">
						{Math.floor(fileSize / 1024)} Ko
					</p>
				)}
			</div>
		</div>
	);
}
