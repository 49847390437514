import { HelpTabs } from './HelpTabs';

export function HelpPage() {
	return (
		<div className="flex h-full w-full flex-col gap-4">
			<h2>Aide</h2>
			<HelpTabs />
		</div>
	);
}
