import { useDisclosure } from '@mantine/hooks';
import {
	IconEraser,
	IconEye,
	IconFolderDown,
	IconPencil,
} from '@tabler/icons-react';
import { useMemo } from 'react';
import { useSession } from '../../../../auth/shared/hooks/useSession';
import { useCurrentResearch } from '../../../../researches/contexts/researches.context';
import { useCurrentParticipant } from '../../../../usersOnResearch/contexts/participants.context';
import { getActionPermissions } from '../../../utils/actionPermissions';
import {
	Action,
	ActionWithResult,
	isActionWithResult,
} from '../../api/actions.types';
import { ActionButton } from '../../components/atoms/ActionButton';
import { ActionHeader } from '../../components/atoms/ActionHeader';
import { ActionResults } from '../../components/atoms/ActionResults';
import { CompleteResponsesModal } from '../../components/modals/CompleteResponsesModal';
import { ConfirmResetResultModal } from '../../components/modals/ConfirmResetResultModal';
import { ViewResponsesModal } from '../../components/modals/ViewResponsesModal';
import { useExportDynamicTableData } from '../api/dynamic-tables.hooks';

type DynamicTableActionItemProps = {
	action: Action | ActionWithResult;
};
export const DynamicTableActionItem = ({
	action,
}: DynamicTableActionItemProps) => {
	const { participant } = useCurrentParticipant();
	const { user } = useSession();
	const { research, userOnResearch } = useCurrentResearch();

	const exportTitle = `${
		research.name
	} - ${action.visit.name} - ${action.name} - Résultats - ${new Date().toLocaleDateString()}`;
	const { exportResultsMutation } = useExportDynamicTableData(
		action.id,
		exportTitle,
	);

	const [
		completeModalOpened,
		{ open: completeModalOpen, close: completeModalClose },
	] = useDisclosure();
	const [
		viewResponsesModalOpened,
		{ open: viewResponsesModalOpen, close: viewResponsesModalClose },
	] = useDisclosure();
	const [resetModalOpened, { open: resetModalOpen, close: resetModalClose }] =
		useDisclosure();

	const permissions = getActionPermissions(action as Action, user?.role);

	const actionButtonProps = useMemo(() => {
		if (userOnResearch.status === 'INACTIVE') return null;
		if (!participant && !isActionWithResult(action))
			return {
				label: 'Exporter les résultats',
				icon: <IconFolderDown />,
				disabled: action.resultsCount === 0,
				handleClick: () => {
					if (action.resultsCount === 0) return;
					exportResultsMutation.mutate();
				},
			};
		if (isActionWithResult(action)) {
			const completed = action.result?.completed;
			if (completed && permissions.canRead)
				return {
					label: 'Voir le résultat',
					icon: <IconEye />,
					handleClick: viewResponsesModalOpen,
				};
			if (!completed && permissions.canWrite)
				return {
					label: 'Compléter',
					icon: <IconPencil />,
					handleClick: completeModalOpen,
				};
		}
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [participant, user, action]);

	const shouldDisplayResetButton =
		isActionWithResult(action) &&
		action.result?.completed &&
		permissions.canWrite &&
		(user?.role === 'INVESTIGATOR' || user?.role === 'ONSITE_RESEARCH_MEMBER');

	const resetButtonProps = {
		label: 'Réinitialiser',
		icon: <IconEraser />,
		color: 'red',
		handleClick: resetModalOpen,
	};

	return (
		<>
			<div className="mt-3 grid grid-cols-8 gap-2">
				<div className="col-span-8 my-auto flex flex-col gap-1 md:col-span-4">
					<ActionHeader action={action} />
				</div>
				<div className="my-auto hidden flex-col gap-1 md:col-span-2 md:block md:pl-8">
					{!participant && !isActionWithResult(action) && (
						<ActionResults action={action} />
					)}
				</div>
				<div className="my-auto hidden flex-col gap-1 md:col-span-2 md:block">
					<div className="flex items-center justify-center gap-2">
						{actionButtonProps && <ActionButton {...actionButtonProps} />}
						{shouldDisplayResetButton && <ActionButton {...resetButtonProps} />}
					</div>
				</div>
			</div>
			{completeModalOpened && (
				<CompleteResponsesModal
					handleClose={completeModalClose}
					actionId={action.id}
					actionType={action.actionType}
				/>
			)}
			{viewResponsesModalOpened && (
				<ViewResponsesModal
					handleClose={viewResponsesModalClose}
					actionId={action.id}
					actionType={action.actionType}
				/>
			)}
			{resetModalOpened && (
				<ConfirmResetResultModal
					handleClose={resetModalClose}
					actionId={action.id}
					actionType={action.actionType}
				/>
			)}
		</>
	);
};
