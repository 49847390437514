import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useInvalidateQueries } from '../../../../shared/hooks/useInvalidateQueries';
import { useSession } from '../../../auth/shared/hooks/useSession';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { CreateMeetDto } from '../meets.dto';
import { createMeet } from '../meets.mutations';
import { meetsQueryKeys } from '../meets.query-keys';

export const useCreateMeetMutation = () => {
	const { research } = useCurrentResearch();

	const { user } = useSession();
	const invalidateQueries = useInvalidateQueries();

	const showSuccessNotification = () => {
		notifications.show({
			message: 'La visioconférence a bien été créée',
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const createMeetMutation = useMutation({
		mutationFn: async (payload: CreateMeetDto) =>
			createMeet(payload, research.id),
		onSuccess: () => {
			invalidateQueries([
				meetsQueryKeys.listByResearch(research.id),
				meetsQueryKeys.listAllByUser(user?.userId ?? 0, {
					status: 'UPCOMING',
				}),
			]);
			showSuccessNotification();
		},
		onError: () => {
			showErrorNotification();
		},
	});

	return { createMeetMutation };
};
