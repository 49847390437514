import { Role } from '../../profile/api/types/user.types';
import { Action } from '../actions/api/actions.types';

export const getActionPermissions = (action: Action, userRole?: Role) => {
	const permissions = {
		canWrite: true,
		canRead: true,
	};

	if (userRole === 'OFFSITE_RESEARCH_MEMBER') {
		permissions.canWrite = action.canOffsiteResearchMemberSetResult;
		permissions.canRead = action.canOffsiteResearchMemberViewResult;
	}

	if (userRole === 'PARTICIPANT') {
		permissions.canWrite = action.canParticipantSetResult;
		permissions.canRead = action.canParticipantViewResult;
	}

	return permissions;
};
