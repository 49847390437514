import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { DashboardData } from '../types/DashboardData';

export const getDashboardData = async (researchId: number) => {
	try {
		const { data } = await privateAxios.get<DashboardData>(
			`/research/${researchId}/dashboard`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
