import { useCallback, useEffect, useRef } from 'react';

export const useDragScroll = () => {
	const nodeRef = useRef<HTMLDivElement>(null);

	const handleTouchStart = useCallback(
		(e: TouchEvent) => {
			const node = nodeRef.current;
			if (!node) {
				return;
			}
			const touch = e.touches[0];
			const startPos = {
				left: node.scrollLeft,
				top: node.scrollTop,
				x: touch.clientX,
				y: touch.clientY,
			};

			const handleTouchMove = (e: TouchEvent) => {
				const touch = e.touches[0];
				const dx = touch.clientX - startPos.x;
				node.scrollLeft = startPos.left - dx;
				updateCursor(node);
			};

			const handleTouchEnd = () => {
				document.removeEventListener('touchmove', handleTouchMove);
				document.removeEventListener('touchend', handleTouchEnd);
				resetCursor(node);
			};

			document.addEventListener('touchmove', handleTouchMove);
			document.addEventListener('touchend', handleTouchEnd);
		},
		[nodeRef],
	);

	const updateCursor = (ele: HTMLElement) => {
		ele.style.cursor = 'grabbing';
		ele.style.userSelect = 'none';
	};

	const resetCursor = (ele: HTMLElement) => {
		ele.style.cursor = 'grab';
		ele.style.removeProperty('user-select');
	};

	useEffect(() => {
		const node = nodeRef.current;
		if (!node) {
			return;
		}
		// node.addEventListener('mousedown', handleMouseDown);
		node.addEventListener('touchstart', handleTouchStart);
		return () => {
			// node.removeEventListener('mousedown', handleMouseDown);
			node.removeEventListener('touchstart', handleTouchStart);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [nodeRef]);

	return nodeRef;
};
