import { AxiosResponse } from 'axios';
import { privateAxios } from '../../../../shared/services/privateAxios';
import { ParticipantProfile } from '../../../profile/api/types/participants.types';
import {
	InvestigatorProfile,
	OffsiteResearchMemberProfile,
	OnsiteResearchMemberProfile,
} from '../../../profile/api/types/research-member-profile.types';
import { Role } from '../../../profile/api/types/user.types';
import {
	UserOnResearch,
	UserOnResearchStatusType,
} from '../types/users-on-research.types';

export const getMyselfOnResearch = async (
	researchId: number,
): Promise<UserOnResearch> => {
	try {
		const { data } = await privateAxios.get<UserOnResearch>(
			`/researches/${researchId}/me`,
		);
		return data;
	} catch (error) {
		throw error as AxiosResponse;
	}
};

export const getUsersOnResearch = async (
	researchId: number,
	roles?: Role[],
	statuses?: UserOnResearchStatusType[],
): Promise<UserOnResearch[]> => {
	try {
		const { data } = await privateAxios.get<UserOnResearch[]>(
			`/researches/${researchId}/get-research-members`,
			{
				params: { status: statuses, role: roles },
			},
		);
		return data;
	} catch (error) {
		throw error as AxiosResponse;
	}
};

export const getUserOnResearchDetails = async (
	researchId: number,
	userId: number,
): Promise<
	UserOnResearch & {
		participantProfile?: ParticipantProfile;
		investigatorProfile?: InvestigatorProfile;
		onsiteResearchMemberProfile?: OnsiteResearchMemberProfile;
		offsiteResearchMemberProfile?: OffsiteResearchMemberProfile;
	}
> => {
	try {
		const { data } = await privateAxios.get<UserOnResearch>(
			`/researches/${researchId}/get-research-member-details/${userId}`,
		);
		return data;
	} catch (error) {
		throw error as AxiosResponse;
	}
};

export const getUsersOnResearchDetails = async (
	researchId: number,
	roles?: Role[],
	statuses?: UserOnResearchStatusType[],
): Promise<
	(UserOnResearch & {
		participantProfile?: ParticipantProfile;
		investigatorProfile?: InvestigatorProfile;
		onsiteResearchMemberProfile?: OnsiteResearchMemberProfile;
		offsiteResearchMemberProfile?: OffsiteResearchMemberProfile;
	})[]
> => {
	try {
		const { data } = await privateAxios.get<UserOnResearch[]>(
			`/researches/${researchId}/get-research-members-details`,
			{
				params: { status: statuses, role: roles },
			},
		);
		return data;
	} catch (error) {
		throw error as AxiosResponse;
	}
};
