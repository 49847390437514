import CaregiverAvatar from '../../../assets/icons/caregiver-avatar.svg';
import OffsiteAvatar from '../../../assets/icons/offsite-avatar.svg';
import OnsiteAvatar from '../../../assets/icons/onsite-avatar.svg';
import ParticipantAvatar from '../../../assets/icons/participant-avatar.svg';
import {
	InvestigatorRoleEnum,
	OffsiteResearchMemberRole,
	OffsiteResearchMemberRoleEnum,
	OnsiteResearchMemberRole,
	OnsiteResearchMemberRoleEnum,
} from '../api/types/research-members.types';

import { UserOnResearch } from '../api/types/users-on-research.types';
import {
	isCaregiver,
	isInvestigator,
	isOffsiteResearchMember,
	isOnsiteResearchMember,
	isParticipant,
} from './validateRoles';

export const getResearchMemberInfo = (
	user: UserOnResearch,
): { avatar: string; displayTitle: string; displayRole: string } => {
	const userFullname = `${user.firstName} ${user.lastName?.toUpperCase()}`;
	if (isInvestigator(user))
		return {
			avatar: OnsiteAvatar,
			displayTitle:
				user.status === 'PENDING' ? 'Membre non inscrit' : userFullname,
			displayRole:
				InvestigatorRoleEnum[user.investigatorOnResearch.roleOnResearch],
		};
	if (isOnsiteResearchMember(user))
		return {
			avatar: OnsiteAvatar,
			displayTitle:
				user.status === 'PENDING' ? 'Membre non inscrit' : userFullname,
			displayRole:
				OnsiteResearchMemberRoleEnum[
					user.onsiteResearchMemberOnResearch
						.roleOnResearch as OnsiteResearchMemberRole
				],
		};

	if (isOffsiteResearchMember(user)) {
		let displayRole = '';
		const roleOnResearch = user.offsiteResearchMemberOnResearch.roleOnResearch;
		const roleAdditionalInfo =
			user.offsiteResearchMemberOnResearch.roleAdditionalInfo;
		if (roleOnResearch === 'OTHER') displayRole = roleAdditionalInfo || '';
		else if (roleOnResearch === 'DOCTOR')
			displayRole =
				OffsiteResearchMemberRoleEnum.DOCTOR + ' - ' + roleAdditionalInfo;
		else {
			displayRole =
				OffsiteResearchMemberRoleEnum[
					roleOnResearch as OffsiteResearchMemberRole
				];
		}
		return {
			avatar: OffsiteAvatar,
			displayTitle:
				user.status === 'PENDING' ? 'Membre non inscrit' : userFullname,
			displayRole,
		};
	}
	if (isParticipant(user))
		return {
			avatar: isCaregiver(user) ? CaregiverAvatar : ParticipantAvatar,
			displayTitle: user.participantOnResearch.code,
			displayRole: '',
		};
	return {
		avatar: OnsiteAvatar,
		displayTitle: 'Centre de recherche',
		displayRole: '',
	};
};
