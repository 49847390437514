import { Checkbox, Group, Text, UnstyledButton } from '@mantine/core';
import classes from '../../../../shared/styles/CheckboxCard.module.scss';

export type VisitLocationCheckboxCardProps = {
	label: string;
	checked: boolean;
	onChange: () => void;
	disabled?: boolean;
};

export const VisitLocationCardCheckbox = ({
	checked,
	label,
	onChange: handleChange,
	disabled = false,
}: VisitLocationCheckboxCardProps) => {
	return (
		<UnstyledButton
			w="100%"
			className={classes.control}
			data-checked={checked || undefined}
			onClick={(e) => {
				e.stopPropagation();
				!disabled && handleChange();
			}}
		>
			<Group justify="space-between">
				<Text size="sm" fw={500}>
					{label}
				</Text>
				<Checkbox
					classNames={{
						root: classes.checkboxWrapper,
					}}
					checked={checked}
					tabIndex={-1}
					readOnly
					size="sm"
					disabled={disabled}
				/>
			</Group>
		</UnstyledButton>
	);
};
