import { QueryKey, useQueryClient } from '@tanstack/react-query';

export const useInvalidateQueries = () => {
	const queryClient = useQueryClient();

	const invalidateQueries = (queryKeys: QueryKey[]) => {
		queryKeys.forEach((queryKey) => {
			queryClient.invalidateQueries({ queryKey });
		});
	};

	return invalidateQueries;
};
