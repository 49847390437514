import { IconArrowRight } from '@tabler/icons-react';
import {
	MRT_Cell,
	MRT_ColumnDef,
	MantineReactTable,
	useMantineReactTable,
} from 'mantine-react-table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDefaultMRTOptions } from '../../../../components/ui/table/table-options';
import { paths } from '../../../../routes/paths';
import {
	customStatusFilterFn,
	isMatch,
} from '../../../../shared/utils/filters';
import { useSession } from '../../../auth/shared/hooks/useSession';
import { useGetResearches } from '../../api/researches.hooks';
import {
	Research,
	ResearchStatusEnum,
	ResearchStatusType,
} from '../../api/researches.types';
import { ResearchStatusBadge } from '../ui/ResearchStatusBadge';

export function ResearchesTable() {
	const { user } = useSession();
	const navigate = useNavigate();
	const { researches, isLoading, error } = useGetResearches();

	const handleOnResearchClick = (researchId: number) => {
		let route = '';
		if (user?.role === 'PARTICIPANT' && user.userId)
			route = `${researchId}/${paths.PARTICIPANT_TAB}/${user.userId}`;
		if (user?.role === 'OFFSITE_RESEARCH_MEMBER')
			route = `${researchId}/${paths.PARTICIPANTS_TAB}`;
		if (
			user?.role === 'INVESTIGATOR' ||
			user?.role === 'ONSITE_RESEARCH_MEMBER'
		)
			route = `${researchId}/${paths.DASHBOARD_TAB}`;

		if (!route) return;
		navigate(route);
	};

	const columns = useMemo<MRT_ColumnDef<Research>[]>(
		() => [
			{
				header: 'Nom de la recherche',
				accessorKey: 'name',
				size: 200,
				Cell: ({ cell }: { cell: MRT_Cell<Research> }) => (
					<p className="line-clamp-2 text-md font-semibold">
						{cell.getValue<string>()}
					</p>
				),
				enableSorting: false,
			},
			{
				header: 'Numéro',
				accessorKey: 'code',
				size: 10,
				Cell: ({ cell }: { cell: MRT_Cell<Research> }) => (
					<p className="word-break line-clamp-2 text-sm">
						{cell.getValue<string>()}
					</p>
				),
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
			},
			{
				header: 'Statut',
				accessorKey: 'status',
				size: 10,
				Cell: ({ cell }: { cell: MRT_Cell<Research> }) => (
					<ResearchStatusBadge status={cell.getValue<ResearchStatusType>()} />
				),
				filterFn: (row, _id, filterValue) =>
					isMatch(ResearchStatusEnum[row.original.status], filterValue),
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
			},
			{
				header: 'Description',
				accessorKey: 'description',
				size: 400,
				Cell: ({ cell }: { cell: MRT_Cell<Research> }) => (
					<p className="line-clamp-2 text-sm">{cell.getValue<string>()}</p>
				),
			},
			{
				id: 'actions',
				header: '',
				columnDefType: 'display', //turns off data column features like sorting, filtering, etc.
				accessorKey: 'id',
				size: 5,
				Cell: () => <IconArrowRight className="text-primary" />,
			},
		],
		[], // eslint-disable-line react-hooks/exhaustive-deps
	);

	const defaultTableOptions = getDefaultMRTOptions<Research>(Boolean(error));

	const table = useMantineReactTable({
		data: researches ?? [],
		columns,
		...defaultTableOptions,
		state: { isLoading },
		filterFns: {
			customStatusFilterFn: (row, _id, filterValue) =>
				customStatusFilterFn(
					row.original.status,
					ResearchStatusEnum,
					filterValue,
				),
			customResearchFilterFn: (row, _id, filterValue) =>
				isMatch(row.original.name, filterValue),
		},
		globalFilterFn: 'customResearchFilterFn',
		mantineTableBodyRowProps: ({ row }) => ({
			onClick: () => handleOnResearchClick(row.original.id),
			style: { cursor: 'pointer' },
		}),
	});
	return <MantineReactTable table={table} />;
}
