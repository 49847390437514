import { cn } from '@/components/utils';
import { UserOnResearch } from '@/features/usersOnResearch/api/types/users-on-research.types';
import { getResearchMemberInfo } from '@/features/usersOnResearch/utils/researchMemberInfo';
import { isSameDay } from '@/shared/utils/dates';
import dayjs from 'dayjs';
import { Message } from '../../api/messages.types';

type Props = {
	message: Message;
	sent: boolean;
};
export const MessageItem = ({ message, sent }: Props) => {
	const currentDate = new Date();
	const { displayTitle } = getResearchMemberInfo(
		message.emitter as UserOnResearch,
	);
	const timeElapsed = isSameDay(message.createdAt, currentDate)
		? dayjs(message.createdAt).fromNow()
		: dayjs(message.createdAt).format('DD MMM YYYY');

	return (
		<div
			className={cn(
				sent ? 'self-end' : 'self-start',
				'flex w-3/5 flex-col gap-1 max-md:w-4/5',
			)}
		>
			<div className="flex justify-between">
				<p className="text-sm">{sent ? 'Vous' : displayTitle}</p>
				<p className="text-xs text-muted-foreground">{timeElapsed}</p>
			</div>
			<div
				className={cn(
					sent
						? 'rounded-bl-[8px] rounded-br-[8px] rounded-tl-[8px] bg-primary text-primary-foreground'
						: 'rounded-bl-[8px] rounded-br-[8px] rounded-tr-[8px] bg-muted text-muted-foreground',
					'border border-gray-50 px-3 py-2',
				)}
			>
				<p className="whitespace-pre-line break-words">{message.content}</p>
			</div>
		</div>
	);
};
