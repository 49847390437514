import { useQuery } from '@tanstack/react-query';
import { calendarQueryKeys } from '../../../calendar/api/calendar.query-keys';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { getVisitSchedule } from '../visits.queries';

export const useGetVisitSchedule = (scheduledVisitId: number) => {
	const { research } = useCurrentResearch();

	const {
		data: schedule,
		isLoading,
		error,
	} = useQuery({
		queryKey: calendarQueryKeys.detail(research.id, scheduledVisitId),
		queryFn: () => getVisitSchedule(research.id, scheduledVisitId),
	});

	return { schedule, isLoading, error };
};
