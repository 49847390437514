import { useIsMobile } from '@/components/hooks/useIsMobile';
import { HomeNavigationMenu } from '@/components/ui/menu/home-navigation-menu';
import { MobileHeader } from '@/components/ui/menu/mobile-header';
import { Outlet } from 'react-router-dom';

export function AppLayout() {
	const isMobile = useIsMobile();
	return (
		<div className="flex h-screen w-screen flex-col md:flex-row">
			{isMobile ? (
				<MobileHeader />
			) : (
				<div className="m-0 bg-primary p-0 md:w-[205px] md:min-w-[205px] lg:w-[250px] lg:min-w-[250px] ">
					<HomeNavigationMenu />
				</div>
			)}
			<div className="flex h-full w-full overflow-y-scroll bg-muted p-4 pr-6">
				<Outlet />
			</div>
		</div>
	);
}
