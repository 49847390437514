import { formatDate } from '../../../shared/utils/dates';
import {
	InvestigatorProfile,
	OffsiteResearchMemberProfile,
	OnsiteResearchMemberProfile,
} from '../../profile/api/types/research-member-profile.types';
import { RoleEnum } from '../../profile/api/types/user.types';
import {
	UserOnResearch,
	UserOnResearchStatusEnum,
} from '../api/types/users-on-research.types';
import { getResearchMemberInfo } from './researchMemberInfo';

export const formatResearchMemberData = (
	researchMember: UserOnResearch & {
		offsiteResearchMemberProfile?: OffsiteResearchMemberProfile;
		onsiteResearchMemberProfile?: OnsiteResearchMemberProfile;
		investigatorProfile?: InvestigatorProfile;
	},
) => {
	const basicInfo = {
		Email: researchMember.email,
		Nom: researchMember.lastName ?? '',
		Prénom: researchMember.firstName ?? '',
		Fonction: RoleEnum[researchMember.role],
		'Sous fonction': getResearchMemberInfo(researchMember).displayRole,
		Statut: UserOnResearchStatusEnum[researchMember.status],
		"Date d'ajout": formatDate(researchMember.createdAt, 'D MMM YYYY'),
		'Date de désactivation':
			researchMember.status === 'INACTIVE' && researchMember.deactivatedAt
				? formatDate(researchMember.deactivatedAt, 'D MMM YYYY')
				: '',
		'Numéro de téléphone': researchMember.phoneNumber ?? '',
	};

	const profile =
		researchMember.offsiteResearchMemberProfile ??
		researchMember.onsiteResearchMemberProfile ??
		researchMember.investigatorProfile;

	if (!profile) return basicInfo;

	return {
		...basicInfo,
		'Identifiant RPPS': profile.rpps ?? '',
		'Lieu de travail':
			researchMember.onsiteResearchMemberProfile ||
			researchMember.investigatorProfile
				? (profile as InvestigatorProfile | OnsiteResearchMemberProfile)
						.workplaceName ?? ''
				: '',
		'Service médical': profile.medicalService ?? '',
		'Adresse du lieu de travail': profile.workplaceAddress ?? '',
		'Code postal du lieu de travail': profile.workplaceZipCode ?? '',
		'Ville du lieu de travail': profile.workplaceCity ?? '',
	};
};
