import { AxiosError } from 'axios';
import { privateAxios } from '../../../../../shared/services/privateAxios';
import { DynamicTable, DynamicTableData } from './dynamic-tables.types';

export const getDynamicTableData = async (
	actionId: number,
	userId?: number,
) => {
	try {
		const { data } = await privateAxios.get<DynamicTableData>(
			`/visits/action/${actionId}/table-data`,
			{
				params: { participantId: userId },
			},
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getDynamicTablePreview = async (actionId: number) => {
	try {
		const { data } = await privateAxios.get<DynamicTable>(
			`/visits/action/${actionId}/table`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
