import {
	UserOnResearch,
	UserOnResearchStatusType,
} from '../../../usersOnResearch/api/types/users-on-research.types';

export type Action = {
	id: number;
	visit: {
		id: number;
		name: string;
		researchId: number;
	};
	actionType: ActionType;
	questionnaireId?: number;
	name: string;
	description: string;
	createdAt: Date;
	actionDocuments: number[];
	participantsCount: number;
	resultsCount: number;
	canOffsiteResearchMemberSetResult: true;
	canOffsiteResearchMemberViewResult: true;
	canParticipantSetResult: true;
	canParticipantViewResult: true;
};

export const ActionTypeEnum = {
	QUESTIONNAIRE: 'Questionnaire',
	DOCUMENT: 'Document',
	TABLE: 'Tableau',
	OTHER: 'Action libre',
};

export type ActionType = keyof typeof ActionTypeEnum;

export type ActionResult = {
	id: number;
	action?: {
		id: number;
		name: string;
	};
	visit?: {
		id: number;
		name: string;
		scheduledDate?: Date;
		forecastDate?: Date;
	};
	participant: UserOnResearch;
	documentsCount?: number;
	completed: boolean;
	updatedAt: Date;
	updatedBy: UserOnResearch;
};

export type ActionResultExportData = {
	participantCode: string;
	participantStatus: UserOnResearchStatusType;
	activatedAt: Date;
	visitName: string;
	visitForecastDate: Date;
	visitScheduledDate: Date;
	actionName: string;
	completed: boolean;
	updatedBy: string;
	updatedAt: Date;
};

export type ActionWithResult = Omit<
	Action,
	'participantsCount' | 'resultsCount'
> & {
	result?: ActionResult;
};

export const isActionWithResult = (
	action: Action | ActionWithResult,
): action is ActionWithResult => {
	return (action as Action).participantsCount === undefined;
};
