import { SegmentedControl } from '@mantine/core';
import React from 'react';
import classes from '../../../../shared/styles/SegmentedControl.module.scss';

type NotificationsReadFilterProps = {
	isUnread: boolean;
	setIsUnread: React.Dispatch<React.SetStateAction<boolean>>;
};

export const NotificationsReadFilter = ({
	isUnread,
	setIsUnread,
}: NotificationsReadFilterProps) => {
	return (
		<SegmentedControl
			data={[
				{ label: 'Non lues', value: '1' },
				{ label: 'Toutes', value: '' },
			]}
			value={isUnread ? '1' : ''}
			onChange={() => setIsUnread((prev) => !prev)}
			radius="lg"
			w="auto"
			size="md"
			classNames={classes}
		/>
	);
};
