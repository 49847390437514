import { Divider, Group, Radio, Stack } from '@mantine/core';
import { Question } from '../../api/questionnaires.types';

type Props = {
	question: Question;
};

export function SingleSelectQuestionPreview({ question }: Props) {
	return (
		<Stack gap="lg" w="90%">
			<Radio.Group
				label={question.label}
				description="Une seule réponse possible"
				size="md"
				withAsterisk={question.required}
			>
				<Group mt="md" justify="start" gap="10%">
					{question.options.map((option) => (
						<Radio
							size="md"
							my="md"
							key={option.id}
							disabled
							value={option.label}
							label={option.label}
							styles={{
								label: { color: 'black' },
								icon: { color: 'var(--mantine-primary-color-6)' },
							}}
						/>
					))}
				</Group>
			</Radio.Group>

			<Divider />
		</Stack>
	);
}
