import { ResearchStatusType } from './researches.types';

export const researchesQueryKeys = {
	all: ['researches'] as const,
	lists: () => [...researchesQueryKeys.all, 'lists'] as const,
	list: (statuses?: ResearchStatusType[]) =>
		[...researchesQueryKeys.lists(), { statuses }] as const,
	detail: (researchId: number) =>
		[...researchesQueryKeys.list(), 'detail', researchId] as const,
};
