import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { PreSignedPutUrl } from '../../../shared/types/PreSignedPutUrl';
import { Consent, ConsentStatus } from './consents.entities';

export const getConsents = async (
	researchId: number,
	userId?: number,
	statuses?: ConsentStatus[],
) => {
	const urlEndpoint = userId
		? `/consents/research/${researchId}/participant/${userId}`
		: `/consents/research/${researchId}`;
	try {
		const { data } = await privateAxios.get<{
			consents: Consent[];
			totalRowCount: number;
		}>(urlEndpoint, {
			params: { statuses },
		});
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getConsentUploadUrl = async (fileName: string) => {
	try {
		const { data } = await privateAxios.get<PreSignedPutUrl>(
			`/consents/get-upload-url`,
			{ params: { 'file-name': fileName } },
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getConsentDownloadUrl = async (consentId: number) => {
	try {
		const { data } = await privateAxios.get<PreSignedPutUrl>(
			`/consents/${consentId}/get-download-url`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getConsentsZip = async (consentIds: number[]) => {
	try {
		const { data } = await privateAxios.get<Blob>(`/consents/get-zip`, {
			params: { id: consentIds.map((id) => id) },
			responseType: 'blob',
		});
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
