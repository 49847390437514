import { AddRecipientsModal } from '@/features/messages/components/modals/AddRecipientGroupModal';
import { useGetContacts } from '@/features/messages/hooks/useGetContacts';
import { Role } from '@/features/profile/api/types/user.types';
import { useCurrentResearch } from '@/features/researches/contexts/researches.context';
import { UserOnResearch } from '@/features/usersOnResearch/api/types/users-on-research.types';
import { ResearchMemberCard } from '@/features/usersOnResearch/components/cards/ResearchMemberCard';
import { getResearchMemberInfo } from '@/features/usersOnResearch/utils/researchMemberInfo';
import { customResearchMemberFilterFn } from '@/shared/utils/filters';
import {
	CheckIcon,
	Checkbox,
	Combobox,
	Group,
	Pill,
	PillsInput,
	Tooltip,
	useCombobox,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

type ContactsPickerProps = {
	recipients: UserOnResearch[];
	setRecipients: React.Dispatch<React.SetStateAction<UserOnResearch[]>>;
};

export const ContactsPicker = ({
	recipients,
	setRecipients,
}: ContactsPickerProps) => {
	const combobox = useCombobox({
		onDropdownClose: () => combobox.resetSelectedOption(),
		onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
	});
	const { userOnResearch: user } = useCurrentResearch();
	const [search, setSearch] = useState('');
	const [isForResearchCenter, setIsForResearchCenter] = useState(false);
	const [opened, { open, close }] = useDisclosure();

	const { contacts } = useGetContacts();

	const handleValueSelect = (val: UserOnResearch) => {
		setRecipients((current) =>
			current.includes(val)
				? current.filter((v) => v !== val)
				: [...current, val],
		);
		setSearch('');
	};
	const handleValueRemove = (val: UserOnResearch) =>
		setRecipients((current) =>
			current.filter((v) => v.userOnResearchId !== val.userOnResearchId),
		);
	const handleOnRecipientsGroupSubmit = (role: Role) => {
		if (!contacts) return;
		if (role === 'PARTICIPANT')
			setRecipients(
				contacts.filter((contact) => contact.role === 'PARTICIPANT'),
			);
		else if (role === 'OFFSITE_RESEARCH_MEMBER')
			setRecipients(
				contacts.filter(
					(contact) => contact.role === 'OFFSITE_RESEARCH_MEMBER',
				),
			);
		else setRecipients(contacts);
	};

	useEffect(() => {
		setRecipients([]);
	}, [isForResearchCenter]); // eslint-disable-line react-hooks/exhaustive-deps

	const isOnsiteResearchMember =
		user.role === 'ONSITE_RESEARCH_MEMBER' || user.role === 'INVESTIGATOR';
	const isDisabled =
		(!isOnsiteResearchMember && recipients.length >= 1) || isForResearchCenter;

	const values = recipients.map((contact) => {
		const { displayTitle, displayRole } = getResearchMemberInfo(contact);
		return (
			<Pill
				key={contact.userOnResearchId}
				withRemoveButton
				onRemove={() => handleValueRemove(contact)}
			>
				{displayTitle} {displayRole && `(${displayRole})`}
			</Pill>
		);
	});

	const options = contacts
		?.filter((contact) => customResearchMemberFilterFn(contact, search))
		.map((contact) => (
			<Combobox.Option
				value={contact.email}
				key={contact.email}
				active={recipients.map((v) => v.email).includes(contact.email)}
				disabled={
					isDisabled && !recipients.map((v) => v.email).includes(contact.email)
				}
				style={{
					borderRadius: 'var(--mantine-radius-md)',
				}}
			>
				<Group justify="space-between">
					<ResearchMemberCard user={contact} />
					{recipients.map((v) => v.email).includes(contact.email) && (
						<CheckIcon size={12} color="teal" />
					)}
				</Group>
			</Combobox.Option>
		));

	return (
		<>
			<div className="flex items-center justify-between gap-6 border-b-2 border-t-2 border-gray-50 px-4 py-2">
				<div className="flex flex-1 items-center gap-4">
					<p className="text-muted-foreground">À</p>
					<div className="flex flex-1">
						<Combobox
							store={combobox}
							onOptionSubmit={(value) => {
								const selected = contacts?.find(
									(contact) => contact.email === value,
								);
								if (selected) handleValueSelect(selected);
							}}
						>
							<Combobox.DropdownTarget>
								<PillsInput
									onClick={() => combobox.openDropdown()}
									styles={{
										root: {
											width: '100%',
										},
										input: {
											border: '1px solid var(--mantine-primary-color-0)',
											borderRadius: 'var(--mantine-radius-md)',
											maxHeight: 100,
											overflow: 'auto',
										},
									}}
								>
									<Pill.Group>
										{values}
										{isForResearchCenter && (
											<Pill size="md">Centre de la recherche</Pill>
										)}

										<Combobox.EventsTarget>
											<PillsInput.Field
												onFocus={() => combobox.openDropdown()}
												onBlur={() => combobox.closeDropdown()}
												value={search}
												disabled={isDisabled}
												placeholder={isDisabled ? '' : 'Ajouter des contacts'}
												onChange={(event) => {
													combobox.updateSelectedOptionIndex();
													setSearch(event.currentTarget.value);
												}}
												onKeyDown={(event) => {
													if (
														event.key === 'Backspace' &&
														search.length === 0
													) {
														event.preventDefault();
														handleValueRemove(
															recipients[recipients.length - 1],
														);
													}
												}}
											/>
										</Combobox.EventsTarget>
									</Pill.Group>
								</PillsInput>
							</Combobox.DropdownTarget>

							<Combobox.Dropdown
								style={{
									borderRadius: 'var(--mantine-radius-md)',
								}}
							>
								<Combobox.Options mah={250} style={{ overflowY: 'auto' }}>
									{options && options.length > 0 ? (
										options
									) : (
										<Combobox.Empty>Aucun contact trouvé</Combobox.Empty>
									)}
								</Combobox.Options>
							</Combobox.Dropdown>
						</Combobox>
					</div>
				</div>

				<div className="">
					{isOnsiteResearchMember ? (
						<Tooltip label="Ajouter un groupe">
							<IconPlus
								size={20}
								onClick={open}
								className="text-muted-foreground"
							/>
						</Tooltip>
					) : (
						<Checkbox
							label="Envoyer au centre de recherche"
							labelPosition="right"
							radius="sm"
							checked={isForResearchCenter}
							onChange={(event) =>
								setIsForResearchCenter(event.currentTarget.checked)
							}
							style={{
								color: 'var(--mantine-primary-color-6)',
								fontWeight: 700,
							}}
							styles={{
								body: {
									alignItems: 'center',
								},
							}}
						/>
					)}
				</div>
			</div>
			{opened && (
				<AddRecipientsModal
					onClose={close}
					onContactGroupSubmit={(role: Role) => {
						handleOnRecipientsGroupSubmit(role);
					}}
				/>
			)}
		</>
	);
};
