import { Question } from '../../api/questionnaires.types';
import { MultipleSelectQuestionPreview } from './MultipleSelectQuestionPreview';
import { RangeQuestionPreview } from './RangeQuestionPreview';
import { SingleSelectQuestionPreview } from './SingleSelectQuestionPreview';
import { TextQuestionPreview } from './TextQuestionPreview';

type QuestionPreviewProps = {
	question: Question;
};

export function QuestionPreview({ question }: QuestionPreviewProps) {
	if (question.type === 'TEXT')
		return <TextQuestionPreview question={question} />;

	if (question.type === 'RANGE')
		return <RangeQuestionPreview question={question} />;

	if (question.type === 'SINGLE_CHOICE')
		return <SingleSelectQuestionPreview question={question} />;

	if (question.type === 'MULTIPLE_CHOICE')
		return <MultipleSelectQuestionPreview question={question} />;

	return null;
}
