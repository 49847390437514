import { Space, Stack, Text, Title } from '@mantine/core';
import { CustomLoader } from '../../../../../../components/ui/loader';
import { useGetQuestionnaireResponse } from '../../api/questionnaires.hooks';
import { ResponseCard } from './ResponseCard';

type QuestionnaireResponseProps = {
	actionId: number;
	userId: number;
};

export function QuestionnaireResponse({
	actionId,
	userId,
}: QuestionnaireResponseProps) {
	const { questionnaire, isLoading, error } = useGetQuestionnaireResponse(
		actionId,
		userId,
	);

	const getResponsesByQuestionId = (questionId: number) => {
		const responses = questionnaire?.responses.filter(
			(response) => response.questionId === questionId,
		);
		if (!responses) return;
		return responses.length === 1 ? responses[0] : responses;
	};

	if (error)
		return (
			<Text fs="italic" c="red" ta="center">
				Une erreur est survenue lors du chargement des données
			</Text>
		);
	if (isLoading || !questionnaire) return <CustomLoader />;

	return (
		<Stack gap="lg" w="100%">
			<Title order={3} ta="center">
				{questionnaire.title}
			</Title>
			<Text
				ta="center"
				fw={500}
				truncate
				style={{
					whiteSpace: 'pre-line',
				}}
			>
				{questionnaire.description}
			</Text>
			<Space h="xl" />
			<Stack gap="xl" w="100%" pb="sm" align="center">
				{questionnaire.questions.map((question) => (
					<ResponseCard
						key={question.id}
						question={question}
						response={getResponsesByQuestionId(question.id)}
					/>
				))}
			</Stack>
		</Stack>
	);
}
