import {
	Box,
	Button,
	Center,
	Group,
	Stack,
	Table,
	Text,
	Textarea,
	Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMemo, useState } from 'react';
import { CustomLoader } from '../../../../../../components/ui/loader';
import { useCurrentParticipant } from '../../../../../usersOnResearch/contexts/participants.context';
import { ConfirmCancelModal } from '../../../../components/modals/ConfirmCancelModal';
import { ConfirmResponsesModal } from '../../../components/modals/ConfirmResponsesModal';
import { DynamicTableCellDto } from '../../api/dynamic-tables.dto';
import {
	useGetDynamicTablePreview,
	useSendDynamicTableData,
} from '../../api/dynamic-tables.hooks';
import {
	DynamicTableColumn,
	DynamicTableRow,
} from '../../api/dynamic-tables.types';
import { default as classes } from '../../styles/DynamicTable.module.scss';
import { MAX_CONTENT_LENGTH } from '../../utils/constants';
import { getHeaderRow } from '../../utils/exportDynamicTableData';

type DynamicTableInputProps = {
	actionId: number;
	handleClose: () => void;
};

export function DynamicTableInput({
	handleClose,
	actionId,
}: DynamicTableInputProps) {
	const { participant } = useCurrentParticipant();
	const [cells, setCells] = useState<DynamicTableCellDto[]>([]);

	const { dynamicTable, isLoading, error } =
		useGetDynamicTablePreview(actionId);

	const [
		confirmSubmitModalOpened,
		{ open: confirmSubmitModalOpen, close: confirmSubmitModalClose },
	] = useDisclosure();
	const [
		confirmCancelModalOpened,
		{ open: confirmCancelModalOpen, close: confirmCancelModalClose },
	] = useDisclosure();

	const handleSubmitSuccess = () => {
		confirmSubmitModalClose();
		handleClose();
	};

	const { sendResultsMutation } = useSendDynamicTableData(
		actionId,
		participant.userId,
		handleSubmitSuccess,
	);

	const handleOnCellChange = (
		value: string,
		rowId: number,
		columnId: number,
	) => {
		setCells((currCells) => [
			...currCells.filter(
				(cell) => cell.rowId !== rowId || cell.columnId !== columnId,
			),
			{
				rowId,
				columnId,
				value,
			},
		]);
	};

	const displayError = (rowId: number, columnId: number) => {
		const value = cells.find(
			(cell) => cell.rowId === rowId && cell.columnId === columnId,
		)?.value;
		if (!value || value.length < MAX_CONTENT_LENGTH) return null;
		return `${MAX_CONTENT_LENGTH} caractères maximum`;
	};

	const formattedTable = useMemo(() => {
		if (!dynamicTable) return null;
		const bodyRows = dynamicTable.rows.map((row: DynamicTableRow) => (
			<Table.Tr key={row.id}>
				<Table.Th>{row.title}</Table.Th>
				{dynamicTable.columns.map((column: DynamicTableColumn) => (
					<Table.Td key={`${row.id}-${column.id}`}>
						<Textarea
							value={
								cells.find(
									(cell) =>
										cell.rowId === row.id && cell.columnId === column.id,
								)?.value
							}
							onChange={(event) =>
								handleOnCellChange(event.target.value, row.id, column.id)
							}
							classNames={classes}
							error={displayError(row.id, column.id)}
						/>
					</Table.Td>
				))}
			</Table.Tr>
		));

		return (
			<Box
				mah="50vh"
				style={{
					overflow: 'auto',
				}}
			>
				<Table mb="lg" classNames={classes}>
					<Table.Thead>
						<Table.Tr>{getHeaderRow(dynamicTable.columns)}</Table.Tr>
					</Table.Thead>
					<Table.Tbody>{bodyRows}</Table.Tbody>
				</Table>
			</Box>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dynamicTable, cells]);

	if (error)
		return (
			<Text fs="italic" c="red" ta="center">
				Une erreur est survenue lors du chargement des données
			</Text>
		);
	if (isLoading || !dynamicTable) return <CustomLoader />;

	return (
		<>
			<Stack gap="lg" w="100%">
				<Title order={3} ta="center">
					{dynamicTable.title}
				</Title>
				<Text
					ta="center"
					fw={500}
					truncate
					style={{
						whiteSpace: 'pre-line',
					}}
				>
					{dynamicTable.description}
				</Text>
				{formattedTable}
				<Center>
					<Group w="90%" grow justify="center" align="center">
						<Button
							size="md"
							variant="outline"
							onClick={confirmCancelModalOpen}
						>
							Annuler
						</Button>
						<Button onClick={confirmSubmitModalOpen} size="md">
							Compléter le tableau
						</Button>
					</Group>
				</Center>
			</Stack>
			{confirmSubmitModalOpened && (
				<ConfirmResponsesModal
					handleClose={confirmSubmitModalClose}
					handleSubmit={() => sendResultsMutation.mutate(cells)}
					isLoading={sendResultsMutation.isPending}
				/>
			)}
			{confirmCancelModalOpened && (
				<ConfirmCancelModal
					handleCancel={() => {
						confirmCancelModalClose();
						handleClose();
					}}
					handleClose={confirmCancelModalClose}
				/>
			)}
		</>
	);
}
