import { useQuery } from '@tanstack/react-query';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';
import {
	getOffsiteResearchMemberDocuments,
	getParticipantDocuments,
	getResearchCenterDocuments,
} from '../documents.queries';
import { documentsQueryKeys } from '../documents.query-keys';
import { DocumentInfo } from '../documents.types';

export const useGetDocuments = () => {
	const { research } = useCurrentResearch();
	const { participant } = useCurrentParticipant();
	const { userOnResearch } = useCurrentResearch();

	const getQueryKeyAndFn = () => {
		if (participant) {
			return {
				queryKey: documentsQueryKeys.filters(research.id, {
					scope: 'PRIVATE',
					userId: participant.userId,
				}),
				queryFn: () => getParticipantDocuments(research.id, participant.userId),
			};
		}

		if (userOnResearch?.role === 'OFFSITE_RESEARCH_MEMBER')
			return {
				queryKey: documentsQueryKeys.filters(research.id, {
					scope: 'PRIVATE',
					userId: userOnResearch.userId,
				}),
				queryFn: () => getOffsiteResearchMemberDocuments(research.id),
			};

		return {
			queryKey: documentsQueryKeys.filters(research.id, {
				scope: 'PROFESSIONAL',
			}),
			queryFn: () => getResearchCenterDocuments(research.id),
		};
	};

	const {
		data: documents,
		isLoading,
		error,
	} = useQuery<DocumentInfo[]>({
		queryKey: getQueryKeyAndFn().queryKey,
		queryFn: () => getQueryKeyAndFn().queryFn(),
	});

	return { documents, isLoading, error };
};
