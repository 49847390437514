import { Divider, Slider, Stack, Text, rem } from '@mantine/core';
import { useMemo } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Question } from '../../api/questionnaires.types';
import { getSliderMarksFromRange } from '../../utils/slider';

type Props = {
	question: Question;
	control: Control;
};

export function RangeQuestionInput({ question, control }: Props) {
	const maxRange = question.maxRange ?? 0;

	const sliderMarks = useMemo(
		() => getSliderMarksFromRange(maxRange),
		[maxRange],
	);

	return (
		<Stack gap="xl" w="90%">
			<Stack gap="sm">
				<Text fw={500}>
					{question.label}{' '}
					{question.required && (
						<Text component="span" c="red">
							*
						</Text>
					)}
				</Text>
				<Controller
					control={control}
					name={question.id.toString()}
					defaultValue={0}
					render={({ field: { onChange, value }, fieldState: { error } }) => (
						<Stack gap="lg" mb="xl">
							<Slider
								onChange={(newValue) => onChange(newValue)}
								value={value}
								size="xl"
								label={null}
								max={maxRange}
								marks={sliderMarks}
								thumbSize={rem(25)}
								styles={{ thumb: { borderWidth: rem(2), padding: rem(3) } }}
							/>
							{error && (
								<Text c="red" size="sm">
									{error.message}
								</Text>
							)}
						</Stack>
					)}
				/>
			</Stack>
			<Divider />
		</Stack>
	);
}
