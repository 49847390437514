import { Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { formatDate } from '../../../shared/utils/dates';
import { CalendarView } from './calendar.utils';

type DayHeaderProps = {
	date: Date;
	isPast: boolean;
	isFuture: boolean;
	isToday: boolean;
	isOther: boolean;
	view: CalendarView;
};

export const renderDayHeader = ({ date, view }: DayHeaderProps) => {
	const weekday = formatDate(date, 'ddd').slice(0, -1).toUpperCase();
	if (view.type === 'dayGridMonth') return <Text size="sm">{weekday}</Text>;

	if (view.type === 'timeGridWeek')
		return (
			<Stack gap={0} py="md">
				<Text size="sm">{weekday}</Text>
				<Text fw={600}>{formatDate(date, 'D')}</Text>
			</Stack>
		);

	if (view.type === 'listWeek')
		return (
			<p className="text-left">{dayjs(date).format('ddd DD MMMM YYYY')}</p>
		);
};
