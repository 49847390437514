import { Checkbox } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { SingleSelectUsersOnResearch } from '../../../../shared/components/inputs/SingleSelectUsersOnResearch';
import { LegalRepresentativeRoleEnum } from '../../../profile/api/types/participants.types';
import {
	useGetUserOnResearchDetails,
	useGetUsersOnResearch,
} from '../../../usersOnResearch/api/hooks/users-on-research.hooks';
import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';
import { CreateSignerDto } from '../../api/consents.dto';
import { SignerRole } from '../../api/consents.entities';

type AddConsentSignersFormProps = {
	selectedParticipant: UserOnResearch | undefined;
	setSelectedParticipant: React.Dispatch<
		React.SetStateAction<UserOnResearch | undefined>
	>;
	signers: CreateSignerDto[];
	setSigners: React.Dispatch<React.SetStateAction<CreateSignerDto[]>>;
};

export const AddConsentSignersForm = ({
	selectedParticipant,
	setSelectedParticipant,
	signers,
	setSigners,
}: AddConsentSignersFormProps) => {
	const { participant: currentParticipant } = useCurrentParticipant();
	const prevSelectedParticipantRef = useRef<UserOnResearch>();
	const { usersOnResearch: activeParticipants } = useGetUsersOnResearch(
		['PARTICIPANT'],
		['ACTIVE'],
	);

	const [isEnabled, setIsEnabled] = useState(false);
	const { userOnResearch: participantProfile } = useGetUserOnResearchDetails(
		selectedParticipant?.userId ?? -1,
		isEnabled,
	);

	useEffect(() => {
		prevSelectedParticipantRef.current = selectedParticipant;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!currentParticipant || selectedParticipant) return;
		setSelectedParticipant(currentParticipant);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentParticipant, selectedParticipant]);

	useEffect(() => {
		if (
			!selectedParticipant ||
			prevSelectedParticipantRef.current?.userId === selectedParticipant.userId
		)
			return;
		prevSelectedParticipantRef.current = selectedParticipant;
		setIsEnabled(true);
		setSigners([
			{
				role: 'PARTICIPANT',
				email: selectedParticipant.email,
				firstName: selectedParticipant.firstName,
				lastName: selectedParticipant.lastName,
				phoneNumber: selectedParticipant.phoneNumber,
			},
		]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedParticipant]);

	const handleSignerChange = (role: SignerRole, checked: boolean) => {
		if (!checked) {
			setSigners((prev) => prev.filter((r) => r.role !== role));
			return;
		}
		if (role === 'PARTICIPANT' && selectedParticipant) {
			setSigners((prev) => [
				...prev,
				{
					role: 'PARTICIPANT',
					email: selectedParticipant.email,
					firstName: selectedParticipant.firstName,
					lastName: selectedParticipant.lastName,
					phoneNumber: selectedParticipant.phoneNumber,
				},
			]);
			return;
		}
		const legalRepresentative =
			participantProfile?.participantProfile?.legalRepresentatives?.find(
				(lr) => lr.role === role,
			);
		if (!legalRepresentative) return;
		setSigners((prev) => [
			...prev,
			{
				role,
				email: legalRepresentative?.email,
				firstName: legalRepresentative?.firstName,
				lastName: legalRepresentative?.lastName,
				phoneNumber: legalRepresentative?.phoneNumber,
			},
		]);
	};

	return (
		<div className="flex flex-col gap-8">
			<div className="flex flex-col gap-1">
				<h5 className="text-center">
					Choisissez les signataires du consentement
				</h5>
				<p className="text-center">
					Sélectionnez le participant concerné par la demande ainsi que les
					destinataires qui lui sont associés.
				</p>
			</div>

			<div className="flex flex-col gap-4">
				{activeParticipants && (
					<SingleSelectUsersOnResearch
						allUsers={activeParticipants}
						selectedUser={selectedParticipant}
						setSelectedUser={setSelectedParticipant}
						placeholder="Sélectionnez un participant"
						disabled={!!currentParticipant}
					/>
				)}

				{participantProfile && (
					<div className="flex flex-col gap-2">
						<Checkbox
							label="Participant"
							size="md"
							radius={0}
							checked={
								!!signers.find((signer) => signer.role === 'PARTICIPANT')
							}
							onChange={(e) =>
								handleSignerChange('PARTICIPANT', e.currentTarget.checked)
							}
						/>
						{participantProfile.participantProfile?.legalRepresentatives?.map(
							(lr) => (
								<Checkbox
									key={lr.legalRepresentativeId}
									label={LegalRepresentativeRoleEnum[lr.role]}
									checked={!!signers.find((signer) => signer.role === lr.role)}
									onChange={(e) =>
										handleSignerChange(lr.role, e.currentTarget.checked)
									}
									size="md"
									radius={0}
								/>
							),
						)}
					</div>
				)}
				{signers.length === 0 && (
					<p className="text-center text-sm text-muted-foreground">
						Aucun signataire séclectionné
					</p>
				)}
			</div>
		</div>
	);
};
