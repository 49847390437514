import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { PreSignedPutUrl } from '../../../shared/types/PreSignedPutUrl';
import { HelpDocument, HelpQuestion } from './help.types';

export const getHelpQuestions = async (): Promise<HelpQuestion[]> => {
	try {
		const { data } =
			await privateAxios.get<HelpQuestion[]>(`/help/get-questions`);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getHelpDocuments = async (): Promise<HelpDocument[]> => {
	try {
		const { data } =
			await privateAxios.get<HelpDocument[]>(`/help/get-documents`);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getHelpDownloadUrl = async (
	documentId: number,
): Promise<PreSignedPutUrl> => {
	try {
		const { data } = await privateAxios.get(
			`/help/get-download-url/${documentId}`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
