import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { useUpdateUserOnResearchStatus } from '../../api/hooks/users-on-research.hooks';
import { UserOnResearch } from '../../api/types/users-on-research.types';

type Props = {
	researchMember: UserOnResearch;
	handleClose: () => void;
};
export function ToggleResearchMemberStatusModal({
	researchMember,
	handleClose,
}: Props) {
	const isMobile = useIsMobile();
	const mutation = useUpdateUserOnResearchStatus(
		researchMember.userId,
		researchMember.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
	);
	const title =
		researchMember.status === 'INACTIVE'
			? 'Ajouter à la recherche'
			: 'Retirer de la recherche';
	const description =
		researchMember.status === 'ACTIVE'
			? `Êtes-vous sûr(e) de vouloir retirer ${researchMember.firstName} ${researchMember.lastName} de la recherche ?`
			: `Êtes-vous sûr(e) de vouloir ajouter ${researchMember.firstName} ${researchMember.lastName} à la recherche ?`;
	const confirmBtnText =
		researchMember.status === 'ACTIVE' ? 'Retirer' : 'Ajouter';
	const confirmBtnVariant =
		researchMember.status === 'ACTIVE' ? 'destructive' : 'default';

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			title={title}
			size="lg"
			fullScreen={isMobile}
		>
			<div className="flex flex-col gap-12">
				<p className="text-center">{description}</p>
				<div className="flex gap-4">
					{!isMobile && (
						<Button onClick={handleClose} variant="outline" className="w-full">
							Annuler
						</Button>
					)}
					<Button
						type="submit"
						disabled={mutation.isPending}
						className="w-full"
						variant={confirmBtnVariant}
						onClick={async () => {
							await mutation.mutateAsync();
							handleClose();
						}}
					>
						{confirmBtnText}
					</Button>
				</div>
			</div>
		</Modal>
	);
}
