import { Role } from '../../profile/api/types/user.types';
import { UserOnResearchStatusType } from './types/users-on-research.types';

export const usersOnResearchQueryKeys = {
	all: ['users-on-research'] as const,
	lists: (researchId: number) =>
		[...usersOnResearchQueryKeys.all, 'research', researchId] as const,
	list: (
		researchId: number,
		params: { roles?: Role[]; statuses?: UserOnResearchStatusType[] },
	) => [...usersOnResearchQueryKeys.lists(researchId), params] as const,
	details: (
		researchId: number,
		params?: { roles?: Role[]; statuses?: UserOnResearchStatusType[] },
	) =>
		[...usersOnResearchQueryKeys.lists(researchId), 'details', params] as const,
	detail: (researchId: number, userId: number) =>
		[...usersOnResearchQueryKeys.details(researchId), 'user', userId] as const,
	me: (researchId: number) =>
		[...usersOnResearchQueryKeys.details(researchId), 'user', 'me'] as const,
	listParticipantsByOffsiteResearchMember: (
		researchId: number,
		offsiteResearchMemberId: number,
		statuses?: UserOnResearchStatusType[],
	) =>
		[
			...usersOnResearchQueryKeys.list(researchId, {
				roles: ['PARTICIPANT'],
				statuses: statuses,
			}),
			'participants-by-offsite-research-member',
			offsiteResearchMemberId,
		] as const,
	listOffsiteResearchMembersByParticipant: (
		researchId: number,
		participantId: number,
		statuses?: UserOnResearchStatusType[],
	) =>
		[
			...usersOnResearchQueryKeys.list(researchId, {
				roles: ['OFFSITE_RESEARCH_MEMBER'],
				statuses: statuses,
			}),
			'offsite-research-members-by-participant',
			participantId,
		] as const,
};
