import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import { cn } from '@/components/utils';
import { IconCircleFilled, IconClock, IconLock } from '@tabler/icons-react';
import { useResendInviteLink } from '../../../auth/shared/hooks/useResendInviteLink';
import { Role } from '../../../profile/api/types/user.types';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import {
	UserOnResearchStatusEnum,
	UserOnResearchStatusType,
} from '../../api/types/users-on-research.types';

type Props = {
	status: UserOnResearchStatusType;
	userId: number;
	userRole: Role;
};

export function ResearchMemberOnResearchStatusBadge({
	status,
	userId,
	userRole,
}: Props) {
	const { research } = useCurrentResearch();

	const icon =
		status === 'ACTIVE' ? (
			<IconCircleFilled size={10} />
		) : status === 'PENDING' ? (
			<IconClock size={14} />
		) : (
			<IconLock size={14} />
		);
	const variant =
		status === 'ACTIVE' ? 'success' : status === 'PENDING' ? 'warning' : 'info';

	const canSendNewInviteLink = status === 'PENDING';
	const mutation = useResendInviteLink(research.id, userId, userRole);

	return (
		<div
			className={cn(
				'flex flex-col items-center',
				canSendNewInviteLink && 'mt-2',
			)}
		>
			<Badge variant={variant}>
				<div className="flex items-center gap-1">
					{icon}
					{UserOnResearchStatusEnum[status]}
				</div>
			</Badge>
			{canSendNewInviteLink && (
				<Button
					size="xs"
					variant="link"
					className="p-0"
					onClick={(e) => {
						e.stopPropagation();
						mutation.mutate();
					}}
				>
					Renvoyer un lien d'invitation
				</Button>
			)}
		</div>
	);
}
