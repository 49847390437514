import { Navigate } from 'react-router-dom';
import { CustomLoader } from '../../../components/ui/loader';
import { paths } from '../../../routes/paths';
import { useGetDashboardData } from '../api/dashboard.hooks';
import { InactiveParticipantsCard } from '../components/charts/InactiveParticipantsCard';
import { InclusionRateCard } from '../components/charts/InclusionRateCard';
import { ParticipantEnrollmentLineChart } from '../components/charts/ParticipantEnrollmentLineChart';
import { ParticipantEnrollmentPieChart } from '../components/charts/ParticipantEnrollmentPieChart';
import { ParticipantsWithCompletedVisitsCard } from '../components/charts/ParticipantsWithCompletedVisitsCard';
import { VisitStatusReportBarChart } from '../components/charts/VisitStatusReportBarChart';

export function DashboardPage() {
	const { dashboardData, isLoading, error } = useGetDashboardData();

	if (isLoading || !dashboardData) return <CustomLoader />;
	if (error) return <Navigate to={`/${paths.ERROR_PATH}`} />;

	return (
		<div className="flex h-full w-full flex-col gap-4">
			<h4>Dashboard</h4>

			<div className="grid grid-cols-2 gap-4">
				<div className="col-span-2 md:col-span-1">
					<InactiveParticipantsCard {...dashboardData} />
				</div>
				<div className="col-span-2 md:col-span-1">
					<ParticipantsWithCompletedVisitsCard {...dashboardData} />
				</div>
				<div className="col-span-2 md:col-span-1">
					<InclusionRateCard {...dashboardData} />
				</div>
				<div className="col-span-2 md:col-span-1">
					<ParticipantEnrollmentPieChart {...dashboardData} />
				</div>
				<div className="col-span-2">
					<ParticipantEnrollmentLineChart
						enrollmentParticipantsDates={
							dashboardData.enrollmentParticipantsDates
						}
					/>
				</div>
				<div className="col-span-2">
					<VisitStatusReportBarChart
						visits={dashboardData.visitCompletionData}
					/>
				</div>
			</div>
		</div>
	);
}
