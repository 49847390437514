import { useCallback, useRef } from 'react';

type InfiniteScrollProps = {
	fetchNextPage: () => void;
	hasNextPage: boolean;
	isFetching: boolean;
	isLoading: boolean;
};
export const useInfiniteScroll = (props: InfiniteScrollProps) => {
	const observer = useRef<IntersectionObserver>();
	const lastElementRef = useCallback(
		(node: HTMLDivElement) => {
			if (props.isLoading) return;
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (
					entries[0].isIntersecting &&
					props.hasNextPage &&
					!props.isFetching
				) {
					props.fetchNextPage();
				}
			});

			if (node) observer.current.observe(node);
		},
		[props],
	);

	return lastElementRef;
};
