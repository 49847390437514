import { AxiosError } from 'axios';
import { privateAxios } from '../../../../../shared/services/privateAxios.ts';
import { ResponseDto } from './questionnaires.dto.ts';

export const resetQuestionnaire = async (
	actionId: number,
	participantId: number,
) => {
	try {
		const { data } = await privateAxios.delete(
			`visits/action/${actionId}/participant/${participantId}/reset-questionnaire`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const submitQuestionnaire = async (
	actionId: number,
	participantId: number,
	responses: ResponseDto[],
) => {
	try {
		const { data } = await privateAxios.post(
			`/visits/action/${actionId}/participant/${participantId}/questionnaire-responses`,
			{
				responses,
			},
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
