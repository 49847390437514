import { AxiosError } from 'axios';
import { privateAxios } from '../../../../shared/services/privateAxios';

export const confirmPasswordQuery = async (password: string) => {
	try {
		const response = await privateAxios.post(`/auth/confirm-password`, {
			password,
		});
		return response.data;
	} catch (error) {
		throw error as AxiosError;
	}
};
