import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { NoteSummary, Note } from './notes.types';

export const getNotesByResearch = async (
	researchId: number,
): Promise<NoteSummary[]> => {
	try {
		const { data } = await privateAxios.get<NoteSummary[]>(
			`/notes/researches/${researchId}`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getNotesByParticipant = async (
	researchId: number,
	participantId: number,
): Promise<NoteSummary[]> => {
	try {
		const { data } = await privateAxios.get<NoteSummary[]>(
			`/notes/researches/${researchId}/participant/${participantId}`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getNoteContent = async (noteId: number): Promise<Note> => {
	try {
		const { data } = await privateAxios.get<Note>(`/notes/${noteId}`);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
