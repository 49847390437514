import { useSession } from '@/features/auth/shared/hooks/useSession';
import { useCurrentParticipant } from '@/features/usersOnResearch/contexts/participants.context';
import { OffsiteResearchMemberTabs } from '@/shared/layouts/OffsiteResearchMemberTabs';
import { OnsiteResearchMemberTabs } from '@/shared/layouts/OnsiteResearchMemberTabs';
import { ParticipantsTabs } from '@/shared/layouts/ParticipantTabs';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { ResearchHeader } from '../components/ui/ResearchHeader';

export const ResearchLayout = () => {
	const { user } = useSession();
	const { participant } = useCurrentParticipant();

	const renderTabs = useMemo(() => {
		if (participant) return <ParticipantsTabs />;
		if (user?.role === 'OFFSITE_RESEARCH_MEMBER')
			return <OffsiteResearchMemberTabs />;
		if (
			user?.role === 'INVESTIGATOR' ||
			user?.role === 'ONSITE_RESEARCH_MEMBER'
		)
			return <OnsiteResearchMemberTabs />;
	}, [participant, user?.role]);

	return (
		<div className="flex h-full w-full flex-col gap-6">
			<div className="flex flex-col gap-1">
				<ResearchHeader />
				{renderTabs}
			</div>
			<Outlet />
		</div>
	);
};
