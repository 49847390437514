import { Checkbox, Divider, Group, Stack } from '@mantine/core';
import { Question } from '../../api/questionnaires.types';

type Props = {
	question: Question;
};

export function MultipleSelectQuestionPreview({ question }: Props) {
	return (
		<Stack gap="lg" w="90%">
			<Checkbox.Group
				label={question.label}
				description="Plusieurs réponses possibles"
				size="md"
				withAsterisk={question.required}
			>
				<Group mt="md" justify="start" gap="10%">
					{question.options.map((option) => (
						<Checkbox
							size="md"
							my="md"
							disabled
							key={option.id}
							value={option.label}
							label={option.label}
							styles={{
								label: { color: 'black' },
								icon: { color: 'var(--mantine-primary-color-6)' },
							}}
						/>
					))}
				</Group>
			</Checkbox.Group>
			<Divider />
		</Stack>
	);
}
