import { Button } from '@/components/ui/button';
import { paths } from '@/routes/paths';
import { notifications } from '@mantine/notifications';
import { IconArrowLeft, IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSession } from '../../shared/hooks/useSession';
import { LoginResponse } from '../../shared/types/AuthToken';
import { SignInOtpVerificationForm } from '../components/forms/SignInOtpVerificationForm';
import { resendSignInOtpQuery } from '../queries/resendSignInOtpQuery';
import { signInQuery } from '../queries/signInQuery';

export function SignInOtpVerificationPage() {
	const navigate = useNavigate();
	const location = useLocation();
	const { createSession } = useSession();

	const email = location.state?.email ?? '';

	const verifyOtpmutation = useMutation({
		mutationFn: async (otp: string) => {
			return await signInQuery({ email, otp });
		},
		onSuccess: async (response: LoginResponse) => createSession(response),
		onError: (error: AxiosError) => {
			if (error.response?.status.toString().startsWith('4')) return;
			notifications.show({
				message:
					'Une erreur est survenue lors de la vérification du code. Veuillez réessayer.',
				color: 'red',
				icon: <IconX style={{ width: '20rem', height: '20rem' }} />,
			});
		},
	});

	const resendOtpCodeMutation = useMutation({
		mutationFn: async () => {
			return await resendSignInOtpQuery(email);
		},
		onSuccess: async () => {
			notifications.show({
				message: `Le code a été renvoyé à ${email}`,
				color: 'teal',
				icon: <IconCheck style={{ width: '20rem', height: '20rem' }} />,
			});
		},
		onError: () => {
			notifications.show({
				message:
					'Une erreur est survenue lors du renvoi du code. Veuillez réessayer.',
				color: 'red',
				icon: <IconX style={{ width: '20rem', height: '20rem' }} />,
			});
		},
	});

	return (
		<div className="flex max-w-xl flex-col justify-center gap-12">
			<div className="flex flex-col gap-3">
				<h1 className="text-center">Entrez le code</h1>
				<p className="text-center text-md font-normal text-muted-foreground">
					Entrez le code d’authentification que nous avons envoyé à {email}.
					Attention à ne pas partager ce code.
				</p>
			</div>

			<div className="flex flex-col items-center gap-4">
				<SignInOtpVerificationForm
					onSubmit={(otp) => verifyOtpmutation.mutate(otp)}
					isLoading={verifyOtpmutation.isPending}
				/>
				{verifyOtpmutation.isError &&
					verifyOtpmutation.error.response?.status
						.toString()
						.startsWith('4') && (
						<p className="text-center text-sm text-error-500">
							Le code est invalide. Veuillez demander l'envoi d'un nouveau code.
						</p>
					)}
				<div className="mx-auto flex items-center space-x-2 max-md:flex-col">
					<p className="text-center">Vous n'avez pas reçu d'email ?</p>
					<Button
						variant="link"
						className="inline-block px-0"
						onClick={() => resendOtpCodeMutation.mutate()}
					>
						Renvoyer le code
					</Button>
				</div>
			</div>

			<Button
				variant="link"
				size={'sm'}
				onClick={() => navigate(paths.SIGNIN_PATH)}
				className="flex justify-center"
			>
				<div className="flex space-x-2">
					<IconArrowLeft />
					<p>Retour à la connexion</p>
				</div>
			</Button>
		</div>
	);
}
