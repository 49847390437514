import { AxiosError } from 'axios';
import { publicAxios } from '../../../../shared/services/publicAxios';
import { JoinResearchResponse } from '../../shared/types/JoinResearchResponse';
import { LoginResponse } from '../../shared/types/AuthToken';
import { InvestigatorSignUpDto } from './investigator.dto';

export const InvestigatorJoinResearchQuery = async (
	key: string,
): Promise<JoinResearchResponse> => {
	try {
		const response = await publicAxios.post<JoinResearchResponse>(
			`/investigators/confirm-join-research`,
			undefined,
			{ headers: { Authorization: `Bearer ${key}` } },
		);
		return response.data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const InvestigatorSignUpQuery = async (
	body: InvestigatorSignUpDto,
	key: string,
): Promise<LoginResponse> => {
	try {
		const response = await publicAxios.post<LoginResponse>(
			`/investigators/signup`,
			body,
			{
				headers: { Authorization: `Bearer ${key}` },
			},
		);
		return response.data;
	} catch (error) {
		throw error as AxiosError;
	}
};
