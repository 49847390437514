import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { useState } from 'react';
import { S3File } from '../../../../shared/types/S3File';
import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';
import { CreateSignerDto } from '../../api/consents.dto';
import { useCreateConsent } from '../../api/consents.hooks';
import { AddConsentSignersForm } from '../forms/AddConsentSignersForm';
import { UploadConsentForm } from '../forms/UploadConsentForm';

type AddConsentModalProps = {
	handleClose: () => void;
	participantId?: number;
};

export function AddConsentModal({ handleClose }: AddConsentModalProps) {
	const isMobile = useIsMobile();

	const [stepForm, setStepForm] = useState<1 | 2>(1);
	const isFirstStep = stepForm === 1;

	const [participant, setParticipant] = useState<UserOnResearch>();
	const [signers, setSigners] = useState<CreateSignerDto[]>([]);
	const [document, setDocument] = useState<S3File>();

	const { addConsent, isLoading } = useCreateConsent(handleClose);

	const handleCancel = () => {
		if (isFirstStep) handleClose();
		setStepForm(1);
	};
	const cancelButtonText = isFirstStep ? 'Annuler' : 'Retour';

	const handleValidate = () => {
		if (isFirstStep) {
			setStepForm(2);
			return;
		}
		handleSubmit();
	};
	const handleSubmit = async () => {
		if (!document || !signers || !participant) return;
		await addConsent(participant.userId, document, signers);
	};
	const submitButtonText = isFirstStep ? 'Suivant' : 'Envoyer pour signature';
	const isDisabled =
		!signers || signers.length === 0 || (stepForm === 2 && !document);

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			title="Faire signer un consentement"
			size="lg"
			fullScreen={isMobile}
		>
			{isFirstStep ? (
				<AddConsentSignersForm
					selectedParticipant={participant}
					setSelectedParticipant={setParticipant}
					signers={signers}
					setSigners={setSigners}
				/>
			) : (
				<UploadConsentForm document={document} setDocument={setDocument} />
			)}
			<div className="flex gap-4">
				{!isMobile && (
					<Button onClick={handleCancel} variant="outline" className="w-full">
						{cancelButtonText}
					</Button>
				)}
				<Button
					onClick={handleValidate}
					disabled={isDisabled || isLoading}
					className="w-full"
				>
					{submitButtonText}
				</Button>
			</div>
		</Modal>
	);
}
