import { UserOnResearch } from '@/features/usersOnResearch/api/types/users-on-research.types';
import { ActionIcon } from '@mantine/core';
import { IconMessageCircle2Filled } from '@tabler/icons-react';
import { ContactsPicker } from '../forms/ContactsPicker';

type NewMessageListHeaderProps = {
	recipients: UserOnResearch[];
	setRecipients: React.Dispatch<React.SetStateAction<UserOnResearch[]>>;
};

export const NewMessageListHeader = (props: NewMessageListHeaderProps) => {
	return (
		<div className="flex flex-col">
			<div className="border-b-0.5 flex min-h-[70px] items-center gap-4 border-gray-50 px-4 py-3">
				<ActionIcon size="50" radius="xl">
					<IconMessageCircle2Filled />
				</ActionIcon>
				<p className="text-lg font-semibold">Nouveau message</p>
			</div>
			<div className="min-h-[30px] w-full">
				<ContactsPicker
					recipients={props.recipients}
					setRecipients={props.setRecipients}
				/>
			</div>
		</div>
	);
};
