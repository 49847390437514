import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useInvalidateQueries } from '../../../../../../shared/hooks/useInvalidateQueries';
import { dashboardQueryKeys } from '../../../../../dashboard/api/dashbord.query-keys';
import { useCurrentResearch } from '../../../../../researches/contexts/researches.context';
import { visitsQueryKeys } from '../../../../api/visits.query-keys';
import { actionsQueryKeys } from '../../../api/actions.query-keys';
import { resetResultDocuments } from '../action-documents.mutations';

export const useResetActionDocuments = (
	actionId: number,
	userId: number,
	successCallback?: () => void,
) => {
	const { research } = useCurrentResearch();
	const invalidateQueries = useInvalidateQueries();

	const resetResultsMutation = useMutation({
		mutationFn: async () => resetResultDocuments(actionId, userId),
		onSuccess: () => {
			notifications.show({
				message: 'Documents supprimés',
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
			invalidateQueries([
				dashboardQueryKeys.detail(research.id),
				visitsQueryKeys.byResearch(research.id),
				actionsQueryKeys.results(actionId),
			]);
			successCallback?.();
		},
		onError: () => {
			notifications.show({
				message: `Une erreur est survenue`,
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		},
	});

	return { resetResultsMutation };
};
