import { CreateDocumentWithoutKeyDto } from '../../api/documents.dto';
import { DocumentCustomType } from '../../api/documents.types';
import { DocumentOption } from './DocumentOption';

type Props = {
	documents: (CreateDocumentWithoutKeyDto & { file: File })[];
	setDocuments: React.Dispatch<
		React.SetStateAction<(CreateDocumentWithoutKeyDto & { file: File })[]>
	>;
};

export function DocumentControlPanel({ documents, setDocuments }: Props) {
	const removeDocument = (name: string) => {
		setDocuments(documents.filter(({ file }) => file.name !== name));
	};

	const handleDocumentPropertyChange = (
		name: string,
		type: DocumentCustomType,
	) => {
		setDocuments((currDocuments) =>
			currDocuments.map((document) => {
				if (document.file.name === name) document.type = type;
				return document;
			}),
		);
	};

	return (
		<div className="flex flex-col gap-2">
			{documents.map((document) => (
				<DocumentOption
					key={document.file.name}
					document={document}
					onDocumentPropertyChange={handleDocumentPropertyChange}
					onRemoveFile={removeDocument}
				/>
			))}
		</div>
	);
}
