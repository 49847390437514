import {
	Accordion,
	Checkbox,
	ScrollArea,
	Skeleton,
	Space,
	Stack,
	Text,
} from '@mantine/core';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useDebounce } from 'use-debounce';
import { SearchBar } from '../../../../shared/components/inputs/SearchBar';
import { isMatch } from '../../../../shared/utils/filters';
import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';
import { FilterVisitsSchedulesZodType } from '../forms/validators/FilterVisitsSchedulesZodSchema';

type ParticipantsFilterItemProps = {
	error: unknown;
	isLoading: boolean;
	participants: UserOnResearch[];
};
export const ParticipantsFilterItem = ({
	error,
	isLoading,
	participants,
}: ParticipantsFilterItemProps) => {
	const [searchValue, setSearchValue] = useState('');
	const [debouncedSearchText] = useDebounce(searchValue, 500);

	const { control } = useFormContext<FilterVisitsSchedulesZodType>();

	if (error)
		return (
			<Accordion.Item value="participants">
				<Accordion.Control>
					<Text size="xl" fw={600}>
						Participants
					</Text>
				</Accordion.Control>
				<Accordion.Panel>
					<Text fs="italic" c="red" size="sm">
						Une erreur est survenue lors de la récupération des données
					</Text>
				</Accordion.Panel>
			</Accordion.Item>
		);

	const searchedParticipants = participants?.filter((participant) =>
		isMatch(participant.participantOnResearch?.code ?? '', debouncedSearchText),
	);

	return (
		<Accordion.Item value="participants">
			<Accordion.Control>
				<Text size="xl" fw={600}>
					Participants
				</Text>
			</Accordion.Control>
			<Accordion.Panel>
				<SearchBar setSearchValue={setSearchValue} />
				<Space h="xl" />
				<Skeleton visible={isLoading}>
					<ScrollArea h={250} type="hover" scrollbars="y">
						<Controller
							control={control}
							name="participantIds"
							defaultValue={participants?.map(
								(participant) => participant.userId,
							)}
							render={({ field: { onChange, value } }) => (
								<Checkbox.Group
									onChange={(newValue) => onChange(newValue)}
									defaultChecked={true}
									value={value?.map((id) => id.toString())}
								>
									<Stack gap="md">
										{searchedParticipants?.map((participant) => (
											<Checkbox
												size="md"
												key={participant.userId}
												label={participant.participantOnResearch?.code}
												value={participant.userId.toString()}
												radius={0}
											/>
										))}
									</Stack>
								</Checkbox.Group>
							)}
						/>
					</ScrollArea>
				</Skeleton>
			</Accordion.Panel>
		</Accordion.Item>
	);
};
