export function PasswordRules() {
	return (
		<div className="flex flex-col gap-0">
			<div className="flex flex-col gap-0 md:flex-row md:gap-24">
				<p className="w-36 text-xs text-muted-foreground">
					• 12 caractères minimum
				</p>
				<p className="text-xs text-muted-foreground">• 1 majuscule</p>
			</div>
			<div className="flex flex-col gap-0 md:flex-row md:gap-24">
				<p className="w-36 text-xs text-muted-foreground">• 1 chiffre</p>
				<p className="text-xs text-muted-foreground">• 1 caractère spécial</p>
			</div>
		</div>
	);
}
