import { Button } from '@/components/ui/button';
import { isParticipant } from '@/features/usersOnResearch/utils/validateRoles';
import { IconArrowLeft, IconLock } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../../routes/paths';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';
import { useCurrentResearch } from '../../contexts/researches.context';
import { ParticipantBadge } from './ParticipantBadge';

export function ResearchHeader() {
	const navigate = useNavigate();
	const { userOnResearch, research } = useCurrentResearch();
	const { participant } = useCurrentParticipant();

	const backButtonText =
		participant && userOnResearch.role !== 'PARTICIPANT'
			? 'Tous les participants'
			: 'Toutes les recherches';

	const getBackPath =
		participant && userOnResearch.role !== 'PARTICIPANT'
			? paths.PARTICIPANTS_TAB
			: paths.INDEX_PATH;

	const isResearchMember =
		userOnResearch.role === 'INVESTIGATOR' ||
		userOnResearch.role === 'ONSITE_RESEARCH_MEMBER' ||
		userOnResearch.role === 'OFFSITE_RESEARCH_MEMBER';

	return (
		<div className="flex flex-col items-start gap-2">
			<Button
				variant="link"
				size={'xs'}
				onClick={() => navigate(getBackPath)}
				className="flex justify-center px-0"
			>
				<div className="flex space-x-1">
					<IconArrowLeft className="h-4 w-4" />
					<p>{backButtonText}</p>
				</div>
			</Button>
			<div className="flex flex-col gap-0">
				<div className="flex items-center space-x-2">
					<p className="text-md font-bold ">{research.name}</p>
					{userOnResearch.status === 'INACTIVE' && (
						<IconLock className="h-6 w-6 text-error-500" />
					)}
				</div>
				<p className="line-clamp-1 text-sm text-muted-foreground">
					{research.description}
				</p>
			</div>
			<div className="mb-3">
				{isResearchMember && isParticipant(participant) && (
					<ParticipantBadge participant={participant} />
				)}
			</div>
		</div>
	);
}
