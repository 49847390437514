import { Control, FieldValues, UseFormRegister } from 'react-hook-form';
import { Question } from '../../api/questionnaires.types';
import { MultipleSelectQuestionInput } from './MultipleSelectQuestionInput';
import { RangeQuestionInput } from './RangeQuestionInput';
import { SingleSelectQuestionInput } from './SingleSelectQuestionInput';
import { TextQuestionInput } from './TextQuestionInput';

type Props = {
	question: Question;
	control: Control;
	register: UseFormRegister<FieldValues>;
	errorMessage: string;
};

export function QuestionInput({
	question,
	control,
	register,
	errorMessage,
}: Props) {
	switch (question.type) {
		case 'TEXT':
			return (
				<TextQuestionInput
					question={question}
					register={register}
					errorMessage={errorMessage}
				/>
			);
		case 'RANGE':
			return <RangeQuestionInput question={question} control={control} />;
		case 'MULTIPLE_CHOICE':
			return (
				<MultipleSelectQuestionInput question={question} control={control} />
			);
		case 'SINGLE_CHOICE':
			return (
				<SingleSelectQuestionInput question={question} control={control} />
			);
		default:
			return null;
	}
}
