import {
	IconFile,
	IconFileSpreadsheet,
	IconFileText,
	IconFileTypePdf,
	IconFileTypePpt,
	IconMovie,
	IconPhoto,
} from '@tabler/icons-react';

type Props = {
	fileName: string;
};

export function FileIcon({ fileName }: Props) {
	const extension = fileName.split('.').pop()?.toLowerCase();

	switch (extension) {
		case 'png':
		case 'jpg':
		case 'jpeg':
			return <IconPhoto size={24} color="var(--mantine-primary-color-6)" />;
		case 'pdf':
			return (
				<IconFileTypePdf size={24} color="var(--mantine-primary-color-6)" />
			);
		case 'doc':
		case 'docx':
			return <IconFileText size={24} color="var(--mantine-primary-color-6)" />;
		case 'xls':
		case 'xlsx':
		case 'csv':
			return (
				<IconFileSpreadsheet size={24} color="var(--mantine-primary-color-6)" />
			);
		case 'ppt':
		case 'pptx':
			return (
				<IconFileTypePpt size={24} color="var(--mantine-primary-color-6)" />
			);
		case 'mp4':
		case 'mov':
		case 'avi':
			return <IconMovie size={24} color="var(--mantine-primary-color-6)" />;
		default:
			return <IconFile size={24} color="var(--mantine-primary-color-6)" />;
	}
}
