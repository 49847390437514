import { useDisclosure } from '@mantine/hooks';
import { IconEdit, IconEye, IconTrash } from '@tabler/icons-react';
import dayjs from 'dayjs';
import {
	MRT_Cell,
	MRT_ColumnDef,
	MantineReactTable,
	useMantineReactTable,
} from 'mantine-react-table';
import { useMemo, useState } from 'react';
import { getDefaultMRTOptions } from '../../../../components/ui/table/table-options';
import {
	customDateFilterFn,
	customNameFilterFn,
	isMatch,
} from '../../../../shared/utils/filters';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';
import { getResearchMemberInfo } from '../../../usersOnResearch/utils/researchMemberInfo';
import { useGetNotes } from '../../api/hooks/useGetNotes';
import { NoteSummary } from '../../api/notes.types';
import { DeleteNoteModal } from '../modals/DeleteNoteModal';
import { UpdateNoteModal } from '../modals/UpdateNoteModal';
import { ViewNoteModal } from '../modals/ViewNoteModal';

export function NotesTable() {
	const { participant } = useCurrentParticipant();
	const { userOnResearch } = useCurrentResearch();

	const { notes, isLoading, error } = useGetNotes();
	const [currentNote, setCurrentNote] = useState<NoteSummary>();

	const canUpdateOrDeleteNote = (note: NoteSummary) => {
		if (userOnResearch.status !== 'ACTIVE') return false;
		if (
			!participant ||
			['INVESTIGATOR', 'ONSITE_RESEARCH_MEMBER'].includes(userOnResearch.role)
		)
			return true;
		return note.owner.userId === userOnResearch.userId;
	};

	const [
		deleteModalOpened,
		{ open: openDeleteModal, close: closeDeleteModal },
	] = useDisclosure();
	const handleOnDeleteModalClick = (note: NoteSummary) => {
		setCurrentNote(note);
		openDeleteModal();
	};
	const [viewModalOpened, { open: openViewModal, close: closeViewModal }] =
		useDisclosure();
	const handleOnViewModalClick = (note: NoteSummary) => {
		setCurrentNote(note);
		openViewModal();
	};
	const [
		updateModalOpened,
		{ open: openUpdateModal, close: closeUpdateModal },
	] = useDisclosure();
	const handleOnUpdateModalClick = (note: NoteSummary) => {
		setCurrentNote(note);
		openUpdateModal();
	};

	const columns = useMemo<MRT_ColumnDef<NoteSummary>[]>(
		() => [
			{
				header: 'Titre',
				id: 'title',
				accessorKey: 'title',
				size: 250,
				Cell: ({ cell }: { cell: MRT_Cell<NoteSummary> }) => (
					<p className="word-break line-clamp-2 text-md font-semibold">
						{cell.getValue<string>()}
					</p>
				),
				enableSorting: false,
				filterFn: (row, _id, filterValue) =>
					isMatch(row.original.title, filterValue),
			},
			{
				header: 'Créateur',
				id: 'owner',
				accessorKey: 'owner',
				size: 50,
				Cell: ({ cell }: { cell: MRT_Cell<NoteSummary> }) => {
					const { displayTitle, displayRole } = getResearchMemberInfo(
						cell.getValue<UserOnResearch>(),
					);
					return (
						<div className="flex w-full flex-col">
							<p className="text-center text-sm">{displayTitle}</p>
							<p className="text-center text-xs text-muted-foreground">
								{displayRole}
							</p>
						</div>
					);
				},
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
				filterFn: (row, _id, filterValue) =>
					customNameFilterFn(
						row.original.owner.firstName,
						row.original.owner.lastName,
						filterValue,
					),
			},

			{
				header: "Date d'ajout",
				id: 'createdAt',
				accessorKey: 'createdAt',
				size: 50,
				Cell: ({ cell }: { cell: MRT_Cell<NoteSummary> }) => (
					<p className="text-sm">
						{dayjs(cell.getValue<Date>()).format('DD/MM/YY')}
					</p>
				),
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
				filterFn: (row, _id, filterValue) =>
					customDateFilterFn(row.original.createdAt, filterValue),
			},
			{
				id: 'actions-view',
				header: '',
				accessorKey: 'id',
				columnDefType: 'display',
				size: 1,
				Cell: ({ cell }: { cell: MRT_Cell<NoteSummary> }) => (
					<IconEye
						size={22}
						onClick={() => handleOnViewModalClick(cell.row.original)}
						className="text-primary"
					/>
				),
			},
			{
				id: 'actions-edit',
				header: '',
				accessorKey: 'id',
				columnDefType: 'display',
				size: 1,
				Cell: ({ cell }: { cell: MRT_Cell<NoteSummary> }) =>
					canUpdateOrDeleteNote(cell.row.original) && (
						<IconEdit
							size={22}
							onClick={() => handleOnUpdateModalClick(cell.row.original)}
							className="text-primary"
						/>
					),
			},
			{
				id: 'actions-delete',
				header: '',
				accessorKey: 'id',
				columnDefType: 'display',
				size: 1,
				Cell: ({ cell }: { cell: MRT_Cell<NoteSummary> }) =>
					canUpdateOrDeleteNote(cell.row.original) && (
						<IconTrash
							size={22}
							onClick={() => handleOnDeleteModalClick(cell.row.original)}
							className="text-destructive"
						/>
					),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const defaultTableOptions = getDefaultMRTOptions<NoteSummary>(Boolean(error));
	const table = useMantineReactTable({
		data: notes || [],
		columns,
		...defaultTableOptions,
		state: { isLoading },
		filterFns: {
			customNoteFilterFn: (row, _id, filterValue) =>
				isMatch(row.original.fileName, filterValue),
		},
		globalFilterFn: 'customNoteFilterfn',
	});

	return (
		<>
			<MantineReactTable table={table} />
			{deleteModalOpened && currentNote && (
				<DeleteNoteModal
					noteId={currentNote.id}
					handleClose={closeDeleteModal}
				/>
			)}
			{updateModalOpened && currentNote && (
				<UpdateNoteModal
					noteId={currentNote.id}
					handleClose={closeUpdateModal}
				/>
			)}
			{viewModalOpened && currentNote && (
				<ViewNoteModal noteId={currentNote.id} handleClose={closeViewModal} />
			)}
		</>
	);
}
