import { DateInputProps } from '@mantine/dates';
import dayjs from 'dayjs';
import locale_fr from 'dayjs/locale/fr';
import CustomParseFormat from 'dayjs/plugin/customParseFormat';
import Duration from 'dayjs/plugin/duration';
import RelativeTime from 'dayjs/plugin/relativeTime';
import weekday from 'dayjs/plugin/weekday';
import { TimeScale } from '../../features/dashboard/types/TimeScale';

dayjs.extend(RelativeTime);
dayjs.extend(CustomParseFormat);
dayjs.extend(weekday);
dayjs.extend(Duration);
dayjs.locale('fr');
dayjs().weekday(1); // Monday

export const isUnder18 = (birthDate: Date) => {
	const age = dayjs().diff(birthDate, 'year');
	return age < 18;
};

export const dateParser: DateInputProps['dateParser'] = (input) =>
	dayjs(input, 'DD/MM/YYYY').toDate();

export const formatDate = (date: string | Date, formatStr: string) => {
	return dayjs(date).locale(locale_fr).format(formatStr);
};

export const isSameTimeScale = (
	date1: Date,
	date2: Date,
	timeScale: TimeScale,
) => dayjs(date1).isSame(date2, timeScale);

export const computeDuration = (before: Date, after: Date) => {
	return after.getTime() - before.getTime();
};

export const addDuration = (
	date: Date,
	increment: number,
	timeScale: TimeScale,
) => {
	return dayjs(date).add(increment, timeScale).toDate();
};

export const formatTimeToHHMMSS = (
	milliseconds: number,
	{ displaySeconds = false } = {},
) => {
	const totalMinutes = Math.floor(milliseconds / 60000); // Convert milliseconds to minutes
	const hours = Math.floor(totalMinutes / 60);
	const minutes = totalMinutes % 60;
	const paddedMinutes = minutes.toString().padStart(2, '0');
	const seconds = Math.floor((milliseconds % 60000) / 1000);
	const paddedSeconds = seconds.toString().padStart(2, '0');

	let result = '';
	if (hours > 0) result += `${hours}h `;
	if (minutes > 0) result += `${paddedMinutes}min `;
	if (displaySeconds) result += `${paddedSeconds}s`;

	return result;
};

export const combineDateAndTime = (date: string, time: string) => {
	const parsedDate = dayjs(date);
	const [hours, minutes] = time.split(':').map(Number);
	return dayjs(parsedDate).minute(minutes).hour(hours).toDate();
};

export const getTimeFromDate = (date: string | Date) => {
	const parsedDate = typeof date === 'string' ? dayjs(date) : date;
	const hours = dayjs(parsedDate).hour();
	const paddedHours = hours.toString().padStart(2, '0');
	const minutes = dayjs(parsedDate).minute();
	const paddedMinutes = minutes.toString().padStart(2, '0');

	return `${paddedHours}:${paddedMinutes}`;
};

export const compareTime = (startTime: string, endTime: string) => {
	const [startHours, startMinutes] = startTime.split(':').map(Number);
	const [endHours, endMinutes] = endTime.split(':').map(Number);

	if (startHours < endHours) return true;
	if (startHours === endHours && startMinutes < endMinutes) return true;

	return false;
};

export const getAllTimeTicksInTimeLapse = (
	startDate: Date,
	endDate: Date,
	scale: TimeScale,
) => {
	const timeTicks = [];

	const start = dayjs(startDate).startOf(scale);
	const end = dayjs(endDate).endOf(scale);

	let current = start;
	while (current.isBefore(end) || current.isSame(end, scale)) {
		timeTicks.push(current.toDate());
		current = current.add(1, scale);
	}

	return timeTicks;
};

export const getTimeElapsedFromNow = (date: Date) => {
	return dayjs(date).fromNow(true);
};

export const getStartOf = (date: Date, timeScale: TimeScale): Date => {
	return dayjs(date).startOf(timeScale).toDate();
};

export const getEndOf = (date: Date, timeScale: TimeScale): Date => {
	return dayjs(date).endOf(timeScale).toDate();
};

export const isSameDay = (date1: Date, date2: Date) => {
	const day1 = dayjs(date1).startOf('day'); // Start of current day
	const day2 = dayjs(date2).startOf('day'); // Start of the given date

	return day1.isSame(day2, 'day');
};
