import { AxiosError } from 'axios';
import { publicAxios } from '../../../../shared/services/publicAxios';

export const requestPasswordResetQuery = async (email: string) => {
	try {
		const response = await publicAxios.post(`/auth/forgot-password`, {
			email,
		});
		return response.data;
	} catch (error) {
		throw error as AxiosError;
	}
};
