import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { useState } from 'react';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { CreateDocumentWithoutKeyDto } from '../../api/documents.dto';
import { useUploadDocuments } from '../../api/hooks/useUploadDocuments';
import { UploadDocumentsForm } from '../forms/UploadDocumentsForm';

type AddParticipantDocumentsModalProps = {
	handleClose: () => void;
};

export function AddParticipantDocumentsModal({
	handleClose,
}: AddParticipantDocumentsModalProps) {
	const isMobile = useIsMobile();
	const { userOnResearch } = useCurrentResearch();

	const [documents, setDocuments] = useState<
		(CreateDocumentWithoutKeyDto & { file: File })[]
	>([]);

	const { uploadDocuments, isLoading } = useUploadDocuments();
	const handleSubmit = async () => {
		await uploadDocuments(documents);
		handleClose();
	};

	const submitButtonText = `Importer ${documents.length} fichier${documents.length > 1 ? 's' : ''}`;

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			title="Importer des documents"
			size="lg"
			fullScreen={isMobile}
		>
			<UploadDocumentsForm
				accessLevel="ALL"
				recipients={[userOnResearch]}
				documents={documents}
				setDocuments={setDocuments}
			/>

			<div className="flex gap-4">
				{!isMobile && (
					<Button onClick={handleClose} variant="outline" className="w-full">
						Annuler
					</Button>
				)}
				<Button
					onClick={handleSubmit}
					disabled={documents.length === 0 || isLoading}
					className="w-full"
				>
					{submitButtonText}
				</Button>
			</div>
		</Modal>
	);
}
