import { Button } from '@/components/ui/button';
import MyField from '@/components/ui/form/form-field';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import {
	RequestPasswordResetZodSchema,
	RequestPasswordResetZodType,
} from './validators/RequestPasswordResetValidator';

type Props = {
	onSubmit: (data: RequestPasswordResetZodType) => void;
	isLoading: boolean;
};

export function RequestPasswordResetForm({ onSubmit, isLoading }: Props) {
	const methods = useForm<RequestPasswordResetZodType>({
		resolver: zodResolver(RequestPasswordResetZodSchema),
	});

	const {
		handleSubmit,
		formState: { errors },
	} = methods;

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={handleSubmit(onSubmit)}
				noValidate
				className="flex w-full flex-col gap-12"
			>
				<MyField
					type="email"
					name="email"
					label="Email"
					placeholder="Entrez l’email lié à votre compte CosyTrials"
					error={errors.email?.message}
					labelClass="text-black"
					inputClass="text-black"
				/>
				<Button
					disabled={isLoading}
					type="submit"
					className="flex justify-center"
				>
					Réinitialiser le mot de passe
				</Button>
			</form>
		</FormProvider>
	);
}
