import { Navigate } from 'react-router-dom';
import { CustomLoader } from '../../../components/ui/loader';
import { paths } from '../../../routes/paths';

import { useGetUserProfile } from '../api/profile.hooks';
import { InvestigatorProfileForm } from '../components/forms/InvestigatorProfileForm';
import { InvestigatorProfileForm2 } from '../components/forms/InvestigatorProfileForm2';
import { LegalRepresentativeForm } from '../components/forms/LegalRepresentativeForm';
import { OffsiteResearchMemberProfileForm } from '../components/forms/OffsiteResearchMemberProfileForm';
import { OffsiteResearchMemberProfileForm2 } from '../components/forms/OffsiteResearchMemberProfileForm2';
import { OnsiteResearchMemberProfileForm } from '../components/forms/OnsiteResearchMemberProfileForm';
import { OnsiteResearchMemberProfileForm2 } from '../components/forms/OnsiteResearchMemberProfileForm2';
import { ParticipantProfileForm } from '../components/forms/PaticipantProfileForm';

export function PersonalInformation() {
	const { profile, isLoading, error } = useGetUserProfile();

	if (error) return <Navigate to={`/${paths.ERROR_PATH}`} />;
	if (isLoading || !profile) return <CustomLoader />;

	return (
		<>
			{profile.role === 'PARTICIPANT' && (
				<div className="flex flex-col gap-12">
					<ParticipantProfileForm />
					{profile.participantProfile?.legalRepresentatives?.map(
						(legalRepresentative) => (
							<LegalRepresentativeForm
								key={legalRepresentative.legalRepresentativeId}
								legalRepresentativeId={
									legalRepresentative.legalRepresentativeId
								}
							/>
						),
					)}
				</div>
			)}

			{profile.role === 'INVESTIGATOR' && (
				<div className="flex flex-col gap-12">
					<InvestigatorProfileForm />
					<InvestigatorProfileForm2 />
				</div>
			)}

			{profile.role === 'ONSITE_RESEARCH_MEMBER' && (
				<div className="flex flex-col gap-12">
					<OnsiteResearchMemberProfileForm />
					<OnsiteResearchMemberProfileForm2 />
				</div>
			)}

			{profile.role === 'OFFSITE_RESEARCH_MEMBER' && (
				<div className="flex flex-col gap-12">
					<OffsiteResearchMemberProfileForm />
					<OffsiteResearchMemberProfileForm2 />
				</div>
			)}
		</>
	);
}
