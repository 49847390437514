import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Group, Space, Stack, Text, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { CustomLoader } from '../../../../../../components/ui/loader.tsx';
import { useCurrentParticipant } from '../../../../../usersOnResearch/contexts/participants.context.tsx';
import { ConfirmCancelModal } from '../../../../components/modals/ConfirmCancelModal.tsx';
import { ConfirmResponsesModal } from '../../../components/modals/ConfirmResponsesModal.tsx';
import {
	useGetQuestionnairePreview,
	useSendQuestionnaireResponse,
} from '../../api/questionnaires.hooks.tsx';
import { formatQuestionnaireReponses } from '../../utils/exportQuestionnaireResults.ts';
import { QuestionInput } from '../inputs/QuestionInput.tsx';
import { createQuestionnaireValidator } from './validators/QuestionnaireValidator.ts';

type QuestionnaireFormProps = {
	actionId: number;
	handleClose: () => void;
};

export function QuestionnaireForm({
	actionId,
	handleClose,
}: QuestionnaireFormProps) {
	const { participant } = useCurrentParticipant();
	const [
		confirmSubmitModalOpened,
		{ open: confirmSubmitModalOpen, close: confirmSubmitModalClose },
	] = useDisclosure();
	const [
		confirmCancelModalOpened,
		{ open: confirmCancelModalOpen, close: confirmCancelModalClose },
	] = useDisclosure();

	const { questionnaire, isLoading, error } =
		useGetQuestionnairePreview(actionId);

	const questionnaireZodSchema = useMemo(
		() =>
			createQuestionnaireValidator(
				questionnaire ? questionnaire.questions : [],
			),
		[questionnaire],
	);

	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
		getValues,
	} = useForm({
		resolver: zodResolver(questionnaireZodSchema),
	});

	const handleSubmitSuccess = () => {
		confirmSubmitModalClose();
		handleClose();
	};

	const { sendResultsMutation } = useSendQuestionnaireResponse(
		actionId,
		participant.userId,
		handleSubmitSuccess,
	);

	const handleQuestionnaireSubmit = async () => {
		const responses = formatQuestionnaireReponses(getValues());
		await sendResultsMutation.mutateAsync(responses);
	};

	if (error)
		return (
			<Text fs="italic" c="red" ta="center">
				Une erreur est survenue lors du chargement des données
			</Text>
		);
	if (isLoading || !questionnaire) return <CustomLoader />;

	return (
		<Stack gap="lg" w="100%">
			<Title order={3} ta="center">
				{questionnaire.title}
			</Title>
			<Text
				ta="center"
				truncate
				fw={500}
				style={{
					whiteSpace: 'pre-line',
				}}
			>
				{questionnaire.description}
			</Text>
			<Space h="xl" />
			<form onSubmit={handleSubmit(confirmSubmitModalOpen)}>
				<Stack gap="xl" w="100%" pb="sm" align="center">
					{questionnaire.questions.map((question) => (
						<QuestionInput
							key={question.id}
							question={question}
							register={register}
							errorMessage={errors[question.id]?.message?.toString() ?? ''}
							control={control}
						/>
					))}
					<Group w="90%" grow>
						<Button
							size="md"
							variant="outline"
							onClick={confirmCancelModalOpen}
						>
							Annuler
						</Button>
						<Button type="submit" size="md">
							Compléter le questionnaire
						</Button>
					</Group>
				</Stack>
			</form>
			{confirmSubmitModalOpened && (
				<ConfirmResponsesModal
					handleClose={confirmSubmitModalClose}
					handleSubmit={handleQuestionnaireSubmit}
					isLoading={sendResultsMutation.isPending}
				/>
			)}
			{confirmCancelModalOpened && (
				<ConfirmCancelModal
					handleCancel={() => {
						confirmCancelModalClose();
						handleClose();
					}}
					handleClose={confirmCancelModalClose}
				/>
			)}
		</Stack>
	);
}
