import { useIsMobile } from '@/components/hooks/useIsMobile';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { CreateRatingDto } from '../../api/ratings.dto';
import { useCreateRating } from '../../api/ratings.hooks';
import { NEXT_RATING_DATE } from '../../constants';
import { CreateRatingForm } from '../forms/CreateRatingForm';

export type Props = {
	onClose: () => void;
};

export const CreateRatingModal = ({ onClose }: Props) => {
	const handleClose = () => {
		const nextRatingDate = new Date();
		nextRatingDate.setMonth(nextRatingDate.getMonth() + 3);
		localStorage.setItem(NEXT_RATING_DATE, nextRatingDate.toISOString());
		onClose();
	};
	const { createRatingMutation } = useCreateRating(handleClose);
	const handleSubmit = (data: CreateRatingDto) => {
		createRatingMutation.mutate(data);
	};
	const isMobile = useIsMobile();

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			fullScreen={isMobile}
			classNames={classes}
			title="Que pensez-vous de cette application ?"
			size={'lg'}
		>
			<CreateRatingForm
				handleSubmit={handleSubmit}
				handleClose={handleClose}
				isLoading={createRatingMutation.isPending}
			/>
		</Modal>
	);
};
