import { Accordion, Divider, rem, Stack } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { VisitActionItem } from '../../actions/components/VisitActionItem';
import {
	isParticipantVisit,
	ParticipantVisit,
	Visit,
} from '../../api/visits.types';
import classes from '../../styles/VisitAccordion.module.scss';
import { VisitAccordionLabel } from './VisitAccordionLabel';

type Props = {
	visits: Visit[] | ParticipantVisit[];
};
export const VisitAccordion = ({ visits }: Props) => {
	const currentDate = new Date();

	const getFirstUpcomingVisit = () =>
		visits?.find(
			(visit) =>
				isParticipantVisit(visit) &&
				((visit.startDate && new Date(visit.startDate) > currentDate) ||
					(visit.forecastDate && new Date(visit.forecastDate) > currentDate)),
		);

	const getFirstPassedVisit = () =>
		visits?.find(
			(visit) =>
				isParticipantVisit(visit) &&
				visit.endDate &&
				new Date(visit.endDate) < currentDate,
		);

	const visitItems = visits?.map((visit) => {
		const isOverdue =
			isParticipantVisit(visit) &&
			visit.endDate &&
			new Date(visit.endDate) < new Date();
		return (
			<Stack key={visit.id} mb="xl">
				{getFirstUpcomingVisit()?.id === visit.id && (
					<Divider
						my="md"
						label="À venir"
						styles={{
							label: {
								fontSize: '16px',
							},
						}}
					/>
				)}
				{getFirstPassedVisit()?.id === visit.id && (
					<Divider
						my="md"
						label="Passées"
						styles={{
							label: {
								fontSize: '16px',
							},
						}}
					/>
				)}
				<Accordion.Item
					value={visit.id.toString()}
					className={isOverdue ? classes.overdue : ''}
				>
					<Accordion.Control>
						<VisitAccordionLabel visit={visit} />
					</Accordion.Control>
					<Accordion.Panel>
						<div className="flex flex-col gap-2">
							{visit?.actions?.length > 0 ? (
								visit?.actions?.map((action) => (
									<VisitActionItem action={action} key={action.id} />
								))
							) : (
								<p className="pt-4 text-sm text-muted-foreground">
									Aucune action à réaliser pour cette visite
								</p>
							)}
						</div>
					</Accordion.Panel>
				</Accordion.Item>
			</Stack>
		);
	});

	return (
		<Accordion
			variant="separated"
			chevronPosition="left"
			chevronSize={rem(25)}
			multiple
			radius="sm"
			chevron={
				<IconChevronDown
					stroke={rem(2.5)}
					color="var(--mantine-primary-color-6)"
				/>
			}
			classNames={classes}
		>
			{visitItems}
		</Accordion>
	);
};
