import { useIsMobile } from '@/components/hooks/useIsMobile';
import { CustomLoader } from '@/components/ui/loader';
import classes from '@/components/ui/modal/modal.module.scss';
import { cn } from '@/components/utils';
import { useCurrentResearch } from '@/features/researches/contexts/researches.context';
import { Modal } from '@mantine/core';
import { IconArrowLeft, IconPencil, IconX } from '@tabler/icons-react';
import { useState } from 'react';
import { useGetVisitSchedule } from '../../api/hooks/useGetVisitSchedule';
import { PreviewVisitSchedule } from './PreviewVisitSchedule';
import { ScheduleVisitForm } from './ScheduleVisitForm';

export type ShowVisitScheduleModalProps = {
	scheduledVisitId: number;
	onClose: () => void;
};

export const ShowVisitScheduleModal = ({
	scheduledVisitId,
	onClose: handleClose,
}: ShowVisitScheduleModalProps) => {
	const isMobile = useIsMobile();
	const { userOnResearch: user } = useCurrentResearch();
	const { schedule, isLoading, error } = useGetVisitSchedule(scheduledVisitId);
	const [updateSchedule, setUpdateSchedule] = useState(false);

	const canScheduleVisit = () => {
		if (!schedule) return false;
		if (schedule.participant?.status === 'INACTIVE') return false;
		if (schedule.isRemote) {
			return (
				user.role === 'INVESTIGATOR' ||
				user.role === 'ONSITE_RESEARCH_MEMBER' ||
				user.role === 'OFFSITE_RESEARCH_MEMBER'
			);
		}
		return (
			user.role === 'INVESTIGATOR' || user.role === 'ONSITE_RESEARCH_MEMBER'
		);
	};

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			onClick={(e) => e.stopPropagation()}
			zIndex={10000}
			fullScreen={isMobile}
			size="xl"
			withCloseButton={false}
		>
			<header className="mb-4 flex w-full items-center justify-between rounded-none px-4 pt-6 text-center md:mx-0">
				<IconArrowLeft
					stroke={1.5}
					size={'2rem'}
					className={cn(
						updateSchedule ? 'visible' : 'invisible',
						'text-gray-500',
					)}
					onClick={() => setUpdateSchedule(false)}
				/>

				<h2 className="pl-12">{schedule?.name}</h2>
				<div className="flex gap-4">
					<IconPencil
						onClick={() => setUpdateSchedule(true)}
						stroke={2}
						size={'2rem'}
						className={cn(
							!updateSchedule && canScheduleVisit() ? 'visible' : 'invisible',
							'text-primary',
						)}
					/>
					<IconX
						onClick={handleClose}
						stroke={1.5}
						size={'2rem'}
						className="text-gray-500"
					/>
				</div>
			</header>
			{error && (
				<p className="text-center text-sm text-error-500">
					Une erreur est survenue lors du chargement de la visite
				</p>
			)}
			{isLoading && <CustomLoader />}
			{schedule && (
				<div className="h-3/5">
					{updateSchedule ? (
						<ScheduleVisitForm
							visit={schedule}
							handleClose={() => setUpdateSchedule(false)}
						/>
					) : (
						<PreviewVisitSchedule schedule={schedule} />
					)}
				</div>
			)}
		</Modal>
	);
};
