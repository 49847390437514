import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { useEffect, useState } from 'react';
import { CustomLoader } from '../../../../components/ui/loader';
import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';
import { DocumentAccessLevelType } from '../../api/documents.types';
import { useGetDocument } from '../../api/hooks/useGetDocument';
import { useUpdateDocumentRecipients } from '../../api/hooks/useUpdateDocumentRecipients';
import { DocumentRecipientsForm } from '../forms/DocumentRecipientsForm';

type Props = {
	documentId: number;
	handleClose: () => void;
};

export function UpdateDocumentRecipientsModal({
	documentId,
	handleClose,
}: Props) {
	const isMobile = useIsMobile();
	const {
		document,
		isLoading: isDocumentInfoLoading,
		error,
	} = useGetDocument(documentId);
	const [recipients, setRecipients] = useState<UserOnResearch[]>(
		document?.recipients || [],
	);
	const [accessLevel, setAccessLevel] = useState<DocumentAccessLevelType>(
		document?.accessLevel || 'ALL',
	);

	const { updateDocumentMutation } = useUpdateDocumentRecipients(
		documentId,
		handleClose,
	);

	useEffect(() => {
		if (!document) return;
		setAccessLevel(document.accessLevel);
		setRecipients(document.recipients ?? []);
	}, [document]);

	const handleSubmit = () => {
		updateDocumentMutation.mutate([
			...recipients.map((recipient) => recipient.userOnResearchId),
		]);
	};

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			title="Modifier les destinataires"
			size="lg"
			fullScreen={isMobile}
		>
			{isDocumentInfoLoading && <CustomLoader />}
			{error && (
				<p className="text-center text-sm text-error-500">
					Une erreur est survenue lors de la récupération du document
				</p>
			)}
			{document && (
				<div className="flex flex-col gap-6">
					<DocumentRecipientsForm
						accessLevel={accessLevel}
						setAccessLevel={setAccessLevel}
						recipients={recipients}
						setRecipients={setRecipients}
						disabled={true}
					/>

					<div className="flex gap-4">
						{!isMobile && (
							<Button
								onClick={handleClose}
								variant="outline"
								className="w-full"
							>
								Annuler
							</Button>
						)}
						<Button
							disabled={
								updateDocumentMutation.isPending || recipients.length === 0
							}
							className="w-full"
							onClick={handleSubmit}
						>
							Valider
						</Button>
					</div>
				</div>
			)}
		</Modal>
	);
}
