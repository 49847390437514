import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import {
	ChangePasswordDto,
	UpdateLegalRepresentativeDto,
	UpdateUserInfoDto,
} from './profile.dto';

export const updateUserInfo = async (body: UpdateUserInfoDto) => {
	try {
		const { data } = await privateAxios.put(`/users/me`, body);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const updateLegalRepresentative = async (
	legalRepresentativeId: number,
	body: UpdateLegalRepresentativeDto,
) => {
	try {
		const { data } = await privateAxios.patch(
			`/participants/update-legal-representative/${legalRepresentativeId}`,
			body,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const deleteLegalRepresentative = async (
	legalRepresentativeId: number,
) => {
	try {
		const { data } = await privateAxios.delete(
			`/participants/delete-legal-representative/${legalRepresentativeId}`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const updatePassword = async (body: ChangePasswordDto) => {
	try {
		const { data } = await privateAxios.patch(`/auth/change-password`, body);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
