import { z } from 'zod';
import {
	addressValidator,
	cityValidator,
	firstNameValidator,
	medicalServiceValidator,
	phoneNumberValidator,
	rppsValidator,
	zipCodeValidator,
} from '../../../../../shared/components/validators/commonValidators';

export type OffsiteResearchMemberProfileZodType = z.infer<
	typeof OffsiteResearchMemberProfileZodSchema
>;

export const OffsiteResearchMemberProfileZodSchema = z.object({
	firstName: firstNameValidator.optional(),
	lastName: firstNameValidator.optional(),
	phoneNumber: phoneNumberValidator.optional(),
	offsiteResearchMemberProfile: z.object({
		workplaceAddress: addressValidator,
		workplaceCity: cityValidator,
		workplaceZipCode: zipCodeValidator,
		medicalService: medicalServiceValidator,
		rpps: rppsValidator.transform((rpps) => rpps ?? ''),
	}),
});
