import { ConsentStatus } from './consents.entities';

export const consentsQueryKeys = {
	all: ['consents'] as const,
	list: (
		researchId: number,
		filters: {
			userId?: number;
			statuses?: ConsentStatus[];
		},
	) => [...consentsQueryKeys.all, researchId, filters] as const,
};
