import { z } from 'zod';
import { Option } from '../../../api/questionnaires.types';

export const MultipleSelectQuestionZodSchema = (options: Option[]) =>
	z
		.array(
			z
				.string()
				.trim()
				.refine((value) => options.some((option) => option.label === value), {
					message: 'La réponse à cette question doit être une option valide',
				}),
		)
		.optional()
		.refine((value) => Boolean(value?.length), {
			message: 'La réponse à cette question est requise',
		});
