import { BaseUser, Role } from '../../../profile/api/types/user.types';
import { ParticipantOnResearch } from './participants.types';
import {
	InvestigatorOnResearch,
	OffsiteResearchMemberOnResearch,
	OnsiteResearchMemberOnResearch,
} from './research-members.types';

export const UserOnResearchStatusEnum = {
	ACTIVE: 'Actif',
	INACTIVE: 'Figé',
	PENDING: 'En attente',
};

export type UserOnResearchStatusType = keyof typeof UserOnResearchStatusEnum;

export type UserOnResearch = BaseUser & {
	researchId: number;
	userOnResearchId: number;
	status: UserOnResearchStatusType;
	role: Role;
	activatedAt?: Date;
	deactivatedAt?: Date;
	createdAt: Date;
	participantOnResearch?: ParticipantOnResearch;
	investigatorOnResearch?: InvestigatorOnResearch;
	offsiteResearchMemberOnResearch?: OffsiteResearchMemberOnResearch;
	onsiteResearchMemberOnResearch?: OnsiteResearchMemberOnResearch;
};
