import { useQuery } from '@tanstack/react-query';
import { getDocumentInfo } from '../documents.queries';
import { documentsQueryKeys } from '../documents.query-keys';
import { DocumentInfo } from '../documents.types';

export const useGetDocument = (documentId: number) => {
	const {
		data: document,
		isLoading,
		error,
	} = useQuery<DocumentInfo>({
		queryKey: documentsQueryKeys.details(documentId),
		queryFn: () => getDocumentInfo(documentId),
	});

	return { document, isLoading, error };
};
