import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../../../App';
import {
	markNotificationAsPerformed,
	markNotificationAsRead,
} from './notifications.mutations';
import {
	getNotifications,
	getUnreadNotificationsCount,
} from './notifications.queries';
import { notificationsQueryKeys } from './notifications.query-keys';

const showErrorNotification = () => {
	notifications.show({
		message: `Une erreur est survenue`,
		color: 'red',
		icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
	});
};

export const useGetNotifications = () => {
	const {
		data: notifications,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isLoading,
		error,
	} = useInfiniteQuery({
		queryKey: notificationsQueryKeys.list(),
		queryFn: ({ pageParam }) => getNotifications(pageParam),
		initialPageParam: 1,
		getNextPageParam: (lastPage, allPages) =>
			lastPage.length ? allPages.length + 1 : undefined,
	});

	return {
		notifications,
		isLoading,
		error,
		fetchNextPage,
		isFetching,
		hasNextPage,
	};
};

export const useGetUnreadNotificationsCount = () => {
	const {
		data: unreadNotificationsCount,
		isLoading,
		error,
	} = useQuery({
		queryKey: notificationsQueryKeys.count(),
		queryFn: async () => getUnreadNotificationsCount(),
	});

	return { unreadNotificationsCount, isLoading, error };
};

export const useMarkAsRead = (notificationId: number) => {
	const markAsReadMutation = useMutation({
		mutationFn: () => markNotificationAsRead(notificationId),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: notificationsQueryKeys.list(),
			});
		},
		onError: showErrorNotification,
	});

	return { markAsReadMutation };
};

export const useMarkAsPerformed = (notificationId: number) => {
	const markAsPerformedMutation = useMutation({
		mutationFn: () => markNotificationAsPerformed(notificationId),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: notificationsQueryKeys.list(),
			});
		},
		onError: showErrorNotification,
	});

	return { markAsPerformedMutation };
};
