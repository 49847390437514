import { useEffect, useState } from 'react';
import { MessageList } from '../components/containers/MessageList';
import { ThreadList } from '../components/containers/ThreadList';

export const DesktopMessagesPage = () => {
	const [currentThread, setCurrentThread] = useState<number>();
	const [newMessage, setNewMessage] = useState<boolean>(false);

	const handleNewMessage = () => {
		setCurrentThread(undefined);
		setNewMessage((prev) => !prev);
	};

	useEffect(() => {
		if (currentThread) setNewMessage(false);
	}, [currentThread]);

	return (
		<div className="flex h-full overflow-hidden">
			<div className="basis-1/3">
				<ThreadList
					setCurrentThread={setCurrentThread}
					currentThread={currentThread}
					setNewMessage={handleNewMessage}
					newMessage={newMessage}
				/>
			</div>
			<div className="basis-2/3">
				<MessageList threadId={currentThread} newMessage={newMessage} />
			</div>
		</div>
	);
};
