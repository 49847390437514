import { UserOnResearch } from '../../../features/usersOnResearch/api/types/users-on-research.types';
import { ResearchMemberPicker } from '../../../features/usersOnResearch/components/inputs/ResearchMemberPicker';

type Props = {
	usersOnResearch: UserOnResearch[];
	setUsersOnResearch: React.Dispatch<React.SetStateAction<UserOnResearch[]>>;
};

export function UserOnResearchControlPanel({
	usersOnResearch,
	setUsersOnResearch,
}: Props) {
	const handleRemoveUserOnResearch = (email: string) => {
		setUsersOnResearch((currUsersOnResearch) =>
			currUsersOnResearch.filter(
				(userOnResearch) => userOnResearch.email !== email,
			),
		);
	};

	return usersOnResearch.length > 0 ? (
		<div className="flex flex-col gap-2">
			{usersOnResearch.map((userOnResearch) => (
				<ResearchMemberPicker
					user={userOnResearch}
					onRemoveUser={handleRemoveUserOnResearch}
					key={userOnResearch.userId}
				/>
			))}
		</div>
	) : (
		<p className="text-center text-sm text-muted-foreground">
			Aucun destinataire sélectionné
		</p>
	);
}
