import { useState } from 'react';
import { MobileMessageList } from '../components/mobile/containers/MobileMessageList';
import { MobileSendNewMessage } from '../components/mobile/containers/MobileSendNewMessage';
import { MobileThreadList } from '../components/mobile/containers/MobileThreadList';

export const MobileMessagesPage = () => {
	const [currentThread, setCurrentThread] = useState<number>();
	const [showNewMessage, setShowNewMessage] = useState(false);

	if (showNewMessage) {
		return (
			<div className="flex h-full">
				<MobileSendNewMessage handleClose={() => setShowNewMessage(false)} />
			</div>
		);
	}

	if (currentThread)
		return (
			<div className="flex h-full">
				<MobileMessageList
					threadId={currentThread}
					resetCurrentThread={() => setCurrentThread(undefined)}
				/>
			</div>
		);

	return (
		<div className="flex h-full overflow-hidden">
			<MobileThreadList
				setCurrentThread={setCurrentThread}
				openNewMessage={() => setShowNewMessage(true)}
			/>
		</div>
	);
};
