import { AxiosError } from 'axios';
import { privateAxios } from '../../../../shared/services/privateAxios.ts';
import { UpdateResearchMemberStatusDto } from '../dto/research-members.dto.ts.ts';

export const updateUserOnResearchStatus = async (
	userId: number,
	status: UpdateResearchMemberStatusDto,
	researchId: number,
) => {
	try {
		const { data } = await privateAxios.patch(
			`/researches/${researchId}/update-research-member-status/researchMember/${userId}`,
			status,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
