import { Badge } from '@mantine/core';
import { ParticipantVisit } from '../../api/visits.types';

type VisitStatusBadgeProps = {
	visit: ParticipantVisit;
};

export const VisitStatusBadge = ({ visit }: VisitStatusBadgeProps) => {
	const leftActionsCount = visit.actionsCount - visit.completedActionsCount;
	const actionsCount = visit.actionsCount;

	const getActionsLeftMessage = () => {
		if (leftActionsCount === 0) {
			if (actionsCount > 1)
				return `${actionsCount} / ${actionsCount} tâches complétées`;
			return `${actionsCount} / ${actionsCount} tâche complétée`;
		}
		if (leftActionsCount > 1)
			return `${leftActionsCount} tâches restantes sur ${actionsCount}`;
		return `${leftActionsCount} tâche restante sur ${actionsCount}`;
	};

	let color = 'orange';
	if (leftActionsCount === 0) color = 'teal';
	if (visit.endDate && new Date(visit.endDate) < new Date()) color = 'red';
	return (
		<Badge
			color={color}
			variant="light"
			size="md"
			styles={{
				label: {
					textTransform: 'none',
					fontSize: '12px',
				},
			}}
		>
			{getActionsLeftMessage()}
		</Badge>
	);
};
