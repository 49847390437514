import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { UploadFileDto } from '../../../../../../shared/api/files.dto';
import { uploadFileQuery } from '../../../../../../shared/api/files.queries';
import { useInvalidateQueries } from '../../../../../../shared/hooks/useInvalidateQueries';
import { PreSignedPutUrl } from '../../../../../../shared/types/PreSignedPutUrl';
import { S3File } from '../../../../../../shared/types/S3File';
import { dashboardQueryKeys } from '../../../../../dashboard/api/dashbord.query-keys';
import { useCurrentResearch } from '../../../../../researches/contexts/researches.context';
import { useCurrentParticipant } from '../../../../../usersOnResearch/contexts/participants.context';
import { visitsQueryKeys } from '../../../../api/visits.query-keys';
import { actionsQueryKeys } from '../../../api/actions.query-keys';
import { CreateActionDocumentDto } from '../action-documents.dto';
import { uploadActionResultDocuments } from '../action-documents.mutations';
import { getActionResultDocumentUploadUrl } from '../action-documents.queries';

export const useUploadActionResultsDocuments = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { research } = useCurrentResearch();
	const invalidateQueries = useInvalidateQueries();
	const { participant } = useCurrentParticipant();

	const showSuccessNotification = (uploadedDocumentCount: number) => {
		notifications.show({
			message:
				uploadedDocumentCount > 1
					? `${uploadedDocumentCount} fichiers ont été importés`
					: '1 fichier à été importé',
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const getActionDocumentUploadUrl = useMutation({
		mutationFn: async (fileName: string): Promise<PreSignedPutUrl> =>
			await getActionResultDocumentUploadUrl(fileName),
	});

	const completeUploadAction = useMutation({
		mutationFn: async ({
			actionId,
			documents,
		}: {
			actionId: number;
			documents: CreateActionDocumentDto[];
		}) =>
			await uploadActionResultDocuments(
				actionId,
				participant.userId,
				documents,
			),
	});
	const uploadFile = useMutation({
		mutationFn: async (data: UploadFileDto) => await uploadFileQuery(data),
	});

	const uploadActionDocuments = async (
		actionId: number,
		documents: S3File[],
	) => {
		setIsLoading(true);
		try {
			const uploadedDocuments: CreateActionDocumentDto[] = [];
			for (const document of documents) {
				const { file } = document;
				const { signedUrl, key } = await getActionDocumentUploadUrl.mutateAsync(
					file.name,
				);
				await uploadFile.mutateAsync({ signedUrl, file });
				uploadedDocuments.push({ documentKey: key, fileName: file.name });
			}
			await completeUploadAction.mutateAsync({
				actionId,
				documents: uploadedDocuments,
			});
			invalidateQueries([
				dashboardQueryKeys.detail(research.id),
				visitsQueryKeys.byResearch(research.id),
				actionsQueryKeys.results(actionId),
			]);
			showSuccessNotification(uploadedDocuments.length);
		} catch (error) {
			showErrorNotification();
		} finally {
			setIsLoading(false);
		}
	};
	return { uploadActionDocuments, isLoading };
};
