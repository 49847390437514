import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { queryClient } from '../../../App';
import { ChangePasswordZodType } from '../components/forms/validators/ChangePasswordValidator';
import { UpdateLegalRepresentativeDto, UpdateUserInfoDto } from './profile.dto';
import {
	deleteLegalRepresentative,
	updateLegalRepresentative,
	updatePassword,
	updateUserInfo,
} from './profile.mutations';
import { getUserInfo } from './profile.queries';
import { profileQueryKeys } from './profile.query-keys';
import { UserProfile } from './types/user.types';

export const useGetUserProfile = () => {
	const {
		data: profile,
		isLoading,
		error,
	} = useQuery<UserProfile>({
		queryKey: profileQueryKeys.me(),
		queryFn: () => getUserInfo(),
	});

	return { profile, isLoading, error };
};

export const useUpdateUserProfile = () => {
	const updateUserInfoMutation = useMutation({
		mutationFn: async (data: UpdateUserInfoDto) => updateUserInfo(data),
		onSuccess: () => {
			notifications.show({
				message: 'Informations mises à jour avec succès',
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
			queryClient.invalidateQueries({ queryKey: profileQueryKeys.me() });
		},
		onError: () => {
			notifications.show({
				message:
					'Une erreur est survenue lors de la mise à jour de vos informations, veuillez réessayer',
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		},
	});

	return { updateUserInfoMutation };
};

export const useUpdateLegalRepresentative = (legalRepresentativeId: number) => {
	const updateLegalRepresentativeMutation = useMutation({
		mutationFn: async (data: UpdateLegalRepresentativeDto) =>
			updateLegalRepresentative(legalRepresentativeId, data),
		onSuccess: () => {
			notifications.show({
				message: 'Informations mises à jour avec succès blabla',
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
			queryClient.invalidateQueries({ queryKey: profileQueryKeys.me() });
		},
		onError: () => {
			notifications.show({
				message:
					'Une erreur est survenue lors de la mise à jour de vos informations, veuillez réessayer',
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		},
	});

	return { updateLegalRepresentativeMutation };
};

export const useDeleteLegalRepresentative = (legalRepresentativeId: number) => {
	const deleteLegalRepresentativeMutation = useMutation({
		mutationFn: async () => deleteLegalRepresentative(legalRepresentativeId),
		onSuccess: () => {
			notifications.show({
				message: 'Parent supprimé avec succès',
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
			queryClient.invalidateQueries({ queryKey: profileQueryKeys.me() });
		},
		onError: () => {
			notifications.show({
				message:
					'Une erreur est survenue lors de la suppression du parent, veuillez réessayer',
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		},
	});

	return { deleteLegalRepresentativeMutation };
};

export const useUpdatePassword = () => {
	const updatePasswordMutation = useMutation({
		mutationFn: async (data: ChangePasswordZodType) => updatePassword(data),
		onSuccess: () => {
			notifications.show({
				message: 'Votre mot de passe a été changé avec succès',
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
			queryClient.invalidateQueries({ queryKey: profileQueryKeys.me() });
		},
		onError: (error: AxiosError) => {
			const message =
				error.response?.status === 403
					? 'Le mot de passe actuel est incorrect'
					: 'Une erreur est survenue lors du changement du mot de passe, veuillez réessayer.';
			notifications.show({
				message,
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		},
	});

	return { updatePasswordMutation };
};
