import { Button } from '@/components/ui/button';
import { cn } from '@/components/utils';
import { IconPlus } from '@tabler/icons-react';

export type AddSecondaryFieldInfoButtonProps = {
	label: string;
	onClick: () => void;
	additionalClass?: string;
};

export const AddSecondaryFieldInfoButton = ({
	label,
	onClick: handleClick,
	additionalClass,
}: AddSecondaryFieldInfoButtonProps) => {
	return (
		<Button
			variant="link"
			size={'sm'}
			onClick={handleClick}
			className={cn(
				'w-full justify-start overflow-hidden whitespace-normal text-left',
				additionalClass,
			)}
		>
			<div className="flex items-center gap-2">
				<IconPlus size={24} />
				<p>{label}</p>
			</div>
		</Button>
	);
};
