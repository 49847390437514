import { Avatar, Paper } from '@mantine/core';

export type FigureCardProps = {
	title: string;
	icon?: React.ReactNode;
	children: React.ReactNode;
};

export const FigureCard = ({ title, icon, children }: FigureCardProps) => {
	return (
		<Paper
			p="lg"
			w="100%"
			h="100%"
			withBorder
			shadow="0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
			style={{
				border: '1px solid var(--mantine-color-gray-2)',
			}}
		>
			<div className="flex flex-col gap-4">
				<div className="flex items-start gap-2">
					{icon && (
						<Avatar
							size={36}
							radius="xl"
							variant="filled"
							color="var(--mantine-primary-color-6)"
						>
							{icon}
						</Avatar>
					)}
					<p className="font-semibold">{title}</p>
				</div>
				{children}
			</div>
		</Paper>
	);
};
