import axios, { AxiosError } from 'axios';
import {
	REFRESH_TOKEN_STORAGE_KEY,
	TOKEN_STORAGE_KEY,
} from '../../features/auth/utils/authTokens';
import { publicAxios } from './publicAxios';

export const privateAxios = axios.create({
	baseURL: import.meta.env.VITE_API_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

privateAxios.interceptors.request.use((axiosConfig) => {
	const token = localStorage.getItem(TOKEN_STORAGE_KEY);
	axiosConfig.headers.Authorization = `Bearer ${token}`;
	return axiosConfig;
});

privateAxios.interceptors.request.use(
	async (config) => {
		const token = localStorage.getItem(TOKEN_STORAGE_KEY);
		if (token) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

privateAxios.interceptors.response.use(
	(response) => response,
	async function (error) {
		const originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			localStorage.removeItem(TOKEN_STORAGE_KEY);
			try {
				const refreshToken = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
				const response = await publicAxios.post(
					'auth/refresh-token',
					{},
					{
						headers: { Authorization: `Bearer ${refreshToken}` },
					},
				);
				const { accessToken } = response.data;
				localStorage.setItem(TOKEN_STORAGE_KEY, accessToken);

				// Retry the original request with the new token
				originalRequest.headers.Authorization = `Bearer ${accessToken}`;
				return privateAxios(originalRequest);
			} catch (error) {
				// Handle refresh token error or redirect to login
				if ((error as AxiosError).response?.status === 401) {
					localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
					const redirectUrl = '/signin?error=session_expired';
					window.location.href = redirectUrl;
				}
				throw error;
			}
		}
		return Promise.reject(error);
	},
);
