import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreateNoteDto } from '../notes.dto';
import { createNote } from '../notes.mutations';
import { notesQueryKeys } from '../notes.query-keys';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';

export const useCreateNote = () => {
	const { research } = useCurrentResearch();
	const queryClient = useQueryClient();

	const showSuccessNotification = () => {
		notifications.show({
			message: 'Note enregistrée',
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const createNoteMutation = useMutation({
		mutationFn: async (data: CreateNoteDto) => createNote(research.id, data),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: notesQueryKeys.list(research.id),
			});
			showSuccessNotification();
		},
		onError: showErrorNotification,
	});

	return { createNoteMutation };
};
