import { useCurrentParticipant } from '../../usersOnResearch/contexts/participants.context';
import { CaregiverInfo } from '../components/cards/CaregiverInfo';
import { LegalRepresentativeInfo } from '../components/cards/LegalRepresentativeInfo';
import { ParticipantPersonalInfo } from '../components/cards/ParticipantPersonalInfo';

export const ParticipantContactsInfo = () => {
	const { participant } = useCurrentParticipant();

	if (participant.status === 'PENDING')
		return (
			<p className="text-sm text-error-500">
				Le participant n'a pas encore confirmé son inscription à la recherche,
				vous ne pouvez pas voir ses coordonnées pour le moment.
			</p>
		);

	return (
		<div className="flex flex-col gap-12">
			<ParticipantPersonalInfo />
			{participant.participantProfile.legalRepresentatives?.map(
				(legalRepresentative) => (
					<LegalRepresentativeInfo
						key={legalRepresentative.legalRepresentativeId}
						legalRepresentative={legalRepresentative}
					/>
				),
			)}
			{(participant.participantOnResearch.caredForBy ||
				participant.participantOnResearch.caresFor) && <CaregiverInfo />}
		</div>
	);
};
