import MyField from '@/components/ui/form/form-field';
import { Checkbox, Fieldset } from '@mantine/core';
import { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useFormContext } from 'react-hook-form';
import { ParticipantSignUpZodType } from './validators/ParticipantSignUpZodSchema';

export const UnderageParticipantFromStep2 = () => {
	const methods = useFormContext<ParticipantSignUpZodType>();
	const {
		control,
		getValues,
		setValue,
		formState: { errors },
	} = methods;
	const { remove } = useFieldArray({
		control,
		name: 'legalRepresentatives',
	});

	const [addParent2, setAddParent2] = useState(false);

	useEffect(() => {
		if (!getValues('legalRepresentatives.0.role'))
			setValue('legalRepresentatives.0.role', 'PARENT1');

		if (addParent2 && !getValues('legalRepresentatives.1.role'))
			setValue('legalRepresentatives.1.role', 'PARENT2');

		if (!addParent2 && getValues('legalRepresentatives.1.role')) remove(1);
	}, [getValues, setValue, addParent2, remove]);

	return (
		<FormProvider {...methods}>
			<div className="flex flex-col gap-12">
				<Fieldset
					legend={
						<div className="flex space-x-1">
							<p className="text-lg font-semibold">Parent 1</p>
							<p className="text-lg font-semibold text-error-500">*</p>
							<p className="text-sm text-muted-foreground">
								(et/ou titulaire de l'autorité parentale)
							</p>
						</div>
					}
				>
					<div className="flex flex-col gap-4">
						<div className="flex flex-col justify-between gap-4 md:flex-row">
							<MyField
								type="text"
								name="legalRepresentatives.0.lastName"
								required={true}
								label="Nom"
								placeholder="Nom"
								error={errors.legalRepresentatives?.[0]?.lastName?.message}
								labelClass="text-black"
								inputClass="text-black"
							/>

							<MyField
								type="text"
								name="legalRepresentatives.0.firstName"
								required={true}
								label="Prénom"
								placeholder="Prénom"
								error={errors.legalRepresentatives?.[0]?.firstName?.message}
								labelClass="text-black"
								inputClass="text-black"
							/>
						</div>

						<MyField
							type="email"
							name="legalRepresentatives.0.email"
							required={true}
							label="Adresse email"
							placeholder="Adresse email"
							error={errors.legalRepresentatives?.[0]?.email?.message}
							labelClass="text-black"
							inputClass="text-black"
						/>

						<MyField
							type="tel"
							name="legalRepresentatives.0.phoneNumber"
							required={true}
							label="Téléphone"
							placeholder="Téléphone"
							error={errors.legalRepresentatives?.[0]?.phoneNumber?.message}
							labelClass="text-black"
							inputClass="text-black"
						/>

						<MyField
							type="text"
							name="legalRepresentatives.0.address"
							required={true}
							label="Adresse postale"
							placeholder="Adresse postale"
							error={errors.legalRepresentatives?.[0]?.address?.message}
							labelClass="text-black"
							inputClass="text-black"
						/>

						<div className="flex flex-col justify-between gap-4 md:flex-row">
							<MyField
								type="text"
								name="legalRepresentatives.0.city"
								required={true}
								label="Ville"
								placeholder="Ville"
								error={errors.legalRepresentatives?.[0]?.city?.message}
								labelClass="text-black"
								inputClass="text-black"
							/>

							<MyField
								type="text"
								name="legalRepresentatives.0.zipCode"
								required={true}
								label="Code postal"
								placeholder="Code postal"
								error={errors.legalRepresentatives?.[0]?.zipCode?.message}
								labelClass="text-black"
								inputClass="text-black"
							/>
						</div>
					</div>
				</Fieldset>

				<div className="flex flex-col gap-6">
					<Checkbox
						checked={addParent2}
						size="md"
						radius="xs"
						onChange={(e) => setAddParent2(e.currentTarget.checked)}
						label="Ajouter un parent supplémentaire"
						className="text-sm"
					/>

					{addParent2 && (
						<Fieldset
							legend={
								<div className="flex space-x-1">
									<p className="text-lg font-semibold">Parent 2</p>
									<p className="text-sm text-muted-foreground">
										(et/ou titulaire de l'autorité parentale)
									</p>
								</div>
							}
						>
							<div className="flex flex-col gap-4">
								<div className="flex flex-col justify-between gap-4 md:flex-row">
									<MyField
										type="text"
										name="legalRepresentatives.1.lastName"
										required={true}
										label="Nom"
										placeholder="Nom"
										error={errors.legalRepresentatives?.[1]?.lastName?.message}
										labelClass="text-black"
										inputClass="text-black"
									/>

									<MyField
										type="text"
										name="legalRepresentatives.1.firstName"
										required={true}
										label="Prénom"
										placeholder="Prénom"
										error={errors.legalRepresentatives?.[1]?.firstName?.message}
										labelClass="text-black"
										inputClass="text-black"
									/>
								</div>

								<MyField
									type="email"
									name="legalRepresentatives.1.email"
									required={true}
									label="Adresse email"
									placeholder="Adresse email"
									error={errors.legalRepresentatives?.[1]?.email?.message}
									labelClass="text-black"
									inputClass="text-black"
								/>

								<MyField
									type="tel"
									name="legalRepresentatives.1.phoneNumber"
									required={true}
									label="Téléphone"
									placeholder="Téléphone"
									error={errors.legalRepresentatives?.[1]?.phoneNumber?.message}
									labelClass="text-black"
									inputClass="text-black"
								/>

								<MyField
									type="text"
									name="legalRepresentatives.1.address"
									required={true}
									label="Adresse postale"
									placeholder="Adresse postale"
									error={errors.legalRepresentatives?.[1]?.address?.message}
									labelClass="text-black"
									inputClass="text-black"
								/>

								<div className="flex flex-col justify-between gap-4 md:flex-row">
									<MyField
										type="text"
										name="legalRepresentatives.1.city"
										required={true}
										label="Ville"
										placeholder="Ville"
										error={errors.legalRepresentatives?.[1]?.city?.message}
										labelClass="text-black"
										inputClass="text-black"
									/>

									<MyField
										type="text"
										name="legalRepresentatives.1.zipCode"
										required={true}
										label="Code postal"
										placeholder="Code postal"
										error={errors.legalRepresentatives?.[1]?.zipCode?.message}
										labelClass="text-black"
										inputClass="text-black"
									/>
								</div>
							</div>
						</Fieldset>
					)}
				</div>
			</div>
		</FormProvider>
	);
};
