import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { useDeleteNote } from '../../api/hooks/useDeleteNote';

export type DeleteNoteModalProps = {
	noteId: number;
	handleClose: () => void;
};

export const DeleteNoteModal = ({
	noteId,
	handleClose,
}: DeleteNoteModalProps) => {
	const isMobile = useIsMobile();
	const { deleteNoteMutation } = useDeleteNote(noteId);

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			title="Supprimer la note"
			size="lg"
			fullScreen={isMobile}
		>
			<div className="flex flex-col gap-6">
				<p className="text-center">
					Êtes-vous sûr(e) de vouloir supprimer cette visioconférence ?
				</p>
				<div className="flex gap-4">
					{!isMobile && (
						<Button onClick={handleClose} variant="outline" className="w-full">
							Annuler
						</Button>
					)}
					<Button
						disabled={deleteNoteMutation.isPending}
						className="w-full"
						variant="destructive"
						onClick={() => {
							deleteNoteMutation.mutate();
							handleClose();
						}}
					>
						Supprimer
					</Button>
				</div>
			</div>
		</Modal>
	);
};
