import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@/components/ui/form';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select';
import clsx from 'clsx';

type BasicComponentProps = {
	name: string;
	label: string;
	placeholder: string;
	required?: boolean;
	labelClass?: string;
	fieldAdditionalClass?: string;
	additionalClass?: string;
};

export type SelectItemProps = {
	value: string | number;
	name: string;
};

type MySelectProps = {
	selectAdditionalClass?: string;
};

function MySelect({
	name,
	label,
	placeholder,
	selectItems,
	required,
	labelClass,
	selectAdditionalClass,
	fieldAdditionalClass,
	additionalClass,
}: BasicComponentProps & {
	selectItems: SelectItemProps[];
} & MySelectProps) {
	const formItemClasses = clsx('flex w-full flex-col', additionalClass);
	const formFieldClasses = clsx(
		'flex h-fit w-full rounded-md border border-input bg-background px-6 py-4 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50',
		fieldAdditionalClass,
	);
	const selectItemClasses = clsx(
		'rounded-none hover:bg-gray-50',
		selectAdditionalClass,
	);

	return (
		<FormField
			name={name}
			render={({ field }) => (
				<FormItem className={formItemClasses}>
					<FormLabel
						className={clsx(labelClass, {
							'text-base font-semibold text-white': !labelClass,
						})}
					>
						<div className="flex space-x-1">
							<p>{label}</p>
							{required && <span className="text-sm text-error-500">*</span>}
						</div>
					</FormLabel>
					<Select onValueChange={field.onChange} defaultValue={field.value}>
						<FormControl className="">
							<SelectTrigger className={formFieldClasses}>
								<SelectValue placeholder={placeholder} />
							</SelectTrigger>
						</FormControl>
						<SelectContent
							side="bottom"
							avoidCollisions={false}
							className="z-[1000] max-h-[11.5rem] rounded-none bg-white"
						>
							{selectItems.map((item) => (
								<SelectItem
									key={item.value}
									value={
										typeof item.value !== 'number'
											? item.value
											: item.value.toString()
									}
									className={selectItemClasses}
								>
									{item.name}
								</SelectItem>
							))}
						</SelectContent>
					</Select>
					<FormMessage className="mt-[6px] text-sm font-medium text-error-500" />
				</FormItem>
			)}
		/>
	);
}

export { MySelect };
