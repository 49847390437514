import { NotificationsCountBadge } from '@/features/notifications/components/atoms/NotificationsCountBadge';
import { useDisclosure } from '@mantine/hooks';
import { IconMenu } from '@tabler/icons-react';
import { MobileNavigationMenu } from './mobile-navigation-menu';

export const MobileHeader = () => {
	const [opened, { open, close }] = useDisclosure();

	return (
		<>
			<div className="flex w-full justify-between bg-primary p-4 text-primary-foreground">
				<div className="flex items-center gap-4">
					<IconMenu className="h-6 w-6" onClick={open} />
					<span className="text-lg font-bold">Menu</span>
				</div>
				<div className="flex items-center gap-6">
					<NotificationsCountBadge />
					<img
						src="/logo-without-name.png"
						alt="Logo textuel CosyTrials"
						className="h-10 w-10"
					/>
				</div>
			</div>
			{opened && <MobileNavigationMenu onClose={close} />}
		</>
	);
};
