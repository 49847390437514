import { Accordion, Space, Stack, Text, Title } from '@mantine/core';
import { Navigate } from 'react-router-dom';
import { CustomLoader } from '../../../components/ui/loader';
import { paths } from '../../../routes/paths';
import { useGetQuestions } from '../api/help.hooks';

export function HelpQuestions() {
	const { questions, isLoading, error } = useGetQuestions();
	if (error) return <Navigate to={`/${paths.ERROR_PATH}`} />;
	if (isLoading || !questions) return <CustomLoader />;

	const items =
		questions && questions.length > 0
			? questions.map((item, index) => (
					<Accordion.Item key={index} value={index.toString()}>
						<Accordion.Control>
							<Text size="lg" fw={600}>
								{item.question}
							</Text>
						</Accordion.Control>
						<Accordion.Panel>
							<Text>{item.response}</Text>
						</Accordion.Panel>
						<Space h="sm" />
					</Accordion.Item>
				))
			: null;

	return (
		<Stack gap="lg">
			<Title order={3}>Nous répondons à vos questions !</Title>
			{items ? (
				<Accordion chevronPosition="right" multiple w="95%" ml="lg">
					{items}
				</Accordion>
			) : (
				<Text fs="italic" c="dimmed">
					Aucune question n'a été posée pour le moment.
				</Text>
			)}
		</Stack>
	);
}
