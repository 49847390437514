import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { PreSignedPutUrl } from '../../../shared/types/PreSignedPutUrl';
import { DocumentInfo } from './documents.types';

export const getDocumentInfo = async (documentId: number) => {
	try {
		const { data } = await privateAxios.get<DocumentInfo>(
			`/documents/${documentId}/details`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getResearchCenterDocuments = async (researchId: number) => {
	try {
		const { data } = await privateAxios.get<DocumentInfo[]>(
			`/documents/researches/${researchId}`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getParticipantDocuments = async (
	researchId: number,
	participantId: number,
) => {
	try {
		const { data } = await privateAxios.get<DocumentInfo[]>(
			`/documents/researches/${researchId}/participant/${participantId}`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getOffsiteResearchMemberDocuments = async (researchId: number) => {
	try {
		const { data } = await privateAxios.get<DocumentInfo[]>(
			`/documents/researches/${researchId}/offsite-research-member-documents`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getDownloadUrl = async (documentId: number) => {
	try {
		const { data } = await privateAxios.get(
			`/documents/${documentId}/get-download-url`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getZip = async (ids: number[]) => {
	try {
		const { data } = await privateAxios.get<Blob>(`/documents/get-zip`, {
			params: { id: ids.map((id) => id) },
			responseType: 'blob',
		});
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getUploadUrl = async (fileName: string) => {
	try {
		const { data } = await privateAxios.get<PreSignedPutUrl>(
			`/documents/get-upload-url`,
			{
				params: { 'file-name': fileName },
			},
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
