import MyField from '@/components/ui/form/form-field';
import { Checkbox, Fieldset } from '@mantine/core';
import {
	Controller,
	FormProvider,
	useFieldArray,
	useFormContext,
} from 'react-hook-form';
import { LegalRepresentativeRole } from '../../../../profile/api/types/participants.types';
import { LegalRepresentativeZodType } from './validators/LegalRepresentativeZodSchema';
import { ParticipantSignUpZodType } from './validators/ParticipantSignUpZodSchema';

export const UnableToConsentParticipantFormStep2 = () => {
	const methods = useFormContext<ParticipantSignUpZodType>();
	const {
		control,
		watch,
		formState: { errors },
	} = methods;
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'legalRepresentatives',
	});

	const isUnableToConsent = watch().isUnableToConsent;

	const allowedRole: LegalRepresentativeRole = 'WITNESS';
	const defaultValues: LegalRepresentativeZodType = {
		firstName: '',
		lastName: '',
		phoneNumber: '',
		address: '',
		city: '',
		zipCode: '',
		email: '',
		role: allowedRole,
	};
	const legalRepresentativeFieldIndex = fields.findIndex(
		(field) => allowedRole === field.role,
	);

	const handleIsUnableToConsentToggle = (
		isChecked: boolean,
		onChange: (checked: boolean) => void,
	) => {
		if (isChecked && legalRepresentativeFieldIndex === -1 && !isUnableToConsent)
			append(defaultValues);
		if (!isChecked && legalRepresentativeFieldIndex !== -1 && isUnableToConsent)
			remove(legalRepresentativeFieldIndex);

		onChange(isChecked);
	};

	return (
		<FormProvider {...methods}>
			<div className="flex flex-col gap-6">
				<Controller
					control={control}
					name="isUnableToConsent"
					render={({ field: { onChange, value } }) => (
						<Checkbox
							checked={value}
							size="md"
							radius="xs"
							className="text-sm"
							onChange={(e) =>
								handleIsUnableToConsentToggle(e.target.checked, onChange)
							}
							label="Participant dans l'incapacité physique de signer"
						/>
					)}
				/>
				{isUnableToConsent && legalRepresentativeFieldIndex !== -1 && (
					<Fieldset
						legend={
							<div className="flex space-x-1">
								<p className="text-lg font-semibold">Témoin impartial</p>
								<p className="text-lg font-semibold text-error-500">*</p>
							</div>
						}
					>
						<div className="flex flex-col gap-4">
							<div className="flex flex-col justify-between gap-4 md:flex-row">
								<MyField
									type="text"
									name={`legalRepresentatives.${legalRepresentativeFieldIndex}.lastName`}
									required={true}
									label="Nom"
									placeholder="Nom"
									error={
										errors.legalRepresentatives?.[legalRepresentativeFieldIndex]
											?.lastName?.message
									}
									labelClass="text-black"
									inputClass="text-black"
								/>

								<MyField
									type="text"
									name={`legalRepresentatives.${legalRepresentativeFieldIndex}.firstName`}
									required={true}
									label="Prénom"
									placeholder="Prénom"
									error={
										errors.legalRepresentatives?.[legalRepresentativeFieldIndex]
											?.firstName?.message
									}
									labelClass="text-black"
									inputClass="text-black"
								/>
							</div>

							<MyField
								type="email"
								name={`legalRepresentatives.${legalRepresentativeFieldIndex}.email`}
								required={true}
								label="Adresse email"
								placeholder="Adresse email"
								error={
									errors.legalRepresentatives?.[legalRepresentativeFieldIndex]
										?.email?.message
								}
								labelClass="text-black"
								inputClass="text-black"
							/>

							<MyField
								type="tel"
								name={`legalRepresentatives.${legalRepresentativeFieldIndex}.phoneNumber`}
								required={true}
								label="Téléphone"
								placeholder="Téléphone"
								error={
									errors.legalRepresentatives?.[legalRepresentativeFieldIndex]
										?.phoneNumber?.message
								}
								labelClass="text-black"
								inputClass="text-black"
							/>

							<MyField
								type="text"
								name={`legalRepresentatives.${legalRepresentativeFieldIndex}.address`}
								required={true}
								label="Adresse postale"
								placeholder="Adresse postale"
								error={
									errors.legalRepresentatives?.[legalRepresentativeFieldIndex]
										?.address?.message
								}
								labelClass="text-black"
								inputClass="text-black"
							/>

							<div className="flex flex-col justify-between gap-4 md:flex-row">
								<MyField
									type="text"
									name={`legalRepresentatives.${legalRepresentativeFieldIndex}.city`}
									required={true}
									label="Ville"
									placeholder="Ville"
									error={
										errors.legalRepresentatives &&
										errors.legalRepresentatives[legalRepresentativeFieldIndex]
											?.city?.message
									}
									labelClass="text-black"
									inputClass="text-black"
								/>

								<MyField
									type="number"
									name={`legalRepresentatives.${legalRepresentativeFieldIndex}.zipCode`}
									required={true}
									label="Code postal"
									placeholder="Code postal"
									error={
										errors.legalRepresentatives &&
										errors.legalRepresentatives[legalRepresentativeFieldIndex]
											?.zipCode?.message
									}
									labelClass="text-black"
									inputClass="text-black"
								/>
							</div>
						</div>
					</Fieldset>
				)}
			</div>
		</FormProvider>
	);
};
