import classes from '@/components/ui/table/table.module.scss';
import { MRT_RowData, MRT_TableOptions } from 'mantine-react-table';
import { MRT_Localization_FR } from 'mantine-react-table/locales/fr/index.cjs';

export const getDefaultMRTOptions = <TData extends MRT_RowData>(
	isError: boolean,
): Partial<MRT_TableOptions<TData>> => ({
	enableColumnActions: false,
	enableSorting: true,
	enablePagination: true,
	enableFullScreenToggle: false,
	enableGlobalFilter: true,
	globalFilterFn: 'contains',
	positionGlobalFilter: 'left',
	mantineSearchTextInputProps: {
		placeholder: 'Rechercher',
		variant: 'filled',
		style: { minWidth: '200px' },
	},
	enableDensityToggle: false,
	enableColumnPinning: false,
	enableColumnOrdering: false,
	enableHiding: false,
	enableBottomToolbar: true,
	localization: {
		...MRT_Localization_FR,
		noRecordsToDisplay: isError
			? 'Une erreur est survenue lors de la récupération des données'
			: 'Aucun enregistrement trouvé',
	},
	initialState: {
		density: 'md',
		showGlobalFilter: true,
	},
	enableStickyHeader: true,
	enableStickyFooter: true,
	mantinePaginationProps: {
		showRowsPerPage: false,
		rowsPerPageOptions: ['10'],
		withEdges: true,
	},
	mantineTopToolbarProps: {
		className: classes.top__toolbar,
	},
	mantineTableHeadCellProps: {
		className: classes.table__thead__cell,
	},
	mantineTableBodyCellProps: {
		className: classes.table__body__cell,
	},
	mantineTableContainerProps: {
		className: classes.table__container,
	},
	mantineTableProps: {
		className: classes.table,
	},
	mantineBottomToolbarProps: {
		className: classes.bottom__toolbar,
	},
	mantineToolbarAlertBannerProps: {
		className: classes.alert__banner__toolbar,
	},
	mantineSelectCheckboxProps: {
		size: 'md',
		className: classes.select__checkbox,
	},
});
