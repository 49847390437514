import { useIsMobile } from '@/components/hooks/useIsMobile';
import { IconCircleCheck } from '@tabler/icons-react';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';
import {
	ParticipantVisit,
	Visit,
	isParticipantVisit,
} from '../../api/visits.types';
import { VisitLocationBadge } from './VisitLocationBadge';
import { VisitScheduledDate } from './VisitScheduledDate';
import { VisitStatusBadge } from './VisitStatusBadge';

type Props = {
	visit: Visit | ParticipantVisit;
};
export const VisitAccordionLabel = ({ visit }: Props) => {
	const isMobile = useIsMobile();
	const { participant } = useCurrentParticipant();

	if (isMobile)
		return (
			<div className="grid grid-cols-8 gap-2">
				<div className="col-span-6 my-auto flex flex-col gap-1">
					<div className="flex flex-col items-start gap-2">
						<div className="flex flex-col gap-1">
							<div className="flex flex-col gap-0">
								<p className="line-clamp-1 text-lg font-semibold">
									{visit.name}
								</p>
								{participant && isParticipantVisit(visit) && (
									<VisitStatusBadge visit={visit} />
								)}
							</div>
							<p className="line-clamp-3 text-sm text-muted-foreground">
								{visit.description}
							</p>
						</div>

						{participant && isParticipantVisit(visit) && (
							<VisitScheduledDate visit={visit} />
						)}
					</div>
				</div>
				<div className="col-span-2 flex items-center">
					<VisitLocationBadge isRemote={visit.isRemote} />
				</div>
			</div>
		);

	return (
		<div className="grid grid-cols-8 gap-2">
			<div className="col-span-4 my-auto flex flex-col gap-1">
				<div className="flex flex-col gap-0">
					<p className="line-clamp-1 text-lg font-semibold">{visit.name}</p>
					{participant && isParticipantVisit(visit) && (
						<VisitStatusBadge visit={visit} />
					)}
				</div>
				<p className="line-clamp-3 text-sm text-muted-foreground">
					{visit.description}
				</p>
			</div>
			<div className="col-span-2 block items-center justify-center">
				{participant && isParticipantVisit(visit) && (
					<VisitScheduledDate visit={visit} />
				)}
				{!participant && (
					<div className="flex h-full items-center justify-center gap-1 align-middle">
						<IconCircleCheck className="h-4 w-4 text-primary" />
						<p className="text-sm font-semibold">Réponses</p>
					</div>
				)}
			</div>
			<div className="col-span-2 flex items-center">
				<VisitLocationBadge isRemote={visit.isRemote} />
			</div>
		</div>
	);
};
