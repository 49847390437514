import { z } from 'zod';
import {
	addressValidator,
	cityValidator,
	firstNameValidator,
	phoneNumberValidator,
	zipCodeValidator,
} from '../../../../../shared/components/validators/commonValidators';
import { LegalRepresentativeZodSchema } from '../../../../auth/participant/components/forms/validators/LegalRepresentativeZodSchema';

export type ParticipantProfileZodType = z.infer<
	typeof ParticipantProfileZodSchema
>;

export const ParticipantProfileZodSchema = z.object({
	firstName: firstNameValidator.optional(),
	lastName: firstNameValidator.optional(),
	phoneNumber: phoneNumberValidator.optional(),
	participantProfile: z.object({
		address: addressValidator.optional(),
		city: cityValidator.optional(),
		zipCode: zipCodeValidator.optional(),
		legalRepresentatives: z.array(LegalRepresentativeZodSchema).optional(),
	}),
});
