import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Separator } from '@/components/ui/separator';
import { Box, Drawer, Skeleton, Space, Stack, Text } from '@mantine/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useGetNotifications } from '../api/notifications.hooks';
import { NotificationEntity } from '../api/notifications.types';
import { NotificationItem } from '../components/atoms/NotificationItem';
import { NotificationsReadFilter } from '../components/atoms/NotificationsReadFilter';
import { NotificationsResearchFilter } from '../components/atoms/NotificationsResearchFilter';

export type NotificationsPaneProps = {
	handleClose: () => void;
};

export const NotificationsPane = ({ handleClose }: NotificationsPaneProps) => {
	const [notifications, setNotifications] = useState<NotificationEntity[]>([]);
	const [isUnread, setIsUnread] = useState(true);
	const [researchId, setResearchId] = useState<number | null>(null);
	const isMobile = useIsMobile();

	const {
		notifications: fetchedNotifications,
		isLoading,
		error,
		fetchNextPage,
		hasNextPage,
		isFetching,
	} = useGetNotifications();

	useEffect(() => {
		if (fetchedNotifications)
			setNotifications(
				fetchedNotifications.pages.reduce((acc, page) => [...acc, ...page], []),
			);
	}, [fetchedNotifications]);

	const filteredNotifications = notifications.filter((notification) => {
		if (isUnread && notification.read) return false;
		if (researchId && notification.researchId !== researchId) return false;
		return true;
	});

	const observer = useRef<IntersectionObserver>();
	const lastElementRef = useCallback(
		(node: HTMLDivElement) => {
			if (isLoading) return;
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && hasNextPage && !isFetching) {
					fetchNextPage();
				}
			});

			if (node) observer.current.observe(node);
		},
		[fetchNextPage, hasNextPage, isFetching, isLoading],
	);

	return (
		<Drawer
			opened
			onClose={handleClose}
			position="right"
			withCloseButton={false}
			size={isMobile ? '100%' : 550}
		>
			<Drawer.Header className="mb-4">
				<div className="flex w-full flex-col gap-4">
					<div className="flex items-center justify-between">
						<h2>Notifications</h2>
						<Drawer.CloseButton />
					</div>
					<Separator />
				</div>
			</Drawer.Header>
			<Drawer.Body>
				<div className="flex flex-col gap-4 md:flex-row md:justify-between">
					<NotificationsReadFilter
						isUnread={isUnread}
						setIsUnread={setIsUnread}
					/>
					<NotificationsResearchFilter
						researchId={researchId}
						setResearchId={setResearchId}
					/>
				</div>
				<Space h="md" />
				<Stack gap="xs" h="100%">
					{isLoading && (
						<>
							<Skeleton height="10vh" />
							<Skeleton height="10vh" />
							<Skeleton height="10vh" />
						</>
					)}

					{error && (
						<Text c="red" fs="italic">
							Une erreur est survenue lors du chargement des notifications
						</Text>
					)}

					{filteredNotifications?.length === 0 && (
						<Text fs="italic" c="dimmed">
							Aucune notification pour le moment
						</Text>
					)}

					{filteredNotifications?.map((notification, index) => (
						<Box
							ref={
								index === notifications.length - 1 ? lastElementRef : undefined
							}
							key={notification.notificationId}
						>
							<NotificationItem
								notification={notification}
								handleClose={handleClose}
							/>
						</Box>
					))}
				</Stack>
			</Drawer.Body>
		</Drawer>
	);
};
