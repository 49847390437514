import { SettingsTabs } from './SettingsTabs';

export function SettingsPage() {
	return (
		<div className="flex h-full w-full flex-col gap-4">
			<h2>Paramètres</h2>
			<SettingsTabs />
		</div>
	);
}
