import { Divider, Stack, Textarea } from '@mantine/core';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { Question } from '../../api/questionnaires.types';
import { MAX_RESPONSE_LENGTH } from '../../utils/constants';

type Props = {
	question: Question;
	register: UseFormRegister<FieldValues>;
	errorMessage: string;
};

export function TextQuestionInput({ question, register, errorMessage }: Props) {
	return (
		<Stack gap="xl" w="90%">
			<Textarea
				label={question.label}
				description={`${MAX_RESPONSE_LENGTH} caractères maximum`}
				placeholder="Entrez votre réponse ici"
				size="md"
				autosize
				minRows={2}
				maxRows={10}
				resize="vertical"
				withAsterisk={question.required}
				radius="md"
				{...register(question.id.toString())}
				error={errorMessage}
				styles={{ input: { marginTop: '3%' } }}
			/>
			<Divider />
		</Stack>
	);
}
