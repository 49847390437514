import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useInvalidateQueries } from '../../../../shared/hooks/useInvalidateQueries';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { ScheduleVisitDto } from '../visits.dto';
import { scheduleVisit } from '../visits.mutations';
import { visitsQueryKeys } from '../visits.query-keys';
import { calendarQueryKeys } from '../../../calendar/api/calendar.query-keys';

export const useScheduleVisit = (
	visitId: number,
	userId: number,
	successCallback: () => void,
) => {
	const { research } = useCurrentResearch();
	const invalidateQueries = useInvalidateQueries();

	const scheduleVisitMutation = useMutation({
		mutationFn: async (payload: ScheduleVisitDto) =>
			scheduleVisit(userId, visitId, payload),
		onSuccess: () => {
			notifications.show({
				message: 'Visite planifiée avec succès',
				color: 'teal',
				icon: <IconCheck style={{ width: '20px', height: '20px' }} />,
			});
			invalidateQueries([
				calendarQueryKeys.lists(research.id),
				visitsQueryKeys.resultsByParticipant(research.id, userId),
			]);
			successCallback();
		},
		onError: () => {
			notifications.show({
				message: 'Une erreur est survenue',
				color: 'red',
				icon: <IconX style={{ width: '20px', height: '20px' }} />,
			});
		},
	});
	return { scheduleVisitMutation };
};
