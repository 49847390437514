import { DocumentScopeType } from './documents.types';

export const documentsQueryKeys = {
	all: ['documents'] as const,
	lists: () => [...documentsQueryKeys.all, 'list'] as const,
	list: (researchId: number) =>
		[...documentsQueryKeys.lists(), researchId] as const,
	filters: (
		researchId: number,
		filters: { scope: DocumentScopeType; userId?: number },
	) => [...documentsQueryKeys.list(researchId), filters] as const,
	details: (documentId: number) =>
		[...documentsQueryKeys.all, 'details', documentId] as const,
};
