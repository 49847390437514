import { Box, Space, Stack, Table, Text, Title } from '@mantine/core';
import { useMemo } from 'react';
import { CustomLoader } from '../../../../../../components/ui/loader';
import { useGetDynamicTablePreview } from '../../api/dynamic-tables.hooks';
import {
	DynamicTableColumn,
	DynamicTableRow,
} from '../../api/dynamic-tables.types';
import { default as classes } from '../../styles/DynamicTable.module.scss';
import { getHeaderRow } from '../../utils/exportDynamicTableData';

type Props = {
	actionId: number;
};

export function DynamicTablePreview({ actionId }: Props) {
	const { dynamicTable, isLoading, error } =
		useGetDynamicTablePreview(actionId);

	const formattedTable = useMemo(() => {
		if (!dynamicTable) return null;
		const bodyRows = dynamicTable.rows.map((row: DynamicTableRow) => (
			<Table.Tr key={row.id}>
				<Table.Th>{row.title}</Table.Th>
				{dynamicTable.columns.map((columns: DynamicTableColumn) => (
					<Table.Td key={columns.id} />
				))}
			</Table.Tr>
		));

		return (
			<Box
				mah="50vh"
				style={{
					overflow: 'auto',
				}}
			>
				<Table mb="lg" classNames={classes}>
					<Table.Thead>
						<Table.Tr>{getHeaderRow(dynamicTable.columns)}</Table.Tr>
					</Table.Thead>
					<Table.Tbody>{bodyRows}</Table.Tbody>
				</Table>
			</Box>
		);
	}, [dynamicTable]);

	if (error)
		return (
			<Text ta="center" c="red" fs="italic">
				Une erreur est survenue lors du chargement du tableau
			</Text>
		);
	if (isLoading || !dynamicTable) return <CustomLoader />;

	return (
		<>
			<Stack gap="lg" w="100%">
				<Title order={3} ta="center">
					{dynamicTable.title}
				</Title>
				<Text
					ta="center"
					fw={500}
					truncate
					style={{
						whiteSpace: 'pre-line',
					}}
				>
					{dynamicTable.description}
				</Text>
				<Space h="xs" />
				{formattedTable}
			</Stack>
		</>
	);
}
