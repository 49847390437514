import dayjs from 'dayjs';
import {
	Consent,
	ConsentStatus,
	ConsentStatusEnum,
	SignerRoleEnum,
} from './api/consents.entities';

export const getConsentStatusBadgeColor = (status: ConsentStatus) => {
	switch (status) {
		case 'DRAFT':
			return 'info';
		case 'DONE':
			return 'success';
		case 'DECLINED':
			return 'destructive';
		case 'EXPIRED':
			return 'warning';
		case 'ONGOING':
			return 'blue';
		default:
			return 'info';
	}
};

export const formatConsentData = (consent: Consent) => ({
	'Nom du consentement': consent.fileName,
	'Code du participant': consent.participant.participantOnResearch?.code,
	Statut: ConsentStatusEnum[consent.status],
	Signataires: consent.signers
		.map((signer) => SignerRoleEnum[signer.role])
		.join(', '),
	'Envoyé le': dayjs(consent.createdAt).format('DD/MM/YYYY'),
	'Mis à jour le': dayjs(consent.createdAt).format('DD/MM/YYYY'),
});
