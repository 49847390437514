import { Button } from '@/components/ui/button';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { useDeleteDocument } from '../../api/hooks/useDeleteDocument';
import { useIsMobile } from '@/components/hooks/useIsMobile';

type Props = {
	documentId: number;
	handleClose: () => void;
};

export function DeleteDocumentModal({ documentId, handleClose }: Props) {
	const isMobile = useIsMobile();
	const { deleteDocumentMutation } = useDeleteDocument(documentId, handleClose);

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			title="Supprimer le document"
			size="md"
			fullScreen={isMobile}
		>
			<div className="flex flex-col gap-6">
				<p className="text-center">
					Êtes-vous sûr(e) de vouloir supprimer ce document ?
				</p>
				<div className="flex gap-4">
					{!isMobile && (
						<Button onClick={handleClose} variant="outline" className="w-full">
							Annuler
						</Button>
					)}
					<Button
						disabled={deleteDocumentMutation.isPending}
						className="w-full"
						variant="destructive"
						onClick={() => deleteDocumentMutation.mutate()}
					>
						Retirer
					</Button>
				</div>
			</div>
		</Modal>
	);
}
