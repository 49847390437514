import { SearchBar } from '@/shared/components/inputs/SearchBar';
import { ActionIcon } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';

type ThreadListHeaderProps = {
	setSearchValue: (value: string) => void;
	setNewMessage: (newMessage: boolean) => void;
};

export const ThreadListHeader = (props: ThreadListHeaderProps) => {
	const { userOnResearch: user } = useCurrentResearch();
	const canSendNewMessage = user.status === 'ACTIVE';

	return (
		<div className="flex flex-col items-start gap-2">
			<div className="flex w-full items-center justify-between">
				<h4>Messages</h4>
				{canSendNewMessage && (
					<ActionIcon
						variant="outline"
						radius="md"
						size="xl"
						style={{
							border: '1px solid var(--mantine-primary-color-0)',
						}}
						onClick={() => props.setNewMessage(true)}
					>
						<IconEdit size={20} />
					</ActionIcon>
				)}
			</div>
			<SearchBar
				setSearchValue={props.setSearchValue}
				label="Rechercher un contact"
			/>
		</div>
	);
};
