import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../../../../App';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { deleteDocument } from '../documents.mutation';
import { documentsQueryKeys } from '../documents.query-keys';

export const useDeleteDocument = (
	documentId: number,
	successCallback: () => void,
) => {
	const { research } = useCurrentResearch();

	const showSuccessNotification = () => {
		notifications.show({
			message: 'Document supprimé',
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const deleteDocumentMutation = useMutation({
		mutationFn: async () => {
			return await deleteDocument(documentId);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: documentsQueryKeys.list(research.id),
			});
			successCallback();
			showSuccessNotification();
		},
		onError: () => {
			showErrorNotification();
		},
	});

	return { deleteDocumentMutation };
};
