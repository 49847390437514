import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import MyField from '@/components/ui/form/form-field';
import classes from '@/components/ui/modal/modal.module.scss';
import {
	ForecastParticipantsCountZodSchema,
	ForecastParticipantsCountZodType,
} from '@/features/dashboard/components/forms/validators/ForecastParticipantsCountZodSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { Modal } from '@mantine/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useUpdateResearch } from '../../../researches/api/researches.hooks';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';

type Props = {
	handleClose: () => void;
};

export function ForecastParticipantsCountForm({ handleClose }: Props) {
	const isMobile = useIsMobile();
	const { research } = useCurrentResearch();
	const { updateResearchMutation } = useUpdateResearch();

	const methods = useForm<ForecastParticipantsCountZodType>({
		defaultValues: {
			forecastParticipantsCount: research.forecastParticipantsCount,
		},
		resolver: zodResolver(ForecastParticipantsCountZodSchema),
	});
	const {
		handleSubmit,
		formState: { errors },
	} = methods;

	const submitForm = async ({
		forecastParticipantsCount,
	}: {
		forecastParticipantsCount: number;
	}) => {
		if (forecastParticipantsCount !== research.forecastParticipantsCount)
			updateResearchMutation.mutate({ forecastParticipantsCount });
		handleClose();
	};

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			fullScreen={isMobile}
			title="Nombre d'inclusions prévues"
		>
			<FormProvider {...methods}>
				<form
					onSubmit={handleSubmit(submitForm)}
					className="flex flex-col gap-12"
				>
					<div className="flex flex-col gap-4">
						<MyField
							type="number"
							name="forecastParticipantsCount"
							required={true}
							label="Nombre d'inclusions prévues"
							placeholder="Indiquer le nombre d'inclusions prévues"
							error={errors.forecastParticipantsCount?.message}
							labelClass="text-black"
							inputClass="text-black"
						/>

						<div className="flex gap-4">
							{!isMobile && (
								<Button
									type="button"
									onClick={handleClose}
									variant="outline"
									className="w-full"
								>
									Annuler
								</Button>
							)}
							<Button
								type="submit"
								disabled={updateResearchMutation.isPending}
								className="w-full"
							>
								Confirmer
							</Button>
						</div>
					</div>
				</form>
			</FormProvider>
		</Modal>
	);
}
