import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from '../../../../routes/paths';
import { useSession } from '../../shared/hooks/useSession';
import { LoginResponse } from '../../shared/types/AuthToken';
import { OnsiteResearchMemberSignUpDto } from '../api/dto/OnsiteResearchMemberSignupDto';
import { OnsiteResearchMemberSignUpQuery } from '../api/onsite-research-member.mutations';
import { OnsiteResearchMemberSignUpForm } from '../components/forms/OnsiteResearchMemberSignUpForm';
import { OnsiteResearchMemberSignUpZodType } from '../components/forms/validators/OnsiteResearchMemberSignUpZodSchema';

export function OnsiteResearchMemberSignUpPage() {
	const location = useLocation();
	const navigate = useNavigate();
	const { createSession } = useSession();

	const key = location.state?.key ?? '';

	const mutation = useMutation({
		mutationFn: async (payload: OnsiteResearchMemberSignUpDto) => {
			return await OnsiteResearchMemberSignUpQuery(payload, key);
		},
		onSuccess: async (data: LoginResponse) => {
			notifications.show({
				message: 'Vous avez été ajouté à la recherche',
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
			createSession(data);
		},
		onError: () => {
			notifications.show({
				message: 'Une erreur est survenue',
				color: 'red',
				icon: <IconX style={{ width: '20px', height: '20px' }} />,
			});
			navigate(paths.SIGNIN_PATH, { replace: true });
		},
	});

	const handleSubmit = (data: OnsiteResearchMemberSignUpZodType) => {
		mutation.mutate(data);
	};

	return (
		<div className="flex h-full w-full max-w-3xl flex-col gap-12 overflow-y-auto">
			<div className="flex flex-col gap-3">
				<h1 className="text-center">Inscrivez-vous</h1>
				<p className="text-center text-md font-normal text-muted-foreground">
					Entrez vos informations pour créer votre espace et rejoindre une
					recherche.
				</p>
			</div>
			<OnsiteResearchMemberSignUpForm
				onSubmit={handleSubmit}
				isLoading={mutation.isPending}
			/>
		</div>
	);
}
