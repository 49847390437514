import { ResearchContactCard } from '../components/cards/ResearchContactCard';
import { ContactsTable } from '../components/tables/ContactsTable';

export function ResearchContactsTab() {
	return (
		<div className="flex h-full w-full flex-col gap-4">
			<div className="flex flex-col gap-1">
				<h4>Contacts</h4>
				<p className="text-muted-foreground">
					Contactez votre centre de recherche ou les membres de l'équipe à
					distance
				</p>
			</div>
			<ResearchContactCard />
			<ContactsTable />
		</div>
	);
}
