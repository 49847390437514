import MyField from '@/components/ui/form/form-field.tsx';
import { MySelect } from '@/components/ui/form/select-field.tsx';
import { zodResolver } from '@hookform/resolvers/zod';
import { ActionIcon } from '@mantine/core';
import { IconPlaylistAdd } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Role, RoleEnum } from '../../../profile/api/types/user.types.ts';
import { useCurrentResearch } from '../../../researches/contexts/researches.context.tsx';
import { CreateResearchMemberDto } from '../../api/dto/research-members.dto.ts.ts';
import {
	OffsiteResearchMemberRole,
	OffsiteResearchMemberRoleEnum,
	OnsiteResearchMemberRole,
	OnsiteResearchMemberRoleEnum,
} from '../../api/types/research-members.types.ts';
import {
	AddResearchMemberZodSchema,
	AddResearchMemberZodType,
} from './validators/AddResearchMemberZodSchema.ts';

type Props = {
	researchMembers: CreateResearchMemberDto[];
	setResearchMembers: React.Dispatch<
		React.SetStateAction<CreateResearchMemberDto[]>
	>;
};

export function AddResearchMemberForm({
	researchMembers,
	setResearchMembers,
}: Props) {
	const { research } = useCurrentResearch();
	const [isDuplicate, setIsDuplicate] = useState(false);
	const [roleOnResearchOptions, setRoleOnResearchOptions] = useState<
		{ value: string; name: string }[]
	>([]);

	const methods = useForm<AddResearchMemberZodType>({
		resolver: zodResolver(AddResearchMemberZodSchema),
	});
	const {
		handleSubmit,
		reset,
		watch,
		formState: { errors },
	} = methods;

	const submitForm = (data: AddResearchMemberZodType) => {
		const researchMember: CreateResearchMemberDto = {
			email: data.email,
			roleOnResearch: data.roleOnResearch as
				| OffsiteResearchMemberRole
				| OnsiteResearchMemberRole
				| 'CO_INVESTIGATOR',
			researchId: research.id,
			role: data.role as Omit<Role, 'PARTICIPANT'>,
			roleAdditionalInfo: data.roleAdditionalInfo,
		};
		if (researchMembers.some((rm) => rm.email === researchMember.email)) {
			setIsDuplicate(true);
			return;
		}
		setResearchMembers((currResearchMembers) => [
			...currResearchMembers,
			researchMember,
		]);
		setRoleOnResearchOptions([]);
		setIsDuplicate(false);
		reset({ email: '', role: '', roleOnResearch: '', roleAdditionalInfo: '' });
	};

	const setOptions = (value: Omit<Role, 'PARTICIPANT'>) => {
		let options: { value: string; name: string }[] = [];
		switch (value) {
			case 'INVESTIGATOR':
				options = [
					{
						value: 'CO_INVESTIGATOR',
						name: 'Co-investigateur',
					},
				];
				break;
			case 'ONSITE_RESEARCH_MEMBER':
				options = Object.entries(OnsiteResearchMemberRoleEnum).map(
					([value, name]) => ({ value, name }),
				);
				break;
			case 'OFFSITE_RESEARCH_MEMBER':
				options = Object.entries(OffsiteResearchMemberRoleEnum).map(
					([value, name]) => ({ value, name }),
				);
				break;
			default:
				options = [];
				break;
		}
		setRoleOnResearchOptions(options);
	};

	useEffect(() => {
		setOptions(watch('role') as Omit<Role, 'PARTICIPANT'>);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watch('role')]);

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className="flex flex-col gap-4">
					<MyField
						type="email"
						name="email"
						label="Email"
						placeholder="Email"
						error={errors.email?.message}
						labelClass="text-black"
						inputClass="text-black"
					/>

					<MySelect
						name="role"
						label="Fonction"
						placeholder="Choisir une fonction"
						required={true}
						key={watch('email')}
						selectItems={[
							{ value: 'INVESTIGATOR', name: RoleEnum.INVESTIGATOR },
							{
								value: 'ONSITE_RESEARCH_MEMBER',
								name: RoleEnum.ONSITE_RESEARCH_MEMBER,
							},
							{
								value: 'OFFSITE_RESEARCH_MEMBER',
								name: RoleEnum.OFFSITE_RESEARCH_MEMBER,
							},
						]}
						labelClass="text-black"
						selectAdditionalClass="text-black"
					/>

					<div className="flex flex-col items-center gap-4 md:flex-row">
						<MySelect
							name="roleOnResearch"
							label="Rôle dans le cadre de la recherche"
							placeholder="Choisir un rôle"
							key={watch('role')}
							required={true}
							selectItems={roleOnResearchOptions}
							labelClass="text-black"
							selectAdditionalClass="text-black"
						/>

						{watch('role') === 'OFFSITE_RESEARCH_MEMBER' &&
							(watch('roleOnResearch') === 'OTHER' ||
								watch('roleOnResearch') === 'DOCTOR') && (
								<MyField
									type="text"
									name="roleAdditionalInfo"
									label={
										watch('roleOnResearch') === 'OTHER'
											? 'Informations supplémentaires'
											: 'Spécialité médicale'
									}
									placeholder={
										watch('roleOnResearch') === 'OTHER'
											? 'Préciser le rôle'
											: 'Spécialité médicale'
									}
									error={errors.roleAdditionalInfo?.message}
									labelClass="text-black"
									inputClass="text-black"
								/>
							)}

						<ActionIcon type="submit" mt="5%" size="xl" maw="5%">
							<IconPlaylistAdd stroke={2} />
						</ActionIcon>
					</div>

					{isDuplicate && (
						<p className="text-center text-sm text-error-500">
							Cette adresse mail a déjà été attribuée
						</p>
					)}
				</div>
			</form>
		</FormProvider>
	);
}
