import { Text } from '@mantine/core';
import { IconForbid } from '@tabler/icons-react';
import { FigureCard } from '../atoms/FigureCard';

export type InactiveParticipantsCardProps = {
	inactiveParticipantsCount: number;
};

export const InactiveParticipantsCard = ({
	inactiveParticipantsCount,
}: InactiveParticipantsCardProps) => {
	return (
		<FigureCard title="Participants inactifs" icon={<IconForbid size={24} />}>
			<Text size="2.25rem" fw={600}>
				{inactiveParticipantsCount}
			</Text>
		</FigureCard>
	);
};
