import {
	CustomNavigationMenuLink,
	NavigationMenu,
	NavigationMenuItem,
	NavigationMenuList,
} from '@/components/ui/menu/navigation-menu';
import classes from '@/components/ui/modal/mobile-navigation-modal.module.scss';
import { useSession } from '@/features/auth/shared/hooks/useSession';
import { paths } from '@/routes/paths';
import { Modal } from '@mantine/core';
import {
	IconHelp,
	IconLayoutGrid,
	IconLogout,
	IconMail,
	IconTool,
	IconX,
} from '@tabler/icons-react';
import { Button } from '../button';
import { NavigationMenuHeader } from './navigation-menu-header';

type MobileNavigationMenuProps = {
	onClose: () => void;
};

export const MobileNavigationMenu = ({
	onClose: handleClose,
}: MobileNavigationMenuProps) => {
	const { signOut } = useSession();

	const items = [
		{
			label: 'Mon espace',
			link: paths.INDEX_PATH,
			icon: <IconLayoutGrid />,
		},
		{
			label: 'Aide',
			link: paths.HELP_PATH,
			icon: <IconHelp />,
		},
		{
			label: 'Paramètres',
			link: paths.SETTINGS_PATH,
			icon: <IconTool />,
		},
	];

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			fullScreen
			withCloseButton={false}
			className="MobileNavigationModal"
		>
			<div className="mt-3 flex w-full items-start justify-between">
				<img src="/logo-literal.svg" className="pt-2" />
				<IconX onClick={handleClose} className="text-white" />
			</div>

			<NavigationMenu className="flex h-full w-full flex-col gap-4 ">
				<div className="flex h-full flex-col justify-between">
					<NavigationMenuHeader />

					<NavigationMenuList className="w-full">
						{items.map((item, index) => (
							<NavigationMenuItem
								key={index}
								className="w-full"
								onClick={handleClose}
							>
								<CustomNavigationMenuLink
									href={item.link}
									icon={item.icon}
									label={item.label}
								/>
							</NavigationMenuItem>
						))}
						<NavigationMenuItem className="w-full">
							<Button
								variant={'ghost'}
								className="w-full justify-start"
								onClick={signOut}
							>
								<div className="flex space-x-2">
									<IconLogout />
									<p className="text-md">Déconnexion</p>
								</div>
							</Button>
						</NavigationMenuItem>
					</NavigationMenuList>

					<div className="flex flex-col gap-2 justify-self-end">
						<Button
							variant={'secondary'}
							size={'default'}
							className="w-full justify-center px-2"
							onClick={signOut}
						>
							<div className="flex items-center space-x-1 px-2">
								<IconMail className="h-6 w-6" />
								<p className="text-bold text-md">Contacter CosyTrials</p>
							</div>
						</Button>
					</div>
				</div>
			</NavigationMenu>
		</Modal>
	);
};
