import { AxiosError } from 'axios';
import { publicAxios } from '../../../../shared/services/publicAxios';
import { ResetPasswordDto } from './dto/ResetPasswordDto';

export const resetPasswordQuery = async (
	key: string,
	body: ResetPasswordDto,
) => {
	try {
		const response = await publicAxios.patch(`/auth/reset-password`, body, {
			headers: { Authorization: `Bearer ${key}` },
		});
		return response.data;
	} catch (error) {
		throw error as AxiosError;
	}
};
