import { Divider } from '@mantine/core';
import dayjs from 'dayjs';
import { isSameDay } from '../../../../shared/utils/dates';

type MessageSeparatorProps = {
	date: Date;
};

export const MessageSeparator = (props: MessageSeparatorProps) => {
	const formattedDate = isSameDay(new Date(), props.date)
		? "Aujourd'hui"
		: dayjs(props.date).format('DD MMMM YYYY');
	return <Divider label={formattedDate} labelPosition="center" />;
};
