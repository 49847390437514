import { z } from 'zod';
import {
	emailValidator,
	phoneNumberValidator,
} from '../../../../shared/components/validators/commonValidators';

export type ParticipantPersonalInfoZodType = z.infer<
	typeof ParticipantPersonalInfoZodSchema
>;

export const ParticipantPersonalInfoZodSchema = z.object({
	emailBis: z.union([
		emailValidator,
		z.null(),
		z
			.string()
			.length(0)
			.transform(() => null),
	]),
	phoneNumberBis: z.union([
		phoneNumberValidator,
		z.null(),
		z
			.string()
			.length(0)
			.transform(() => null),
	]),
});
