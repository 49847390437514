import { Divider, Group, Radio, Stack } from '@mantine/core';
import { Control, Controller } from 'react-hook-form';
import { Question } from '../../api/questionnaires.types';

type Props = {
	question: Question;
	control: Control;
};

export function SingleSelectQuestionInput({ question, control }: Props) {
	return (
		<Stack gap="lg" w="90%">
			<Controller
				control={control}
				name={question.id.toString()}
				render={({ field: { onChange, value }, fieldState: { error } }) => (
					<Radio.Group
						onChange={(newValue) => onChange(newValue)}
						label={question.label}
						description="Une seule réponse possible"
						value={value}
						size="md"
						error={error?.message}
						withAsterisk={question.required}
					>
						<Group mt="md" justify="start" gap="10%">
							{question.options.map((option) => (
								<Radio
									size="md"
									my="md"
									key={option.id}
									value={option.label}
									label={option.label}
								/>
							))}
						</Group>
					</Radio.Group>
				)}
			/>
			<Divider />
		</Stack>
	);
}
