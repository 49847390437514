import { CustomDatePicker } from '@/components/ui/date-picker/date-picker';
import MyField from '@/components/ui/form/form-field';
import { FormProvider, useFormContext } from 'react-hook-form';
import { PasswordRules } from '../../../shared/components/ui/PasswordRules';
import { MyPasswordField } from '@/components/ui/form/password-form-field';

type ParticipantSignUpFormStep1Props = {
	email: string;
};
export function ParticipantSignUpFormStep1({
	email,
}: ParticipantSignUpFormStep1Props) {
	const methods = useFormContext();
	const {
		control,
		formState: { errors },
	} = methods;

	return (
		<FormProvider {...methods}>
			<div className="flex flex-col gap-4">
				<div className="flex flex-col justify-between gap-4 md:flex-row">
					<MyField
						type="text"
						name="lastName"
						required={true}
						label="Nom"
						placeholder="Entrez votre nom"
						error={errors.lastName?.message?.toString()}
						labelClass="text-black"
						inputClass="text-black"
						additionalClass="md:w-1/2"
					/>

					<MyField
						type="text"
						name="firstName"
						required={true}
						label="Prénom"
						placeholder="Entrez votre prénom"
						error={errors.firstName?.message?.toString()}
						labelClass="text-black"
						inputClass="text-black"
						additionalClass="md:w-1/2"
					/>
				</div>

				<div className="flex flex-col justify-between gap-4 md:flex-row">
					<MyField
						type="tel"
						name="phoneNumber"
						required={true}
						label="Téléphone"
						placeholder="Entrez votre numéro de téléphone"
						error={errors.phoneNumber?.message?.toString()}
						labelClass="text-black"
						inputClass="text-black"
						additionalClass="md:w-1/2"
					/>

					<div className="md:w-1/2">
						<CustomDatePicker
							name="birthDate"
							label="Date de naissance"
							placeholder="Entrez votre date de naissance"
							control={control}
							required
							maxDate={new Date()}
						/>
					</div>
				</div>
				<MyField
					type="text"
					name="address"
					required={true}
					label="Adresse postale"
					placeholder="Entrez votre adresse postale"
					error={errors.address?.message?.toString()}
					labelClass="text-black"
					inputClass="text-black"
				/>
				<div className="flex flex-col justify-between gap-4 md:flex-row">
					<MyField
						type="text"
						name="city"
						required={true}
						label="Ville"
						placeholder="Entrez votre ville"
						error={errors.city?.message?.toString()}
						labelClass="text-black"
						inputClass="text-black"
						additionalClass="md:w-1/2"
					/>
					<MyField
						type="number"
						name="zipCode"
						required={true}
						label="Code postal"
						placeholder="Entrez votre code postal"
						error={errors.zipCode?.message?.toString()}
						labelClass="text-black"
						inputClass="text-black"
						additionalClass="md:w-1/2"
					/>
				</div>
				<MyField
					type="email"
					name="email"
					label="Email"
					placeholder="Entrez votre adresse email"
					disabled={true}
					defaultValue={email}
					labelClass="text-black"
					inputClass="text-black"
				/>
				<div className="flex flex-col gap-2">
					<MyPasswordField
						name="password"
						required={true}
						label="Mot de passe"
						placeholder="Entrez votre mot de passe"
						error={errors.password?.message?.toString()}
						labelClass="text-black"
						inputClass="text-black"
						autoComplete="new-password"
					/>
					<PasswordRules />
				</div>
				<MyPasswordField
					name="confirmPassword"
					required={true}
					label="Confirmation du mot de passe"
					placeholder="Confirmez votre mot de passe"
					error={errors.confirmPassword?.message?.toString()}
					labelClass="text-black"
					autoComplete="new-password"
					inputClass="text-black"
					onPaste={(event) => {
						event.preventDefault();
					}}
				/>
			</div>
		</FormProvider>
	);
}
