import { AxiosError } from 'axios';
import { privateAxios } from '../../../../shared/services/privateAxios';

export const resendInvestigatorInviteLink = async (
	userId: number,
	researchId: number,
): Promise<void> => {
	try {
		await privateAxios.get<void>(
			`/investigators/${userId}/resend-invite-link/research/${researchId}`,
		);
	} catch (error) {
		throw error as AxiosError;
	}
};
