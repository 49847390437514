import { CreateResearchMemberDto } from '../../api/dto/research-members.dto.ts.ts';
import { ResearchMemberPicker } from './ResearchMemberPicker.tsx';

type Props = {
	researchMembers: CreateResearchMemberDto[];
	setResearchMembers: React.Dispatch<
		React.SetStateAction<CreateResearchMemberDto[]>
	>;
};

export function ResearchMembersControlPanel({
	researchMembers,
	setResearchMembers,
}: Props) {
	const handleRemoveResearchMember = (email: string) => {
		setResearchMembers((currResearchMembers) =>
			currResearchMembers.filter(
				(researchMember) => researchMember.email !== email,
			),
		);
	};

	return researchMembers?.length > 0 ? (
		<div className="flex flex-col gap-2">
			{researchMembers.map((researchMember) => {
				return (
					<ResearchMemberPicker
						key={researchMember.email}
						user={researchMember}
						onRemoveUser={handleRemoveResearchMember}
					/>
				);
			})}
		</div>
	) : (
		<p className="text-center text-sm text-muted-foreground">
			Aucun membre sélectionné
		</p>
	);
}
