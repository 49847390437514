import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import {
	keepPreviousData,
	useMutation,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import { useState } from 'react';
import { UploadFileDto } from '../../../shared/api/files.dto';
import { uploadFileQuery } from '../../../shared/api/files.queries';
import { PreSignedPutUrl } from '../../../shared/types/PreSignedPutUrl';
import { S3File } from '../../../shared/types/S3File';
import { useCurrentResearch } from '../../researches/contexts/researches.context';
import { CreateConsentDto, CreateSignerDto } from './consents.dto';
import { Consent, ConsentStatus } from './consents.entities';
import {
	createConsent,
	deleteConsent,
	reactivateConsent,
} from './consents.mutations';
import { getConsentUploadUrl, getConsents } from './consents.queries';
import { consentsQueryKeys } from './consents.query-keys';

const showSuccessNotification = (message: string) => {
	notifications.show({
		message: message,
		color: 'teal',
		icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
	});
};

const showErrorNotification = () => {
	notifications.show({
		message: `Une erreur est survenue`,
		color: 'red',
		icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
	});
};

export const useGetConsents = (
	researchId: number,
	userId?: number,
	statuses?: ConsentStatus[],
) => {
	const { data, isLoading, error } = useQuery({
		queryKey: consentsQueryKeys.list(researchId, {
			userId,
			statuses,
		}),
		queryFn: () => getConsents(researchId, userId, statuses),
		placeholderData: keepPreviousData,
	});

	return { data, isLoading, error };
};

export const useCreateConsent = (successCallback: () => void) => {
	const { research } = useCurrentResearch();
	const queryClient = useQueryClient();

	const [isLoading, setIsLoading] = useState(false);

	const getUploadUrlMutation = useMutation({
		mutationFn: async (fileName: string): Promise<PreSignedPutUrl> =>
			getConsentUploadUrl(fileName),
	});
	const uploadConsentMutation = useMutation({
		mutationFn: async (data: UploadFileDto) => uploadFileQuery(data),
	});
	const createConsentMutation = useMutation({
		mutationFn: async (data: CreateConsentDto) =>
			createConsent(research.id, data),
	});

	const addConsent = async (
		participantId: number,
		file: S3File,
		signers: CreateSignerDto[],
	) => {
		setIsLoading(true);
		try {
			const { signedUrl, key } = await getUploadUrlMutation.mutateAsync(
				file.fileName,
			);
			await uploadConsentMutation.mutateAsync({ signedUrl, file: file.file });
			await createConsentMutation.mutateAsync({
				documentKey: key,
				fileName: file.fileName,
				participantId,
				signers,
			});
			successCallback();
			showSuccessNotification(
				'Demande de consentement créée et envoyée aux signataires',
			);
			queryClient.invalidateQueries({
				queryKey: consentsQueryKeys.list(research.id, {}),
			});
		} catch (error) {
			showErrorNotification();
		} finally {
			setIsLoading(false);
		}
	};
	return { addConsent, isLoading };
};

export const useReactivateConsent = () => {
	const { research } = useCurrentResearch();
	const queryClient = useQueryClient();

	const reactivateConsentMutation = useMutation({
		mutationFn: async (consentId: number) =>
			reactivateConsent(research.id, consentId),
		onSuccess: (_, context) => {
			showSuccessNotification(
				'Consentement réactivé, un lien de signature électonique va être renvoyé par email aux signataires.',
			);
			const consents = queryClient.getQueryData<Consent[]>(
				consentsQueryKeys.list(research.id, {}),
			);
			const updatedConsents = consents?.map((consent) => {
				return consent.consentId === context
					? { ...consent, status: 'ONGOING' }
					: consent;
			});
			queryClient.setQueryData(
				consentsQueryKeys.list(research.id, {}),
				updatedConsents,
			);
		},
		onError: showErrorNotification,
	});

	return reactivateConsentMutation;
};

export const useDeleteConsent = (
	consentId: number,
	successCallback: () => void,
) => {
	const { research } = useCurrentResearch();
	const queryClient = useQueryClient();

	const deleteConsentMutation = useMutation({
		mutationFn: async () => deleteConsent(research.id, consentId),
		onSuccess: () => {
			showSuccessNotification('Consentement supprimé');
			queryClient.invalidateQueries({
				queryKey: consentsQueryKeys.list(research.id, {}),
			});
			successCallback();
		},
		onError: showErrorNotification,
	});

	return deleteConsentMutation;
};
