import { useIsMobile } from '@/components/hooks/useIsMobile';
import classes from '@/components/ui/form/mantine-select-field.module.scss';
import { cn } from '@/components/utils';
import { ActionIcon, Paper, Select } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { FileTile } from '../../../../shared/components/files/FileTile';
import { CreateDocumentWithoutKeyDto } from '../../api/documents.dto';
import {
	DocumentCustomType,
	DocumentTypeEnum,
} from '../../api/documents.types';

type Props = {
	document: CreateDocumentWithoutKeyDto & { file: File };
	onRemoveFile: (name: string) => void;
	onDocumentPropertyChange: (name: string, type: DocumentCustomType) => void;
};

export function DocumentOption({
	document,
	onRemoveFile,
	onDocumentPropertyChange,
}: Props) {
	const isMobile = useIsMobile();
	const getSelectValues = () => {
		const data = [];
		for (const [key, value] of Object.entries(DocumentTypeEnum))
			data.push({ value: key, label: value });
		return data;
	};

	return (
		<Paper
			shadow="sm"
			radius="md"
			className="flex w-full items-center justify-between gap-2 p-2"
		>
			<div className="flex grow gap-2 max-md:flex-col">
				<div className={cn(isMobile ? 'w-full' : 'max-w-[50%]')}>
					<FileTile
						fileName={document.file.name}
						fileSize={document.file.size}
					/>
				</div>
				<Select
					data={getSelectValues()}
					defaultValue={document.type}
					size="sm"
					w={'45%'}
					classNames={classes}
					styles={{
						input: { paddingLeft: '12px' },
					}}
					allowDeselect={false}
					onChange={(value) => {
						onDocumentPropertyChange(
							document.file.name ?? '',
							value as DocumentCustomType,
						);
					}}
				/>
			</div>
			<ActionIcon
				variant="transparent"
				onClick={() => onRemoveFile(document.file.name ?? '')}
			>
				<IconTrash color="red" />
			</ActionIcon>
		</Paper>
	);
}
