import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useCurrentParticipant } from '../../../../../usersOnResearch/contexts/participants.context';
import { downloadActionResultDocuments } from '../action-documents.queries';

export const useDownloadActionResultDocuments = (actionId: number) => {
	const { participant } = useCurrentParticipant();

	const handleMultipleFilesDownload = (blobData: Blob, fileName: string) => {
		const blobUrl = URL.createObjectURL(blobData);
		const downloadLink = document.createElement('a');
		downloadLink.href = blobUrl;
		downloadLink.download = fileName;
		downloadLink.click();
		URL.revokeObjectURL(blobUrl);
	};

	const showSuccessNotification = () => {
		notifications.show({
			message: 'Documents téléchargés',
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const downloadDocumentsMutation = useMutation({
		mutationFn: async (allDocuments: boolean) =>
			downloadActionResultDocuments(
				actionId,
				allDocuments ? undefined : participant.userId,
			),
		onSuccess: (data, allDocuments) => {
			let fileName = `${actionId}-${new Date().toLocaleDateString()}-documents.zip`;
			if (!allDocuments)
				fileName = `${participant.participantOnResearch.code}-` + fileName;

			handleMultipleFilesDownload(data as Blob, fileName);
			showSuccessNotification();
		},
		onError: showErrorNotification,
	});

	return { downloadDocumentsMutation };
};
