import { Text } from '@mantine/core';
import { IconCircleCheck } from '@tabler/icons-react';
import { FigureCard } from '../atoms/FigureCard';

export type ParticipantsWithCompletedVisitsCardProps = {
	activeParticipantsCount: number;
	participantsWithCompletedVisitsCount: number;
};

export const ParticipantsWithCompletedVisitsCard = ({
	activeParticipantsCount,
	participantsWithCompletedVisitsCount,
}: ParticipantsWithCompletedVisitsCardProps) => {
	return (
		<FigureCard
			title="Participants ayant complété toutes les visites"
			icon={<IconCircleCheck size={24} />}
		>
			<Text size="2.25rem" fw={600}>
				{participantsWithCompletedVisitsCount}{' '}
				<Text component="span" size="md">
					/ {activeParticipantsCount}
				</Text>
			</Text>
		</FigureCard>
	);
};
