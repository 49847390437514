import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { useEffect, useState } from 'react';
import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';
import { MeetWithParticipants } from '../../api/meets.types';
import { UpdateMeetForm } from '../forms/UpdateMeetForm';
import { useIsMobile } from '@/components/hooks/useIsMobile';

type Props = {
	meet: MeetWithParticipants;
	handleClose: () => void;
};

const initialState = (meet: MeetWithParticipants) => {
	return meet.participants?.filter(
		(participant) => participant.roleOnMeet !== 'ORGANIZER',
	);
};

export function UpdateMeetModal({ meet, handleClose }: Props) {
	const isMobile = useIsMobile();
	const [participants, setParticipants] = useState<UserOnResearch[]>(
		initialState(meet),
	);
	useEffect(() => {
		setParticipants(initialState(meet));
	}, [meet]);

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			fullScreen={isMobile}
			classNames={classes}
			title="Modifier la visioconférence"
			size="xl"
		>
			<UpdateMeetForm
				meet={meet}
				handleClose={handleClose}
				participants={participants}
				setParticipants={setParticipants}
			/>
		</Modal>
	);
}
