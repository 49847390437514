import { Avatar, Fieldset } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import CaregiverAvatar from '../../../../assets/icons/caregiver-avatar.svg';
import ParticipantAvatar from '../../../../assets/icons/participant-avatar.svg';

import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import { cn } from '@/components/utils';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../../routes/paths';
import { useSession } from '../../../auth/shared/hooks/useSession';
import { useGetParticipantsByOffsiteResearchMember } from '../../../usersOnResearch/api/hooks/participants.hooks';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';

export const CaregiverInfo = () => {
	const isMobile = useIsMobile();
	const { participant } = useCurrentParticipant();
	const { user } = useSession();
	const navigate = useNavigate();

	const isCaregiver = participant.participantOnResearch.caresFor;
	const caregiverInfo = isCaregiver
		? participant.participantOnResearch.caresFor
		: participant.participantOnResearch.caredForBy;

	const { participants: linkedParticipants } =
		useGetParticipantsByOffsiteResearchMember(
			user?.userId ?? 0,
			undefined,
			user?.role === 'OFFSITE_RESEARCH_MEMBER',
		);

	const shouldActivateBadgeLink = () => {
		if (user?.role !== 'OFFSITE_RESEARCH_MEMBER') return true;
		return linkedParticipants?.find(
			(p) =>
				p.participantOnResearch?.code ===
				caregiverInfo?.participantOnResearch?.code,
		);
	};

	return (
		<div className="flex gap-4 max-md:flex-col">
			<h5 className={cn(isMobile ? 'w-full' : 'w-1/4')}>
				{isCaregiver ? 'Aidé' : 'Aidant'}
			</h5>
			<div className="flex flex-1 flex-col">
				<Fieldset
					p="lg"
					styles={{
						root: {
							backgroundColor: 'white',
						},
					}}
				>
					<div className="flex gap-4 max-md:flex-col max-md:items-start">
						<div className="flex grow items-center gap-2">
							<Avatar src={isCaregiver ? ParticipantAvatar : CaregiverAvatar} />
							<div>
								<p>{caregiverInfo?.participantOnResearch.code}</p>
								<p className="text-sm text-muted-foreground">
									{caregiverInfo?.email}
								</p>
							</div>
						</div>

						{shouldActivateBadgeLink() && (
							<Button
								variant="link"
								size={'sm'}
								className="max-md:self-start"
								onClick={() =>
									navigate(
										`../../${paths.PARTICIPANT_TAB}/${caregiverInfo?.userId}`,
									)
								}
							>
								<div className="flex items-center gap-1">
									<p>Accéder à l'espace</p>
									<IconArrowRight size={24} />
								</div>
							</Button>
						)}
					</div>
				</Fieldset>
			</div>
		</div>
	);
};
