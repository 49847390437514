import { Button } from '@/components/ui/button';
import { notifications } from '@mantine/notifications';
import { IconArrowLeft, IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from '../../../../routes/paths';
import { requestPasswordResetQuery } from '../queries/requestPasswordResetQuery';

export function VerifyEmailPage() {
	const navigate = useNavigate();
	const location = useLocation();

	const email = location.state?.email ?? '';

	const mutation = useMutation({
		mutationFn: async () => {
			return await requestPasswordResetQuery(email);
		},
		onSuccess: async () => {
			notifications.show({
				message: `Un email a été envoyé à l’adresse ${email}.`,
				color: 'teal',
				icon: <IconCheck style={{ width: '20rem', height: '20rem' }} />,
			});
		},
		onError: () => {
			notifications.show({
				message:
					'Une erreur est survenue lors de l’envoi de l’email. Veuillez réessayer.',
				color: 'red',
				icon: <IconX style={{ width: '20rem', height: '20rem' }} />,
			});
		},
	});

	const handleSubmit = () => {
		mutation.mutate(email);
	};

	return (
		<div className="flex max-w-xl flex-col justify-center gap-12">
			<div className="flex flex-col gap-3">
				<h1 className="text-center">Vérifiez vos emails</h1>
				<p className="text-center text-md font-normal text-muted-foreground">
					Nous avons envoyé un lien de réinitialisation du mot de passe à
					l’adresse email {email}.
				</p>
			</div>
			<div className="mx-auto flex items-center space-x-2">
				<p className="text-center text-md font-normal text-muted-foreground">
					Vous n’avez pas reçu d’email ?
				</p>
				<Button
					variant={'link'}
					disabled={mutation.isPending}
					onClick={handleSubmit}
					className="inline-block px-0"
				>
					Renvoyer
				</Button>
			</div>
			<Button
				variant="link"
				size={'sm'}
				onClick={() => navigate(paths.SIGNIN_PATH)}
				className="flex justify-center"
			>
				<div className="flex space-x-2">
					<IconArrowLeft />
					<p>Retour à la connexion</p>
				</div>
			</Button>
		</div>
	);
}
