import classes from '@/components/ui/modal/modal.module.scss';
import { Button, Modal } from '@mantine/core';
import { ActionType } from '../../api/actions.types';
import { DynamicTablePreview } from '../../dynamic-tables/components/cards/DynamicTablePreview';
import { QuestionnairePreview } from '../../questionnaires/components/cards/QuestionnairePreview';

type Props = {
	handleClose: () => void;
	actionId: number;
	actionType: ActionType;
};

export function PreviewActionModal({
	handleClose,
	actionId,
	actionType,
}: Props) {
	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			fullScreen={true}
		>
			<div className="flex h-full flex-col items-center justify-between">
				{actionType === 'QUESTIONNAIRE' && (
					<QuestionnairePreview actionId={actionId} />
				)}
				{actionType === 'TABLE' && <DynamicTablePreview actionId={actionId} />}
				<Button size="md" fullWidth w="90%" onClick={handleClose}>
					Fermer
				</Button>
			</div>
		</Modal>
	);
}
