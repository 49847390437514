import { Avatar, Skeleton, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useGetUsersOnResearch } from '../../../usersOnResearch/api/hooks/users-on-research.hooks';
import { ShowContactsModal } from '../modals/ShowContactsModal';

export function ContactsBadge() {
	const [opened, { open, close }] = useDisclosure();

	const {
		usersOnResearch: contacts,
		isLoading,
		error,
	} = useGetUsersOnResearch(
		['ONSITE_RESEARCH_MEMBER', 'INVESTIGATOR'],
		['ACTIVE'],
	);

	const [contactsToDisplay, others] =
		contacts && contacts?.length > 4
			? [contacts?.slice(0, 4), contacts?.slice(4)]
			: [contacts, []];

	return (
		<>
			{isLoading ? (
				<Skeleton visible={isLoading || Boolean(error)} h={38} w={38} circle />
			) : (
				<Tooltip
					label="Voir les membres de l'équipe sur centre"
					position="top"
					onClick={open}
				>
					<Avatar.Group
						style={{
							cursor: 'pointer',
						}}
					>
						{contactsToDisplay?.map((_, index) => (
							<Avatar
								size="md"
								key={index}
								radius="xl"
								color="var(--mantine-primary-color-1)"
							/>
						))}
						{others.length > 0 && <Avatar>+{others.length}</Avatar>}
					</Avatar.Group>
				</Tooltip>
			)}
			{opened && contacts && (
				<ShowContactsModal handleClose={close} contacts={contacts} />
			)}
		</>
	);
}
