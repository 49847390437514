import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { getHelpDownloadUrl } from '../../../help/api/help.queries';
import { getDownloadUrl } from '../documents.queries';

export const useDownloadDocument = (helpDocument: boolean = false) => {
	const handleDownload = (signedUrl: string) => {
		const downloadLink = document.createElement('a');
		downloadLink.href = signedUrl;
		downloadLink.click();
	};
	const showSuccessNotification = () => {
		notifications.show({
			message: 'Document téléchargé',
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const mutation = useMutation({
		mutationFn: async (id: number) =>
			helpDocument ? getHelpDownloadUrl(id) : getDownloadUrl(id),
		onSuccess: ({ signedUrl }) => {
			handleDownload(signedUrl);
			showSuccessNotification();
		},
		onError: showErrorNotification,
	});

	return { mutation };
};
