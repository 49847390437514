import { Grid, Text } from '@mantine/core';
import { Navigate } from 'react-router-dom';
import { CustomLoader } from '../../../components/ui/loader';
import { paths } from '../../../routes/paths';
import { useGetDocuments } from '../api/help.hooks';
import { HelpDocumentPreviewCard } from '../components/cards/HelpDocumentPreviewCard';

export function HelpDocuments() {
	const { documents, isLoading, error } = useGetDocuments();

	if (error) return <Navigate to={`/${paths.ERROR_PATH}`} />;
	if (isLoading || !documents) return <CustomLoader />;

	return (
		<>
			{documents && documents?.length > 0 ? (
				<Grid>
					{documents?.map((document, index) => (
						<Grid.Col span={4} key={index}>
							<HelpDocumentPreviewCard document={document} />
						</Grid.Col>
					))}
				</Grid>
			) : (
				<Text fs="italic" c="dimmed">
					Aucun document n'a été déposé pour le moment.
				</Text>
			)}
		</>
	);
}
