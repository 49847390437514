import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from '@/components/utils';

const badgeVariants = cva(
	'inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2',
	{
		variants: {
			variant: {
				default:
					'border-transparent bg-primary text-primary-foreground hover:bg-primary/80',
				secondary:
					'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80',
				success:
					'border-transparent bg-success-50 text-success-700 hover:bg-success-50/80',
				warning:
					'border-transparent bg-warning-50 text-warning-700 hover:bg-warning-50/80',
				destructive:
					'border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80',
				outline: 'text-foreground',
				info: 'border-transparent bg-gray-100 text-gray-700 hover:bg-gray-100/80',
				blue: 'border-transparent bg-blue-100 text-blue-700 hover:bg-blue-100/80',
				green:
					'border-transparent bg-green-100 text-green-700 hover:bg-green-100/80',
				yellow:
					'border-transparent bg-yellow-100 text-yellow-700 hover:bg-yellow-100/80',
				red: 'border-transparent bg-red-100 text-red-700 hover:bg-red-100/80',
			},
		},
		defaultVariants: {
			variant: 'default',
		},
	},
);

export interface BadgeProps
	extends React.HTMLAttributes<HTMLDivElement>,
		VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
	return (
		<div className={cn(badgeVariants({ variant }), className)} {...props} />
	);
}

export { Badge, badgeVariants };
