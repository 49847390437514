import { useIsMobile } from '@/components/hooks/useIsMobile';
import { cn } from '@/components/utils';
import { Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconFileExport, IconPlus } from '@tabler/icons-react';
import { useSession } from '../../auth/shared/hooks/useSession';
import { useExportResearchMemberData } from '../api/hooks/research-members.hooks';
import { AddResearchMembersModal } from '../components/modals/AddResearchMembersModal';
import { ResearchMembersTable } from '../components/tables/ResearchMemberTable';
import { Button } from '@/components/ui/button';

export function ResearchMembersTab() {
	const [opened, { open, close }] = useDisclosure();
	const { user } = useSession();
	const canAdd = user?.role === 'INVESTIGATOR';
	const isMobile = useIsMobile();

	const { isLoadingExport, exportResearchMemberData } =
		useExportResearchMemberData();

	return (
		<>
			<div className="flex h-full w-full flex-col gap-4">
				<div className="flex flex-col justify-start gap-2 md:flex-row md:justify-between">
					<h4>Équipe de recherche</h4>
					<div className="flex gap-2 max-md:flex-col">
						{canAdd && (
							<Button
								size={isMobile ? 'xs' : 'sm'}
								className="w-fit"
								onClick={open}
							>
								<div className="flex flex-row items-center gap-1">
									{
										<IconPlus
											className={cn(isMobile ? 'h-4 w-4' : 'h-5 w-5')}
										/>
									}
									<p>Ajouter un membre</p>
								</div>
							</Button>
						)}
						<Tooltip
							label="Exporter la liste des membres de l'équipe de recherche"
							radius={'md'}
						>
							<Button
								size={isMobile ? 'xs' : 'sm'}
								className="w-fit"
								variant="outline"
								onClick={exportResearchMemberData}
								disabled={isLoadingExport}
							>
								<div className="flex flex-row items-center gap-1">
									{
										<IconFileExport
											className={cn(isMobile ? 'h-4 w-4' : 'h-5 w-5')}
										/>
									}
									<p>Exporter</p>
								</div>
							</Button>
						</Tooltip>
					</div>
				</div>
				<ResearchMembersTable />
			</div>
			{opened && <AddResearchMembersModal handleClose={close} />}
		</>
	);
}
