import { Button } from '@/components/ui/button';
import MyField from '@/components/ui/form/form-field';
import { MyPasswordField } from '@/components/ui/form/password-form-field';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { SignInDto } from '../../queries/dto/SignInDto';

type Props = {
	onSubmit: (data: SignInDto) => void;
	isLoading: boolean;
};

export function SignInForm({ onSubmit, isLoading }: Props) {
	const location = useLocation();
	const email = location.state?.email ?? '';

	const methods = useForm<SignInDto>({
		defaultValues: {
			email,
			password: '',
		},
	});

	const {
		handleSubmit,
		formState: { errors },
	} = methods;

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={handleSubmit(onSubmit)}
				noValidate
				className="flex w-full flex-col gap-12"
			>
				<div className="flex flex-col gap-4">
					<MyField
						type="email"
						name="email"
						label="Email"
						placeholder="Entrer votre email"
						error={errors.email?.message}
						labelClass="text-black"
						inputClass="text-black"
					/>
					<MyPasswordField
						name="password"
						label="Mot de passe"
						placeholder="Entrer votre de passe"
						error={errors.password?.message}
						labelClass="text-black"
						inputClass="text-black"
					/>
				</div>
				<Button
					disabled={isLoading}
					type="submit"
					className="flex justify-center"
				>
					Se connecter
				</Button>
			</form>
		</FormProvider>
	);
}
