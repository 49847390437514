import {
	ActionIcon,
	Button,
	Group,
	Indicator,
	Stack,
	Text,
} from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import {
	useMarkAsPerformed,
	useMarkAsRead,
} from '../../api/notifications.hooks';
import { NotificationEntity } from '../../api/notifications.types';
import { getNotificationIconAndTitle } from '../../notifications.utils';

export type NotificationItemProps = {
	notification: NotificationEntity;
	handleClose: () => void;
};

export const NotificationItem = ({
	notification,
	handleClose,
}: NotificationItemProps) => {
	const navigate = useNavigate();
	const { markAsReadMutation } = useMarkAsRead(notification.notificationId);
	const { markAsPerformedMutation } = useMarkAsPerformed(
		notification.notificationId,
	);

	const { icon, title } = getNotificationIconAndTitle(notification.category);

	const performedByFullName = `${notification.performedBy?.firstName} ${notification.performedBy?.lastName}`;
	const actionRequired =
		notification.type === 'ACTION' && !notification.performed;

	return (
		<Indicator color="red" disabled={notification.read} offset={15}>
			<Stack
				p="md"
				gap="md"
				bg={notification.read ? 'white' : '#f9f9fb'}
				style={{ borderRadius: 'var(--mantine-radius-sm)' }}
			>
				<Stack gap="sm">
					<Group gap="sm" align="start">
						{icon}
						<Text fw={600}>{title}</Text>
					</Group>
					<Group gap="xs" preventGrowOverflow>
						<Text w="90%">{notification.content}</Text>
						<ActionIcon
							variant="transparent"
							onClick={() => {
								markAsReadMutation.mutate();
								handleClose();
								navigate(notification.redirectUrl);
							}}
						>
							<IconArrowRight />
						</ActionIcon>
					</Group>
				</Stack>
				<Group justify="space-between">
					<Stack gap="xs">
						{notification.performed && notification.performedBy && (
							<Text c="dimmed" size="sm">
								Traitée le {dayjs(notification.updatedAt).format('DD MMM')} par{' '}
								{performedByFullName}
							</Text>
						)}
						<Text c="dimmed" size="sm">
							{dayjs(notification.createdAt).fromNow()}
						</Text>
					</Stack>
					<Group gap={0}>
						{actionRequired && (
							<Button
								variant="outline"
								onClick={() => markAsPerformedMutation.mutate()}
							>
								Marquer comme réalisée
							</Button>
						)}
						{!notification.read && (
							<Button
								variant="transparent"
								onClick={() => markAsReadMutation.mutate()}
							>
								Marquer comme lue
							</Button>
						)}
					</Group>
				</Group>
			</Stack>
		</Indicator>
	);
};
