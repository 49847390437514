import { useIsMobile } from '@/components/hooks/useIsMobile';
import { cn } from '@/components/utils';
import { IconBuildingSkyscraper, IconHome } from '@tabler/icons-react';

type Props = {
	isRemote: boolean;
};

export const VisitLocationBadge = ({ isRemote }: Props) => {
	const isMobile = useIsMobile();
	return (
		<div
			className={cn(
				'mx-auto flex items-center justify-center gap-2 rounded-sm bg-secondary px-2 py-2',
				isRemote ? 'bg-green-100 text-green-600' : 'bg-blue-100 text-blue-600',
			)}
		>
			{isRemote ? <IconHome size={16} /> : <IconBuildingSkyscraper size={16} />}
			{!isMobile && (
				<p className="hidden text-xs font-semibold md:block">
					{isRemote ? 'À domicile' : 'Sur centre'}
				</p>
			)}
		</div>
	);
};
