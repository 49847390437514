import { z } from 'zod';

export type ForecastParticipantsCountZodType = z.infer<
	typeof ForecastParticipantsCountZodSchema
>;

const errorMessage =
	'Le nombre de participants prévus doit être un nombre entier positif';

export const ForecastParticipantsCountZodSchema = z.object({
	forecastParticipantsCount: z.coerce
		.number({
			invalid_type_error: errorMessage,
			required_error: errorMessage,
		})
		.positive({
			message: errorMessage,
		}),
});
