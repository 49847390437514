import { useIsMobile } from '@/components/hooks/useIsMobile';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { IconVideo } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { CustomLoader } from '../../../../components/ui/loader';
import { Countdown } from '../../../../shared/components/miscellaneous/Countdown';
import { useJoinMeetMutation } from '../../api/hooks/useJoinMeet';
import { Meet } from '../../api/meets.types';

export type CreateOrUpdateMeetType = {
	name: string;
	startDate: Date;
	endDate: Date;
	participants: number[];
};

type JoinMeetModalProps = {
	meet: Meet;
	handleClose: () => void;
};

export function JoinMeetModal({ meet, handleClose }: JoinMeetModalProps) {
	const [isTimeElapsed, setIsTimeElapsed] = useState(false);
	const { joinMeetMutation } = useJoinMeetMutation(meet.id);
	const isMobile = useIsMobile();

	useEffect(() => {
		if (isTimeElapsed) joinMeetMutation.mutate();
	}, [isTimeElapsed]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			title="Rejoindre la visioconférence"
			size="lg"
			fullScreen={isMobile}
		>
			<div className="flex flex-col gap-6">
				<div className="flex flex-col items-center">
					<IconVideo className="h-10 w-10 text-orange-500" />
					<h4>{meet.name}</h4>
					<h5 className="font-normal">
						{dayjs(meet.startDate).format('[le] D MMM YYYY [à] HH:mm')}
					</h5>
				</div>
				<div className="flex flex-col items-center gap-4">
					{joinMeetMutation.isSuccess ? (
						<p className="text-muted-foreground">
							La réunion a déjà commencé:{' '}
							<a
								className="inline-block text-lg font-bold text-orange-700 hover:underline"
								href={joinMeetMutation.data.joinUrl}
								target="_blank"
							>
								cliquez ici
							</a>{' '}
							pour vous connecter
						</p>
					) : (
						<>
							{!isTimeElapsed ? (
								<>
									<p>Veuillez patienter, votre réunion commence dans :</p>
									<Countdown
										startDate={meet.startDate}
										setIsTimeElapsed={setIsTimeElapsed}
									/>
								</>
							) : (
								<CustomLoader />
							)}
							<p className="text-center text-muted-foreground">
								Vous allez automatiquement être redirigé(e) vers la
								visioconférence...
							</p>
						</>
					)}
				</div>
			</div>
		</Modal>
	);
}
