import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import { cn } from '@/components/utils';
import { useDisclosure } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';
import { useCurrentResearch } from '../../researches/contexts/researches.context';
import { AddNoteModal } from '../components/modals/AddNoteModal';
import { NotesTable } from '../components/tables/NotesTable';

export function NotesTab() {
	const isMobile = useIsMobile();
	const [opened, { open, close }] = useDisclosure();

	const { userOnResearch } = useCurrentResearch();
	const canCreateNote = userOnResearch.status === 'ACTIVE';

	return (
		<>
			<div className="flex h-full w-full flex-col gap-4">
				<div className="flex flex-col justify-start gap-2 md:flex-row md:justify-between">
					<h4>Notes</h4>
					{canCreateNote && (
						<Button
							size={isMobile ? 'xs' : 'sm'}
							className="w-fit"
							onClick={open}
						>
							<div className="flex flex-row items-center gap-1">
								{<IconPlus className={cn(isMobile ? 'h-4 w-4' : 'h-5 w-5')} />}
								<p>Créer une note</p>
							</div>
						</Button>
					)}
				</div>
				<NotesTable />
			</div>
			{opened && <AddNoteModal handleClose={close} />}
		</>
	);
}
