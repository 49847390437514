export type FieldInfoProps = {
	label: string;
	value: string;
};

export const FieldInfo = ({ label, value }: FieldInfoProps) => {
	return (
		<div className="flex flex-1 flex-col gap-1">
			<p className="text-sm text-muted-foreground">{label}</p>
			<p className="whitespace-pre-line">{value}</p>
		</div>
	);
};
