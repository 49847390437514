export const notesQueryKeys = {
	all: ['notes'] as const,
	lists: () => [...notesQueryKeys.all, 'list'] as const,
	list: (researchId?: number) => [...notesQueryKeys.lists(), researchId],
	listByParticipant: (researchId: number, participantId: number) => [
		...notesQueryKeys.list(researchId),
		'participant',
		participantId,
	],
	details: () => [...notesQueryKeys.all, 'details'] as const,
	detail: (noteId: number) => [...notesQueryKeys.details(), noteId],
};
