import { useQuery } from '@tanstack/react-query';
import { getActionResults } from '../actions.queries';
import { actionsQueryKeys } from '../actions.query-keys';

export const useGetActionResults = (actionId: number) => {
	const {
		data: actionWithResults,
		isLoading,
		error,
	} = useQuery({
		queryKey: actionsQueryKeys.results(actionId),
		queryFn: async () => getActionResults(actionId),
	});
	return { actionWithResults, isLoading, error };
};
