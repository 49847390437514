const SIGNIN_PATH = '/signin';
const VERIFY_SIGNIN_PATH = 'verify-signin';

const FORGOT_PASSWORD_PATH = 'forgot-password';
const VERIFY_EMAIL_PATH = 'verify-email';
const RESET_PASSWORD_PATH = 'reset-password';
const RESET_PASSWORD_SUCCESS_PATH = 'reset-password-success';

const INVESTIGATOR_JOIN_RESEARCH_PATH = '/join-research/investigators';
const PARTICIPANT_JOIN_RESEARCH_PATH = '/join-research/participants';
const OFFSITE_RESEARCH_MEMBER_JOIN_RESEARCH_PATH =
	'/join-research/offsite-research-members';
const ONSITE_RESEARCH_MEMBER_JOIN_RESEARCH_PATH =
	'/join-research/onsite-research-members';

const INVESTIGATOR_SIGNUP_PATH = '/signup/investigators';
const PARTICIPANT_SIGNUP_PATH = '/signup/participants';
const OFFSITE_RESEARCH_MEMBER_SIGNUP_PATH = '/signup/offsite-research-members';
const ONSITE_RESEARCH_MEMBER_SIGNUP_PATH = '/signup/onsite-research-members';

const INDEX_PATH = '/';
const ERROR_PATH = 'error';
const RESEARCH_PATH = 'researches';
const NOTIFICATIONS_PATH = 'notifications';
const HELP_PATH = 'help';
const SETTINGS_PATH = 'settings';

const DOCUMENTS_TAB = 'documents';
const CONSENT_TAB = 'consent';
const MEETS_TAB = 'meets';
const MESSAGING_TAB = 'messaging';
const NOTES_TAB = 'notes';

const PARTICIPANTS_TAB = 'participants';
const RESEARCH_MEMBERS_TAB = 'research-members';
const DASHBOARD_TAB = 'dashboard';
const CALENDAR_TAB = 'calendar';
const QUESTIONNAIRES_TAB = 'questionnaires';

const PARTICIPANT_TAB = 'participant';

const VISITS_TAB = 'visits';
const CONTACTS_TAB = 'contacts';

export const paths = {
	SIGNIN_PATH,
	VERIFY_SIGNIN_PATH,
	FORGOT_PASSWORD_PATH,
	RESET_PASSWORD_PATH,
	RESET_PASSWORD_SUCCESS_PATH,
	VERIFY_EMAIL_PATH,
	INVESTIGATOR_JOIN_RESEARCH_PATH,
	PARTICIPANT_JOIN_RESEARCH_PATH,
	OFFSITE_RESEARCH_MEMBER_JOIN_RESEARCH_PATH,
	ONSITE_RESEARCH_MEMBER_JOIN_RESEARCH_PATH,
	INVESTIGATOR_SIGNUP_PATH,
	PARTICIPANT_SIGNUP_PATH,
	OFFSITE_RESEARCH_MEMBER_SIGNUP_PATH,
	ONSITE_RESEARCH_MEMBER_SIGNUP_PATH,
	HELP_PATH,
	INDEX_PATH,
	RESEARCH_PATH,
	PARTICIPANTS_TAB,
	RESEARCH_MEMBERS_TAB,
	NOTIFICATIONS_PATH,
	SETTINGS_PATH,
	DOCUMENTS_TAB,
	QUESTIONNAIRES_TAB,
	MEETS_TAB,
	MESSAGING_TAB,
	CONSENT_TAB,
	NOTES_TAB,
	VISITS_TAB,
	CONTACTS_TAB,
	PARTICIPANT_TAB,
	DASHBOARD_TAB,
	CALENDAR_TAB,
	ERROR_PATH,
} as const;
