import classes from '@/components/ui/form/segmented-control.module.scss';
import { SegmentedControl } from '@mantine/core';
import { VisitCategory } from '../../../visits/api/visits.types';

export type ToggleVisitCategoryProps = {
	visitCategory: VisitCategory;
	setVisitCategory: (visitCategory: VisitCategory) => void;
};

export const ToggleVisitCategory = ({
	visitCategory,
	setVisitCategory,
}: ToggleVisitCategoryProps) => {
	return (
		<SegmentedControl
			data={[
				{ label: 'Participants', value: 'Participants' },
				{ label: 'Aidants', value: 'Aidants' },
			]}
			value={visitCategory}
			onChange={(value: string) => setVisitCategory(value as VisitCategory)}
			radius="md"
			classNames={classes}
			w="fit-content"
		/>
	);
};
