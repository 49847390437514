import { UserOnResearch } from '../../usersOnResearch/api/types/users-on-research.types';

export type DocumentInfo = {
	id: number;
	key: string;
	fileName: string;
	uploadedBy: UserOnResearch;
	accessLevel: DocumentAccessLevelType;
	type: DocumentCustomType;
	scope: DocumentScopeType;
	createdAt: Date;
	recipients?: UserOnResearch[];
	participantId?: number;
};

export const DocumentScopeEnum = {
	PRIVATE: 'Privé',
	PROFESSIONAL: 'Professionnel',
};
export type DocumentScopeType = keyof typeof DocumentScopeEnum;

export const DocumentAccessLevelEnum = {
	ALL: 'Tous',
	RESEARCH_CENTER: 'Centre de recherche',
	PARTICIPANTS: 'Participants',
	OFFSITE_RESEARCH_MEMBERS: 'Équipe de recherche à distance',
	CUSTOM: 'Personnalisé',
};
export type DocumentAccessLevelType = keyof typeof DocumentAccessLevelEnum;

export const DocumentTypeEnum = {
	ORGANIZATION: 'Organisation',
	TEMPLATE: 'Template',
	RESEARCH_DOCUMENT: 'Recherche',
	PARTICIPANT_DOCUMENT: 'Participant',
	OTHER: 'Autre',
};
export type DocumentCustomType = keyof typeof DocumentTypeEnum;
