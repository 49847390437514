import { UserOnResearch } from '../../usersOnResearch/api/types/users-on-research.types';

export type EyesonMeet = {
	joinUrl: string;
	meetId: number;
};

export type Meet = {
	id: number;
	name: string;
	startDate: Date;
	endDate: Date;
	accessKey: string;
	status: MeetStatusType;
	createdAt: Date;
	researchId: number;
	researchName: string;
};

export type MeetWithParticipants = Meet & {
	participants: UserOnMeet[];
};

export const MeetRoleEnum = {
	ORGANIZER: 'Organisateur',
	ATTENDEE: 'Participant',
};
export type MeetRole = keyof typeof MeetRoleEnum;

export const MeetStatusEnum = {
	UPCOMING: 'A venir',
	ONGOING: 'En cours',
	ENDED: 'Terminée',
};
export type MeetStatusType = keyof typeof MeetStatusEnum;

export type UserOnMeet = UserOnResearch & {
	roleOnMeet: MeetRole;
};
