import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { QueryKey, useMutation, useQuery } from '@tanstack/react-query';
import { useInvalidateQueries } from '../../../shared/hooks/useInvalidateQueries';
import { useCurrentResearch } from '../../researches/contexts/researches.context';
import { UserOnResearch } from '../../usersOnResearch/api/types/users-on-research.types';
import { sendMessage } from './messages.mutations';
import { getThread } from './messages.queries';
import { messagesQueryKeys } from './messages.query-keys';

export const useGetThread = (threadId: number) => {
	const {
		data: thread,
		isLoading,
		error,
	} = useQuery({
		queryKey: messagesQueryKeys.thread(threadId),
		queryFn: () => getThread(threadId),
		refetchInterval: 1000 * 60 * 3,
		enabled: !!threadId,
	});

	return { thread, isLoading, error };
};

export const useSendMessage = (
	successCallback: () => void,
	threadId?: number,
	recipients?: UserOnResearch[],
) => {
	const { research } = useCurrentResearch();
	const invalidateQueries = useInvalidateQueries();
	const sendMessageMutation = useMutation({
		mutationFn: async (content: string) => {
			if (threadId) return sendMessage(research.id, { content }, threadId);
			return sendMessage(research.id, {
				content,
				receiverUserIds:
					recipients && recipients.length > 0
						? recipients.map((r) => r.userId)
						: undefined,
			});
		},
		onSuccess: () => {
			const queriesToInvalidate: QueryKey[] = [
				messagesQueryKeys.threads(research.id),
			];
			threadId && queriesToInvalidate.push(messagesQueryKeys.thread(threadId));
			invalidateQueries(queriesToInvalidate);
			successCallback();
		},
		onError: () => {
			notifications.show({
				message: 'Une erreur est survenue',
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		},
	});

	return { sendMessageMutation };
};
