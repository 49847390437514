import { useIsMobile } from '@/components/hooks/useIsMobile';
import classes from '@/components/ui/drawer/drawer.module.scss';
import { Drawer } from '@mantine/core';
import { VisitScheduleQueryParams } from '../../api/visit-schedule.types';
import { FilterVisitsSchedulesForm } from '../forms/FilterVisitsSchedulesForm';
import { FilterVisitsSchedulesZodType } from '../forms/validators/FilterVisitsSchedulesZodSchema';

export type FilterPaneProps = {
	isLoading: boolean;
	onClose: () => void;
	onSubmit: (data: FilterVisitsSchedulesZodType) => void;
	currentParams: VisitScheduleQueryParams;
};

export const FilterVisitsSchedulesPane = ({
	isLoading,
	onClose: handleClose,
	onSubmit: handleSubmit,
	currentParams,
}: FilterPaneProps) => {
	const isMobile = useIsMobile();
	return (
		<Drawer
			opened
			onClose={handleClose}
			position="right"
			classNames={classes}
			withCloseButton={true}
			size={isMobile ? '100%' : 500}
		>
			<FilterVisitsSchedulesForm
				currentParams={currentParams}
				isLoading={isLoading}
				onSubmit={handleSubmit}
			/>
		</Drawer>
	);
};
