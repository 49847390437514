import { IconMail } from '@tabler/icons-react';
import {
	MRT_Cell,
	MRT_ColumnDef,
	MantineReactTable,
	useMantineReactTable,
} from 'mantine-react-table';
import { useMemo } from 'react';
import { getDefaultMRTOptions } from '../../../../components/ui/table/table-options';
import { customResearchMemberFilterFn } from '../../../../shared/utils/filters';
import { useGetOffsiteResearchMembersByParticipant } from '../../../usersOnResearch/api/hooks/research-members.hooks';
import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';
import { ResearchMemberCard } from '../../../usersOnResearch/components/cards/ResearchMemberCard';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';

export function ContactsTable() {
	const { participant } = useCurrentParticipant();

	const {
		offsiteResearchMembers: contacts,
		isLoading,
		error,
	} = useGetOffsiteResearchMembersByParticipant(participant.userId, ['ACTIVE']);

	const columns = useMemo<MRT_ColumnDef<UserOnResearch>[]>(
		() => [
			{
				header: "Membre de l'équipe à distance",
				accessorFn: (row) => row,
				id: 'research-member',
				size: 300,
				Cell: ({ cell }: { cell: MRT_Cell<UserOnResearch> }) => (
					<ResearchMemberCard user={cell.row.original} />
				),
				filterFn: 'customResearchMemberFilterFn',
				enableSorting: false,
			},
			{
				header: 'Téléphone',
				id: 'phoneNumber',
				accessorKey: 'phoneNumber',
				size: 100,
				Cell: ({ cell }: { cell: MRT_Cell<UserOnResearch> }) => {
					return (
						<p className=" text-center text-sm">{cell.getValue<string>()}</p>
					);
				},
				enableSorting: false,
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
			},
			{
				id: 'actions-mail-to',
				header: '',
				accessorKey: 'email',
				columnDefType: 'display',
				size: 1,
				Cell: ({ cell }: { cell: MRT_Cell<UserOnResearch> }) => (
					<a
						href={`mailto:${cell.getValue<string>()}`}
						className="my-auto flex cursor-pointer flex-row items-center justify-end gap-1 pr-4 font-semibold text-primary hover:underline"
					>
						{<IconMail className={'h-5 w-5'} />}
						<p className="text-sm">Contacter</p>
					</a>
				),
				mantineTableHeadCellProps: {
					align: 'right',
				},
				mantineTableBodyCellProps: {
					align: 'right',
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const defaultTableOptions = getDefaultMRTOptions<UserOnResearch>(
		Boolean(error),
	);
	const table = useMantineReactTable({
		data: contacts || [],
		columns,
		...defaultTableOptions,
		state: { isLoading },

		filterFns: {
			customResearchMemberFilterFn: (row, _id, filterValue) =>
				customResearchMemberFilterFn(row.original, filterValue),
		},
		globalFilterFn: 'customResearchMemberFilterFn',
	});

	return <MantineReactTable table={table} />;
}
