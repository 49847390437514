import { AxiosError } from 'axios';
import { privateAxios } from '../../../../shared/services/privateAxios.ts';
import { JoinResearchResponse } from '../../../auth/shared/types/JoinResearchResponse.ts';
import { CreateParticipantDto } from '../dto/participants.dto.ts.ts';

export const addParticipantOnResearch = async (
	payload: CreateParticipantDto,
) => {
	try {
		const { data } = await privateAxios.post<JoinResearchResponse>(
			`/participants/create-on-research`,
			payload,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const updateParticipantsOnOffsiteResearchMember = async (
	participants: number[],
	researchId: number,
	userId: number,
): Promise<{ addedParticipants: number[]; removedParticipants: number[] }> => {
	try {
		const { data } = await privateAxios.put(
			`/researches/${researchId}/update-participants/offsite-research-member/${userId}`,
			{
				participants,
			},
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
