import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import { CustomDatePicker } from '@/components/ui/date-picker/date-picker';
import { CustomTimePicker } from '@/components/ui/date-picker/time-picker';
import MyField from '@/components/ui/form/form-field';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Control, FormProvider, useForm } from 'react-hook-form';
import {
	addDuration,
	combineDateAndTime,
	getTimeFromDate,
} from '../../../../shared/utils/dates';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';
import { ScheduleVisitDto } from '../../api/visits.dto';
import { ParticipantVisit } from '../../api/visits.types';
import { VisitLocationCardCheckbox } from '../atoms/VisitLocationCheckboxCard';
import {
	ScheduleVisitZodSchema,
	ScheduleVisitZodType,
} from './validators/ScheduleVisitZodSchema';

export type ScheduleRemoteVisitFormProps = {
	visit: ParticipantVisit;
	handleClose: () => void;
	handleSubmit: (payload: ScheduleVisitDto) => void;
};

export const ScheduleRemoteVisitForm = ({
	visit,
	handleClose: onClose,
	handleSubmit: onSubmit,
}: ScheduleRemoteVisitFormProps) => {
	const { participant } = useCurrentParticipant();
	const isMobile = useIsMobile();

	const isParticipantAddress = () => {
		if (!participant.participantProfile) return false;
		return (
			visit.address === participant.participantProfile.address &&
			visit.city === participant.participantProfile.city &&
			visit.zipCode === participant.participantProfile.zipCode
		);
	};
	const [atParticipantAddress, setAtParticipantAddress] = useState<boolean>(
		isParticipantAddress(),
	);

	const getDefaultStartDate = () => {
		if (visit.startDate) return new Date(visit.startDate);
		if (visit.forecastDate) return new Date(visit.forecastDate);
		return new Date();
	};
	const getDefaultEndDate = () => {
		if (visit.endDate) return new Date(visit.endDate);
		return addDuration(getDefaultStartDate(), 1, 'hour');
	};
	const getDefaultLocation = () => {
		if (atParticipantAddress)
			return {
				address: participant.participantProfile.address,
				city: participant.participantProfile.city,
				zipCode: participant.participantProfile.zipCode,
			};
		return {
			address: visit.address ?? '',
			city: visit.city ?? '',
			zipCode: visit.zipCode ?? '',
		};
	};

	const methods = useForm<ScheduleVisitZodType>({
		resolver: zodResolver(ScheduleVisitZodSchema),
		defaultValues: {
			date: getDefaultStartDate(),
			startTime: getTimeFromDate(getDefaultStartDate()),
			endTime: getTimeFromDate(getDefaultEndDate()),
			address: getDefaultLocation().address,
			zipCode: getDefaultLocation().zipCode,
			city: getDefaultLocation().city,
		},
	});
	const {
		handleSubmit,
		control,
		resetField,
		formState: { errors },
	} = methods;

	useEffect(() => {
		resetField('address', { defaultValue: getDefaultLocation().address });
		resetField('zipCode', { defaultValue: getDefaultLocation().zipCode });
		resetField('city', { defaultValue: getDefaultLocation().city });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [atParticipantAddress]);

	const submitForm = async (data: ScheduleVisitZodType) => {
		const { date, startTime, endTime, ...location } = data;
		const startDate = combineDateAndTime(date.toISOString(), startTime);
		const endDate = combineDateAndTime(date.toISOString(), endTime);

		const payload: ScheduleVisitDto = {
			startDate,
			endDate,
			...location,
		};
		onSubmit(payload);
	};

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					handleSubmit(submitForm)();
				}}
			>
				<div className="flex flex-col gap-12">
					<div className="flex flex-col gap-4">
						<CustomDatePicker
							name="date"
							label="Date de la visite"
							placeholder="Choisir une date"
							control={control as unknown as Control}
							required
							minDate={new Date()}
						/>

						<div className="flex flex-col justify-between gap-4 md:flex-row">
							<CustomTimePicker
								name="startTime"
								label="Horaire de début"
								placeholder="Choisir une heure"
								control={control as unknown as Control}
								required
							/>

							<CustomTimePicker
								name="endTime"
								label="Horaire de fin"
								placeholder="Choisir une heure"
								control={control as unknown as Control}
								required
							/>
						</div>
					</div>

					<div className="flex flex-col gap-4">
						<p className="text-center text-lg font-semibold">
							Où se déroulera la visite ?
						</p>
						<div className="flex flex-col justify-between gap-4 md:flex-row">
							<VisitLocationCardCheckbox
								label="Au domicile du participant"
								checked={atParticipantAddress}
								onChange={() => {
									setAtParticipantAddress((prev) => !prev);
								}}
								disabled={!participant.participantProfile}
							/>
							<VisitLocationCardCheckbox
								label="Autre"
								checked={!atParticipantAddress}
								onChange={() => {
									setAtParticipantAddress((prev) => !prev);
								}}
								disabled={!participant.participantProfile}
							/>
						</div>

						<MyField
							type="text"
							name="address"
							required={true}
							placeholder={
								atParticipantAddress
									? participant.participantProfile.address
									: 'Adresse'
							}
							disabled={atParticipantAddress}
							label="Adresse postale"
							error={errors.address?.message}
							labelClass="text-black"
							inputClass="text-black"
						/>
						<div className="flex flex-col justify-between gap-4 md:flex-row">
							<MyField
								type="text"
								name="city"
								required={true}
								label="Ville"
								placeholder={
									atParticipantAddress
										? participant.participantProfile.city
										: 'Ville'
								}
								error={errors.city?.message}
								disabled={atParticipantAddress}
								labelClass="text-black"
								inputClass="text-black"
								additionalClass="md:w-1/2"
							/>
							<MyField
								type="number"
								name="zipCode"
								required={true}
								label="Code postal"
								placeholder={
									atParticipantAddress
										? participant.participantProfile.zipCode
										: 'Code postal'
								}
								error={errors.zipCode?.message}
								disabled={atParticipantAddress}
								labelClass="text-black"
								inputClass="text-black"
								additionalClass="md:w-1/2"
							/>
						</div>
					</div>

					<div className="flex gap-4">
						{!isMobile && (
							<Button
								onClick={onClose}
								variant="outline"
								className="w-full"
								type="button"
							>
								Annuler
							</Button>
						)}
						<Button type="submit" className="w-full">
							Planifier
						</Button>
					</div>
				</div>
			</form>
		</FormProvider>
	);
};
