import { DonutChart } from '@mantine/charts';
import { Anchor, Box, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useSession } from '../../../auth/shared/hooks/useSession';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { FigureCard } from '../atoms/FigureCard';
import { ForecastParticipantsCountForm } from '../forms/ForecastParticipantsCountForm';

export type InclusionRateCardProps = {
	activeParticipantsCount: number;
};

export const InclusionRateCard = ({
	activeParticipantsCount,
}: InclusionRateCardProps) => {
	const { research } = useCurrentResearch();
	const { user } = useSession();
	const [opened, { open, close }] = useDisclosure();

	const forecastParticipantsCount = research.forecastParticipantsCount;

	const figureData = forecastParticipantsCount
		? [
				{
					name: 'Participants inclus',
					value: activeParticipantsCount,
					color: 'var(--mantine-primary-color-6)',
				},
				{
					name: 'Participants restants',
					value: forecastParticipantsCount - activeParticipantsCount,
					color: 'var(--mantine-color-gray-3)',
				},
			]
		: [
				{
					name: 'Participants inclus',
					value: activeParticipantsCount,
					color: 'var(--mantine-color-gray-3)',
				},
			];

	const chartLabel = (
		<Text
			size="2.25rem"
			fw={600}
			style={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
			}}
		>
			{forecastParticipantsCount
				? `${((activeParticipantsCount / forecastParticipantsCount) * 100).toFixed(0)}%`
				: '-'}
		</Text>
	);

	const editButton = (
		<Anchor
			component="button"
			onClick={open}
			size="sm"
			fw={500}
			style={{
				position: 'absolute',
				top: '80%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
			}}
			underline="always"
			c={forecastParticipantsCount ? 'var(--mantine-primary-color-6' : 'red'}
		>
			{forecastParticipantsCount ? 'Modifier' : 'Ajouter un nombre'}
		</Anchor>
	);

	return (
		<>
			<FigureCard title="Taux d'inclusion">
				<Box
					style={{
						position: 'relative',
						overflow: 'hidden',
					}}
					mah={200}
				>
					<DonutChart
						size={200}
						thickness={30}
						withTooltip
						tooltipDataSource="segment"
						w="100%"
						data={figureData}
						startAngle={180}
						endAngle={0}
						tooltipProps={{
							wrapperStyle: {
								zIndex: 1000,
							},
						}}
						mih={230}
					/>
					{chartLabel}
					{user?.role === 'INVESTIGATOR' && editButton}
				</Box>
			</FigureCard>
			{opened && <ForecastParticipantsCountForm handleClose={close} />}
		</>
	);
};
