import { z } from 'zod';
import { Option } from '../../../api/questionnaires.types';

export const SingleSelectQuestionZodSchema = (options: Option[]) =>
	z
		.string({ required_error: 'La réponse à cette question est requise' })
		.trim()
		.min(1, {
			message: 'La réponse à cette question est requise',
		})
		.refine((value) => options.some((option) => option.label === value), {
			message: 'La réponse à cette question doit être une option valide',
		});
