import { useIsMobile } from '@/components/hooks/useIsMobile';
import classes from '@/components/ui/form/mantine-select-field.module.scss';
import { Label } from '@/components/ui/label';
import { Switch } from '@/components/ui/switch';
import { Divider, Select } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { useEffect } from 'react';
import { MultiSelectUsersOnResearch } from '../../../../shared/components/inputs/MultiSelectUsersOnResearch';
import { UserOnResearchControlPanel } from '../../../../shared/components/inputs/UserOnResearchControlPanel';
import { useGetUsersOnResearch } from '../../../usersOnResearch/api/hooks/users-on-research.hooks';
import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';
import { DocumentAccessLevelType } from '../../api/documents.types';

type DocumentRecipientsFormProps = {
	accessLevel: DocumentAccessLevelType;
	setAccessLevel: React.Dispatch<React.SetStateAction<DocumentAccessLevelType>>;
	recipients?: UserOnResearch[];
	setRecipients: React.Dispatch<React.SetStateAction<UserOnResearch[]>>;
	disabled?: boolean;
};

export function DocumentRecipientsForm({
	accessLevel,
	setAccessLevel,
	recipients,
	setRecipients,
	disabled = false,
}: DocumentRecipientsFormProps) {
	const isMobile = useIsMobile();
	const { usersOnResearch: allRecipients } = useGetUsersOnResearch(
		['PARTICIPANT', 'OFFSITE_RESEARCH_MEMBER'],
		['ACTIVE'],
	);

	useEffect(() => {
		if (!recipients || recipients?.length === 0) return;
		if (accessLevel !== 'CUSTOM') setRecipients([]);
		else setAccessLevel('CUSTOM');
	}, [accessLevel, recipients, setRecipients, setAccessLevel]);

	return (
		<div className="flex flex-col gap-8">
			<div className="flex flex-col gap-2">
				<h5 className="text-center">Sélectionnez les destinataires</h5>
				<div className="flex items-center justify-center gap-2 bg-gray-100 px-4 py-3 text-sm italic">
					<IconInfoCircle size={16} />
					<p className="text-center">
						Il s’agit des personnes uniquement de cette recherche
					</p>
				</div>
			</div>
			<div className="flex flex-col gap-4">
				<div className="flex items-center space-x-2">
					<Switch
						id="research-center"
						checked={accessLevel === 'RESEARCH_CENTER'}
						onCheckedChange={(checked) =>
							checked
								? setAccessLevel('RESEARCH_CENTER')
								: setAccessLevel('ALL')
						}
						disabled={disabled}
					/>

					<Label htmlFor="research-center">
						Restreindre la diffusion au centre de recherche
					</Label>
				</div>
				{accessLevel !== 'RESEARCH_CENTER' && (
					<>
						<Divider
							label="Ou"
							styles={{
								label: {
									fontSize: 'var(--mantine-font-size-sm)',
									color: 'black',
								},
							}}
						/>

						<div className="flex items-center justify-between max-md:flex-col max-md:items-start max-md:gap-2">
							<Label htmlFor="research-center" className="w-1/3">
								Partager à un groupe
							</Label>
							<Select
								size="md"
								w={isMobile ? '100%' : '70%'}
								classNames={classes}
								withCheckIcon={false}
								allowDeselect={false}
								onChange={(value) => {
									setAccessLevel(value as DocumentAccessLevelType);
								}}
								defaultValue={accessLevel}
								disabled={disabled}
								data={[
									{ value: 'ALL', label: 'Tous les membres de la recherche' },
									{ value: 'PARTICIPANTS', label: 'Tous les participants' },
									{
										value: 'OFFSITE_RESEARCH_MEMBERS',
										label: "Tous les membres de l'équipe à distance",
									},
									{
										value: 'CUSTOM',
										label: 'Membres spécifiques',
									},
								]}
							/>
						</div>

						{allRecipients && accessLevel === 'CUSTOM' && (
							<>
								<Divider
									label="Ou"
									styles={{
										label: {
											fontSize: 'var(--mantine-font-size-sm)',
											color: 'black',
										},
									}}
								/>

								<div className="flex flex-col gap-4">
									<MultiSelectUsersOnResearch
										selectedUsers={recipients as UserOnResearch[]}
										setSelectedUsers={setRecipients}
										allUsers={allRecipients as UserOnResearch[]}
										label="Ajouter des participants à la visioconférence"
									/>

									<UserOnResearchControlPanel
										usersOnResearch={recipients ?? []}
										setUsersOnResearch={setRecipients}
									/>
								</div>
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
}
