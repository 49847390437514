import { Button } from '@/components/ui/button';
import { Thread } from '@/features/messages/api/messages.types';
import { getThreadContact } from '@/features/messages/utils/messages';
import { useCurrentResearch } from '@/features/researches/contexts/researches.context';
import { UserOnResearch } from '@/features/usersOnResearch/api/types/users-on-research.types';
import { getResearchMemberInfo } from '@/features/usersOnResearch/utils/researchMemberInfo';
import { paths } from '@/routes/paths';
import { Avatar } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

type MobileMessageListHeaderProps = {
	thread: Thread;
	handleClose: () => void;
};

export const MobileMessageListHeader = ({
	thread,
	handleClose,
}: MobileMessageListHeaderProps) => {
	const navigate = useNavigate();
	const { userOnResearch: user, research } = useCurrentResearch();

	const contact: UserOnResearch | 'RESEARCH_CENTER' = getThreadContact(
		thread,
		user.role,
	);
	const { displayTitle, displayRole, avatar } = (() => {
		if (contact === 'RESEARCH_CENTER')
			return {
				displayTitle: 'Centre de recherche',
				displayRole: '',
				avatar: '',
			};
		return getResearchMemberInfo(contact);
	})();
	const handleContactClick = (contactId: number) =>
		navigate(
			`/${paths.RESEARCH_PATH}/${research.id}/${paths.PARTICIPANT_TAB}/${contactId}`,
		);

	return (
		<div className="flex items-center gap-4 border-b-2 border-gray-50 py-3 pl-3">
			<IconArrowLeft size={24} className="text-primary" onClick={handleClose} />
			<div className="flex flex-col items-start gap-1">
				<div className="flex items-center gap-4">
					<Avatar src={avatar} size={50} />
					<div className="flex flex-col">
						<p className="text-lg font-semibold">{displayTitle}</p>
						{displayRole && (
							<p className="text-muted-foreground">{displayRole}</p>
						)}
					</div>
				</div>
				{contact !== 'RESEARCH_CENTER' && contact.role === 'PARTICIPANT' && (
					<Button
						onClick={() => handleContactClick(contact.userId)}
						variant="link"
						size={'sm'}
						className="m-0 p-0"
					>
						Accéder à l'espace
					</Button>
				)}
			</div>
		</div>
	);
};
