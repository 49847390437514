import { Navigate, createBrowserRouter } from 'react-router-dom';
import { InvestigatorJoinResearchPage } from '../features/auth/investigator/pages/InvestigatorJoinResearchPage';
import { InvestigatorSignUpPage } from '../features/auth/investigator/pages/InvestigatorSignUpPage';
import { OffsiteResearchMemberJoinResearchPage } from '../features/auth/offsite-research-member/pages/OffsiteResearchMemberJoinResearchPage';
import { OffsiteResearchMemberSignUpPage } from '../features/auth/offsite-research-member/pages/OffsiteResearchMemberSignUpPage';
import { OnsiteResearchMemberJoinResearchPage } from '../features/auth/onsite-research-member/pages/OnsiteResearchMemberJoinResearchPage';
import { OnsiteResearchMemberSignUpPage } from '../features/auth/onsite-research-member/pages/OnsiteResearchMemberSignUpPage';
import { ParticipantJoinResearchPage } from '../features/auth/participant/pages/ParticipantJoinResearchPage';
import { ParticipantSignUpPage } from '../features/auth/participant/pages/ParticipantSignUpPage';
import { RequestPasswordResetPage } from '../features/auth/reset-password/pages/RequestPasswordResetPage';
import { ResetPasswordPage } from '../features/auth/reset-password/pages/ResetPasswordPage';
import { SuccessPasswordReset } from '../features/auth/reset-password/pages/SuccessPasswordResetPage';
import { VerifyEmailPage } from '../features/auth/reset-password/pages/VerifyEmailPage';
import { AuthLayout } from '../features/auth/shared/layouts/AuthLayout';
import { SignInOtpVerificationPage } from '../features/auth/signin/pages/SignInOtpVerificationPage';
import { SignInPage } from '../features/auth/signin/pages/SignInPage';
import { CalendarPage } from '../features/calendar/pages/CalendarPage';
import { ConsentTab } from '../features/consent/pages/ConsentTab';
import { ContactsTab } from '../features/contacts/pages/ContactsTab';
import { DashboardPage } from '../features/dashboard/pages/DashboardPage';
import { DocumentsTab } from '../features/documents/pages/DocumentTab';
import { HelpPage } from '../features/help/pages/HelpPage';
import { MeetsTab } from '../features/meets/pages/MeetsTab';
import { NotesTab } from '../features/notes/pages/NotesTab';
import { SettingsPage } from '../features/profile/pages/SettingsPage';
import { ResearchProvider } from '../features/researches/contexts/researches.context';
import { ResearchLayout } from '../features/researches/layouts/ResearchLayout';
import { ResearchesPage } from '../features/researches/pages/ResearchesPage';
import { ParticipantProvider } from '../features/usersOnResearch/contexts/participants.context';
import { ParticipantsTab } from '../features/usersOnResearch/pages/ParticipantsTab';
import { ResearchMembersTab } from '../features/usersOnResearch/pages/ResearchMembersTab';
import { VisitsPage } from '../features/visits/pages/VisitsPage';
import { AppLayout } from '../shared/layouts/AppLayout';
import { ErrorPage } from '../shared/pages/ErrorPage';
import { PrivateRoute } from './PrivateRoute';
import { paths } from './paths';
import { MessagesPage } from '@/features/messages/pages/MessagesPage';

export const router = createBrowserRouter([
	{
		element: (
			<PrivateRoute>
				<AppLayout />
			</PrivateRoute>
		),
		errorElement: (
			<ErrorPage
				title="Page non trouvée"
				message="Une erreur est survenue, la page que vous recherchez est introuvable"
			/>
		),
		children: [
			{
				index: true,
				element: <Navigate to={paths.RESEARCH_PATH} replace={true} />,
			},
			{
				path: paths.ERROR_PATH,
				element: (
					<ErrorPage
						title="Une erreur serveur est survenue"
						message="Veuillez nous excuser pour la gêne occasionnée. Veuillez réessayer plus tard ou contacter le support technique"
					/>
				),
			},
			{
				path: paths.RESEARCH_PATH,
				children: [
					{
						index: true,
						element: <ResearchesPage />,
					},
					{
						path: ':researchId',
						element: (
							<ResearchProvider>
								<ParticipantProvider>
									<ResearchLayout />
								</ParticipantProvider>
							</ResearchProvider>
						),
						children: [
							{
								index: true,
								element: <Navigate to={paths.DASHBOARD_TAB} replace={true} />,
							},
							{
								path: paths.PARTICIPANTS_TAB,
								element: (
									<PrivateRoute
										allowedRoles={[
											'INVESTIGATOR',
											'ONSITE_RESEARCH_MEMBER',
											'OFFSITE_RESEARCH_MEMBER',
										]}
									>
										<ParticipantsTab />
									</PrivateRoute>
								),
							},
							{
								path: paths.RESEARCH_MEMBERS_TAB,
								element: (
									<PrivateRoute
										allowedRoles={['INVESTIGATOR', 'ONSITE_RESEARCH_MEMBER']}
									>
										<ResearchMembersTab />
									</PrivateRoute>
								),
							},
							{
								path: paths.DOCUMENTS_TAB,
								element: (
									<PrivateRoute
										allowedRoles={[
											'INVESTIGATOR',
											'ONSITE_RESEARCH_MEMBER',
											'OFFSITE_RESEARCH_MEMBER',
										]}
									>
										<DocumentsTab />
									</PrivateRoute>
								),
							},
							{
								path: paths.VISITS_TAB,
								element: (
									<PrivateRoute
										allowedRoles={['INVESTIGATOR', 'ONSITE_RESEARCH_MEMBER']}
									>
										<VisitsPage />
									</PrivateRoute>
								),
							},
							{
								path: paths.DASHBOARD_TAB,
								element: (
									<PrivateRoute
										allowedRoles={['INVESTIGATOR', 'ONSITE_RESEARCH_MEMBER']}
									>
										<DashboardPage />
									</PrivateRoute>
								),
							},
							{
								path: paths.CALENDAR_TAB,
								element: (
									<PrivateRoute
										allowedRoles={['INVESTIGATOR', 'ONSITE_RESEARCH_MEMBER']}
									>
										<CalendarPage />
									</PrivateRoute>
								),
							},
							{
								path: paths.MEETS_TAB,
								element: (
									<PrivateRoute
										allowedRoles={['INVESTIGATOR', 'ONSITE_RESEARCH_MEMBER']}
									>
										<MeetsTab />
									</PrivateRoute>
								),
							},
							{
								path: paths.MESSAGING_TAB,
								element: (
									<PrivateRoute
										allowedRoles={[
											'INVESTIGATOR',
											'ONSITE_RESEARCH_MEMBER',
											'OFFSITE_RESEARCH_MEMBER',
										]}
									>
										<MessagesPage />
									</PrivateRoute>
								),
							},
							{
								path: paths.NOTES_TAB,
								element: (
									<PrivateRoute
										allowedRoles={['INVESTIGATOR', 'ONSITE_RESEARCH_MEMBER']}
									>
										<NotesTab />
									</PrivateRoute>
								),
							},
							{
								path: paths.CONSENT_TAB,
								element: (
									<PrivateRoute
										allowedRoles={['INVESTIGATOR', 'ONSITE_RESEARCH_MEMBER']}
									>
										<ConsentTab />
									</PrivateRoute>
								),
							},
							{
								path: `${paths.PARTICIPANT_TAB}/:participantId`,
								children: [
									{
										index: true,
										element: <Navigate to={paths.VISITS_TAB} replace={true} />,
									},
									{
										path: paths.VISITS_TAB,
										element: <VisitsPage />,
									},
									{
										path: paths.DOCUMENTS_TAB,
										element: <DocumentsTab />,
									},
									{
										path: paths.MEETS_TAB,
										element: <MeetsTab />,
									},
									{
										path: paths.MESSAGING_TAB,
										element: <MessagesPage />,
									},
									{
										path: paths.NOTES_TAB,
										element: <NotesTab />,
									},
									{
										path: paths.CONSENT_TAB,
										element: <ConsentTab />,
									},
									{
										path: paths.CONTACTS_TAB,
										element: <ContactsTab />,
									},
								],
							},
						],
					},
				],
			},
			{
				path: paths.HELP_PATH,
				element: <HelpPage />,
			},
			{
				path: paths.SETTINGS_PATH,
				element: <SettingsPage />,
			},
		],
	},
	{
		element: <AuthLayout />,
		children: [
			{
				path: paths.SIGNIN_PATH,
				children: [
					{
						index: true,
						element: <SignInPage />,
					},
					{
						path: paths.VERIFY_SIGNIN_PATH,
						element: <SignInOtpVerificationPage />,
					},
					{
						path: paths.FORGOT_PASSWORD_PATH,
						children: [
							{
								index: true,
								element: <RequestPasswordResetPage />,
							},
							{
								path: paths.VERIFY_EMAIL_PATH,
								element: <VerifyEmailPage />,
							},
							{
								path: `${paths.RESET_PASSWORD_PATH}/:key`,
								element: <ResetPasswordPage />,
							},
							{
								path: paths.RESET_PASSWORD_SUCCESS_PATH,
								element: <SuccessPasswordReset />,
							},
						],
					},
				],
			},
			{
				path: `${paths.INVESTIGATOR_JOIN_RESEARCH_PATH}/:key`,
				element: <InvestigatorJoinResearchPage />,
			},
			{
				path: `${paths.ONSITE_RESEARCH_MEMBER_JOIN_RESEARCH_PATH}/:key`,
				element: <OnsiteResearchMemberJoinResearchPage />,
			},
			{
				path: `${paths.OFFSITE_RESEARCH_MEMBER_JOIN_RESEARCH_PATH}/:key`,
				element: <OffsiteResearchMemberJoinResearchPage />,
			},
			{
				path: `${paths.PARTICIPANT_JOIN_RESEARCH_PATH}/:key`,
				element: <ParticipantJoinResearchPage />,
			},
			{
				path: paths.INVESTIGATOR_SIGNUP_PATH,
				element: <InvestigatorSignUpPage />,
			},
			{
				path: paths.ONSITE_RESEARCH_MEMBER_SIGNUP_PATH,
				element: <OnsiteResearchMemberSignUpPage />,
			},
			{
				path: paths.OFFSITE_RESEARCH_MEMBER_SIGNUP_PATH,
				element: <OffsiteResearchMemberSignUpPage />,
			},
			{
				path: paths.PARTICIPANT_SIGNUP_PATH,
				element: <ParticipantSignUpPage />,
			},
		],
	},
]);
