import { useIsMobile } from '@/components/hooks/useIsMobile.ts';
import { Button } from '@/components/ui/button.tsx';
import MyField from '@/components/ui/form/form-field.tsx';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { useCurrentResearch } from '../../../../researches/contexts/researches.context.tsx';
import { useAddParticipants } from '../../../api/hooks/participants.hooks.tsx';
import { UserOnResearch } from '../../../api/types/users-on-research.types.ts';
import {
	AddParticipantZodSchema,
	AddParticipantZodType,
} from './AddParticipantZodSchema.ts';

type AddCaregiverFormProps = {
	participant: UserOnResearch;
	handleClose: () => void;
};

export function AddCaregiverForm({
	handleClose,
	participant,
}: AddCaregiverFormProps) {
	const { research } = useCurrentResearch();
	const isMobile = useIsMobile();
	const methods = useForm<AddParticipantZodType>({
		resolver: zodResolver(AddParticipantZodSchema),
	});
	const {
		handleSubmit,
		formState: { errors },
	} = methods;
	const { addParticipantsMutation, isLoading } =
		useAddParticipants(handleClose);

	const submitForm = async (data: AddParticipantZodType) => {
		const caregiver = {
			...data,
			researchId: research.id,
			caresForParticipantId: participant.userId,
		};
		await addParticipantsMutation([caregiver]);
	};

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(submitForm)}>
				<div className="flex flex-col gap-6">
					<div className="flex flex-col gap-4">
						<MyField
							type="email"
							name="email"
							label="Email"
							placeholder="Email"
							error={errors.email?.message}
							labelClass="hidden"
							inputClass="text-black"
							additionalClass="space-y-0"
						/>
						<MyField
							type="text"
							name="code"
							label="Identifiant pour la recherche"
							placeholder="Identifiant pour la recherche"
							error={errors.code?.message}
							labelClass="hidden"
							inputClass="text-black"
							additionalClass="space-y-0"
						/>
					</div>
					<div className="flex gap-4">
						{!isMobile && (
							<Button
								onClick={handleClose}
								variant="outline"
								className="w-full"
							>
								Annuler
							</Button>
						)}
						<Button type="submit" disabled={isLoading} className="w-full">
							Associer
						</Button>
					</div>
				</div>
			</form>
		</FormProvider>
	);
}
