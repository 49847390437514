import { formatDate } from '../../../shared/utils/dates';
import {
	LegalRepresentativeRoleEnum,
	ParticipantProfile,
} from '../../profile/api/types/participants.types';
import { ParticipantOnResearch } from '../api/types/participants.types';
import {
	UserOnResearch,
	UserOnResearchStatusEnum,
} from '../api/types/users-on-research.types';

export const formatParticipantData = (
	participant: UserOnResearch & {
		participantOnResearch?: ParticipantOnResearch;
		participantProfile?: ParticipantProfile;
	},
) => {
	if (!participant.participantOnResearch) return null;

	const legalRepresentatives =
		participant.participantProfile?.legalRepresentatives;

	return {
		Identifiant: participant.participantOnResearch.code,
		Email: participant.email,
		Nom: participant.lastName ?? '',
		Prénom: participant.firstName ?? '',
		Statut: UserOnResearchStatusEnum[participant.status],
		"Date d'ajout": formatDate(participant.createdAt, 'DD/MM/YYYY'),
		'Date de désactivation':
			participant.status === 'INACTIVE' && participant.deactivatedAt
				? formatDate(participant.deactivatedAt, 'DD/MM/YYYY')
				: '',
		'Personne vulnérable': participant.participantProfile?.isVulnerable
			? 'Oui'
			: 'Non',
		"Personne dans l'incapacité physique de signer": participant
			.participantProfile?.isUnableToConsent
			? 'Oui'
			: 'Non',
		'Aidant de':
			(participant.participantOnResearch.caresFor &&
				participant.participantOnResearch.caresFor.participantOnResearch
					.code) ??
			'',
		'Aidé par':
			(participant.participantOnResearch.caredForBy &&
				participant.participantOnResearch.caredForBy.participantOnResearch
					.code) ??
			'',
		'Numéro de téléphone': participant.phoneNumber ?? '',
		'Date de naissance':
			(participant.participantProfile?.birthDate &&
				formatDate(participant.participantProfile.birthDate, 'DD/MM/YYYY')) ??
			'',
		Adresse: participant.participantProfile?.address,
		'Code postal': participant.participantProfile?.zipCode,
		Ville: participant.participantProfile?.city,
		'Représentant légal 1': legalRepresentatives?.[0]
			? LegalRepresentativeRoleEnum[legalRepresentatives[0].role]
			: '',
		'Prénom du représentant légal 1':
			legalRepresentatives?.[0]?.firstName ?? '',
		'Nom du représentant légal 1': legalRepresentatives?.[0]?.lastName ?? '',
		'Email du représentant légal 1': legalRepresentatives?.[0]?.email,
		'Téléphone du représentant légal 1': legalRepresentatives?.[0]?.phoneNumber,
		'Adresse du représentant légal 1': legalRepresentatives?.[0]?.address,
		'Code postal du représentant légal 1': legalRepresentatives?.[0]?.zipCode,
		'Ville du représentant légal 1': legalRepresentatives?.[0]?.city,
		'Représentant légal 2': legalRepresentatives?.[1]
			? LegalRepresentativeRoleEnum[legalRepresentatives[1].role]
			: '',
		'Prénom du représentant légal 2':
			legalRepresentatives?.[1]?.firstName ?? '',
		'Nom du représentant légal 2': legalRepresentatives?.[1]?.lastName ?? '',
		'Email du représentant légal 2': legalRepresentatives?.[1]?.email,
		'Téléphone du représentant légal 2': legalRepresentatives?.[1]?.phoneNumber,
		'Adresse du représentant légal 2': legalRepresentatives?.[1]?.address,
		'Code postal du représentant légal 2': legalRepresentatives?.[1]?.zipCode,
		'Ville du représentant légal 2': legalRepresentatives?.[1]?.city,
	};
};
