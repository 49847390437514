import { Box, Stack, Table, Text, Title } from '@mantine/core';
import { useMemo } from 'react';
import { CustomLoader } from '../../../../../../components/ui/loader';
import { useGetDynamicTableData } from '../../api/dynamic-tables.hooks';
import {
	DynamicTableColumn,
	DynamicTableRow,
} from '../../api/dynamic-tables.types';
import { default as classes } from '../../styles/DynamicTable.module.scss';
import { getHeaderRow } from '../../utils/exportDynamicTableData';

type DynamicTableResultProps = {
	actionId: number;
	userId: number;
};

export function DynamicTableResult({
	actionId,
	userId,
}: DynamicTableResultProps) {
	const { dynamicTable, isLoading, error } = useGetDynamicTableData(
		actionId,
		userId,
	);

	const formattedTable = useMemo(() => {
		if (!dynamicTable) return null;
		const bodyRows = dynamicTable.rows.map((row: DynamicTableRow) => (
			<Table.Tr key={row.id}>
				<Table.Th>{row.title}</Table.Th>
				{dynamicTable.columns.map((columns: DynamicTableColumn) => {
					const cell = dynamicTable.cells?.find(
						(cell) => cell.columnId === columns.id && cell.rowId === row.id,
					);
					return (
						<Table.Td key={columns.id}>
							<Text size="sm">{cell?.value}</Text>
						</Table.Td>
					);
				})}
			</Table.Tr>
		));

		return (
			<Box
				mah="50vh"
				style={{
					overflow: 'auto',
				}}
			>
				<Table mb="lg" classNames={classes}>
					<Table.Thead>
						<Table.Tr>{getHeaderRow(dynamicTable.columns)}</Table.Tr>
					</Table.Thead>
					<Table.Tbody>{bodyRows}</Table.Tbody>
				</Table>
			</Box>
		);
	}, [dynamicTable]);

	if (error)
		return (
			<Text fs="italic" c="red" ta="center">
				Une erreur est survenue lors du chargement des données
			</Text>
		);
	if (isLoading || !dynamicTable) return <CustomLoader />;

	return (
		<>
			<Stack gap="lg" w="100%">
				<Title order={3} ta="center">
					{dynamicTable.title}
				</Title>
				<Text
					ta="center"
					fw={500}
					truncate
					style={{
						whiteSpace: 'pre-line',
					}}
				>
					{dynamicTable.description}
				</Text>
				{formattedTable}
			</Stack>
		</>
	);
}
