import { MeetStatusType } from './meets.types';

export const meetsQueryKeys = {
	all: ['meets'] as const,
	lists: () => [...meetsQueryKeys.all, 'list'] as const,
	listAllByUser: (userId: number, params?: { status: MeetStatusType }) =>
		[...meetsQueryKeys.lists(), 'user', userId, params] as const,
	listByResearch: (researchId?: number) => [
		...meetsQueryKeys.lists(),
		'research',
		researchId,
	],
	listByUser: (
		researchId: number,
		userId: number,
		params?: { status: MeetStatusType },
	) => [...meetsQueryKeys.listByResearch(researchId), 'user', userId, params],
};
