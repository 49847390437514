import { AxiosError } from 'axios';
import { privateAxios } from '../../../../shared/services/privateAxios';
import { PreSignedPutUrl } from '../../../../shared/types/PreSignedPutUrl';
import { ActionResult, ActionResultExportData } from './actions.types';

export const getVisitsResults = async (researchId: number) => {
	try {
		const { data } = await privateAxios.get<ActionResultExportData[]>(
			`visits/research/${researchId}/results`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getActionResults = async (actionId: number) => {
	try {
		const response = await privateAxios.get<ActionResult[]>(
			`visits/action/${actionId}`,
		);
		return response.data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const downloadActionDocument = async (
	actionId: number,
	documentId: number,
) => {
	try {
		const { data } = await privateAxios.get<PreSignedPutUrl>(
			`visits/action/${actionId}/get-action-document-download-url/${documentId}`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const downloadActionDocuments = async (actionId: number) => {
	try {
		const { data } = await privateAxios.get<Blob>(
			`visits/action/${actionId}/get-action-documents-zip`,
			{ responseType: 'blob' },
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
