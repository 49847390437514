import { MIME_TYPES } from '@mantine/dropzone';
import { download, generateCsv, mkConfig } from 'export-to-csv';

export type MIME_TYPE = (typeof MIME_TYPES)[keyof typeof MIME_TYPES];
export const ALLOWED_MIME_TYPES = [
	MIME_TYPES.csv,
	MIME_TYPES.doc,
	MIME_TYPES.docx,
	MIME_TYPES.jpeg,
	MIME_TYPES.mp4,
	MIME_TYPES.pdf,
	MIME_TYPES.png,
	MIME_TYPES.ppt,
	MIME_TYPES.pptx,
	MIME_TYPES.xls,
	MIME_TYPES.xlsx,
];
const MEGA_BYTE = 1024 ** 2;
export const MAX_FILE_SIZE = 200 * MEGA_BYTE;

const csvConfig = mkConfig({
	fieldSeparator: ',',
	decimalSeparator: '.',
	useKeysAsHeaders: true,
});

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const handleExportData = (data: any, filename: string) => {
	const csv = generateCsv(csvConfig)(data);
	download({ ...csvConfig, filename })(csv);
};
