import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import classes from '@/components/ui/form/segmented-control.module.scss';
import { cn } from '@/components/utils';
import { SegmentedControl } from '@mantine/core';
import { IconFileExport } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { CustomLoader } from '../../../components/ui/loader';
import { paths } from '../../../routes/paths';
import { useCurrentResearch } from '../../researches/contexts/researches.context';
import { useCurrentParticipant } from '../../usersOnResearch/contexts/participants.context';
import { useExportVisitsData } from '../api/hooks/useExportVisitsData';
import { getParticipantVisits, getVisits } from '../api/visits.queries';
import { visitsQueryKeys } from '../api/visits.query-keys';
import { ParticipantVisit, Visit, VisitCategory } from '../api/visits.types';
import { VisitAccordion } from '../components/atoms/VisitAccordion';

export function VisitsPage() {
	const isMobile = useIsMobile();
	const { research } = useCurrentResearch();
	const { participant } = useCurrentParticipant();
	const [visitCategory, setVisitCategory] =
		useState<VisitCategory>('Participants');

	const { queryKey, queryFn } = participant
		? {
				queryKey: visitsQueryKeys.resultsByParticipant(
					research.id,
					participant.userId,
				),
				queryFn: () => getParticipantVisits(research.id, participant.userId),
			}
		: {
				queryKey: visitsQueryKeys.byResearch(research.id),
				queryFn: () => getVisits(research.id),
			};

	const {
		data: visits,
		isLoading,
		error,
	} = useQuery<Visit[] | ParticipantVisit[]>({
		queryKey,
		queryFn,
	});

	const filteredVisits = useMemo(() => {
		if (!visits) return [];
		if (participant) return visits;
		return visits.filter((visit) =>
			visitCategory === 'Participants'
				? !visit.isForCaregiver
				: visit.isForCaregiver,
		);
	}, [visits, visitCategory, participant]);

	const canExportVisitsStats = !!visits && visits.length > 0;
	const exportTitle = `Résultats des visites - ${
		research.name
	} - ${new Date().toLocaleDateString()}`;
	const { exportResultsMutation } = useExportVisitsData(exportTitle);

	if (isLoading || !visits) return <CustomLoader />;
	if (error) return <Navigate to={`/${paths.ERROR_PATH}`} />;

	return (
		<>
			<div className="flex h-full w-full flex-col gap-4">
				<div className="flex flex-col justify-center gap-2 md:flex-row md:justify-between">
					<h4 className="my-auto">Visites</h4>
					{!participant && (
						<div className="flex flex-col justify-start gap-2 md:flex-row md:justify-between md:gap-4">
							<SegmentedControl
								data={['Participants', 'Aidants']}
								value={visitCategory}
								onChange={(value: string) =>
									setVisitCategory(value as VisitCategory)
								}
								withItemsBorders={false}
								classNames={classes}
							/>
							<Button
								size={isMobile ? 'xs' : 'sm'}
								className="w-fit"
								onClick={() => exportResultsMutation.mutate()}
								disabled={!canExportVisitsStats}
								variant="outline"
							>
								<div className="flex flex-row items-center gap-1">
									{
										<IconFileExport
											className={cn(isMobile ? 'h-4 w-4' : 'h-5 w-5')}
										/>
									}
									<p>Exporter</p>
								</div>
							</Button>
						</div>
					)}
				</div>

				{filteredVisits.length > 0 ? (
					<VisitAccordion visits={filteredVisits} />
				) : (
					<p className="mt-6 flex w-full flex-1 text-sm text-muted-foreground">
						Aucune visite pour le moment
					</p>
				)}
			</div>
		</>
	);
}
