import { z } from 'zod';
import { Question } from '../../../api/questionnaires.types';
import { MultipleSelectQuestionZodSchema } from './MultipleSelectQuestionValidator';
import { RangeQuestionZodSchema } from './RangeQuestionValidator';
import { SingleSelectQuestionZodSchema } from './SingleSelectQuestionValidator';
import { TextQuestionZodSchema } from './TextQuestionValidator';

export const createQuestionnaireValidator = (questions: Question[]) => {
	const questionValidators: { [key: string]: z.ZodTypeAny } = {};

	questions?.forEach((question) => {
		let questionValidator;

		switch (question.type) {
			case 'TEXT':
				questionValidator = TextQuestionZodSchema(question.required);
				break;
			case 'RANGE':
				questionValidator = RangeQuestionZodSchema(question.maxRange ?? 5);
				break;
			case 'SINGLE_CHOICE':
				questionValidator = SingleSelectQuestionZodSchema(question.options);
				break;
			case 'MULTIPLE_CHOICE':
				questionValidator = MultipleSelectQuestionZodSchema(question.options);
				break;
			default:
				questionValidator = z.any();
		}

		if (!question.required) questionValidator = questionValidator.optional();
		questionValidators[question.id.toString()] = questionValidator;
	});

	return z.object(questionValidators);
};
