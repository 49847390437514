import { Button } from '@/components/ui/button';
import { zodResolver } from '@hookform/resolvers/zod';
import { IconArrowLeft } from '@tabler/icons-react';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { isUnder18 } from '../../../../shared/utils/dates';
import { ParticipantSignUpDto } from '../api/participants.dto';
import { useParticipantSignup } from '../api/participants.hooks';
import { ParticipantSignUpFormStep1 } from '../components/forms/ParticipantSignUpFormStep1';
import { UnableToConsentParticipantFormStep2 } from '../components/forms/UnableToConsentParticipantFormStep2';
import { UnderageParticipantFromStep2 } from '../components/forms/UnderageParticipantFormStep2';
import { VulnerableParticipantFormStep2 } from '../components/forms/VulnerableParticipantFormStep2';
import {
	ParticipantSignUpZodSchema,
	ParticipantSignUpZodType,
} from '../components/forms/validators/ParticipantSignUpZodSchema';

export function ParticipantSignUpPage() {
	const location = useLocation();
	const key = location.state?.key ?? '';
	const email = location.state?.email ?? '';

	const [isUnderage, setIsUnderage] = useState(false);

	const formMethods = useForm<ParticipantSignUpZodType>({
		resolver: zodResolver(ParticipantSignUpZodSchema),
		defaultValues: { email },
	});

	const birthDate = formMethods.watch('birthDate');

	const [signUpStep, setSignUpStep] = useState<1 | 2>(1);
	const isParticipantUnderage = useCallback(() => {
		return birthDate && isUnder18(birthDate);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [birthDate]);

	const handleOnSubmitButtonClick = async () => {
		if (signUpStep === 2) formMethods.handleSubmit(handleFormSubmit)();
		const isStep1Valid = await formMethods.trigger([
			'firstName',
			'lastName',
			'email',
			'phoneNumber',
			'birthDate',
			'address',
			'zipCode',
			'city',
			'password',
			'confirmPassword',
		]);
		if (isStep1Valid) {
			setIsUnderage(isParticipantUnderage());
			setSignUpStep(2);
		}
	};
	const handleOnGoBackButtonClick = () => {
		formMethods.clearErrors();
		setSignUpStep(1);
	};

	const { signUpMutation } = useParticipantSignup(key);
	const handleFormSubmit = (data: ParticipantSignUpZodType) =>
		signUpMutation.mutate(data as ParticipantSignUpDto);

	useEffect(() => {
		if (isUnderage !== isParticipantUnderage()) {
			formMethods.resetField('legalRepresentatives');
			formMethods.resetField('isVulnerable');
			formMethods.resetField('isUnableToConsent');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUnderage, birthDate]);

	const submitButtonLabel =
		signUpStep === 1 ? 'Suivant' : "Finaliser l'inscription";

	return (
		<div className="flex h-full w-full max-w-3xl flex-col gap-12 overflow-y-auto">
			<div className="flex flex-col gap-3">
				<h1 className="text-center">Inscrivez-vous</h1>
				<p className="text-center text-md font-normal text-muted-foreground">
					Entrez vos informations pour créer votre espace et rejoindre une
					recherche.
				</p>
				{signUpStep === 2 && (
					<Button
						variant="link"
						size={'sm'}
						onClick={handleOnGoBackButtonClick}
						className="flex justify-center"
					>
						<div className="flex space-x-2">
							<IconArrowLeft />
							<p>Retour aux informations du participant</p>
						</div>
					</Button>
				)}
			</div>

			<FormProvider {...formMethods}>
				<form className="flex w-full flex-col gap-12">
					{signUpStep === 1 && <ParticipantSignUpFormStep1 email={email} />}
					{signUpStep === 2 &&
						(isParticipantUnderage() ? (
							<UnderageParticipantFromStep2 />
						) : (
							<>
								<VulnerableParticipantFormStep2 />
								<UnableToConsentParticipantFormStep2 />
							</>
						))}
					<Button
						type="submit"
						onClick={(e) => {
							e.preventDefault();
							handleOnSubmitButtonClick();
						}}
						disabled={signUpMutation.isPending}
					>
						{submitButtonLabel}
					</Button>
				</form>
			</FormProvider>
		</div>
	);
}
