import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreateNoteDto } from '../notes.dto';
import { updateNote } from '../notes.mutations';
import { notesQueryKeys } from '../notes.query-keys';

export const useUpdateNote = (noteId: number) => {
	const queryClient = useQueryClient();

	const showSuccessNotification = () => {
		notifications.show({
			message: 'Modification enregistrée',
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const updateNoteMutation = useMutation({
		mutationFn: async (data: CreateNoteDto) => updateNote(noteId, data),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: notesQueryKeys.detail(noteId),
			});
			showSuccessNotification();
		},
		onError: showErrorNotification,
	});

	return { updateNoteMutation };
};
