import { useDisclosure } from '@mantine/hooks';
import { IconEraser, IconFolderDown, IconPencil } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useSession } from '../../../../auth/shared/hooks/useSession';
import { useCurrentResearch } from '../../../../researches/contexts/researches.context';
import { useCurrentParticipant } from '../../../../usersOnResearch/contexts/participants.context';
import { getActionPermissions } from '../../../utils/actionPermissions';
import {
	Action,
	ActionWithResult,
	isActionWithResult,
} from '../../api/actions.types';
import { ActionButton } from '../../components/atoms/ActionButton';
import { ActionHeader } from '../../components/atoms/ActionHeader';
import { ActionResults } from '../../components/atoms/ActionResults';
import { ConfirmResetResultModal } from '../../components/modals/ConfirmResetResultModal';
import { useDownloadActionResultDocument } from '../api/hooks/useDownloadActionResultDocument';
import { useDownloadActionResultDocuments } from '../api/hooks/useDownloadActionResultDocumentsAsZip';
import { UploadActionDocumentsModal } from './UploadActionDocumentsModal';

type UploadActionItemProps = {
	action: Action | ActionWithResult;
};
export const UploadActionItem = ({ action }: UploadActionItemProps) => {
	const { participant } = useCurrentParticipant();
	const { user } = useSession();
	const { userOnResearch } = useCurrentResearch();

	const [
		uploadModalOpened,
		{ open: uploadModalOpen, close: uploadModalClose },
	] = useDisclosure();
	const [resetModalOpened, { open: resetModalOpen, close: resetModalClose }] =
		useDisclosure();

	const { downloadDocumentMutation } = useDownloadActionResultDocument(
		action.id,
	);
	const { downloadDocumentsMutation } = useDownloadActionResultDocuments(
		action.id,
	);

	const permissions = getActionPermissions(action as Action, user?.role);

	const actionButtonProps = useMemo(() => {
		if (userOnResearch.status === 'INACTIVE') return null;
		if (!participant && !isActionWithResult(action))
			return {
				label: 'Télécharger les résultats',
				icon: <IconFolderDown />,
				disabled: action.resultsCount === 0,
				handleClick: async () => {
					if (action.resultsCount === 0) return;
					downloadDocumentsMutation.mutate(true);
				},
			};
		if (isActionWithResult(action)) {
			const completed = action.result?.completed;
			if (completed && permissions.canRead)
				return {
					label: 'Télécharger',
					icon: <IconFolderDown />,
					handleClick: () => {
						if (action.result?.documentsCount === 0) return;
						if (action.result?.documentsCount === 1)
							downloadDocumentMutation.mutate();
						else downloadDocumentsMutation.mutate(false);
					},
				};
			else if (!completed && permissions.canWrite)
				return {
					label: 'Déposer',
					icon: <IconPencil />,
					handleClick: uploadModalOpen,
				};
		}

		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [participant, user, action]);

	const shouldDisplayResetButton =
		isActionWithResult(action) &&
		action.result?.completed &&
		permissions.canWrite;

	const resetButtonProps = {
		label: 'Réinitialiser',
		icon: <IconEraser />,
		color: 'red',
		handleClick: resetModalOpen,
	};

	return (
		<>
			<div className="mt-3 grid grid-cols-8 gap-2">
				<div className="col-span-8 my-auto flex flex-col gap-1 md:col-span-4">
					<ActionHeader action={action} />
				</div>
				<div className="my-auto hidden flex-col gap-1 md:col-span-2 md:block md:pl-8">
					{!participant && !isActionWithResult(action) && (
						<ActionResults action={action} />
					)}
				</div>
				<div className="my-auto hidden flex-col gap-1 md:col-span-2 md:block">
					<div className="flex items-center justify-center gap-2">
						{actionButtonProps && <ActionButton {...actionButtonProps} />}
						{shouldDisplayResetButton && <ActionButton {...resetButtonProps} />}
					</div>
				</div>
			</div>
			{uploadModalOpened && (
				<UploadActionDocumentsModal
					handleClose={uploadModalClose}
					actionId={action.id ?? -1}
				/>
			)}
			{resetModalOpened && (
				<ConfirmResetResultModal
					handleClose={resetModalClose}
					actionId={action.id}
					actionType={action.actionType}
				/>
			)}
		</>
	);
};
