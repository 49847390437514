import { z } from 'zod';
import {
	addressValidator,
	birthDateValidator,
	cityValidator,
	emailValidator,
	firstNameValidator,
	lastNameValidator,
	passwordValidator,
	phoneNumberValidator,
	zipCodeValidator,
} from '../../../../../../shared/components/validators/commonValidators';
import { isUnder18 } from '../../../../../../shared/utils/dates';
import { LegalRepresentativeZodSchema } from './LegalRepresentativeZodSchema';

export const ParticipantSignUpZodSchema = z
	.object({
		firstName: firstNameValidator,
		lastName: lastNameValidator,
		email: emailValidator,
		password: passwordValidator,
		phoneNumber: phoneNumberValidator,
		birthDate: birthDateValidator,
		address: addressValidator,
		zipCode: zipCodeValidator,
		city: cityValidator,
		confirmPassword: passwordValidator,
		isVulnerable: z.boolean().default(false),
		isUnableToConsent: z.boolean().default(false),
		legalRepresentatives: z.array(LegalRepresentativeZodSchema),
	})
	.refine((data) => data.password === data.confirmPassword, {
		path: ['confirmPassword'],
		message: 'Les mots de passe sont différents',
	})
	.refine(
		(data) => {
			if (!data.birthDate) return false;
			if (!isUnder18(data.birthDate)) return true;
			return (
				data.legalRepresentatives &&
				data.legalRepresentatives?.length > 0 &&
				data.legalRepresentatives?.find((lr) => lr && lr.role === 'PARENT1') !==
					undefined
			);
		},
		{
			path: ['legalRepresentatives'],
			message:
				"Veuillez renseigner les informations d'un parent pour le participant mineur",
		},
	)
	.refine(
		(data) => {
			if (!data.isVulnerable) return true;
			return (
				data.legalRepresentatives &&
				data.legalRepresentatives?.length > 0 &&
				data.legalRepresentatives?.find(
					(lr) =>
						lr &&
						[
							'LEGAL_REPRESENTATIVE',
							'CUSTODIAN',
							'GUARDIAN',
							'TRUSTED_PERSON',
							'FAMILY_MEMBER',
							'RELATIVE',
						].includes(lr.role),
				) !== undefined
			);
		},
		{
			path: ['isVulnerable'],
			message:
				"Veuillez renseigner les informations d'un représentant légal pour le participant vulnérable",
		},
	)
	.refine(
		(data) => {
			if (!data.isUnableToConsent) return true;
			return (
				data.legalRepresentatives &&
				data.legalRepresentatives?.length > 0 &&
				data.legalRepresentatives?.find((lr) => lr && lr.role === 'WITNESS') !==
					undefined
			);
		},
		{
			path: ['isUnableToConsent'],
			message:
				"Veuillez renseigner les informations d'un témoin impartial pour le participant dans l'incapacité physique de signer",
		},
	);

export type ParticipantSignUpZodType = z.infer<
	typeof ParticipantSignUpZodSchema
>;
