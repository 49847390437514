import { Loader } from '@mantine/core';
import { cn } from '../utils';

type CustomLoaderProps = {
	additionalClasses?: string;
};

export const CustomLoader = (props: CustomLoaderProps) => {
	return (
		<div
			className={cn(
				'flex h-full w-full items-center justify-center',
				props.additionalClasses,
			)}
		>
			<Loader size="xl" />
		</div>
	);
};
