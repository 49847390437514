import { Button } from '@/components/ui/button';
import { IconArrowLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../../routes/paths';

export function SuccessPasswordReset() {
	const navigate = useNavigate();

	return (
		<div className="flex max-w-xl flex-col justify-center gap-12">
			<div className="flex flex-col gap-3">
				<h1 className="text-center">Succès</h1>
				<p className="text-center text-md font-normal text-muted-foreground">
					Votre mot de passe a bien été réinitalisé. Vous pouvez désormais vous
					connecter.
				</p>
			</div>

			<Button
				variant="link"
				size={'sm'}
				onClick={() => navigate(paths.SIGNIN_PATH)}
				className="flex justify-center"
			>
				<div className="flex space-x-2">
					<IconArrowLeft />
					<p>Retour à la connexion</p>
				</div>
			</Button>
		</div>
	);
}
