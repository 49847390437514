import { useQuery } from '@tanstack/react-query';
import { useSession } from '../../../auth/shared/hooks/useSession';
import { getMyMeets } from '../meets.queries';
import { meetsQueryKeys } from '../meets.query-keys';
import { MeetWithParticipants } from '../meets.types';

export const useGetUpcomingMeets = () => {
	const { user } = useSession();
	const userId = user?.userId ?? 0;

	const {
		data: meets,
		isLoading,
		error,
	} = useQuery<MeetWithParticipants[]>({
		queryKey: meetsQueryKeys.listAllByUser(userId, {
			status: 'UPCOMING',
		}),
		queryFn: () => getMyMeets('UPCOMING'),
	});

	return { meets, isLoading, error };
};
