import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import { cn } from '@/components/utils';
import { Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconDownload, IconTrash } from '@tabler/icons-react';
import dayjs from 'dayjs';
import {
	MRT_Cell,
	MRT_ColumnDef,
	MantineReactTable,
	useMantineReactTable,
} from 'mantine-react-table';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDefaultMRTOptions } from '../../../../components/ui/table/table-options';
import { paths } from '../../../../routes/paths';
import {
	customDateFilterFn,
	customResearchMemberFilterFn,
	isMatch,
} from '../../../../shared/utils/filters';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';
import {
	Consent,
	ConsentStatus,
	ConsentStatusEnum,
} from '../../api/consents.entities';
import { useGetConsents, useReactivateConsent } from '../../api/consents.hooks';
import {
	useDownloadConsent,
	useDownloadConsentsAsZip,
} from '../../hooks/useDownloadConsent';
import { ConsentStatusBadge } from '../atoms/ConsentStatusBadge';
import { DeleteConsentModal } from '../modals/DeleteConsentModal';

export function ConsentTable() {
	const { userOnResearch } = useCurrentResearch();
	const { research } = useCurrentResearch();
	const { participant } = useCurrentParticipant();
	const navigate = useNavigate();

	const [currentConsentId, setCurrentConsentId] = useState<number>();

	const { data, isLoading, error } = useGetConsents(
		research.id,
		participant?.userId,
	);

	const isMobile = useIsMobile();

	const downloadConsentMutation = useDownloadConsent();
	const downloadConsentsMutation = useDownloadConsentsAsZip();
	const reactivateConsentMutation = useReactivateConsent();

	const [
		deleteModalOpened,
		{ open: openDeleteModal, close: closeDeleteModal },
	] = useDisclosure();
	const handleOnDeleteModalClick = (consentId: number) => {
		setCurrentConsentId(consentId);
		openDeleteModal();
	};

	const getSelectedConsentIds = () => {
		const selectedConsentIds = table
			.getSelectedRowModel()
			.rows.map((row) => row.original.consentId);
		return selectedConsentIds;
	};
	const getConsentAction = (consent: Consent) => {
		if (
			!['INVESTIGATOR', 'ONSITE_RESEARCH_MEMBER'].includes(userOnResearch.role)
		)
			return;

		switch (consent.status) {
			case 'EXPIRED':
				if (consent.participant.status !== 'ACTIVE') return;
				return (
					<Button
						variant="link"
						size={'sm'}
						onClick={() => reactivateConsentMutation.mutate(consent.consentId)}
					>
						Réactiver
					</Button>
				);
			case 'DRAFT':
			case 'ONGOING':
			case 'DECLINED':
			case 'DONE':
				return (
					<IconTrash
						size={22}
						onClick={() => handleOnDeleteModalClick(consent.consentId)}
						className="text-destructive"
					/>
				);
			default:
				return null;
		}
	};

	const columns = useMemo<MRT_ColumnDef<Consent>[]>(() => {
		const columns: MRT_ColumnDef<Consent>[] = [
			{
				header: 'Consentement',
				id: 'name',
				accessorKey: 'fileName',
				size: 300,
				Cell: ({ cell }: { cell: MRT_Cell<Consent> }) => (
					<p className="word-break line-clamp-2 text-md font-semibold">
						{cell.getValue<string>()}
					</p>
				),
				mantineTableBodyCellProps: {
					style: {
						paddingLeft: 0,
					},
				},
				mantineTableHeadCellProps: {
					style: {
						paddingLeft: 0,
					},
				},
			},
			{
				header: 'Participant',
				id: 'participant',
				accessorKey: 'participant',
				size: 150,
				Cell: ({ cell }: { cell: MRT_Cell<Consent> }) => {
					const participantId = cell.row.original.participant.userId;
					return (
						<Button
							variant="link"
							size={'sm'}
							onClick={() =>
								navigate(
									`../${paths.PARTICIPANT_TAB}/${participantId}/${paths.CONSENT_TAB}`,
								)
							}
						>
							{cell.getValue<UserOnResearch>().participantOnResearch?.code}
						</Button>
					);
				},
				filterFn: (row, _id, filterValue) =>
					customResearchMemberFilterFn(row.original.participant, filterValue),
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
			},
			{
				header: 'Statut',
				id: 'status',
				accessorKey: 'status',
				size: 150,
				Cell: ({ cell }: { cell: MRT_Cell<Consent> }) => (
					<ConsentStatusBadge status={cell.getValue<ConsentStatus>()} />
				),
				filterFn: (row, _id, filterValue) =>
					isMatch(ConsentStatusEnum[row.original.status], filterValue),
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
			},
			{
				header: "Date d'envoi",
				id: 'createdAt',
				accessorKey: 'createdAt',
				size: 20,
				Cell: ({ cell }: { cell: MRT_Cell<Consent> }) => (
					<p className="text-center text-sm">
						{dayjs(cell.getValue<Date>()).format('DD/MM/YY')}
					</p>
				),
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
				filterFn: (row, _id, filterValue) =>
					customDateFilterFn(row.original.createdAt, filterValue),
			},
			{
				id: 'actions-download',
				header: '',
				accessorKey: 'consentId',
				columnDefType: 'display',
				size: 1,
				Cell: ({ cell }: { cell: MRT_Cell<Consent> }) => {
					return (
						<IconDownload
							className="text-primary"
							size={22}
							onClick={() =>
								downloadConsentMutation.mutate(cell.getValue<number>())
							}
						/>
					);
				},
				mantineTableBodyCellProps: {
					style: { padding: 0 },
					align: 'center',
				},
			},
			{
				id: 'actions-consent',
				header: '',
				accessorKey: 'id',
				columnDefType: 'display',
				size: 1,
				Cell: ({ cell }: { cell: MRT_Cell<Consent> }) =>
					getConsentAction(cell.row.original),
				mantineTableBodyCellProps: {
					align: 'center',
					style: {
						padding: 0,
					},
				},
			},
		];
		if (participant) columns.splice(1, 1);
		return columns;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [participant]);

	const defaultTableOptions = getDefaultMRTOptions<Consent>(Boolean(error));
	const table = useMantineReactTable({
		data: data?.consents ?? [],
		columns,
		...defaultTableOptions,
		state: { isLoading },
		enableRowSelection: true,
		enableSelectAll: true,
		positionToolbarAlertBanner: 'bottom',
		renderTopToolbarCustomActions: () => (
			<Group ml="auto" pr="sm">
				{getSelectedConsentIds().length > 0 && (
					<Button
						variant={'outline'}
						size={isMobile ? 'xs' : 'sm'}
						className="w-fit"
						onClick={() =>
							downloadConsentsMutation.mutate(getSelectedConsentIds())
						}
					>
						<div className="flex flex-row items-center gap-1">
							{
								<IconDownload
									className={cn(isMobile ? 'h-4 w-4' : 'h-5 w-5')}
								/>
							}
							<p>Télécharger la sélection</p>
						</div>
					</Button>
				)}
			</Group>
		),
	});

	return (
		<>
			<MantineReactTable table={table} />
			{deleteModalOpened && currentConsentId && (
				<DeleteConsentModal
					consentId={currentConsentId}
					handleClose={closeDeleteModal}
				/>
			)}
		</>
	);
}
