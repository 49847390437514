import { MyTabsWithScroll } from '@/components/ui/tabs/tabs-with-scroll';
import { paths } from '../../routes/paths';

export function OnsiteResearchMemberTabs() {
	const tabItems = [
		{
			link: paths.DASHBOARD_TAB,
			label: 'Dashboard',
		},
		{
			link: paths.CALENDAR_TAB,
			label: 'Agenda',
		},
		{
			link: paths.VISITS_TAB,
			label: 'Suivi des visites',
		},
		{
			link: paths.PARTICIPANTS_TAB,
			label: 'Participants',
		},
		{
			link: paths.RESEARCH_MEMBERS_TAB,
			label: 'Équipe de recherche',
		},
		{
			link: paths.MEETS_TAB,
			label: 'Visioconférences',
		},
		{
			link: paths.MESSAGING_TAB,
			label: 'Messagerie',
		},
		{
			link: paths.DOCUMENTS_TAB,
			label: 'Documents',
		},
		{
			link: paths.CONSENT_TAB,
			label: 'Consentements',
		},
		{
			link: paths.NOTES_TAB,
			label: 'Notes',
		},
	];

	return <MyTabsWithScroll tabItems={tabItems} />;
}
