import { S3File } from '../../types/S3File';
import { FileOption } from './FileOption';

type Props = {
	files: S3File[];
	onRemoveFile: (name: string) => void;
};

export default function FilesControlPanel({ files, onRemoveFile }: Props) {
	return (
		<div className="flex flex-col gap-2">
			{files.map((file) => (
				<FileOption
					key={file.file.name}
					file={file}
					onRemoveFile={onRemoveFile}
				/>
			))}
		</div>
	);
}
