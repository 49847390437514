import { Button } from '@/components/ui/button';
import { AddToCalendarCustomButton } from '@/features/calendar/components/atoms/AddToCalendarButton';
import { useCurrentResearch } from '@/features/researches/contexts/researches.context';
import { ResearchMemberCard } from '@/features/usersOnResearch/components/cards/ResearchMemberCard';
import { useCurrentParticipant } from '@/features/usersOnResearch/contexts/participants.context';
import { paths } from '@/routes/paths';
import { IconBuildingSkyscraper, IconHome } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { ParticipantVisit } from '../../api/visits.types';

type PreviewVisitScheduleProps = {
	schedule: ParticipantVisit;
};
export const PreviewVisitSchedule = ({
	schedule,
}: PreviewVisitScheduleProps) => {
	const { research, userOnResearch: user } = useCurrentResearch();
	const { participant: currenParticipant } = useCurrentParticipant();
	const navigate = useNavigate();

	const handleParticipantClick = () => {
		navigate(
			`/${paths.RESEARCH_PATH}/${research.id}/${paths.PARTICIPANT_TAB}/${schedule.participant?.userId}`,
		);
	};
	if (!schedule || !schedule.participant) return null;

	return (
		<div className="flex flex-col items-center gap-12">
			<div className="flex flex-col items-center gap-6">
				<div className="flex flex-col gap-1">
					{schedule.description && (
						<p className="text-center">{schedule.description}</p>
					)}
					<div className="mx-auto flex w-full flex-col items-center justify-center gap-1 align-middle md:flex-row md:gap-4">
						<p className="text-center text-md font-bold">
							Le{' '}
							{dayjs(schedule.startDate).format('dddd DD MMMM YYYY [de] HH:mm')}{' '}
							à {dayjs(schedule.endDate).format('HH:mm')}
						</p>
						<AddToCalendarCustomButton visit={schedule} />
					</div>
				</div>

				{user.role !== 'PARTICIPANT' && (
					<div className="flex flex-col items-center gap-0 align-middle">
						<div className="mx-auto">
							<ResearchMemberCard user={schedule.participant} />
						</div>
						{!currenParticipant && (
							<Button
								onClick={handleParticipantClick}
								variant="link"
								className="ml-3 mt-0"
							>
								Accéder à l'espace
							</Button>
						)}
					</div>
				)}
				<div className="flex flex-col gap-4">
					<div className="align-end mx-auto flex w-full flex-row items-end justify-center gap-2">
						{schedule.isRemote ? (
							<IconHome size={32} />
						) : (
							<IconBuildingSkyscraper size={32} />
						)}
						<p className="text-center text-lg font-semibold">
							{schedule.isRemote ? 'Visite à domicile' : 'Visite sur centre'}
						</p>
					</div>
					<div className="flex flex-col gap-0">
						<p className="text-center font-semibold">{schedule.address}</p>
						<p className="text-center font-semibold">
							{schedule.city}, {schedule.zipCode}
						</p>
						{schedule.addressDetails && (
							<p className="text-center">
								Complément d'adresse : {schedule.addressDetails}
							</p>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
