import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal, TextInput } from '@mantine/core';
import Color from '@tiptap/extension-color';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useEffect, useState } from 'react';
import { CustomLoader } from '../../../../components/ui/loader';
import { useGetNote } from '../../api/hooks/useGetNote';
import { useUpdateNote } from '../../api/hooks/useUpdateNote';
import { CreateNoteDto } from '../../api/notes.dto';
import { MAX_CONTENT_LENGTH, MAX_DESCRIPTION_LENGTH } from '../../constants';
import { CustomEditor } from '../inputs/CustomEditor';

export type UpdateNoteModalProps = {
	noteId: number;
	handleClose: () => void;
};

export const UpdateNoteModal = ({
	noteId,
	handleClose,
}: UpdateNoteModalProps) => {
	const isMobile = useIsMobile();
	const { note, isLoading, error } = useGetNote(noteId);

	const [title, setTitle] = useState('');
	const { updateNoteMutation } = useUpdateNote(noteId);
	const editor = useEditor({
		extensions: [
			StarterKit,
			Underline,
			TextAlign.configure({ types: ['heading', 'paragraph'] }),
			Placeholder.configure({
				placeholder: `${MAX_CONTENT_LENGTH} caractères maximum`,
			}),
			TextStyle,
			Color,
		],
	});

	useEffect(() => {
		if (note) {
			setTitle(note.title);
			editor?.commands.setContent(note.htmlContent);
		}
	}, [note, editor]);

	const handleSubmit = () => {
		const body: CreateNoteDto = {
			title,
			description: editor?.getText().slice(0, MAX_DESCRIPTION_LENGTH) ?? '',
			htmlContent: editor?.getHTML() ?? '',
		};
		updateNoteMutation.mutate(body);
		handleClose();
	};

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			fullScreen={isMobile}
			title="Modifier la note"
			size="xl"
			overlayProps={{
				backgroundOpacity: 0.7,
				blur: 2,
			}}
		>
			<div className="flex flex-col gap-6">
				{error && (
					<p className="text-error-00 text-center text-sm">
						Une erreur est survenue lors du chargement de la note
					</p>
				)}
				{isLoading && <CustomLoader />}

				{!error && note && (
					<div className="flex flex-col gap-6">
						<TextInput
							label="Titre"
							placeholder="Veuillez renseigner le titre"
							size="lg"
							withAsterisk
							styles={{
								input: {
									borderRadius: '10px',
								},
								label: {
									fontSize: '1.2rem',
									fontWeight: 600,
								},
							}}
							value={title}
							onChange={(e) => {
								setTitle(e.currentTarget.value);
							}}
						/>

						{editor && <CustomEditor editor={editor} />}

						{editor && editor?.getHTML().length > MAX_CONTENT_LENGTH && (
							<p className="text-error-00 text-center text-sm">
								Le contenu ne doit pas faire plus de {MAX_CONTENT_LENGTH}{' '}
								caractères (actuellement {editor?.getHTML().length} caractères)
							</p>
						)}

						<div className="flex gap-4">
							{!isMobile && (
								<Button
									onClick={handleClose}
									variant="outline"
									className="w-full"
								>
									Annuler
								</Button>
							)}
							<Button
								onClick={handleSubmit}
								disabled={
									!title.trim() ||
									!editor ||
									editor?.getHTML().length > MAX_CONTENT_LENGTH
										? true
										: false
								}
								className="w-full"
							>
								Créer
							</Button>
						</div>
					</div>
				)}
			</div>
		</Modal>
	);
};
