import { AxiosError } from 'axios';
import { publicAxios } from '../../../../shared/services/publicAxios';
import { LoginResponse } from '../../shared/types/AuthToken';
import { VerifySignInDto } from './dto/VerifySignInDto';

export const signInQuery = async (
	body: VerifySignInDto,
): Promise<LoginResponse> => {
	try {
		const response = await publicAxios.post<LoginResponse>(
			`/auth/login/verify-email`,
			body,
		);
		return response.data;
	} catch (error) {
		throw error as AxiosError;
	}
};
