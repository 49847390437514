import { Anchor } from '@mantine/core';
import { CalendarView } from './calendar.utils';

type MoreLinkProps = {
	num: number;
	view: CalendarView;
};

export const renderMoreLink = ({ num, view }: MoreLinkProps) => {
	if (view.type === 'dayGridMonth')
		return (
			<Anchor fw={900} size="sm">
				Voir plus ({num})
			</Anchor>
		);

	if (view.type === 'timeGridWeek')
		return (
			<Anchor fw={900} size="sm">
				+{num}
			</Anchor>
		);
};
