import { ActionIcon } from '@mantine/core';
import { IconEdit, IconSearch } from '@tabler/icons-react';
import { useCurrentResearch } from '../../../../researches/contexts/researches.context';

type MobileThreadListHeaderProps = {
	openSearchModal: () => void;
	openNewMessage: () => void;
};

export const MobileThreadListHeader = (props: MobileThreadListHeaderProps) => {
	const { userOnResearch: user } = useCurrentResearch();
	const canSendNewMessage = user.status === 'ACTIVE';

	return (
		<div className="flex w-full items-center justify-between">
			<h4>Messages</h4>
			<div className="flex gap-2">
				{canSendNewMessage && (
					<ActionIcon
						variant="outline"
						radius="md"
						size="lg"
						style={{
							border: '1px solid var(--mantine-primary-color-0)',
						}}
						onClick={props.openNewMessage}
					>
						<IconEdit size={20} />
					</ActionIcon>
				)}
				<ActionIcon
					variant="outline"
					radius="md"
					size="lg"
					style={{
						border: '1px solid var(--mantine-primary-color-0)',
					}}
					onClick={props.openSearchModal}
				>
					<IconSearch size={20} />
				</ActionIcon>
			</div>
		</div>
	);
};
