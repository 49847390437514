import { Role } from '../../profile/api/types/user.types';
import { ParticipantOnResearch } from '../api/types/participants.types';
import {
	InvestigatorOnResearch,
	OffsiteResearchMemberOnResearch,
	OnsiteResearchMemberOnResearch,
} from '../api/types/research-members.types';
import { UserOnResearch } from '../api/types/users-on-research.types';

export function validateRoles(userRole: Role, allowedRoles?: Role[]) {
	if (!allowedRoles || allowedRoles?.length === 0) return true;
	if (!userRole) return false;
	return allowedRoles.some((role) => role === userRole);
}

export const isInvestigator = (
	researchMember: UserOnResearch,
): researchMember is UserOnResearch & {
	investigatorOnResearch: InvestigatorOnResearch;
} => {
	return researchMember && researchMember.role === 'INVESTIGATOR';
};

export const isOnsiteResearchMember = (
	researchMember: UserOnResearch,
): researchMember is UserOnResearch & {
	onsiteResearchMemberOnResearch: OnsiteResearchMemberOnResearch;
} => {
	return researchMember && researchMember.role === 'ONSITE_RESEARCH_MEMBER';
};

export const isOffsiteResearchMember = (
	researchMember: UserOnResearch,
): researchMember is UserOnResearch & {
	offsiteResearchMemberOnResearch: OffsiteResearchMemberOnResearch;
} => {
	return researchMember && researchMember.role === 'OFFSITE_RESEARCH_MEMBER';
};

export const isParticipant = (
	researchMember: UserOnResearch,
): researchMember is UserOnResearch & {
	participantOnResearch: ParticipantOnResearch;
} => {
	return researchMember && researchMember.role === 'PARTICIPANT';
};

export const isCaregiver = (participant: UserOnResearch) =>
	isParticipant(participant) &&
	Boolean(participant.participantOnResearch.caresFor);

export const isCenterResearchMember = (researchMember: UserOnResearch) =>
	isInvestigator(researchMember) || isOnsiteResearchMember(researchMember);
