import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { CreateRatingDto } from './ratings.dto';
import { createRating } from './ratings.mutations';

export const useCreateRating = (settledCallback: () => void) => {
	const createRatingMutation = useMutation({
		mutationFn: (body: CreateRatingDto) => createRating(body),
		onSuccess: () => {
			notifications.show({
				title: 'Avis envoyé',
				message: 'Merci pour votre retour !',
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
		},
		onError: () => {
			notifications.show({
				message: "Une erreur est survenue lors de l'envoi de votre avis",
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		},
		onSettled: settledCallback,
	});
	return { createRatingMutation };
};
