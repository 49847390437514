import { z } from 'zod';
import { MAX_CONTENT_LENGTH } from '../../../constants';

export type CreateRatingZodType = z.infer<typeof CreateRatingZodSchema>;

export const CreateRatingZodSchema = z.object({
	rating: z
		.number()
		.min(0, { message: 'La note doit positive' })
		.max(5, { message: 'La note doit être inférieure à 5' }),
	title: z.string().min(1, { message: 'Le titre est requis' }),
	comment: z.optional(
		z.string().max(MAX_CONTENT_LENGTH, {
			message: `Le commentaire ne doit pas dépasser ${MAX_CONTENT_LENGTH} caractères`,
		}),
	),
});
