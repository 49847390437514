import { Checkbox, Divider, Group, Stack } from '@mantine/core';
import { Question, Response } from '../../api/questionnaires.types';

type MultipleSelectResponseCardProps = {
	question: Question;
	responses: Response[];
};

export function MultipleSelectResponseCard({
	question,
	responses,
}: MultipleSelectResponseCardProps) {
	const defaultValues = responses
		.map((response) => response.value)
		.filter((res) => {
			return question.options.some((option) => option.label === res);
		});
	return (
		<Stack gap="lg" w="90%">
			<Checkbox.Group
				label={question.label}
				description="Plusieurs réponses possibles"
				size="md"
				defaultValue={defaultValues}
				withAsterisk={question.required}
			>
				<Group mt="md" justify="start" gap="10%">
					{question.options.map((option) => (
						<Checkbox
							size="md"
							my="md"
							disabled
							key={option.id}
							value={option.label}
							label={option.label}
							styles={{
								label: { color: 'black' },
								icon: { color: 'var(--mantine-primary-color-6)' },
							}}
						/>
					))}
				</Group>
			</Checkbox.Group>
			<Divider />
		</Stack>
	);
}
