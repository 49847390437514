import { Skeleton } from '@mantine/core';
import { useGetUpcomingMeets } from '../../api/hooks/useGetUpcomingMeets';
import { NextMeetingItem } from './NextMeetingCard';

export function NextMeetingsCard() {
	const { meets, isLoading, error } = useGetUpcomingMeets();
	return (
		<div className="flex h-full w-3/5 flex-1 flex-col items-start gap-4">
			<h3>Prochaines visioconférences</h3>
			{error && (
				<p className="text-sm text-error-500">
					Erreur lors de la récupération des visioconférences.
				</p>
			)}
			{isLoading && (
				<div className="flex flex-col gap-4">
					<Skeleton height={50} mt={6} width="95%" radius="md" />
					<Skeleton height={50} mt={6} width="95%" radius="md" />
					<Skeleton height={50} mt={6} width="95%" radius="md" />
				</div>
			)}
			{meets &&
				(meets.length > 0 ? (
					<div className="flex w-full flex-col gap-4 overflow-y-scroll">
						{meets?.map((meet) => (
							<NextMeetingItem key={meet.id} meet={meet} />
						))}
					</div>
				) : (
					<p className="text-sm text-muted-foreground">
						Vous n'avez pas de visioconférence prévue dans les prochains jours.
					</p>
				))}
		</div>
	);
}
