import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { useState } from 'react';

import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';
import { CreateMeetForm } from '../forms/CreateMeetForm';
import { useIsMobile } from '@/components/hooks/useIsMobile';

export type CreateOrUpdateMeetType = {
	name: string;
	startDate: string;
	endDate: string;
	participants: number[];
};

type AddMeetModalProps = {
	handleClose: () => void;
};

export function AddMeetModal({ handleClose }: AddMeetModalProps) {
	const [participants, setParticipants] = useState<UserOnResearch[]>([]);
	const isMobile = useIsMobile();

	return (
		<Modal
			opened={true}
			onClose={() => {
				setParticipants([]);
				handleClose();
			}}
			centered
			classNames={classes}
			fullScreen={isMobile}
			title="Créer une visioconférence"
			size="xl"
		>
			<CreateMeetForm
				participants={participants}
				setParticipants={setParticipants}
				handleClose={handleClose}
			/>
		</Modal>
	);
}
