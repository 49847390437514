import classes from '@/components/ui/modal/modal.module.scss';
import { Avatar, Modal, Skeleton } from '@mantine/core';
import { IconUsers } from '@tabler/icons-react';
import { useMemo } from 'react';
import { getActionIcon } from '../../../utils/actionType';
import { Action } from '../../api/actions.types';
import { useGetActionResults } from '../../api/hooks/useGetActionResults';
import { ActionResultCard } from '../atoms/ActionResultCard';

export type ViewActionResultsModalProps = {
	action: Action;
	onClose: () => void;
};

export const ViewActionResultsModal = ({
	action,
	onClose: handleClose,
}: ViewActionResultsModalProps) => {
	const { actionWithResults, isLoading, error } = useGetActionResults(
		action.id,
	);

	const { icon, color } = useMemo(
		() => getActionIcon(action.actionType, 36),
		[action.actionType],
	);

	return (
		<Modal
			opened
			onClose={handleClose}
			centered
			classNames={classes}
			title="Réponses des participants"
			size="lg"
		>
			<div className="flex flex-col gap-6">
				<div className="flex flex-col items-center gap-1">
					<Avatar size={48} color={color} variant="filled">
						{icon}
					</Avatar>
					<h5 className="text-center">{action.name}</h5>
					<p className="text-center">{action.description}</p>
				</div>

				<div className="flex flex-col items-center gap-4">
					<div className="flex gap-2">
						<IconUsers size={20} className="font-semibold" />
						<p className="inline font-semibold">
							{action.participantsCount} participants{'  '}
							<p className="inline-block pl-4 font-semibold text-primary">
								{action.resultsCount} réponses
							</p>
						</p>
					</div>
				</div>

				{error ? (
					<p className="text-center text-sm text-error-500">
						Erreur lors de la récupération des résultats.
					</p>
				) : (
					<Skeleton mih={100} radius="md" visible={isLoading}>
						<div className="flex flex-col gap-2">
							{actionWithResults?.map((result) => (
								<ActionResultCard
									key={result.participant.userId}
									actionResult={result}
								/>
							))}
						</div>
					</Skeleton>
				)}
			</div>
		</Modal>
	);
};
