import { useCurrentResearch } from '../../../../researches/contexts/researches.context';
import { useCurrentParticipant } from '../../../../usersOnResearch/contexts/participants.context';
import {
	Action,
	ActionWithResult,
	isActionWithResult,
} from '../../api/actions.types';
import { ActionHeader } from '../../components/atoms/ActionHeader';
import { ActionResults } from '../../components/atoms/ActionResults';
import { ActionCheckbox } from './ActionCheckbox';

type Props = {
	action: Action | ActionWithResult;
};
export const MiscellaneousActionItem = ({ action }: Props) => {
	const { participant } = useCurrentParticipant();
	const { userOnResearch } = useCurrentResearch();

	const canUpdateActionResult = () => {
		if (!isActionWithResult(action)) return false;
		if (!participant) return false;
		if (userOnResearch.status === 'INACTIVE') return false;
		return true;
	};

	return (
		<div className="mt-3 grid grid-cols-8 gap-2">
			<div className="col-span-8 my-auto flex flex-col gap-1 md:col-span-4">
				<ActionHeader action={action} />
			</div>
			<div className="my-auto hidden flex-col gap-1 md:col-span-2 md:block md:pl-8">
				{!participant && !isActionWithResult(action) && (
					<ActionResults action={action} />
				)}
			</div>
			<div className="my-auto hidden flex-col gap-1 md:col-span-2 md:block">
				{canUpdateActionResult() && <ActionCheckbox action={action} />}
			</div>
		</div>
	);
};
