import { z } from 'zod';
import { compareTime } from '../../../../../shared/utils/dates';

export type CreateMeetInfoType = z.infer<typeof CreateMeetInfoSchema>;

export const CreateMeetInfoSchema = z
	.object({
		title: z.string().min(1, { message: 'Le titre est requis' }),
		startDate: z
			.date()
			.refine((value) => value, { message: 'La date de début est requise' }),
		startTime: z.string({
			invalid_type_error: "L'horaire de début est requis",
		}),
		endTime: z.string({
			invalid_type_error: "L'horaire de début est requis",
		}),
	})
	.refine((data) => compareTime(data.startTime ?? '', data.endTime ?? ''), {
		path: ['endTime'],
		message: "L'horaire de fin doit être après l'horaire de début",
	});
