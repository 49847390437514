import { Button } from '@/components/ui/button';
import { notifications } from '@mantine/notifications';
import { IconArrowLeft, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../../routes/paths';
import { RequestPasswordResetForm } from '../components/forms/RequestPasswordResetForm';
import { RequestPasswordResetZodType } from '../components/forms/validators/RequestPasswordResetValidator';
import { requestPasswordResetQuery } from '../queries/requestPasswordResetQuery';

export function RequestPasswordResetPage() {
	const navigate = useNavigate();

	const mutation = useMutation({
		mutationFn: async (email: string) => {
			return await requestPasswordResetQuery(email);
		},
		onSuccess: async (_, email) => {
			navigate(paths.VERIFY_EMAIL_PATH, { state: { email } });
		},
		onError: (error: AxiosError) => {
			if (error.response?.status.toString().startsWith('4')) return;
			notifications.show({
				message:
					'Une erreur est survenue lors de l’envoi de l’email. Veuillez réessayer.',
				color: 'red',
				icon: <IconX style={{ width: '20rem', height: '20rem' }} />,
			});
		},
	});

	const handleSubmit = (data: RequestPasswordResetZodType) => {
		mutation.mutate(data.email);
	};

	return (
		<div className="flex max-w-xl flex-col justify-center gap-12">
			<div className="flex flex-col gap-3">
				<h1 className="text-center">Mot de passe oublié ?</h1>
				<p className="text-center text-md font-normal text-muted-foreground">
					Vous allez recevoir un lien de réinitialisation
				</p>
			</div>

			<div className="flex flex-col items-center gap-4">
				<RequestPasswordResetForm
					onSubmit={handleSubmit}
					isLoading={mutation.isPending}
				/>
				{mutation.isError &&
					mutation.error.response?.status.toString().startsWith('4') && (
						<p className="text-center text-sm ">Mail non reconnu</p>
					)}
			</div>
			<Button
				variant="link"
				size={'sm'}
				onClick={() => navigate(paths.SIGNIN_PATH)}
				className="flex justify-center"
			>
				<div className="flex space-x-2">
					<IconArrowLeft />
					<p>Retour à la connexion</p>
				</div>
			</Button>
		</div>
	);
}
