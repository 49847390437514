import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { handleExportData } from '../../../shared/utils/files';
import { useCurrentResearch } from '../../researches/contexts/researches.context';
import { getConsents } from '../api/consents.queries';
import { formatConsentData } from '../consent.utils';

export const useExportConsents = () => {
	const { research } = useCurrentResearch();

	const exportConsentsMutation = useMutation({
		mutationFn: async () => getConsents(research.id),
		onSuccess: ({ consents }) => {
			const result = consents.map((consent) => formatConsentData(consent));
			handleExportData(
				result,
				`Consentements - ${research.name} - ${new Date().toLocaleDateString()}`,
			);
			notifications.show({
				message: `Export réussi`,
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
		},
		onError: () => {
			notifications.show({
				message: `Une erreur est survenue lors de l'export`,
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		},
	});

	return exportConsentsMutation;
};
