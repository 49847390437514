import { Modal, Stack } from '@mantine/core';
import classes from '../../../../../shared/styles/Modal.module.scss';
import { ActionType } from '../../api/actions.types';
import { DynamicTableInput } from '../../dynamic-tables/components/inputs/DynamicTableInput';
import { QuestionnaireForm } from '../../questionnaires/components/forms/QuestionnaireForm';

type Props = {
	handleClose: () => void;
	actionId: number;
	actionType: ActionType;
};

export function CompleteResponsesModal({
	handleClose,
	actionId,
	actionType,
}: Props) {
	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			radius="sm"
			classNames={classes}
			styles={{
				root: {
					'--modal-size': '90%',
				},
				header: {
					marginBottom: '2rem',
				},
			}}
		>
			<Stack align="center" w="90%" mx="auto">
				{actionType === 'QUESTIONNAIRE' && (
					<QuestionnaireForm actionId={actionId} handleClose={handleClose} />
				)}
				{actionType === 'TABLE' && (
					<DynamicTableInput actionId={actionId} handleClose={handleClose} />
				)}
			</Stack>
		</Modal>
	);
}
