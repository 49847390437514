import * as jose from 'jose';
import { Role } from '../../profile/api/types/user.types';

export const TOKEN_STORAGE_KEY = 'react.auth.access_token';
export const REFRESH_TOKEN_STORAGE_KEY = 'react.auth.refresh_token';

export const decodeJwtAccessToken = (token: string) => {
	const decodedToken = jose.decodeJwt(token);
	if (!decodedToken) return;
	return {
		userId: Number(decodedToken.sub),
		email: decodedToken.email as string,
		role: decodedToken.role as Role,
	};
};
