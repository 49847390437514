import { useGetUserProfile } from '../../../profile/api/profile.hooks';

export function WelcomeCard() {
	const { profile } = useGetUserProfile();

	return (
		<div className="flex h-full w-2/5 flex-1 items-end rounded-[12px] bg-gray-50">
			<div className="w-2-5 flex flex-col px-2 pb-1 lg:w-2/5">
				<p>Bonjour</p>
				{profile && (
					<h3 className="line-clamp-2 break-words">{`${profile.firstName} ${profile.lastName}`}</h3>
				)}
			</div>
			<img
				src="/motifs.png"
				alt="motifs"
				className="hidden h-full w-3/5 rounded-[12px] object-cover lg:block"
			/>
		</div>
	);
}
