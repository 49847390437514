export enum LegalRepresentativeRoleEnum {
	PARENT1 = 'Parent 1',
	PARENT2 = 'Parent 2',
	LEGAL_REPRESENTATIVE = 'Représentant légal',
	GUARDIAN = 'Tuteur',
	CUSTODIAN = 'Curateur',
	TRUSTED_PERSON = 'Personne de confiance',
	FAMILY_MEMBER = 'Membre de la famille',
	RELATIVE = 'Proche',
	WITNESS = 'Témoin impartial',
}

export type LegalRepresentativeRole = keyof typeof LegalRepresentativeRoleEnum;

export type LegalRepresentative = {
	legalRepresentativeId: number;
	firstName: string;
	lastName: string;
	email: string;
	role: LegalRepresentativeRole;
	additionalInfo?: string;
	isActive: boolean;
	phoneNumber: string;
	address: string;
	zipCode: string;
	city: string;
	createdAt: Date;
	updatedAt: Date;
};

export type ParticipantProfile = {
	birthDate: Date;
	address: string;
	zipCode: string;
	city: string;
	isVulnerable: boolean;
	isUnableToConsent: boolean;
	legalRepresentatives: LegalRepresentative[];
};
