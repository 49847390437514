import { useInfiniteScroll } from '@/components/hooks/useInfiniteScroll';
import { useDisclosure } from '@mantine/hooks';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { CustomLoader } from '../../../../../components/ui/loader';
import { paths } from '../../../../../routes/paths';
import { useCurrentResearch } from '../../../../researches/contexts/researches.context';
import { getThreadsLatestMessages } from '../../../api/messages.queries';
import { messagesQueryKeys } from '../../../api/messages.query-keys';
import { Thread } from '../../../api/messages.types';
import { ThreadItem } from '../../atoms/ThreadItem';
import { MobileThreadListHeader } from '../atoms/MobileThreadListHeader';
import { MobileSearchThreads } from './MobileSearchThreads';

type MobileThreadListProps = {
	setCurrentThread: (threadId: number) => void;
	openNewMessage: () => void;
};

export const MobileThreadList = (props: MobileThreadListProps) => {
	const { research } = useCurrentResearch();

	const { data, fetchNextPage, hasNextPage, isFetching, isLoading, error } =
		useInfiniteQuery({
			queryKey: messagesQueryKeys.threads(research.id),
			queryFn: ({ pageParam }) =>
				getThreadsLatestMessages(research.id, pageParam),
			initialPageParam: 1,
			getNextPageParam: (lastPage, allPages) =>
				lastPage.length ? allPages.length + 1 : undefined,
			refetchInterval: 1000 * 60 * 3,
		});

	const [threads, setThreads] = useState<Thread[]>([]);
	const [opened, { open, close }] = useDisclosure();

	const infiniteScrollRef = useInfiniteScroll({
		isLoading,
		hasNextPage,
		isFetching,
		fetchNextPage,
	});

	useEffect(() => {
		if (!data) return;
		const allThreads = data?.pages.reduce((acc, page) => [...acc, ...page], []);
		setThreads(allThreads);
	}, [data]);

	if (isLoading) return <CustomLoader additionalClasses="bg-white" />;
	if (error) return <Navigate to={`/${paths.ERROR_PATH}`} />;

	if (opened)
		return (
			<MobileSearchThreads
				handleClose={close}
				threads={threads}
				onThreadClick={props.setCurrentThread}
			/>
		);

	return (
		<div className="flex h-full w-full flex-col gap-4 border border-gray-50 bg-white px-4 py-2">
			<MobileThreadListHeader
				openSearchModal={open}
				openNewMessage={props.openNewMessage}
			/>
			<div className="flex flex-col divide-y divide-gray-50 overflow-y-auto">
				{threads.map((thread, index) => (
					<div
						key={thread.id}
						ref={index === threads.length - 1 ? infiniteScrollRef : undefined}
					>
						<ThreadItem
							thread={thread}
							onThreadClick={() => props.setCurrentThread(thread.id)}
							isActive={false}
						/>
					</div>
				))}
			</div>
		</div>
	);
};
