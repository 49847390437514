import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';

type ConfirmDeleteModalProps = {
	handleConfirm: () => void;
	handleClose: () => void;
	isLoading: boolean;
};

export function ConfirmDeleteModal({
	handleClose,
	handleConfirm,
	isLoading,
}: ConfirmDeleteModalProps) {
	const isMobile = useIsMobile();

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			title="Supprimer le représentant légal"
			size="lg"
			fullScreen={isMobile}
		>
			<div className="flex flex-col gap-6">
				<p className="text-center">
					Etes-vous sûr de vouloir supprimer ce représentant légal ? Ces données
					seront effacées le cas échéant.
				</p>
				<div className="flex gap-4">
					{!isMobile && (
						<Button onClick={handleClose} variant="outline" className="w-full">
							Annuler
						</Button>
					)}
					<Button
						disabled={isLoading}
						className="w-full"
						variant="destructive"
						onClick={handleConfirm}
					>
						Supprimer
					</Button>
				</div>
			</div>
		</Modal>
	);
}
