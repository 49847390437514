import { EventClickArg } from '@fullcalendar/core/index.js';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { Skeleton, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import dayjs from 'dayjs';
import { useRef, useState } from 'react';
import { formatDate, getEndOf, getStartOf } from '../../../shared/utils/dates';
import { ShowVisitScheduleModal } from '../../visits/components/modals/ShowVisitScheduleModal';
import { useVisitsSchedules } from '../api/visit-schedule.hooks';
import { VisitScheduleQueryParams } from '../api/visit-schedule.types';
import { renderDayHeader } from '../utils/renderDayHeader';
import { renderEventContent } from '../utils/renderEventContent';
import { renderMoreLink } from '../utils/renderMoreLink';
import { CalendarHeader } from './atoms/CalendarHeader';
import { FilterVisitsSchedulesZodType } from './forms/validators/FilterVisitsSchedulesZodSchema';

export const Calendar = () => {
	const calendarRef = useRef<FullCalendar>(null);
	const [currentScheduledVisitId, setCurrentScheduledVisitId] = useState<
		number | null
	>(null);

	const currentDate = new Date();
	const defaultStartDate = getStartOf(currentDate, 'week');
	const defaultEndDate = dayjs(getEndOf(currentDate, 'week'))
		.subtract(1, 'day')
		.add(1, 'millisecond')
		.toDate();
	const getNoEventContentMessage = () => {
		const startDate = calendarRef.current?.getApi()?.view.currentStart;
		const endDate = calendarRef.current?.getApi()?.view.currentEnd;
		if (!startDate || !endDate) return 'Aucune visite planifiée';
		const text = `Aucune visite planifiée du ${formatDate(calendarRef.current?.getApi()?.view.currentStart, 'DD MMM YYYY')} au ${formatDate(calendarRef.current?.getApi()?.view.currentEnd, 'DD MMM YYYY')}`;
		return (
			<p className="px-4 text-center text-sm font-semibold text-muted-foreground">
				{text}
			</p>
		);
	};

	const [fetchVisitsSchedulesParams, setFetchVisitsSchedulesParams] =
		useState<VisitScheduleQueryParams>({
			from: defaultStartDate,
			to: defaultEndDate,
		});
	const [filterPaneOpened, { open: openFilterPane, close: closeFilterPane }] =
		useDisclosure();
	const handleFilterSubmit = (data: FilterVisitsSchedulesZodType) => {
		const newParams = {
			isRemote: data.isInCenter === data.isRemote ? undefined : data.isRemote,
			participantIds:
				data.participantIds?.length === 0 ? undefined : data.participantIds,
		};
		setFetchVisitsSchedulesParams((prev) => ({
			from: prev.from,
			to: prev.to,
			...newParams,
		}));
		closeFilterPane();
	};

	const { visitsSchedules, isLoading, error } = useVisitsSchedules(
		fetchVisitsSchedulesParams,
	);

	const [
		previewParticipantVisitOpened,
		{ open: openPreviewVisit, close: closePreviewParticipantVisit },
	] = useDisclosure();
	const handleEventClick = (clickInfo: EventClickArg) => {
		const { extendedProps: visit } = clickInfo.event;
		setCurrentScheduledVisitId(visit.scheduledVisitId);
		openPreviewVisit();
	};

	if (error)
		return (
			<div className="flex h-full items-center bg-gray-50">
				<p className="text-center text-sm text-error-500">
					Erreur lors du chargement de l'agenda, veuillez réessayer plus tard
				</p>
			</div>
		);

	return (
		<Stack>
			<CalendarHeader
				calendarRef={calendarRef}
				currentParams={fetchVisitsSchedulesParams}
				onFilterPaneOpen={openFilterPane}
				onFilterPaneClose={closeFilterPane}
				filterPaneOpened={filterPaneOpened}
				onFilterSubmit={handleFilterSubmit}
				isLoading={isLoading}
			/>
			<Skeleton visible={isLoading} height={600}>
				<FullCalendar
					ref={calendarRef}
					plugins={[
						dayGridPlugin,
						timeGridPlugin,
						listPlugin,
						interactionPlugin,
					]}
					locale={'fr'}
					initialView={'timeGridWeek'}
					initialDate={currentDate}
					firstDay={1}
					hiddenDays={[0]}
					businessHours={{
						daysOfWeek: [1, 2, 3, 4, 5],
						startTime: '08:00',
						endTime: '20:00',
					}}
					headerToolbar={false}
					dayHeaderContent={renderDayHeader}
					noEventsContent={getNoEventContentMessage}
					nowIndicator={true}
					slotDuration={'01:00:00'}
					slotLabelFormat={{
						hour: '2-digit',
						minute: '2-digit',
						omitZeroMinute: false,
					}}
					slotMinTime={'08:00:00'}
					slotMaxTime={'20:00:00'}
					allDaySlot={false}
					expandRows={true}
					dayMaxEventRows={3}
					eventMaxStack={1}
					datesSet={(dateInfo) => {
						if (
							dayjs(dateInfo.start).valueOf() ===
								dayjs(fetchVisitsSchedulesParams.from).valueOf() &&
							dayjs(dateInfo.end).valueOf() ===
								dayjs(fetchVisitsSchedulesParams.to).valueOf()
						)
							return;

						setFetchVisitsSchedulesParams((prev) => ({
							...prev,
							from: dateInfo.start,
							to: dateInfo.end,
						}));
					}}
					moreLinkContent={renderMoreLink}
					eventBorderColor="transparent"
					eventBackgroundColor="transparent"
					events={visitsSchedules}
					eventClick={handleEventClick}
					eventContent={renderEventContent}
				/>
			</Skeleton>
			{previewParticipantVisitOpened && currentScheduledVisitId && (
				<ShowVisitScheduleModal
					scheduledVisitId={currentScheduledVisitId}
					onClose={closePreviewParticipantVisit}
				/>
			)}
		</Stack>
	);
};
