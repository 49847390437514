import { Button } from '@/components/ui/button';
import { MyPasswordField } from '@/components/ui/form/password-form-field';
import { Separator } from '@/components/ui/separator';
import { zodResolver } from '@hookform/resolvers/zod';
import { Fieldset } from '@mantine/core';
import { FormProvider, useForm } from 'react-hook-form';
import { PasswordRules } from '../../../auth/shared/components/ui/PasswordRules';
import {
	ChangePasswordZodSchema,
	ChangePasswordZodType,
} from './validators/ChangePasswordValidator';

type Props = {
	onSubmit: (data: ChangePasswordZodType) => void;
	isLoading: boolean;
};

export function ChangePasswordForm({ onSubmit, isLoading }: Props) {
	const methods = useForm<ChangePasswordZodType>({
		resolver: zodResolver(ChangePasswordZodSchema),
	});
	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = methods;

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={handleSubmit((data) => {
					onSubmit(data);
					reset();
				})}
				className="w-full"
			>
				<Fieldset
					p="lg"
					styles={{
						root: {
							backgroundColor: 'white',
						},
					}}
				>
					<div className="flex flex-col gap-12">
						<div className="flex flex-col gap-4">
							<div className="flex flex-col gap-2">
								<MyPasswordField
									name="newPassword"
									label="Nouveau mot de passe"
									placeholder="Choisissez un nouveau mot de passe"
									error={errors.newPassword?.message}
									labelClass="text-black"
									inputClass="text-black"
									autoComplete="new-password"
								/>
								<PasswordRules />
							</div>

							<MyPasswordField
								name="confirmNewPassword"
								required={true}
								label="Confirmation du mot de passe"
								placeholder="Confirmez votre nouveau mot de passe"
								error={errors.confirmNewPassword?.message}
								labelClass="text-black"
								autoComplete="new-password"
								inputClass="text-black"
								onPaste={(event) => {
									event.preventDefault();
								}}
							/>
						</div>
						<Separator />

						<div className="flex gap-4 max-md:flex-col">
							<Button
								onClick={() => reset()}
								variant="outline"
								className="w-full"
								type="button"
							>
								Annuler
							</Button>

							<Button type="submit" disabled={isLoading} className="w-full">
								Changer le mot de passe
							</Button>
						</div>
					</div>
				</Fieldset>
			</form>
		</FormProvider>
	);
}
