import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import classes from '@/components/ui/modal/modal.module.scss';
import { ComboboxItem, Modal, Select } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { useState } from 'react';
import { Role } from '../../../profile/api/types/user.types';

type AddRecipientsModalProps = {
	onClose: () => void;
	onContactGroupSubmit: (role: Role) => void;
};
export const AddRecipientsModal = ({
	onContactGroupSubmit: handleContactGroupSubmit,
	onClose: handleClose,
}: AddRecipientsModalProps) => {
	const isMobile = useIsMobile();
	const [value, setValue] = useState<ComboboxItem | null>(null);

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			withCloseButton={false}
		>
			<div className="mt-6 flex flex-col gap-4">
				<div className="flex flex-col items-start gap-2">
					<p className="font-semibold">Ajouter un groupe de destinataire</p>

					<Select
						size="md"
						w="100%"
						withCheckIcon={false}
						allowDeselect={false}
						data={[
							{ value: 'ALL', label: 'Tous' },
							{ value: 'PARTICIPANT', label: 'Participants' },
							{
								value: 'OFFSITE_RESEARCH_MEMBER',
								label: "Membres de l'équipe à distance",
							},
						]}
						value={value ? value.value : null}
						onChange={(_value, option) => setValue(option)}
					/>
				</div>
				<div className="flex items-center justify-center gap-2 bg-gray-100 px-4 py-3 text-sm italic">
					<IconInfoCircle size={16} />
					<p className="text-center">
						Il s’agit des personnes uniquement de cette recherche
					</p>
				</div>
				<div className="flex gap-4">
					{!isMobile && (
						<Button onClick={handleClose} variant="outline" className="w-full">
							Annuler
						</Button>
					)}
					<Button
						onClick={() => {
							handleContactGroupSubmit(value?.value as Role);
							handleClose();
						}}
						className="w-full"
					>
						Valider
					</Button>
				</div>
			</div>
		</Modal>
	);
};
