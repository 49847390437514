import { Anchor, Center, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Action } from '../../api/actions.types';
import { ViewActionResultsModal } from '../modals/ViewActionResultsModal';

type Props = {
	action: Action;
};
export const ActionResults = ({ action }: Props) => {
	const [opened, { open, close }] = useDisclosure();

	return (
		<>
			<Center h="100%">
				<Text ta="center">
					<Anchor
						component="span"
						fw={600}
						size="lg"
						c="var(--mantine-primary-color-6)"
						onClick={open}
					>
						{action.resultsCount}
					</Anchor>
					{` / ${action.participantsCount}`}
				</Text>
			</Center>
			{opened && <ViewActionResultsModal action={action} onClose={close} />}
		</>
	);
};
