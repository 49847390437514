import { CustomLoader } from '@/components/ui/loader';
import { useCurrentResearch } from '@/features/researches/contexts/researches.context';
import { UserOnResearch } from '@/features/usersOnResearch/api/types/users-on-research.types';
import { isSameDay } from '@/shared/utils/dates';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useGetThread } from '../../api/messages.hooks';
import { MessageItem } from '../atoms/MessageItem';
import { MessageListHeader } from '../atoms/MessageListHeader';
import { MessageSeparator } from '../atoms/MessageSeparator';
import { NewMessageListHeader } from '../atoms/NewMessageListHeader';
import { SendNewMessageForm } from '../forms/SendNewMessageForm';

type MessageListProps = {
	threadId?: number;
	newMessage: boolean;
};

export const MessageList = ({ threadId, newMessage }: MessageListProps) => {
	const { userOnResearch: user } = useCurrentResearch();
	const { thread, isLoading, error } = useGetThread(threadId ?? 0);

	const [recipients, setRecipients] = useState<UserOnResearch[]>([]);

	const containerRef = useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (!containerRef.current) return;
		containerRef.current.scroll({
			top: containerRef.current.scrollHeight,
			left: 0,
		});
	}, [containerRef, thread]);

	const formattedMessages = useMemo(() => {
		if (!thread) return null;
		let previousDate: Date;

		const messages = thread?.messages;
		if (!messages) return null;

		return messages.map((message, index) => {
			const currentDate = new Date(message.createdAt);
			if (index > 0) previousDate = new Date(messages[index - 1].createdAt);
			const isNewDay = index === 0 || !isSameDay(previousDate, currentDate);
			return (
				<div key={message.id} className="flex flex-col gap-4">
					{isNewDay && <MessageSeparator date={currentDate} />}
					{
						<MessageItem
							message={message}
							sent={message.emitter.userId === user?.userId}
						/>
					}
				</div>
			);
		});
	}, [thread, user.userId]);

	if (error)
		return (
			<p className="h-full w-full bg-white text-sm text-error-500">
				Erreur lors de la récupération de la conversation.
			</p>
		);
	if (isLoading) return <CustomLoader additionalClasses="bg-white" />;

	return (
		<div className="flex h-full flex-col border border-gray-50 bg-white py-2">
			{newMessage ? (
				<NewMessageListHeader
					recipients={recipients}
					setRecipients={setRecipients}
				/>
			) : (
				thread && <MessageListHeader thread={thread} />
			)}

			<div
				className="flex grow flex-col gap-4 overflow-y-auto px-6 py-6"
				ref={containerRef}
			>
				{!newMessage &&
					(formattedMessages ?? (
						<p className="text-sm text-muted-foreground">
							Aucun message pour le moment
						</p>
					))}
			</div>

			<div>
				{<SendNewMessageForm threadId={threadId} recipients={recipients} />}
			</div>
		</div>
	);
};
