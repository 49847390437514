import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { CreateMessageDto } from './messages.dto';

export const sendMessage = async (
	researchId: number,
	body: CreateMessageDto,
	threadId?: number,
) => {
	try {
		const response = await privateAxios.post(
			`/chat/research/${researchId}`,
			body,
			{
				params: {
					threadId,
				},
			},
		);
		return response.data;
	} catch (error) {
		throw error as AxiosError;
	}
};
