import { AxiosError } from 'axios';
import { privateAxios } from '../../../../../shared/services/privateAxios';
import { Questionnaire, QuestionnaireResponse } from './questionnaires.types';

export const getQuestionnairePreview = async (actionId: number) => {
	try {
		const { data } = await privateAxios.get<Questionnaire>(
			`/visits/action/${actionId}/questionnaire`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getQuestionnaireResponse = async (
	actionId: number,
	userId?: number,
) => {
	try {
		const { data } = await privateAxios.get<QuestionnaireResponse>(
			`/visits/action/${actionId}/questionnaire-result`,
			{
				params: { participantId: userId },
			},
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
