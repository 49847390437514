import { IconFile, IconPencil, IconTable } from '@tabler/icons-react';

export const getActionIcon = (
	actionType: string,
	size: number = 20,
): { icon: React.ReactNode; color: string } => {
	switch (actionType) {
		case 'QUESTIONNAIRE':
			return {
				icon: <IconFile size={20} />,
				color: 'var(--mantine-primary-color-6)',
			};
		case 'DOCUMENT':
			return {
				icon: (
					<IconFile size={size} color="var(--mantine-color-custom-green-9)" />
				),
				color: 'var(--mantine-color-custom-green-0)',
			};
		case 'TABLE':
			return {
				icon: (
					<IconTable size={size} color="var(--mantine-color-custom-orange-9)" />
				),
				color: 'var(--mantine-color-custom-orange-0)',
			};
		default:
			return {
				icon: <IconPencil size={size} color="var(--mantine-color-gray-9)" />,
				color: 'var(--mantine-color-gray-3)',
			};
	}
};
