import { VisitSchedule } from '../api/visit-schedule.types';

export type CalendarDirection = 'prev' | 'next';

export type CalendarView = {
	type: string;
};

export type CalendarEvent = {
	start: Date;
	end: Date;
	title: string;
	extendedProps: {
		scheduledVisitId: number;
		isRemote: boolean;
		participantCode: string;
	};
};

export const formatVisitScheduleToCalendarEvent = (
	schedule: VisitSchedule,
): CalendarEvent => {
	return {
		start: schedule.startDate,
		end: schedule.endDate,
		title: schedule.name,
		extendedProps: {
			scheduledVisitId: schedule.scheduledVisitId,
			isRemote: schedule.isRemote,
			participantCode: schedule.participantCode,
		},
	};
};
