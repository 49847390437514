import { Button, Group, Modal, Stack, Text, Title } from '@mantine/core';
import { useMemo } from 'react';
import classes from '../../../../../shared/styles/Modal.module.scss';
import { useCurrentParticipant } from '../../../../usersOnResearch/contexts/participants.context';
import { useResetActionDocuments } from '../../action-documents/api/hooks/useResetActionResultDocuments';
import { ActionType } from '../../api/actions.types';
import { useResetDynamicTable } from '../../dynamic-tables/api/dynamic-tables.hooks';
import { useResetQuestionnaire } from '../../questionnaires/api/questionnaires.hooks';

type Props = {
	handleClose: () => void;
	actionId: number;
	actionType: ActionType;
};

export function ConfirmResetResultModal({
	handleClose,
	actionId,
	actionType,
}: Props) {
	const { participant } = useCurrentParticipant();

	const { resetResultsMutation: resetDynamicTableMutation } =
		useResetDynamicTable(actionId, participant.userId, handleClose);

	const { resetResultsMutation: resetQuestionnaireMutation } =
		useResetQuestionnaire(actionId, participant.userId, handleClose);

	const { resetResultsMutation: resetDocumentMutation } =
		useResetActionDocuments(actionId, participant.userId, handleClose);

	const actionProps = useMemo(() => {
		if (actionType === 'DOCUMENT')
			return {
				title: 'Etes-vous sûr de vouloir supprimer ces fichiers ?',
				message: 'Vous ne pourrez pas les récupérer une fois ceux-ci effacés.',
				mutation: resetDocumentMutation,
			};
		if (actionType === 'QUESTIONNAIRE')
			return {
				title: 'Etes-vous sûr de vouloir réinitialiser les réponses ?',
				message:
					'Vous ne pourrez pas les récupérer une fois celles-ci effacées.',
				mutation: resetQuestionnaireMutation,
			};
		if (actionType === 'TABLE')
			return {
				title: 'Etes-vous sûr de vouloir réinitialiser les réponses ?',
				message:
					'Vous ne pourrez pas les récupérer une fois celles-ci effacées.',
				mutation: resetDynamicTableMutation,
			};
		return;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [actionType]);

	return (
		<Modal opened={true} onClose={handleClose} centered classNames={classes}>
			<Stack gap="lg" m="lg">
				<Title order={3} ta="center">
					{actionProps?.title}
				</Title>
				<Text size="md" ta="center">
					{actionProps?.message}
				</Text>
			</Stack>

			<Group grow gap="5%" mx="5%">
				<Button onClick={handleClose} variant="outline" size="md">
					Retour
				</Button>
				<Button
					variant="filled"
					size="md"
					color="red"
					loading={actionProps?.mutation.isPending}
					onClick={() => actionProps?.mutation.mutate()}
				>
					Effacer
				</Button>
			</Group>
		</Modal>
	);
}
