import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { useInvalidateQueries } from '../../../../shared/hooks/useInvalidateQueries.ts';
import { handleExportData } from '../../../../shared/utils/files.ts';
import { useSession } from '../../../auth/shared/hooks/useSession.ts';
import { dashboardQueryKeys } from '../../../dashboard/api/dashbord.query-keys.ts';
import { useCurrentResearch } from '../../../researches/contexts/researches.context.tsx';
import { formatParticipantData } from '../../utils/formatParticipantData.ts';
import { CreateParticipantDto } from '../dto/participants.dto.ts.ts';
import {
	addParticipantOnResearch,
	updateParticipantsOnOffsiteResearchMember,
} from '../mutations/participants.mutations.ts';
import { getParticipantsByOffsiteResearchMember } from '../queries/participants.queries.ts';
import {
	UserOnResearch,
	UserOnResearchStatusType,
} from '../types/users-on-research.types.ts';
import { usersOnResearchQueryKeys } from '../users-on-research.query-keys.ts';
import { useGetUsersOnResearchDetails } from './users-on-research.hooks.tsx';

export const useGetParticipantsByOffsiteResearchMember = (
	offsiteResearchMemberId: number,
	statuses?: UserOnResearchStatusType[],
	enabled = true,
) => {
	const { research } = useCurrentResearch();

	const {
		data: participants,
		isLoading,
		error,
	} = useQuery<UserOnResearch[]>({
		queryKey: usersOnResearchQueryKeys.listParticipantsByOffsiteResearchMember(
			research.id,
			offsiteResearchMemberId,
			statuses,
		),
		queryFn: () =>
			getParticipantsByOffsiteResearchMember(
				research.id,
				offsiteResearchMemberId,
				statuses,
			),
		enabled,
	});

	return { participants, isLoading, error };
};

export const useAddParticipants = (onSettled?: () => void) => {
	const { research } = useCurrentResearch();
	const invalidateQueries = useInvalidateQueries();
	const addedParticipants = useRef(0);
	const [isLoading, setIsLoading] = useState(false);

	const handleSuccess = () => {
		invalidateQueries([
			dashboardQueryKeys.detail(research.id),
			usersOnResearchQueryKeys.lists(research.id),
		]);
		const message =
			addedParticipants.current > 1
				? `Ajout de ${addedParticipants.current} participants à la recherche`
				: "Ajout d'un participant à la recherche";
		notifications.show({
			message: message,
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const addParticipantsMutation = async (
		participants: CreateParticipantDto[],
	) => {
		const promises = participants.map(
			async (participant) =>
				await addParticipantMutation.mutateAsync(participant),
		);
		setIsLoading(true);
		await Promise.all(promises);
		setIsLoading(false);
		handleSuccess();
		onSettled?.();
	};

	const addParticipantMutation = useMutation({
		mutationFn: async (payload: CreateParticipantDto) =>
			addParticipantOnResearch(payload),
		onSuccess: () => {
			addedParticipants.current += 1;
		},
		onError: (_, payload) =>
			notifications.show({
				message: `Une erreur est survenue lors de l'ajout de ${payload.email}`,
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			}),
	});

	return { addParticipantsMutation, isLoading };
};

export const useUpdateParticipantsOnOffsiteResearchMember = (
	userId: number,
) => {
	const { research } = useCurrentResearch();
	const invalidateQueries = useInvalidateQueries();

	const showSuccessNotification = (
		addedParticipants: number,
		removedParticipants: number,
	) => {
		const addMessage =
			addedParticipants > 1
				? `${addedParticipants} participants ont été associés au membre de l'équipe à distance`
				: "Un participant a été associé au membre de l'équipe à distance";
		addedParticipants > 0 &&
			notifications.show({
				message: addMessage,
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
		const removeMessage =
			removedParticipants > 1
				? `${removedParticipants} participants ont été désaffiliés du membre de l'équipe à distance`
				: "Un participant a été désaffilié du membre de l'équipe à distance";
		removedParticipants > 0 &&
			notifications.show({
				message: removeMessage,
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const updateParticipantsOnOffsiteResearchMemberMutation = useMutation({
		mutationFn: async (participants: number[]) => {
			const { addedParticipants, removedParticipants } =
				await updateParticipantsOnOffsiteResearchMember(
					participants,
					research.id,
					userId,
				);
			return { addedParticipants, removedParticipants };
		},
		onSuccess: ({ addedParticipants, removedParticipants }) => {
			showSuccessNotification(
				addedParticipants.length,
				removedParticipants.length,
			);
			invalidateQueries([
				usersOnResearchQueryKeys.listParticipantsByOffsiteResearchMember(
					research.id,
					userId,
				),
				[...addedParticipants, ...removedParticipants].map((p) =>
					usersOnResearchQueryKeys.listOffsiteResearchMembersByParticipant(
						research.id,
						p,
					),
				),
			]);
		},
		onError: () => {
			showErrorNotification();
		},
	});

	return {
		updateParticipantsOnOffsiteResearchMemberMutation,
	};
};

export const useExportParticipantData = () => {
	const { user } = useSession();
	const { research } = useCurrentResearch();
	const [isLoading, setIsLoading] = useState(false);

	const canExportParticipantData =
		user?.role === 'INVESTIGATOR' || user?.role === 'ONSITE_RESEARCH_MEMBER';

	const { usersOnResearch: participants } = useGetUsersOnResearchDetails([
		'PARTICIPANT',
	]);

	const exportParticipantData = async () => {
		setIsLoading(true);
		try {
			if (!participants) {
				notifications.show({
					message:
						'Les données des participants ne sont pas disponibles, veuillez réessayer plus tard',
					color: 'red',
					icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
				});
				return;
			} else {
				const result = participants.map((participant) =>
					formatParticipantData(participant),
				);
				handleExportData(
					result,
					`Liste de correspondance des participants - ${
						research.name
					} - ${new Date().toLocaleDateString()}`,
				);
				notifications.show({
					message: `Export réussi`,
					color: 'teal',
					icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
				});
			}
		} catch (error) {
			notifications.show({
				message: "Une erreur est survenue lors de l'export",
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return {
		canExportParticipantData,
		exportParticipantData,
		isLoadingExport: isLoading,
	};
};
