import { VisitScheduleQueryParams } from './visit-schedule.types';

export const calendarQueryKeys = {
	all: ['calendar'] as const,
	lists: (researchId: number) =>
		[...calendarQueryKeys.all, 'research', researchId] as const,
	list: (researchId: number, params: VisitScheduleQueryParams) =>
		[...calendarQueryKeys.lists(researchId), params] as const,
	detail: (researchId: number, visitScheduleId: number) =>
		[...calendarQueryKeys.lists(researchId), visitScheduleId] as const,
};
