import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../../routes/paths';
import { useSession } from '../../shared/hooks/useSession';
import { LoginResponse } from '../../shared/types/AuthToken';
import { ParticipantSignUpDto } from './participants.dto';
import { signUpAsParticipant } from './participants.mutations';

export const useParticipantSignup = (key: string) => {
	const navigate = useNavigate();
	const { createSession } = useSession();

	const signUpMutation = useMutation({
		mutationFn: async (payload: ParticipantSignUpDto) =>
			signUpAsParticipant(payload, key),
		onSuccess: async (data: LoginResponse) => {
			notifications.show({
				message: 'Vous avez été ajouté à la recherche',
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
			createSession(data);
		},
		onError: () => {
			notifications.show({
				message: 'Une erreur est survenue',
				color: 'red',
				icon: <IconX style={{ width: '20px', height: '20px' }} />,
			});
			navigate(paths.SIGNIN_PATH, { replace: true });
		},
	});

	return { signUpMutation };
};
