import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Paper } from '@mantine/core';
import { IconMail, IconPhone } from '@tabler/icons-react';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { ContactsBadge } from '../badge/ContactsBadge';

export function ResearchContactCard() {
	const isMobile = useIsMobile();
	const { research } = useCurrentResearch();

	return (
		<Paper p="lg" radius="lg" c="white" bg="var(--mantine-primary-color-6)">
			<div className="flex flex-col items-center gap-4">
				<h5>Contact du centre</h5>
				{isMobile ? (
					<div className="flex flex-col items-center gap-2">
						<div className="flex gap-1">
							<IconPhone stroke={1.5} />
							<p>{research.phoneNumber}</p>
						</div>
						<div className="flex gap-1">
							<IconMail stroke={1.5} />
							<p>{research.email}</p>
						</div>
					</div>
				) : (
					<div className="grid w-full grid-cols-3 gap-4">
						<div className="flex items-center gap-1">
							<IconPhone stroke={1.5} />
							<p>{research.phoneNumber}</p>
						</div>
						<div className="flex items-center gap-1">
							<IconMail stroke={1.5} />
							<p>{research.email}</p>
						</div>
						<div className="justify-self-end">
							<ContactsBadge />
						</div>
					</div>
				)}
			</div>
		</Paper>
	);
}
