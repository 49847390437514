import MyField from '@/components/ui/form/form-field';
import { MySelect } from '@/components/ui/form/select-field';
import { Checkbox, Fieldset } from '@mantine/core';
import {
	Controller,
	FormProvider,
	useFieldArray,
	useFormContext,
} from 'react-hook-form';
import {
	LegalRepresentativeRole,
	LegalRepresentativeRoleEnum,
} from '../../../../profile/api/types/participants.types';
import { LegalRepresentativeZodType } from './validators/LegalRepresentativeZodSchema';
import { ParticipantSignUpZodType } from './validators/ParticipantSignUpZodSchema';

export const VulnerableParticipantFormStep2 = () => {
	const methods = useFormContext<ParticipantSignUpZodType>();
	const {
		control,
		watch,
		getValues,
		formState: { errors },
	} = methods;
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'legalRepresentatives',
	});

	const vulnerableLegalRepresentativeRoles: LegalRepresentativeRole[] = [
		'LEGAL_REPRESENTATIVE',
		'CUSTODIAN',
		'GUARDIAN',
		'TRUSTED_PERSON',
		'FAMILY_MEMBER',
		'RELATIVE',
	];
	const defaultLegalRepresentativeValues: LegalRepresentativeZodType = {
		firstName: '',
		lastName: '',
		phoneNumber: '',
		address: '',
		city: '',
		zipCode: '',
		email: '',
		role: 'LEGAL_REPRESENTATIVE',
	};
	const isVulnerable = watch().isVulnerable;

	const vulnerableFieldIndex = fields.findIndex((field) =>
		vulnerableLegalRepresentativeRoles.includes(
			field.role as LegalRepresentativeRole,
		),
	);

	const handleIsVulnerableToggle = (
		isChecked: boolean,
		onChange: (checked: boolean) => void,
	) => {
		if (vulnerableFieldIndex === -1 && !isVulnerable)
			append(defaultLegalRepresentativeValues);
		if (vulnerableFieldIndex !== -1 && isVulnerable)
			remove(vulnerableFieldIndex);

		onChange(isChecked);
	};

	return (
		<FormProvider {...methods}>
			<div className="flex flex-col gap-6">
				<Controller
					control={control}
					name="isVulnerable"
					render={({ field: { onChange, value } }) => (
						<Checkbox
							checked={value}
							size="md"
							radius="xs"
							className="text-sm"
							onChange={(e) =>
								handleIsVulnerableToggle(e.currentTarget.checked, onChange)
							}
							label="Personne vulnérable"
						/>
					)}
				/>
				{isVulnerable && vulnerableFieldIndex !== -1 && (
					<Fieldset
						legend={
							<div className="flex space-x-1">
								<p className="text-lg font-semibold">
									Personne signataire du consentement
								</p>
								<p className="text-lg font-semibold text-error-500">*</p>
							</div>
						}
					>
						<div className="flex flex-col gap-4">
							<MySelect
								name={`legalRepresentatives.${vulnerableFieldIndex}.role`}
								label="Type de signataire"
								required={true}
								selectItems={vulnerableLegalRepresentativeRoles.map((role) => ({
									name: LegalRepresentativeRoleEnum[role],
									value: role,
								}))}
								placeholder="Sélectionner le type de signataire"
								labelClass="text-black"
								selectAdditionalClass="text-black"
							/>
							{getValues('legalRepresentatives')[vulnerableFieldIndex].role ===
								'FAMILY_MEMBER' && (
								<MyField
									type="text"
									name={`legalRepresentatives.${vulnerableFieldIndex}.additionalInfo`}
									label="Lien de parenté"
									required={true}
									placeholder="Lien de parenté"
									error={
										errors.legalRepresentatives &&
										errors.legalRepresentatives[
											vulnerableFieldIndex
										]?.additionalInfo?.message?.toString()
									}
								/>
							)}

							<div className="flex flex-col justify-between gap-4 md:flex-row">
								<MyField
									type="text"
									name={`legalRepresentatives.${vulnerableFieldIndex}.lastName`}
									required={true}
									label="Nom"
									placeholder="Nom"
									error={
										errors.legalRepresentatives?.[vulnerableFieldIndex]
											?.lastName?.message
									}
									labelClass="text-black"
									inputClass="text-black"
								/>

								<MyField
									type="text"
									name={`legalRepresentatives.${vulnerableFieldIndex}.firstName`}
									required={true}
									label="Prénom"
									placeholder="Prénom"
									error={
										errors.legalRepresentatives?.[vulnerableFieldIndex]
											?.firstName?.message
									}
									labelClass="text-black"
									inputClass="text-black"
								/>
							</div>

							<MyField
								type="email"
								name={`legalRepresentatives.${vulnerableFieldIndex}.email`}
								required={true}
								label="Adresse email"
								placeholder="Adresse email"
								error={
									errors.legalRepresentatives?.[vulnerableFieldIndex]?.email
										?.message
								}
								labelClass="text-black"
								inputClass="text-black"
							/>

							<MyField
								type="tel"
								name={`legalRepresentatives.${vulnerableFieldIndex}.phoneNumber`}
								required={true}
								label="Téléphone"
								placeholder="Téléphone"
								error={
									errors.legalRepresentatives?.[vulnerableFieldIndex]
										?.phoneNumber?.message
								}
								labelClass="text-black"
								inputClass="text-black"
							/>

							<MyField
								type="text"
								name={`legalRepresentatives.${vulnerableFieldIndex}.address`}
								required={true}
								label="Adresse postale"
								placeholder="Adresse postale"
								error={
									errors.legalRepresentatives?.[vulnerableFieldIndex]?.address
										?.message
								}
								labelClass="text-black"
								inputClass="text-black"
							/>

							<div className="flex flex-col justify-between gap-4 md:flex-row">
								<MyField
									type="text"
									name={`legalRepresentatives.${vulnerableFieldIndex}.city`}
									required={true}
									label="Ville"
									placeholder="Ville"
									error={
										errors.legalRepresentatives &&
										errors.legalRepresentatives[vulnerableFieldIndex]?.city
											?.message
									}
									labelClass="text-black"
									inputClass="text-black"
								/>

								<MyField
									type="number"
									name={`legalRepresentatives.${vulnerableFieldIndex}.zipCode`}
									required={true}
									label="Code postal"
									placeholder="Code postal"
									error={
										errors.legalRepresentatives &&
										errors.legalRepresentatives[vulnerableFieldIndex]?.zipCode
											?.message
									}
									labelClass="text-black"
									inputClass="text-black"
								/>
							</div>
						</div>
					</Fieldset>
				)}
			</div>
		</FormProvider>
	);
};
