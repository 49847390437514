import { createTheme } from '@mantine/core';

export const theme = createTheme({
	primaryColor: 'custom-violet',

	colors: {
		'custom-violet': [
			'#eaeaf3',
			'#c4c2dc',
			'#8682b7',
			'#6b66a7',
			'#58529c',
			'#453e91',
			'#322a85',
			'#2c2578',
			'#231e66',
			'#18164e',
		],
		'custom-orange': [
			'#FEF3EF',
			'#f18a64',
			'#f18a64',
			'#f18a64',
			'#f18a64',
			'#f18a64',
			'#f18a64',
			'#f18a64',
			'#f18a64',
			'#DB6539',
		],
		'custom-green': [
			'#D1F3DF',
			'#A6D4BA',
			'#A6D4BA',
			'#A6D4BA',
			'#A6D4BA',
			'#A6D4BA',
			'#A6D4BA',
			'#A6D4BA',
			'#A6D4BA',
			'#6DA284',
		],
	},

	shadows: {
		md: '1px 1px 3px rgba(0, 0, 0, .25)',
		xl: '5px 5px 3px rgba(0, 0, 0, .25)',
	},

	headings: {
		fontFamily: 'Manrope',
	},

	fontFamily: 'Manrope',
	fontSizes: {
		xs: '0.75rem',
		sm: '0.875rem',
		md: '1rem',
	},
	lineHeights: {
		sm: '1.25rem',
		md: '1.5rem',
	},

	defaultRadius: 'var(--mantine-radius-lg)',
});
