import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import { CustomDatePicker } from '@/components/ui/date-picker/date-picker';
import { CustomTimePicker } from '@/components/ui/date-picker/time-picker';
import MyField from '@/components/ui/form/form-field';
import { zodResolver } from '@hookform/resolvers/zod';
import { Control, FormProvider, useForm } from 'react-hook-form';
import { MultiSelectUsersOnResearch } from '../../../../shared/components/inputs/MultiSelectUsersOnResearch';
import { UserOnResearchControlPanel } from '../../../../shared/components/inputs/UserOnResearchControlPanel';
import {
	combineDateAndTime,
	getTimeFromDate,
} from '../../../../shared/utils/dates';
import { useSession } from '../../../auth/shared/hooks/useSession';
import { useGetUsersOnResearch } from '../../../usersOnResearch/api/hooks/users-on-research.hooks';
import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';
import { useUpdateMeet } from '../../api/hooks/useUpdateMeetMutation';
import { UpdateMeetDto } from '../../api/meets.dto';
import { Meet } from '../../api/meets.types';
import {
	CreateMeetInfoSchema,
	CreateMeetInfoType,
} from './validators/CreateMeetInfoSchema';

type Props = {
	meet: Meet;
	handleClose: () => void;
	participants: UserOnResearch[];
	setParticipants: React.Dispatch<React.SetStateAction<UserOnResearch[]>>;
};
export function UpdateMeetForm({
	meet,
	handleClose,
	participants,
	setParticipants,
}: Props) {
	const isMobile = useIsMobile();
	const { user } = useSession();
	const { updateMeetMutation } = useUpdateMeet(meet.id);
	const usersOnResearch = useGetUsersOnResearch(undefined, [
		'ACTIVE',
	]).usersOnResearch?.filter((member) => member.email !== user?.email);

	const methods = useForm<CreateMeetInfoType>({
		resolver: zodResolver(CreateMeetInfoSchema),
		defaultValues: {
			title: meet.name,
			startDate: new Date(meet.startDate),
			startTime: getTimeFromDate(meet.startDate),
			endTime: getTimeFromDate(meet.endDate),
		},
	});
	const {
		handleSubmit,
		control,
		reset,
		formState: { errors },
	} = methods;

	const submitForm = async (data: CreateMeetInfoType) => {
		const startDate = combineDateAndTime(
			data.startDate?.toISOString() ?? '',
			data.startTime ?? '',
		);
		const endDate = combineDateAndTime(
			data.startDate?.toISOString() ?? '',
			data.endTime ?? '',
		);

		const payload: UpdateMeetDto = {
			name: data.title,
			startDate: new Date(startDate),
			endDate: new Date(endDate),
			participants: participants.map((participant) => participant.userId),
		};
		await updateMeetMutation.mutateAsync(payload);
		reset();
		setParticipants([]);
		handleClose();
	};

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				handleSubmit(submitForm)();
			}}
		>
			<FormProvider {...methods}>
				<div className="flex flex-col gap-6">
					<div className="flex flex-col gap-4">
						<MyField
							type="text"
							name="title"
							label="Titre"
							required={true}
							placeholder="Ajouter un titre"
							error={errors.title?.message}
							labelClass="text-black"
							inputClass="text-black"
						/>

						<CustomDatePicker
							name="startDate"
							control={control as unknown as Control}
							label="Date de la visioconférence"
							placeholder="Choisir une date"
							required
							minDate={new Date()}
						/>

						<div className="flex flex-col justify-between gap-4 md:flex-row">
							<CustomTimePicker
								name="startTime"
								label="Horaire de début"
								placeholder="Choisir une heure"
								control={control as unknown as Control}
								required
							/>

							<CustomTimePicker
								name="endTime"
								label="Horaire de fin"
								placeholder="Choisir une heure"
								control={control as unknown as Control}
								required
							/>
						</div>

						<div className="flex flex-col gap-4">
							<MultiSelectUsersOnResearch
								selectedUsers={participants}
								setSelectedUsers={
									setParticipants as React.Dispatch<
										React.SetStateAction<UserOnResearch[]>
									>
								}
								allUsers={
									(usersOnResearch &&
										usersOnResearch?.filter(
											(participant) => participant.email !== user?.email,
										)) ??
									[]
								}
								label="Ajouter des participants à la visioconférence"
							/>

							<UserOnResearchControlPanel
								usersOnResearch={participants}
								setUsersOnResearch={
									setParticipants as React.Dispatch<
										React.SetStateAction<UserOnResearch[]>
									>
								}
							/>
						</div>
					</div>
					<div className="flex gap-4">
						{!isMobile && (
							<Button
								onClick={() => {
									reset();
									setParticipants([]);
									handleClose();
								}}
								variant="outline"
								className="w-full"
							>
								Annuler
							</Button>
						)}
						<Button
							type="submit"
							disabled={
								updateMeetMutation.isPending || participants.length === 0
							}
							className="w-full"
						>
							Modifier
						</Button>
					</div>
				</div>
			</FormProvider>
		</form>
	);
}
