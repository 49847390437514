import { Button } from '@/components/ui/button';
import { useDisclosure } from '@mantine/hooks';
import { IconCalendarEvent, IconPencil } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';
import { ParticipantVisit } from '../../api/visits.types';
import { ScheduleVisitModal } from '../modals/ScheduleVisitModal';
import { ShowVisitScheduleModal } from '../modals/ShowVisitScheduleModal';

type VisitScheduledDateProps = {
	visit: ParticipantVisit;
};

export const VisitScheduledDate = ({ visit }: VisitScheduledDateProps) => {
	const { userOnResearch } = useCurrentResearch();
	const { participant } = useCurrentParticipant();
	const isPast = visit.endDate && new Date(visit.endDate) < new Date();

	const canScheduleVisit = () => {
		if (participant.status === 'INACTIVE') return false;
		if (visit.isRemote) {
			return (
				userOnResearch.role === 'INVESTIGATOR' ||
				userOnResearch.role === 'ONSITE_RESEARCH_MEMBER' ||
				userOnResearch.role === 'OFFSITE_RESEARCH_MEMBER'
			);
		}
		return (
			userOnResearch.role === 'INVESTIGATOR' ||
			userOnResearch.role === 'ONSITE_RESEARCH_MEMBER'
		);
	};

	const [
		scheduleVisitModalOpened,
		{ open: openScheduleVisitModal, close: closeScheduleVisitModal },
	] = useDisclosure();
	const [
		previewVisitModalOpened,
		{ open: openPreviewVisitModal, close: closePreviewVisitModal },
	] = useDisclosure();

	if (visit.startDate && visit.endDate) {
		return (
			<>
				<div className="flex flex-col items-center gap-1 max-md:items-start">
					<div className="flex flex-col items-center gap-0 max-md:items-start">
						<p className="text-sm font-semibold">
							Le {dayjs(visit.startDate).format('DD MMM YYYY')}
						</p>
						<p className="text-sm font-semibold">
							de {dayjs(visit.startDate).format('HH[h]mm')} à{' '}
							{dayjs(visit.endDate).format('HH[h]mm')}
						</p>
					</div>
					<Button
						variant={'link'}
						size={'sm'}
						onClick={(e) => {
							e.stopPropagation();
							openPreviewVisitModal();
						}}
						className="m-0 p-0"
					>
						<div className="flex items-center gap-1">
							<IconCalendarEvent className="h-4 w-4 text-primary" />
							<p className="text-sm text-primary">Détails</p>
						</div>
					</Button>
				</div>
				{previewVisitModalOpened && visit.scheduledVisitId && (
					<ShowVisitScheduleModal
						scheduledVisitId={visit.scheduledVisitId}
						onClose={closePreviewVisitModal}
					/>
				)}
			</>
		);
	}

	if (visit.forecastDate)
		return (
			<>
				<div className="flex h-full flex-col items-center justify-center gap-1 max-md:items-start">
					<div className="flex flex-col items-center justify-center gap-0 max-md:items-start">
						<p className="text-sm italic text-muted-foreground">Date estimée</p>
						<p className="text-sm font-semibold text-muted-foreground">
							{dayjs(visit.forecastDate).format('DD/MM/YYYY')}
						</p>
					</div>
					{!isPast && canScheduleVisit() && (
						<Button
							size="sm"
							variant={'link'}
							className="m-0 p-0"
							onClick={(e) => {
								e.stopPropagation();
								openScheduleVisitModal();
							}}
						>
							<div className="flex flex-row items-center gap-1">
								{<IconPencil className={'h-4 w-4'} />}
								<p>Planifier</p>
							</div>
						</Button>
					)}
				</div>
				{scheduleVisitModalOpened && (
					<ScheduleVisitModal
						visit={visit}
						handleClose={closeScheduleVisitModal}
					/>
				)}
			</>
		);

	if (visit.delayInDays === 0 && canScheduleVisit())
		return (
			<>
				<div className="flex h-full justify-center">
					<Button
						size="sm"
						variant={'link'}
						className="m-0 p-0"
						onClick={(e) => {
							e.stopPropagation();
							openScheduleVisitModal();
						}}
					>
						<div className="flex flex-row items-center gap-1">
							{<IconPencil className={'h-4 w-4'} />}
							<p>Planifier</p>
						</div>
					</Button>
				</div>
				{scheduleVisitModalOpened && (
					<ScheduleVisitModal
						visit={visit}
						handleClose={closeScheduleVisitModal}
					/>
				)}
			</>
		);
};
