import { dateParser } from '@/shared/utils/dates';
import { DateInput } from '@mantine/dates';
import { Control, Controller } from 'react-hook-form';
import classes from './date-picker.module.scss';

type CustomDatePickerProps = {
	name: string;
	label: string;
	placeholder: string;
	control: Control;
	error?: string;
	required?: boolean;
	additionalClass?: string;
	fieldAdditionalClass?: string;
	minDate?: Date;
	maxDate?: Date;
};

export const CustomDatePicker = ({
	name,
	label,
	placeholder,
	required,
	control,
	minDate,
	maxDate,
}: CustomDatePickerProps) => {
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<DateInput
					onChange={(newValue) => onChange(newValue)}
					value={value}
					withAsterisk={required}
					classNames={classes}
					label={label}
					placeholder={placeholder}
					locale="fr"
					valueFormat="DD/MM/YYYY"
					minDate={minDate}
					maxDate={maxDate}
					dateParser={dateParser}
					error={error?.message}
				/>
			)}
		/>
	);
};
