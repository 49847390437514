import { useIsMobile } from '@/components/hooks/useIsMobile';
import { useGetUserProfile } from '@/features/profile/api/profile.hooks';

export const NavigationMenuHeader = () => {
	const isMobile = useIsMobile();
	const { profile } = useGetUserProfile();

	if (isMobile)
		return (
			<div className="flex max-h-[150px] min-h-[150px] w-full flex-1 items-end rounded-[12px] bg-gray-50 text-black">
				<div className="flex w-3/5 flex-col px-2 pb-1">
					<p>Bonjour</p>
					{profile && (
						<h3 className="line-clamp-2 break-words">{`${profile.firstName} ${profile.lastName}`}</h3>
					)}
				</div>
				<img
					src="/motifs.png"
					alt="motifs"
					className="h-full w-2/5 rounded-[12px] object-cover"
				/>
			</div>
		);

	return (
		<div className="mr-4 flex items-center">
			<img src="/logo-white.png" className="w-1/2 object-contain" />
		</div>
	);
};
