import { UserOnResearch } from '../../usersOnResearch/api/types/users-on-research.types';
import { Action, ActionWithResult } from '../actions/api/actions.types';

export type Visit = {
	id: number;
	name: string;
	description: string;
	delayInDays: number;
	isRemote: boolean;
	isForCaregiver: boolean;
	researchId: number;
	actions: Action[];
	createdAt: Date;
};

export type VisitCategory = 'Participants' | 'Aidants';

export type ParticipantVisit = Visit & {
	actions: ActionWithResult[];
	scheduledVisitId?: number;
	forecastDate?: Date;
	startDate?: Date;
	endDate?: Date;
	isInResearchCenter: boolean;
	address?: string;
	zipCode?: string;
	city?: string;
	addressDetails?: string;
	actionsCount: number;
	completedActionsCount: number;
	participant?: UserOnResearch;
};

export const isParticipantVisit = (
	visit: Visit | ParticipantVisit,
): visit is ParticipantVisit => {
	return (visit as ParticipantVisit).actionsCount !== undefined;
};
