import { z } from 'zod';
import { passwordValidator } from '../../../../../../shared/components/validators/commonValidators';

export type ResetPasswordZodType = z.infer<typeof ResetPasswordZodSchema>;

export const ResetPasswordZodSchema = z
	.object({
		password: passwordValidator,
		confirmPassword: passwordValidator,
	})
	.refine((data) => data.password === data.confirmPassword, {
		path: ['confirmPassword'],
		message: 'Les mots de passe sont différents',
	});
