import { useIsMobile } from '@/components/hooks/useIsMobile';
import { cn } from '@/components/utils';
import FullCalendar from '@fullcalendar/react';
import {
	IconChevronLeft,
	IconChevronRight,
	IconFilter,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { capitalize } from '../../../../shared/utils/strings';
import { VisitScheduleQueryParams } from '../../api/visit-schedule.types';
import { CalendarDirection } from '../../utils/calendar.utils';
import { FilterVisitsSchedulesZodType } from '../forms/validators/FilterVisitsSchedulesZodSchema';
import { FilterVisitsSchedulesPane } from './FilterVisitsSchedulesPane';
import { ToggleCalendarView } from './ToggleCalendarView';

type CalendarHeaderProps = {
	calendarRef: React.RefObject<FullCalendar>;
	onFilterPaneOpen: () => void;
	onFilterPaneClose: () => void;
	filterPaneOpened: boolean;
	currentParams: VisitScheduleQueryParams;
	onFilterSubmit: (data: FilterVisitsSchedulesZodType) => void;
	isLoading: boolean;
};

export const CalendarHeader = ({
	calendarRef,
	onFilterPaneOpen: openFilterPane,
	onFilterPaneClose: closeFilterPane,
	filterPaneOpened,
	currentParams,
	onFilterSubmit: handleFilterSubmit,
	isLoading,
}: CalendarHeaderProps) => {
	const [currentView, setCurrentView] = useState<string | undefined>(
		calendarRef.current?.getApi()?.view.type,
	);
	const [currentDate, setCurrentDate] = useState<Date>(new Date());

	const isMobile = useIsMobile();
	useEffect(() => {
		if (isMobile) handleViewChange('listWeek');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMobile]);

	const handleDateChange = (direction: CalendarDirection): void => {
		if (direction === 'prev') calendarRef.current?.getApi().prev();
		if (direction === 'next') calendarRef.current?.getApi().next();
		setCurrentDate(calendarRef.current?.getApi()?.getDate() ?? new Date());
	};
	const handleViewChange = (view: string): void => {
		calendarRef.current?.getApi()?.changeView(view);
		setCurrentView(view);
	};

	return (
		<>
			<div className="flex flex-row items-center justify-between gap-2">
				<div className="flex items-center gap-4">
					<h4>Agenda</h4>
					<div className="flex">
						<IconChevronLeft
							onClick={() => handleDateChange('prev')}
							className={cn('text-primary', isMobile ? 'h-4 w-4' : 'h-6 w-6')}
						/>
						<IconChevronRight
							onClick={() => handleDateChange('next')}
							className={cn('text-primary', isMobile ? 'h-4 w-4' : 'h-6 w-6')}
						/>
					</div>
					<h5>{capitalize(dayjs(currentDate).format('MMMM YYYY'))}</h5>
				</div>
				<div className="flex items-center gap-4">
					<div className="hidden md:block">
						<ToggleCalendarView
							view={currentView}
							setCalendarView={handleViewChange}
						/>
					</div>
					<IconFilter onClick={openFilterPane} className="text-primary" />
				</div>
			</div>
			{filterPaneOpened && (
				<FilterVisitsSchedulesPane
					currentParams={currentParams}
					isLoading={isLoading}
					onClose={closeFilterPane}
					onSubmit={handleFilterSubmit}
				/>
			)}
		</>
	);
};
