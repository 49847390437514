import { Button } from '@/components/ui/button';
import { paths } from '@/routes/paths';
import { IconHelp, IconLayoutGrid } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

type Props = {
	title: string;
	message: string;
};
export function ErrorPage({ title, message }: Props) {
	const navigate = useNavigate();

	return (
		<div className="m-auto flex h-full max-w-[700px] flex-col justify-center gap-8 px-12">
			<div className="flex flex-col gap-4">
				<h1 className="text-center">{title}</h1>
				<p className="text-center italic">{message}</p>
			</div>
			<div className="flex gap-4 max-sm:flex-col">
				<Button onClick={() => navigate(paths.INDEX_PATH)} className="w-full">
					<div className="flex flex-row items-center gap-1">
						{<IconLayoutGrid className="h-5 w-5" />}
						<p>Retour aux recherches</p>
					</div>
				</Button>
				<Button
					onClick={() => navigate(`/${paths.HELP_PATH}`)}
					variant="outline"
					className="w-full"
				>
					<div className="flex flex-row items-center gap-1">
						{<IconHelp className="h-5 w-5" />}
						<p>Aide</p>
					</div>
				</Button>
			</div>
		</div>
	);
}
