import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { CreateRatingDto } from './ratings.dto';

export const createRating = async (body: CreateRatingDto) => {
	try {
		const { data } = await privateAxios.post(`/ratings/create-rating`, body);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
