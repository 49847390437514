import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import { CustomDatePicker } from '@/components/ui/date-picker/date-picker';
import { CustomTimePicker } from '@/components/ui/date-picker/time-picker';
import MyField from '@/components/ui/form/form-field';
import { zodResolver } from '@hookform/resolvers/zod';
import { Control, FormProvider, useForm } from 'react-hook-form';
import {
	addDuration,
	combineDateAndTime,
	getTimeFromDate,
} from '../../../../shared/utils/dates';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { ScheduleVisitDto } from '../../api/visits.dto';
import { ParticipantVisit } from '../../api/visits.types';
import {
	ScheduleVisitZodSchema,
	ScheduleVisitZodType,
} from './validators/ScheduleVisitZodSchema';

export type ScheduleOnsiteVisitFormProps = {
	visit: ParticipantVisit;
	handleClose: () => void;
	handleSubmit: (payload: ScheduleVisitDto) => void;
};

export const ScheduleOnsiteVisitForm = ({
	visit,
	handleClose: onClose,
	handleSubmit: onSubmit,
}: ScheduleOnsiteVisitFormProps) => {
	const { research } = useCurrentResearch();
	const isMobile = useIsMobile();

	const getDefaultStartDate = () => {
		if (visit.startDate) return new Date(visit.startDate);
		if (visit.forecastDate) return new Date(visit.forecastDate);
		return new Date();
	};

	const getDefaultEndDate = () => {
		if (visit.endDate) return new Date(visit.endDate);
		return addDuration(getDefaultStartDate(), 1, 'hour');
	};

	const methods = useForm<ScheduleVisitZodType>({
		resolver: zodResolver(ScheduleVisitZodSchema),
		defaultValues: {
			date: getDefaultStartDate(),
			startTime: getTimeFromDate(getDefaultStartDate()),
			endTime: getTimeFromDate(getDefaultEndDate()),
			address: research.address,
			zipCode: research.zipCode,
			city: research.city,
			addressDetails: visit.addressDetails ?? '',
		},
	});
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = methods;

	const submitForm = async (data: ScheduleVisitZodType) => {
		const { date, startTime, endTime, ...location } = data;
		const startDate = combineDateAndTime(date.toISOString(), startTime);
		const endDate = combineDateAndTime(date.toISOString(), endTime);

		const payload: ScheduleVisitDto = {
			startDate,
			endDate,
			...location,
		};
		onSubmit(payload);
	};

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={(e) => {
					e.preventDefault();
					handleSubmit(submitForm)();
				}}
			>
				<div className="flex flex-col gap-12">
					<div className="flex flex-col gap-4">
						<CustomDatePicker
							name="date"
							label="Date de la visite"
							placeholder="Choisir une date"
							control={control as unknown as Control}
							required
							minDate={new Date()}
						/>

						<div className="flex flex-col justify-between gap-4 md:flex-row">
							<CustomTimePicker
								name="startTime"
								label="Horaire de début"
								placeholder="Choisir une heure"
								control={control as unknown as Control}
								required
							/>

							<CustomTimePicker
								name="endTime"
								label="Horaire de fin"
								placeholder="Choisir une heure"
								control={control as unknown as Control}
								required
							/>
						</div>
					</div>

					<div className="flex flex-col gap-4">
						<p className="text-center text-lg font-semibold">
							Où se déroulera la visite ?
						</p>

						<MyField
							type="text"
							name="address"
							required={true}
							placeholder={research.address}
							disabled={true}
							label="Adresse postale"
							error={errors.address?.message}
							labelClass="text-black"
							inputClass="text-black"
						/>

						<div className="flex flex-col justify-between gap-4 md:flex-row">
							<MyField
								type="text"
								name="city"
								required={true}
								label="Ville"
								placeholder={research.city}
								error={errors.city?.message}
								disabled={true}
								labelClass="text-black"
								inputClass="text-black"
								additionalClass="md:w-1/2"
							/>
							<MyField
								type="number"
								name="zipCode"
								required={true}
								label="Code postal"
								placeholder={research.zipCode}
								error={errors.zipCode?.message}
								disabled={true}
								labelClass="text-black"
								inputClass="text-black"
								additionalClass="md:w-1/2"
							/>
						</div>

						<MyField
							type="text"
							name="addressDetails"
							label="Complément d'adresse"
							placeholder="Précisez ici le lieu de rendez-vous si nécessaire"
							error={errors.addressDetails?.message}
							labelClass="text-black"
							inputClass="text-black"
						/>
					</div>

					<div className="flex gap-4">
						{!isMobile && (
							<Button
								onClick={onClose}
								variant="outline"
								className="w-full"
								type="button"
							>
								Annuler
							</Button>
						)}
						<Button type="submit" className="w-full">
							Planifier
						</Button>
					</div>
				</div>
			</form>
		</FormProvider>
	);
};
