import { IconArrowRight, IconVideo } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../../routes/paths';
import { useSession } from '../../../auth/shared/hooks/useSession';
import { MeetWithParticipants } from '../../api/meets.types';

type Props = {
	meet: MeetWithParticipants;
};

export function NextMeetingItem({ meet }: Props) {
	const { user } = useSession();
	const navigate = useNavigate();
	const participant = meet.participants.find((p) => p.role === 'PARTICIPANT');

	const redirectLink =
		participant &&
		(user?.role === 'PARTICIPANT' || user?.role === 'OFFSITE_RESEARCH_MEMBER')
			? `${meet.researchId}/${paths.PARTICIPANT_TAB}/${participant.userId}/${paths.MEETS_TAB}`
			: `${meet.researchId}/${paths.MEETS_TAB}`;

	return (
		<div
			className="flex items-center justify-between rounded-[12px] bg-white p-2 hover:cursor-pointer hover:bg-gray-50"
			onClick={() => {
				navigate(redirectLink);
			}}
		>
			<div className="flex max-w-[95%] items-center gap-3">
				<IconVideo className="h-10 w-10 text-orange-500" />
				<div className="flex w-full flex-col">
					<p className="word-break line-clamp-2 font-semibold">
						{meet.name} à {dayjs(meet.startDate).format('HH:mm')} le{' '}
						{dayjs(meet.startDate).format('DD MMM')}
					</p>

					<p className="word-break line-clamp-1 text-sm">{meet.researchName}</p>
				</div>
			</div>
			<IconArrowRight size={24} className="flex w-fit text-primary" />
		</div>
	);
}
