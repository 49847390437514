import { z } from 'zod';
import {
	addressValidator,
	cityValidator,
	emailValidator,
	firstNameValidator,
	phoneNumberValidator,
	zipCodeValidator,
} from '../../../../../shared/components/validators/commonValidators';

export type UpdateLegalRepresentativeZodType = z.infer<
	typeof UpdateLegalRepresentativeZodSchema
>;

export const UpdateLegalRepresentativeZodSchema = z.object({
	firstName: firstNameValidator.optional(),
	lastName: firstNameValidator.optional(),
	phoneNumber: phoneNumberValidator.optional(),
	email: emailValidator.optional(),
	address: addressValidator.optional(),
	city: cityValidator.optional(),
	zipCode: zipCodeValidator.optional(),
});
