import { Accordion, Checkbox, Stack, Text } from '@mantine/core';
import { useFormContext } from 'react-hook-form';
import { FilterVisitsSchedulesZodType } from '../forms/validators/FilterVisitsSchedulesZodSchema';

export const VisitLocationFilterItem = () => {
	const {
		register,
		formState: { errors },
	} = useFormContext<FilterVisitsSchedulesZodType>();

	return (
		<Accordion.Item value="visits">
			<Accordion.Control>
				<Text size="xl" fw={600}>
					Localisation des visites
				</Text>
			</Accordion.Control>
			<Accordion.Panel>
				<Stack gap="md">
					<Checkbox
						size="md"
						label="Sur centre"
						radius={0}
						color="green"
						{...register('isInCenter')}
						error={errors.isInCenter?.message}
					/>
					<Checkbox
						size="md"
						label="A domicile"
						color="blue"
						radius={0}
						{...register('isRemote')}
						error={errors.isRemote?.message}
					/>
				</Stack>
			</Accordion.Panel>
		</Accordion.Item>
	);
};
