import { Table } from '@mantine/core';
import {
	DynamicTableColumn,
	DynamicTableData,
} from '../api/dynamic-tables.types';

export const exportDynamicTableData = (data: DynamicTableData) => {
	const { columns, rows, cells } = data;

	const participants = [
		...new Set(cells.map((c) => c.participant.participantOnResearch?.code)),
	];
	return participants.map((participant) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const output: any = {};
		output['Code participant'] = participant;
		rows.forEach((row) => {
			columns.forEach((column) => {
				const cell = cells.find(
					(c) =>
						c.rowId === row.id &&
						c.columnId === column.id &&
						c.participant.participantOnResearch?.code === participant,
				);
				output[`${row.title} - ${column.title}`] = cell?.value ?? '';
			});
		});
		return output;
	});
};

export const getHeaderRow = (columns: DynamicTableColumn[]) => {
	const row = columns.map((column: DynamicTableColumn) => (
		<Table.Th key={column.id}>{column.title}</Table.Th>
	));
	row.unshift(<Table.Th key={0} />);
	return row;
};
