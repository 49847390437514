import { z } from 'zod';
import {
	addressValidator,
	cityValidator,
	emailValidator,
	firstNameValidator,
	lastNameValidator,
	medicalServiceValidator,
	passwordValidator,
	phoneNumberValidator,
	rppsValidator,
	workplaceValidator,
	zipCodeValidator,
} from '../../../../../../shared/components/validators/commonValidators';

export type OnsiteResearchMemberSignUpZodType = z.infer<
	typeof OnsiteResearchMemberSignUpZodSchema
>;

export const OnsiteResearchMemberSignUpZodSchema = z
	.object({
		password: passwordValidator,
		firstName: firstNameValidator,
		lastName: lastNameValidator,
		email: emailValidator,
		phoneNumber: phoneNumberValidator,
		workplaceName: workplaceValidator,
		workplaceAddress: addressValidator,
		workplaceCity: cityValidator,
		workplaceZipCode: zipCodeValidator,
		medicalService: medicalServiceValidator,
		confirmPassword: passwordValidator,
		rpps: rppsValidator,
	})
	.refine((data) => data.password === data.confirmPassword, {
		path: ['confirmPassword'],
		message: 'Les mots de passe sont différents',
	});
