import { Avatar } from '@mantine/core';
import { UserOnMeet } from '../../api/meets.types';

type ParticipantsBadgeProps = {
	participants: UserOnMeet[];
};

export function ParticipantsBadge({ participants }: ParticipantsBadgeProps) {
	const guests = participants.filter(
		(participant) => participant.roleOnMeet === 'ATTENDEE',
	);

	const [guestsToDisplay, others] =
		guests?.length > 2 ? [guests.slice(0, 2), guests.slice(2)] : [guests, []];

	return (
		<Avatar.Group>
			<Avatar radius="xl" color="var(--mantine-color-orange-6)" />
			{guestsToDisplay.map((_, index) => (
				<Avatar key={index} radius="xl" />
			))}
			{others.length > 0 && <Avatar>+{others.length}</Avatar>}
		</Avatar.Group>
	);
}
