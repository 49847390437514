import { useSendMessage } from '@/features/messages/api/messages.hooks';
import { MAX_MESSAGE_LENGTH } from '@/features/messages/utils/constants';
import { UserOnResearch } from '@/features/usersOnResearch/api/types/users-on-research.types';
import { zodResolver } from '@hookform/resolvers/zod';
import { ActionIcon, Textarea } from '@mantine/core';
import { IconSend } from '@tabler/icons-react';
import { useForm } from 'react-hook-form';
import { CreateMessageZodSchema } from '../../forms/validators/CreateMessageValidator';

type MobileSendNewMessageFormProps = {
	threadId?: number;
	recipients?: UserOnResearch[];
	onMessageSent?: () => void;
};

export const MobileSendNewMessageForm = ({
	threadId,
	recipients,
	onMessageSent: handleMessageSent,
}: MobileSendNewMessageFormProps) => {
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<{ content: string }>({
		resolver: zodResolver(CreateMessageZodSchema),
	});

	const onMessageSent = () => {
		reset();
		handleMessageSent && handleMessageSent();
	};
	const { sendMessageMutation } = useSendMessage(
		onMessageSent,
		threadId,
		recipients,
	);

	return (
		<div className="mx-4">
			<form
				onSubmit={handleSubmit((data) =>
					sendMessageMutation.mutate(data.content),
				)}
			>
				<div className="flex items-center gap-4">
					<Textarea
						placeholder={`Envoyer un message (${MAX_MESSAGE_LENGTH} caractères maximum)`}
						autosize
						maxRows={5}
						w={'100%'}
						radius="sm"
						{...register('content')}
						error={errors.content?.message}
					/>
					<ActionIcon type="submit" variant="transparent">
						<IconSend size={24} className="text-primary" />
					</ActionIcon>
				</div>
			</form>
		</div>
	);
};
