import { AxiosError } from 'axios';
import { privateAxios } from '../../../../shared/services/privateAxios.ts';
import { ParticipantOnResearch } from '../types/participants.types.ts';
import {
	UserOnResearch,
	UserOnResearchStatusType,
} from '../types/users-on-research.types.ts';

export const getParticipantDetails = async (
	researchId: number,
	participantId: number,
): Promise<
	UserOnResearch & { participantOnResearch: ParticipantOnResearch }
> => {
	try {
		const { data } = await privateAxios.get<
			UserOnResearch & { participantOnResearch: ParticipantOnResearch }
		>(`/researches/${researchId}/get-participant/${participantId}/details`);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getParticipantsByOffsiteResearchMember = async (
	researchId: number,
	offsiteResearchMemberId: number,
	statuses?: UserOnResearchStatusType[],
): Promise<
	(UserOnResearch & { participantOnResearch: ParticipantOnResearch })[]
> => {
	try {
		const { data } = await privateAxios.get<
			(UserOnResearch & { participantOnResearch: ParticipantOnResearch })[]
		>(
			`/researches/${researchId}/get-participants/offsite-research-member/${offsiteResearchMemberId}`,
			{
				params: { status: statuses },
			},
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
