import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import {
	CalendarEvent,
	formatVisitScheduleToCalendarEvent,
} from '../utils/calendar.utils';
import {
	VisitSchedule,
	VisitScheduleQueryParams,
} from './visit-schedule.types';

export const getVisitsSchedules = async (
	researchId: number,
	params?: VisitScheduleQueryParams,
): Promise<CalendarEvent[]> => {
	try {
		const { data } = await privateAxios.get<VisitSchedule[]>(
			`visits/research/${researchId}/schedules`,
			{ params },
		);
		return data.map((visit) => formatVisitScheduleToCalendarEvent(visit));
	} catch (error) {
		throw error as AxiosError;
	}
};
