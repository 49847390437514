import { AxiosError } from 'axios';
import { privateAxios } from '../../../../shared/services/privateAxios';
import { publicAxios } from '../../../../shared/services/publicAxios';
import { LoginResponse } from '../../shared/types/AuthToken';
import { JoinResearchResponse } from '../../shared/types/JoinResearchResponse';
import { ParticipantSignUpDto } from './participants.dto';

export const signUpAsParticipant = async (
	body: ParticipantSignUpDto,
	key: string,
): Promise<LoginResponse> => {
	try {
		const { data } = await publicAxios.post<LoginResponse>(
			`/participants/signup`,
			body,
			{
				headers: { Authorization: `Bearer ${key}` },
			},
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const joinResearchAsParticipant = async (
	key: string,
): Promise<JoinResearchResponse> => {
	try {
		const { data } = await publicAxios.post<JoinResearchResponse>(
			`/participants/confirm-join-research`,
			undefined,
			{ headers: { Authorization: `Bearer ${key}` } },
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const resendParticipantInviteLink = async (
	participantId: number,
	researchId: number,
): Promise<void> => {
	try {
		await privateAxios.get<void>(
			`/participants/${participantId}/resend-invite-link/research/${researchId}`,
		);
	} catch (error) {
		throw error as AxiosError;
	}
};
