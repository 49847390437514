import React from 'react';
import { FileDropzone } from '../../../../shared/components/files/FileDropzone';
import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';
import { CreateDocumentWithoutKeyDto } from '../../api/documents.dto';
import { DocumentAccessLevelType } from '../../api/documents.types';
import { DocumentControlPanel } from '../inputs/DocumentsControlPanel';

type UploadDocumentsFormProps = {
	accessLevel: DocumentAccessLevelType;
	recipients: UserOnResearch[];
	documents: (CreateDocumentWithoutKeyDto & { file: File })[];
	setDocuments: React.Dispatch<
		React.SetStateAction<(CreateDocumentWithoutKeyDto & { file: File })[]>
	>;
};

export const UploadDocumentsForm = ({
	accessLevel,
	recipients,
	documents,
	setDocuments,
}: UploadDocumentsFormProps) => {
	const { participant } = useCurrentParticipant();

	const formatDocument = (file: File) => {
		const document: CreateDocumentWithoutKeyDto = {
			fileName: file.name,
			type: 'OTHER',
			accessLevel,
			scope: participant ? 'PRIVATE' : 'PROFESSIONAL',
			recipientIds: recipients.map((recipient) => recipient.userOnResearchId),
			participantId: participant?.userOnResearchId,
		};
		return { ...document, file };
	};

	const handleFilesUpload = (files: File[]) => {
		const documents = files.map((file) => formatDocument(file as File));
		setDocuments((currDocuments) => [...currDocuments, ...documents]);
	};

	return (
		<div className="flex flex-col gap-8">
			<div className="flex flex-col gap-1">
				<h5 className="text-center">Choisir un ou pluiseurs fichiers</h5>
				<p className="text-center">
					Ces documents seront accessibles par les destinataires sélectionnés
					dans l'étape précédente.
				</p>
			</div>
			<div className="flex flex-col gap-4">
				<FileDropzone
					onFilesUpload={handleFilesUpload}
					mimeTypes={['application/pdf']}
				/>
				<DocumentControlPanel
					documents={documents}
					setDocuments={setDocuments}
				/>
			</div>
		</div>
	);
};
