import { rem, Stack, Text } from '@mantine/core';
import { Dropzone, DropzoneProps } from '@mantine/dropzone';
import { notifications } from '@mantine/notifications';
import { IconPhoto, IconUpload, IconX } from '@tabler/icons-react';
import {
	ALLOWED_MIME_TYPES,
	MAX_FILE_SIZE,
	MIME_TYPE,
} from '../../utils/files';

type Props = {
	onFilesUpload: (files: File[]) => void;
	mimeTypes?: MIME_TYPE[];
};

export function FileDropzone({
	onFilesUpload,
	mimeTypes,
	...props
}: Props & Partial<DropzoneProps>) {
	return (
		<Dropzone
			onDrop={onFilesUpload}
			onReject={() =>
				notifications.show({
					message: `La taille maximale autorisée pour un fichier est de ${Math.floor(MAX_FILE_SIZE / 1024 ** 2)} Mo`,
					color: 'red',
					icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
				})
			}
			maxSize={MAX_FILE_SIZE}
			accept={mimeTypes ?? ALLOWED_MIME_TYPES}
			{...props}
		>
			<Stack
				justify="center"
				align="center"
				gap="xl"
				mih="20%"
				style={{ pointerEvents: 'none' }}
			>
				<Dropzone.Accept>
					<IconUpload
						style={{
							width: rem(52),
							height: rem(52),
							color: 'var(--mantine-color-blue-6)',
						}}
						stroke={1.5}
					/>
				</Dropzone.Accept>
				<Dropzone.Reject>
					<IconX
						style={{
							width: rem(52),
							height: rem(52),
							color: 'var(--mantine-color-red-6)',
						}}
						stroke={1.5}
					/>
				</Dropzone.Reject>
				<Dropzone.Idle>
					<IconPhoto
						style={{
							width: rem(52),
							height: rem(52),
							color: 'var(--mantine-color-dimmed)',
						}}
						stroke={1.5}
					/>
				</Dropzone.Idle>

				<Stack gap={0} justify="center" align="center">
					<Text size="md" ta="center">
						<Text component="span" fw={700} c="var(--mantine-primary-color-6)">
							Cliquer ici pour importer
						</Text>{' '}
						ou glisser et déposer des fichiers
					</Text>
					<Text c="var(--mantine-color-dimmed)" fs="italic" ta="center">
						(Taille maximale : {Math.floor(MAX_FILE_SIZE / 1024 ** 2)} Mo)
					</Text>
				</Stack>
			</Stack>
		</Dropzone>
	);
}
