import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import classes from '@/components/ui/modal/modal.module.scss';
import { MultiSelectUsersOnResearch } from '@/shared/components/inputs/MultiSelectUsersOnResearch';
import { Modal } from '@mantine/core';
import { useEffect, useState } from 'react';
import {
	useGetParticipantsByOffsiteResearchMember,
	useUpdateParticipantsOnOffsiteResearchMember,
} from '../../api/hooks/participants.hooks';
import { useGetUsersOnResearch } from '../../api/hooks/users-on-research.hooks';
import { UserOnResearch } from '../../api/types/users-on-research.types';
import { ResearchMemberCard } from '../cards/ResearchMemberCard';
import { ParticipantControlPanel } from '../inputs/ParticipantControlPanel';

type Props = {
	offsiteResearchMember: UserOnResearch;
	handleClose: () => void;
};

export function LinkParticipantsToOffsiteResearchMemberModal({
	offsiteResearchMember,
	handleClose,
}: Props) {
	const isMobile = useIsMobile();
	const [participants, setParticipants] = useState<UserOnResearch[]>([]);
	const { updateParticipantsOnOffsiteResearchMemberMutation: mutation } =
		useUpdateParticipantsOnOffsiteResearchMember(offsiteResearchMember.userId);

	const { usersOnResearch: allParticipants } = useGetUsersOnResearch(
		['PARTICIPANT'],
		['ACTIVE'],
	);

	const { participants: participantsOnOffsiteResearchMember } =
		useGetParticipantsByOffsiteResearchMember(offsiteResearchMember.userId);

	useEffect(() => {
		setParticipants(
			(participantsOnOffsiteResearchMember ?? []) as UserOnResearch[],
		);
	}, [offsiteResearchMember, participantsOnOffsiteResearchMember]);

	const confirm = async () => {
		await mutation.mutateAsync(
			participants.map((participant) => participant.userId),
		);
		setParticipants([]);
		handleClose();
	};

	return (
		<Modal
			opened={true}
			onClose={() => {
				setParticipants([]);
				handleClose();
			}}
			centered
			title="Associer à des participants"
			classNames={classes}
			fullScreen={isMobile}
		>
			<div className="flex flex-col gap-6">
				<p className="text-center">
					Associer un ou plusieurs participants à ce professionnel de santé. Ce
					dernier aura accès à l'espace des participants choisis.
				</p>
				<div className="mx-auto">
					<ResearchMemberCard user={offsiteResearchMember as UserOnResearch} />
				</div>
				<div className="flex flex-col gap-2">
					<MultiSelectUsersOnResearch
						selectedUsers={participants as UserOnResearch[]}
						setSelectedUsers={
							setParticipants as React.Dispatch<
								React.SetStateAction<UserOnResearch[]>
							>
						}
						allUsers={allParticipants as UserOnResearch[]}
						label="Sélectionner les participants à associer"
					/>

					<ParticipantControlPanel
						participants={participants}
						setParticipants={setParticipants}
					/>
				</div>
				<div className="flex gap-4">
					{!isMobile && (
						<Button onClick={handleClose} variant="outline" className="w-full">
							Annuler
						</Button>
					)}
					<Button
						onClick={confirm}
						disabled={mutation.isPending}
						className="w-full"
					>
						Associer
					</Button>
				</div>
			</div>
		</Modal>
	);
}
