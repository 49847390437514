import { Button } from '@/components/ui/button';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';
import {
	DocumentAccessLevelEnum,
	DocumentAccessLevelType,
} from '../../api/documents.types';

export type DocumentRecipientsProps = {
	accessLevel: DocumentAccessLevelType;
	onRecipientsUpdate: () => void;
};

export const DocumentRecipients = ({
	accessLevel,
	onRecipientsUpdate: handleRecipientsUpdate,
}: DocumentRecipientsProps) => {
	const { participant } = useCurrentParticipant();
	const { userOnResearch } = useCurrentResearch();
	if (accessLevel !== 'CUSTOM')
		return <p className="text-sm">{DocumentAccessLevelEnum[accessLevel]}</p>;

	if (participant || userOnResearch?.role === 'OFFSITE_RESEARCH_MEMBER')
		return <p className="text-sm">Privé</p>;

	return (
		<Button variant={'link'} onClick={handleRecipientsUpdate} size={'sm'}>
			Personnalisé
		</Button>
	);
};
