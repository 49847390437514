import { Button } from '@/components/ui/button';
import { zodResolver } from '@hookform/resolvers/zod';
import { Accordion } from '@mantine/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useGetUsersOnResearch } from '../../../usersOnResearch/api/hooks/users-on-research.hooks';
import { VisitScheduleQueryParams } from '../../api/visit-schedule.types';
import { ParticipantsFilterItem } from '../atoms/ParticipantsFilterItem';
import { VisitLocationFilterItem } from '../atoms/VisitLocationFilterItem';
import {
	FilterVisitsSchedulesZodSchema,
	FilterVisitsSchedulesZodType,
} from './validators/FilterVisitsSchedulesZodSchema';

export type FilterPaneProps = {
	onSubmit: (data: FilterVisitsSchedulesZodType) => void;
	isLoading: boolean;
	currentParams: VisitScheduleQueryParams;
};

export const FilterVisitsSchedulesForm = ({
	isLoading: isFetchVisitsSchedulesQueryLoading,
	currentParams,
	onSubmit,
}: FilterPaneProps) => {
	const {
		usersOnResearch: participants,
		isLoading,
		error,
	} = useGetUsersOnResearch(['PARTICIPANT']);

	const getFiltersFromCurrentQueryParams = () => ({
		isInCenter:
			currentParams.isRemote === undefined ? false : !currentParams.isRemote,
		isRemote:
			currentParams.isRemote === undefined ? false : currentParams.isRemote,
		participantIds: currentParams.participantIds ?? [],
	});

	const resetFilters = () => {
		formMethods.reset({
			isInCenter: false,
			isRemote: false,
			participantIds: [],
		});
	};

	const formMethods = useForm<FilterVisitsSchedulesZodType>({
		resolver: zodResolver(FilterVisitsSchedulesZodSchema),
		defaultValues: getFiltersFromCurrentQueryParams(),
	});

	return (
		<FormProvider {...formMethods}>
			<form onSubmit={formMethods.handleSubmit(onSubmit)} className="h-full">
				<div className="flex h-full flex-col justify-between gap-4">
					<Accordion
						order={3}
						defaultValue={['participants', 'visits']}
						multiple={true}
					>
						<div className="flex flex-col gap-4">
							<ParticipantsFilterItem
								participants={participants ?? []}
								isLoading={isLoading}
								error={error}
							/>
							<VisitLocationFilterItem />
						</div>
					</Accordion>

					<div className="flex gap-4">
						<Button onClick={resetFilters} variant="outline" className="w-full">
							Réinitialiser
						</Button>

						<Button
							type="submit"
							disabled={isFetchVisitsSchedulesQueryLoading}
							className="w-full"
						>
							Filtrer
						</Button>
					</div>
				</div>
			</form>
		</FormProvider>
	);
};
