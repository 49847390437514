import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '../../../App';
import { useCurrentResearch } from '../../researches/contexts/researches.context';
import { usersOnResearchQueryKeys } from '../../usersOnResearch/api/users-on-research.query-keys';
import { useCurrentParticipant } from '../../usersOnResearch/contexts/participants.context';
import { UpdateParticipantContactInfoDto } from './participant-contact.dto';
import { updateParticipantContactInfo } from './participant-contact.mutations';

export const useUpdateParticipantContactInfo = () => {
	const { research } = useCurrentResearch();
	const { participant } = useCurrentParticipant();

	const updateUserInfoMutation = useMutation({
		mutationFn: async (data: UpdateParticipantContactInfoDto) =>
			updateParticipantContactInfo(research.id, participant.userId, data),
		onSuccess: () => {
			notifications.show({
				message: 'Informations mises à jour avec succès',
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
			queryClient.invalidateQueries({
				queryKey: usersOnResearchQueryKeys.detail(
					research.id,
					Number(participant.userId),
				),
			});
		},
		onError: () => {
			notifications.show({
				message:
					'Une erreur est survenue lors de la mise à jour de vos informations, veuillez réessayer',
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		},
	});

	return { updateUserInfoMutation };
};
