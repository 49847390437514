import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ParticipantProfile } from '../../../profile/api/types/participants.types';
import { Role } from '../../../profile/api/types/user.types';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { updateUserOnResearchStatus } from '../mutations/users-on-research.mutations';
import {
	getMyselfOnResearch,
	getUserOnResearchDetails,
	getUsersOnResearch,
	getUsersOnResearchDetails,
} from '../queries/user-on-research.queries';
import {
	UserOnResearch,
	UserOnResearchStatusType,
} from '../types/users-on-research.types';
import { usersOnResearchQueryKeys } from '../users-on-research.query-keys';

export const useGetMyselfOnResearch = (researchId: number) => {
	const {
		data: userOnResearch,
		isLoading,
		error,
	} = useQuery<UserOnResearch>({
		queryKey: usersOnResearchQueryKeys.me(researchId),
		queryFn: () => getMyselfOnResearch(researchId),
	});

	return { userOnResearch, isLoading, error };
};

export const useGetUsersOnResearch = (
	roles?: Role[],
	statuses?: UserOnResearchStatusType[],
) => {
	const { research } = useCurrentResearch();

	const {
		data: usersOnResearch,
		isLoading,
		error,
	} = useQuery<UserOnResearch[]>({
		queryKey: usersOnResearchQueryKeys.list(research.id, { roles, statuses }),
		queryFn: () => getUsersOnResearch(research.id, roles, statuses),
	});

	return { usersOnResearch, isLoading, error };
};

export const useGetUserOnResearchDetails = (
	userId: number,
	enabled: boolean = false,
) => {
	const { research } = useCurrentResearch();

	const {
		data: userOnResearch,
		isLoading,
		error,
	} = useQuery<
		UserOnResearch & {
			participantProfile?: ParticipantProfile;
		}
	>({
		queryKey: usersOnResearchQueryKeys.detail(research.id, userId),
		queryFn: () => getUserOnResearchDetails(research.id, userId),
		enabled,
	});

	return { userOnResearch, isLoading, error };
};

export const useGetUsersOnResearchDetails = (
	roles?: Role[],
	statuses?: UserOnResearchStatusType[],
) => {
	const { research, userOnResearch } = useCurrentResearch();

	const {
		data: usersOnResearch,
		isLoading,
		error,
	} = useQuery<UserOnResearch[]>({
		queryKey: usersOnResearchQueryKeys.details(research.id, {
			roles,
			statuses,
		}),
		queryFn: () => getUsersOnResearchDetails(research.id, roles, statuses),
		enabled: userOnResearch?.role !== 'OFFSITE_RESEARCH_MEMBER',
	});

	return { usersOnResearch, isLoading, error };
};

export const useUpdateUserOnResearchStatus = (
	memberId: number,
	status: Omit<UserOnResearchStatusType, 'PENDING'>,
	successCallback?: () => void,
) => {
	const { research } = useCurrentResearch();
	const queryClient = useQueryClient();

	const showSuccessNotification = () => {
		notifications.show({
			message: `Membre ${status === 'ACTIVE' ? 'ajouté à' : 'retiré de'} la recherche`,
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const mutation = useMutation({
		mutationFn: async () =>
			updateUserOnResearchStatus(memberId, { status }, research.id),
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: usersOnResearchQueryKeys.lists(research.id),
			});
			showSuccessNotification();
			successCallback?.();
		},
		onError: showErrorNotification,
	});

	return mutation;
};
