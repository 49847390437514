import { AxiosError } from 'axios';
import { publicAxios } from '../../../../shared/services/publicAxios';
import { JoinResearchResponse } from '../../shared/types/JoinResearchResponse';
import { LoginResponse } from '../../shared/types/AuthToken';
import { OnsiteResearchMemberSignUpDto } from './dto/OnsiteResearchMemberSignupDto';

export const onsiteResearchMemberJoinResearchQuery = async (
	key: string,
): Promise<JoinResearchResponse> => {
	try {
		const response = await publicAxios.post<JoinResearchResponse>(
			`/onsite-research-members/confirm-join-research`,
			undefined,
			{ headers: { Authorization: `Bearer ${key}` } },
		);
		return response.data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const OnsiteResearchMemberSignUpQuery = async (
	body: OnsiteResearchMemberSignUpDto,
	key: string,
): Promise<LoginResponse> => {
	try {
		const response = await publicAxios.post<LoginResponse>(
			`/onsite-research-members/signup`,
			body,
			{
				headers: { Authorization: `Bearer ${key}` },
			},
		);
		return response.data;
	} catch (error) {
		throw error as AxiosError;
	}
};
