import { useIsMobile } from '@/components/hooks/useIsMobile';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { UserOnResearch } from '../../api/types/users-on-research.types';
import { ResearchMemberCard } from '../cards/ResearchMemberCard';
import { AddCaregiverForm } from '../forms/validators/AddCaregiverForm';

type Props = {
	participant: UserOnResearch;
	handleClose: () => void;
};
export function AddCaregiverModal({ participant, handleClose }: Props) {
	const isMobile = useIsMobile();

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			fullScreen={isMobile}
			title="Associer un aidant à ce participant"
			size="lg"
		>
			<div className="flex flex-col gap-6">
				<p className="text-center">
					L'aidant désigné recevra une invitation par mail pour s'inscrire et
					rejoindre la recherche.
				</p>
				<div className="mx-auto">
					<ResearchMemberCard user={participant} />
				</div>
				<AddCaregiverForm handleClose={handleClose} participant={participant} />
			</div>
		</Modal>
	);
}
