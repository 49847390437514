import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import MyField from '@/components/ui/form/form-field';
import { MyTextArea } from '@/components/ui/form/text-area-field';
import { zodResolver } from '@hookform/resolvers/zod';
import { Rating } from '@mantine/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { MAX_CONTENT_LENGTH, MAX_RATING } from '../../constants';
import {
	CreateRatingZodSchema,
	CreateRatingZodType,
} from './validators/CreateRatingValidator';

type Props = {
	isLoading: boolean;
	handleSubmit: (data: CreateRatingZodType) => void;
	handleClose: () => void;
};
export function CreateRatingForm({
	isLoading,
	handleSubmit: submit,
	handleClose,
}: Props) {
	const isMobile = useIsMobile();
	const methods = useForm<CreateRatingZodType>({
		resolver: zodResolver(CreateRatingZodSchema),
		defaultValues: {
			rating: 0,
			title: '',
			comment: '',
		},
	});
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = methods;

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(submit)}>
				<div className="flex flex-col gap-8">
					<div className="flex flex-col gap-1">
						<p className="text-sm">Note globale</p>
						<Controller
							control={control}
							name="rating"
							render={({ field: { onChange, value } }) => (
								<Rating
									onChange={(newValue) => onChange(newValue)}
									value={value}
									count={MAX_RATING}
									size="xl"
								/>
							)}
						/>
					</div>

					<MyField
						type="text"
						name="title"
						label="Titre de l'avis"
						required
						placeholder="Entrez un titre pour votre avis"
						labelClass="text-black"
						inputClass="text-black"
						error={errors.title?.message}
					/>

					<MyTextArea
						name="comment"
						label="Commentaire"
						placeholder={`Entrez votre commentaire ici, ${MAX_CONTENT_LENGTH} caractères maximum.`}
						maxChar={MAX_CONTENT_LENGTH}
						labelAdditionalClass="text-black"
						textareaAdditionalClass="text-black"
					/>

					<div className="flex gap-4">
						{!isMobile && (
							<Button
								onClick={handleClose}
								variant="outline"
								className="w-full"
							>
								Annuler
							</Button>
						)}
						<Button type="submit" disabled={isLoading} className="w-full">
							Enregistrer
						</Button>
					</div>
				</div>
			</form>
		</FormProvider>
	);
}
