import { LineChart } from '@mantine/charts';
import { Box, Stack } from '@mantine/core';
import { useState } from 'react';
import {
	formatDate,
	getAllTimeTicksInTimeLapse,
	isSameTimeScale,
} from '../../../../shared/utils/dates';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { DayTimeScale } from '../../types/TimeScale';
import { FigureCard } from '../atoms/FigureCard';
import { ToggleXAxisTimeScale } from '../atoms/ToggleXAxisTimeScale';

export type ParticipantEnrollmentLineChartProps = {
	enrollmentParticipantsDates: Date[];
};

export const ParticipantEnrollmentLineChart = ({
	enrollmentParticipantsDates,
}: ParticipantEnrollmentLineChartProps) => {
	const { research } = useCurrentResearch();
	const [timeScale, setTimeScale] = useState<DayTimeScale>('month');

	const getLegendLabel = () => {
		let label = 'Participants inclus durant ';
		switch (timeScale) {
			case 'week':
				label += 'la semaine';
				break;
			case 'month':
				label += 'le mois';
				break;
			case 'year':
				label += "l'année";
				break;
			default:
				// eslint-disable-next-line no-case-declarations
				const _exhaustiveCheck: never = timeScale;
				return _exhaustiveCheck;
		}
		return label;
	};

	const getChartData = () => {
		const result: {
			date: string;
			Participants: number;
		}[] = [];

		const timeTicks = getAllTimeTicksInTimeLapse(
			research.createdAt,
			new Date(),
			timeScale,
		);

		timeTicks.forEach((timeTick) => {
			const enrolledParticipants = enrollmentParticipantsDates.filter(
				(d) => d && isSameTimeScale(d, timeTick, timeScale),
			);
			result.push({
				date: formatDate(
					timeTick,
					timeScale === 'week' ? 'D MMM YYYY' : 'MMM YYYY',
				),
				Participants: enrolledParticipants.length,
			});
		});
		return result;
	};

	return (
		<FigureCard title={"Courbe d'inclusion des participants"}>
			<Stack gap="lg">
				<ToggleXAxisTimeScale
					timeScale={timeScale}
					setTimeScale={setTimeScale}
				/>
				<Box w="95%" mx="auto">
					<LineChart
						data={getChartData()}
						dataKey="date"
						h={300}
						series={[
							{
								name: 'Participants',
								label: getLegendLabel(),
								color: 'var(--mantine-primary-color-6)',
							},
						]}
						curveType="linear"
						tickLine="x"
						gridAxis="y"
						yAxisProps={{
							tickMargin: 10,
							tick: {
								fontSize: 'var(--mantine-font-size-sm)',
								fill: 'var(--mantine-color-gray-7)',
							},
							label: {
								value: 'Nombre de participants',
								position: 'insideBottomLeft',
								angle: -90,
								offset: 10,
							},
							allowDecimals: false,
						}}
						xAxisProps={{
							tickMargin: 10,
							tick: {
								fontSize: 'var(--mantine-font-size-sm)',
								fill: 'var(--mantine-color-gray-7)',
							},
						}}
						dotProps={{ r: 6, strokeWidth: 2, stroke: '#fff' }}
						withLegend
						legendProps={{ verticalAlign: 'bottom', align: 'center' }}
					/>
				</Box>
			</Stack>
		</FigureCard>
	);
};
