import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@/components/ui/form';
import { cn } from '@/components/utils';
import { IconEye, IconEyeClosed } from '@tabler/icons-react';
import clsx from 'clsx';
import { useState } from 'react';
import MyField, { MyFieldProps } from './form-field';

export function MyPasswordField({
	name,
	label,
	placeholder,
	error,
	onChange,
	onPaste,
	required,
	disabled,
	accept,
	additionalClass,
	fieldAdditionalClass,
	labelClass,
	inputClass,
	direction,
	defaultValue,
	inputMode,
	pattern,
	autoComplete,
	maxLength,
	step,
	min,
}: Omit<MyFieldProps, 'type'>) {
	const [showPassword, setShowPassword] = useState(false);
	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const formItemClasses = clsx('flex w-full flex-col', additionalClass);
	const formFieldClasses = clsx(
		'flex w-full rounded-md border border-input bg-background px-6 py-4 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium  focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50',
		fieldAdditionalClass,
	);
	const inputClasses = clsx(
		'visible:ring-0 flex-1 placeholder:text-muted-foreground focus:outline-none focus-visible:ring-0',
		{
			'text-white': !inputClass,
		},
	);

	return (
		<FormItem className={formItemClasses}>
			<FormLabel
				className={clsx(labelClass, {
					'text-base font-semibold text-white': !labelClass,
				})}
			>
				<div className="flex space-x-1">
					<p>{label}</p>
					{required && <span className="text-sm text-error-500">*</span>}
				</div>
			</FormLabel>
			<div className={direction ? '' : 'flex w-full flex-col'}>
				<FormField
					name={name}
					render={({ field }) => (
						<FormControl>
							<div className={cn(formFieldClasses, 'justify-between')}>
								<input
									type={showPassword ? 'text' : 'password'}
									{...field}
									placeholder={placeholder}
									className={inputClasses}
									inputMode={inputMode}
									pattern={pattern}
									autoComplete={autoComplete}
									maxLength={maxLength}
									onChange={(e) => {
										field.onChange(e);
										onChange && onChange(e);
									}}
									onPaste={(e) => {
										onPaste && onPaste(e);
									}}
									accept={accept}
									defaultValue={defaultValue}
									step={step}
									disabled={disabled}
									min={min}
								/>
								<span
									onClick={togglePasswordVisibility}
									className="inset-y-0 right-0 flex cursor-pointer items-center pr-3"
								>
									{showPassword ? (
										<IconEyeClosed
											size={20}
											className="text-muted-foreground"
										/>
									) : (
										<IconEye size={20} className="text-muted-foreground" />
									)}
								</span>
							</div>
						</FormControl>
					)}
				/>
				{error && (
					<FormMessage className="mt-[6px] text-sm font-medium text-error-500">
						{error}
					</FormMessage>
				)}
			</div>
		</FormItem>
	);
}

MyField.defaultProps = {
	error: '',
	onChange: () => {},
	accept: '',
	additionalClass: '',
	fieldAdditionalClass: '',
	labelClass: '',
	inputClass: '',
};

export default MyField;
