import { useIsMobile } from '@/components/hooks/useIsMobile.ts';
import { Button } from '@/components/ui/button.tsx';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { useState } from 'react';
import { CreateParticipantDto } from '../../api/dto/participants.dto.ts.ts';
import { useAddParticipants } from '../../api/hooks/participants.hooks.tsx';
import { AddParticipantForm } from '../forms/validators/AddParticipantForm.tsx';
import { ParticipantControlPanel } from '../inputs/ParticipantControlPanel.tsx';

type Props = {
	handleClose: () => void;
};
export function AddParticipantModal({ handleClose }: Props) {
	const [participants, setParticipants] = useState<CreateParticipantDto[]>([]);
	const { addParticipantsMutation, isLoading } =
		useAddParticipants(handleClose);
	const isMobile = useIsMobile();

	return (
		<Modal
			opened={true}
			onClose={() => {
				setParticipants([]);
				handleClose();
			}}
			centered
			classNames={classes}
			fullScreen={isMobile}
			title="Ajouter un ou plusieurs participants à cette recherche"
			size="xl"
		>
			<div className="flex flex-col gap-6">
				<AddParticipantForm
					participants={participants}
					setParticipants={setParticipants}
				/>
				<ParticipantControlPanel
					participants={participants}
					setParticipants={setParticipants}
				/>
			</div>
			<div className="flex gap-4">
				{!isMobile && (
					<Button
						onClick={() => {
							setParticipants([]);
							handleClose();
						}}
						variant="outline"
						className="w-full"
					>
						Annuler
					</Button>
				)}
				<Button
					onClick={() => addParticipantsMutation(participants)}
					disabled={isLoading || participants.length === 0}
					className="w-full"
				>
					Ajouter
				</Button>
			</div>
		</Modal>
	);
}
