import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useInvalidateQueries } from '../../../../../shared/hooks/useInvalidateQueries';
import { dashboardQueryKeys } from '../../../../dashboard/api/dashbord.query-keys';
import { useCurrentResearch } from '../../../../researches/contexts/researches.context';
import { visitsQueryKeys } from '../../../api/visits.query-keys';
import { actionsQueryKeys } from '../../api/actions.query-keys';
import {
	completeMiscellaneousAction,
	resetMiscellaneousAction,
} from './miscellaneous-actions.mutations';

export const useSetMiscellaneousActionResultMutation = (
	actionId: number,
	userId: number,
	successCallback: () => void,
) => {
	const { research } = useCurrentResearch();
	const invalidateQueries = useInvalidateQueries();

	const showSuccessNotification = (isChecked: boolean) => {
		const message = isChecked
			? 'Action marquée comme complétée'
			: 'Action réinitialisée';
		notifications.show({
			message,
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const setMiscellaneousActionResultMutation = useMutation({
		mutationFn: async (isChecked: boolean) =>
			isChecked
				? completeMiscellaneousAction(actionId, userId)
				: resetMiscellaneousAction(actionId, userId),
		onSuccess: (_, isChecked) => {
			showSuccessNotification(isChecked);
			successCallback();
			invalidateQueries([
				dashboardQueryKeys.detail(research.id),
				visitsQueryKeys.byResearch(research.id),
				actionsQueryKeys.results(actionId),
			]);
		},
		onError: showErrorNotification,
	});

	return { setMiscellaneousActionResultMutation };
};
