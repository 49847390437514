import { TimeInput } from '@mantine/dates';
import { IconClock } from '@tabler/icons-react';
import { Control, Controller } from 'react-hook-form';
import classes from './time-picker.module.scss';

type CustomTimePickerProps = {
	name: string;
	label: string;
	placeholder: string;
	control: Control;
	error?: string;
	required?: boolean;
	additionalClass?: string;
	fieldAdditionalClass?: string;
};

export const CustomTimePicker = ({
	name,
	label,
	placeholder,
	required,
	control,
}: CustomTimePickerProps) => {
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { onChange, value }, fieldState: { error } }) => (
				<TimeInput
					onChange={(newValue) => onChange(newValue)}
					value={value?.toString()}
					withAsterisk={required}
					classNames={classes}
					label={label}
					leftSection={<IconClock className={'h-6 w-5'} />}
					placeholder={placeholder}
					error={error?.message}
				/>
			)}
		/>
	);
};
