import { ActionIcon, Tooltip } from '@mantine/core';
import { ReactNode } from 'react';

type Props = {
	handleClick: () => void;
	label: string;
	icon: ReactNode;
	color?: string;
	disabled?: boolean;
};

export const ActionButton = ({
	handleClick,
	label,
	icon,
	color,
	disabled,
}: Props) => {
	return (
		<Tooltip label={label} radius="md" offset={4}>
			<ActionIcon
				onClick={handleClick}
				variant="transparent"
				size="lg"
				disabled={disabled}
				c={color}
			>
				{icon}
			</ActionIcon>
		</Tooltip>
	);
};
