import { AxiosError } from 'axios';
import { privateAxios } from '../../../../../shared/services/privateAxios';

export const completeMiscellaneousAction = async (
	actionId: number,
	participantId: number,
) => {
	try {
		const { data } = await privateAxios.post(
			`visits/action/${actionId}/participant/${participantId}/complete-miscellaneous-action`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const resetMiscellaneousAction = async (
	actionId: number,
	participantId: number,
) => {
	try {
		const { data } = await privateAxios.delete(
			`visits/action/${actionId}/participant/${participantId}/reset-miscellaneous-action`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
