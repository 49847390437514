import { useIsMobile } from '@/components/hooks/useIsMobile';
import { CreateRatingModal } from '@/features/ratings/components/modals/CreateRatingModal';
import { useDisclosure } from '@mantine/hooks';
import { useEffect } from 'react';
import { NextMeetingsCard } from '../../meets/components/cards/NextMeetingsCard';
import { NEXT_RATING_DATE } from '../../ratings/constants';
import { ResearchesTable } from '../components/tables/ResearchesTable';
import { WelcomeCard } from '../components/ui/WelcomeCard';

export function ResearchesPage() {
	const isMobile = useIsMobile();
	const [opened, { open, close }] = useDisclosure();

	useEffect(() => {
		const nextRatingDate = localStorage.getItem(NEXT_RATING_DATE);
		if (nextRatingDate && new Date(nextRatingDate) < new Date()) {
			open();
		}
	}, [open]);

	return (
		<div className="flex h-full w-full flex-col gap-4">
			<h2>Recherches cliniques</h2>
			{!isMobile && (
				<div className="flex max-h-[30%] min-h-32 justify-between gap-6">
					<WelcomeCard />
					<NextMeetingsCard />
				</div>
			)}
			<ResearchesTable />
			{opened && <CreateRatingModal onClose={close} />}
		</div>
	);
}
