export const actionsQueryKeys = {
	all: ['actions'] as const,
	byResearch: (researchId: number) =>
		[...actionsQueryKeys.all, 'research', researchId] as const,
	byVisit: (researchId: number, visitId: number) =>
		[...actionsQueryKeys.byResearch(researchId), 'visit', visitId] as const,

	byId: (actionId: number) =>
		[...actionsQueryKeys.all, 'id', actionId] as const,
	detail: (actionId: number) =>
		[...actionsQueryKeys.byId(actionId), 'detail'] as const,
	results: (actionId: number) =>
		[...actionsQueryKeys.byId(actionId), 'results'] as const,
	result: (actionId: number, participantId: number) =>
		[
			...actionsQueryKeys.results(actionId),
			'participant',
			participantId,
		] as const,
};
