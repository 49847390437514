import { MyTabsWithScroll } from '@/components/ui/tabs/tabs-with-scroll';
import { paths } from '../../routes/paths';

export function OffsiteResearchMemberTabs() {
	const tabItems = [
		{
			link: paths.PARTICIPANTS_TAB,
			label: 'Participants',
		},
		{
			link: paths.DOCUMENTS_TAB,
			label: 'Documents',
		},
		{
			link: paths.MESSAGING_TAB,
			label: 'Messagerie',
		},
	];

	return <MyTabsWithScroll tabItems={tabItems} />;
}
