import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { Research, ResearchStatusType } from './researches.types';

export const getResearch = async (researchId: number): Promise<Research> => {
	try {
		const { data } = await privateAxios.get<Research>(
			`/researches/${researchId}`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getResearches = async (
	statuses?: ResearchStatusType[],
): Promise<Research[]> => {
	try {
		const { data } = await privateAxios.get<Research[]>('/researches', {
			params: { status: statuses },
		});
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
