import { useIsMobile } from '@/components/hooks/useIsMobile';
import classes from '@/components/ui/modal/modal.module.scss';
import { ResearchMemberCard } from '@/features/usersOnResearch/components/cards/ResearchMemberCard';
import { Modal } from '@mantine/core';
import { IconVideo } from '@tabler/icons-react';
import dayjs from 'dayjs';
import { UserOnMeet } from '../../api/meets.types';

type ShowMeetParticipantsModalProps = {
	participants: UserOnMeet[];
	meetName: string;
	startDate: Date;
	handleClose: () => void;
};

export function ShowMeetParticipantsModal({
	participants,
	meetName,
	startDate,
	handleClose,
}: ShowMeetParticipantsModalProps) {
	const isMobile = useIsMobile();

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			title="Participants de la visioconférence"
			size="lg"
			fullScreen={isMobile}
		>
			<div className="flex flex-col gap-6">
				<div className="flex flex-col items-center">
					<IconVideo className="h-10 w-10 text-orange-500" />
					<h4>{meetName}</h4>
					<h5 className="font-normal">
						{dayjs(startDate).format('[le] D MMM YYYY [à] HH:mm')}
					</h5>
				</div>
				<div className="flex flex-col items-center justify-center gap-2">
					{participants.map((participant) => (
						<ResearchMemberCard key={participant.userId} user={participant} />
					))}
				</div>
			</div>
		</Modal>
	);
}
