import { AxiosError, AxiosResponse } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import {
	MeetStatusType,
	MeetWithParticipants,
	EyesonMeet,
} from './meets.types';

export const getMyMeets = async (
	status: MeetStatusType,
): Promise<MeetWithParticipants[]> => {
	try {
		const { data } = await privateAxios.get<MeetWithParticipants[]>(`/meets`, {
			params: { status },
		});
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getMeetsByParticipant = async (
	researchId: number,
	participantId: number,
): Promise<MeetWithParticipants[]> => {
	try {
		const { data } = await privateAxios.get<MeetWithParticipants[]>(
			`/meets/researches/${researchId}/participant/${participantId}`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const getResearchMeets = async (
	researchId: number,
): Promise<MeetWithParticipants[]> => {
	try {
		const { data } = await privateAxios.get<MeetWithParticipants[]>(
			`/meets/researches/${researchId}`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const joinMeet = async (meetId: number) => {
	try {
		const { data } = await privateAxios.get<EyesonMeet>(
			`/meets/${meetId}/join-meet`,
		);
		return data;
	} catch (error) {
		throw error as AxiosResponse;
	}
};
