import { AxiosError } from 'axios';
import { privateAxios } from '../../../../../shared/services/privateAxios';
import { PreSignedPutUrl } from '../../../../../shared/types/PreSignedPutUrl';

export const getActionResultDocumentUploadUrl = async (
	fileName: string,
): Promise<PreSignedPutUrl> => {
	try {
		const { data } = await privateAxios.get<PreSignedPutUrl>(
			`visits/get-upload-url`,
			{
				params: { 'file-name': fileName },
			},
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const downloadActionResultDocument = async (
	actionId: number,
	participantId: number,
): Promise<PreSignedPutUrl> => {
	try {
		const { data } = await privateAxios.get(
			`visits/action/${actionId}/participant/${participantId}/get-result-document`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const downloadActionResultDocuments = async (
	actionId: number,
	participantId?: number,
): Promise<Blob> => {
	try {
		const { data } = await privateAxios.get<Blob>(
			`/visits/action/${actionId}/get-result-documents-zip`,
			{
				responseType: 'blob',
				params: { participantId },
			},
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
