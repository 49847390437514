import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { CreateDocumentDto, UpdateDocumentDto } from './documents.dto';

export const createDocument = async (
	payload: CreateDocumentDto,
	researchId: number,
) => {
	try {
		const { data } = await privateAxios.post(
			`/documents/researches/${researchId}/create-document`,
			payload,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const deleteDocument = async (documentId: number) => {
	try {
		const { data } = await privateAxios.delete(
			`/documents/${documentId}/delete-document`,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const updateDocumentRecipients = async (
	documentId: number,
	payload: UpdateDocumentDto,
) => {
	try {
		const { data } = await privateAxios.patch(
			`/documents/${documentId}/update-document-recipients`,
			payload,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
