import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Badge } from '@/components/ui/badge';
import { Button } from '@/components/ui/button';
import '@/components/ui/menu/navigation-menu.css';
import { Indicator } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconBell } from '@tabler/icons-react';
import { useGetUnreadNotificationsCount } from '../../api/notifications.hooks';
import { NotificationsPane } from '../../pages/NotificationsPane';

export const NotificationsCountBadge = () => {
	const [
		opened,
		{ close: closeNotificationPane, toggle: toggleNotificationPane },
	] = useDisclosure(false);
	const isMobile = useIsMobile();
	const { unreadNotificationsCount, isLoading, error } =
		useGetUnreadNotificationsCount();

	const shouldDisplayBadge = !isLoading && !error && !!unreadNotificationsCount;
	const label =
		shouldDisplayBadge && unreadNotificationsCount <= 99
			? unreadNotificationsCount
			: '99+';

	if (isMobile)
		return (
			<>
				<div onClick={toggleNotificationPane} className="cursor-pointer">
					<Indicator
						variant="filled"
						color="red"
						disabled={!shouldDisplayBadge}
						label={label}
						size={'md'}
					>
						<IconBell size="1.5rem" />
					</Indicator>
				</div>
				{opened && <NotificationsPane handleClose={closeNotificationPane} />}
			</>
		);

	return (
		<>
			<Button
				size={'sm'}
				variant={'ghost'}
				className="NavigationMenuLink w-full justify-start"
				onClick={toggleNotificationPane}
			>
				<div className="flex flex-1 items-center gap-2">
					<div className="flex space-x-2">
						<IconBell />
						<p className="text-sm">Notifications</p>
					</div>

					{shouldDisplayBadge && (
						<Badge variant={'secondary'} className="px-2 py-0">
							{label}
						</Badge>
					)}
				</div>
			</Button>
			{opened && <NotificationsPane handleClose={closeNotificationPane} />}
		</>
	);
};
