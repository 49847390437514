import axios, { AxiosResponse } from 'axios';
import { UploadFileDto } from './files.dto';

export const uploadFileQuery = async (data: UploadFileDto) => {
	const response = await axios({
		method: 'put',
		url: data.signedUrl,
		data: data.file,
	});

	if (response.status === 200) {
		const axiosResponse = response as AxiosResponse;
		return axiosResponse.data;
	}

	throw response;
};
