import { AxiosError } from 'axios';
import { privateAxios } from '../../../../shared/services/privateAxios';

export const resendOffsiteResearchMemberInviteLink = async (
	userId: number,
	researchId: number,
): Promise<void> => {
	try {
		await privateAxios.get<void>(
			`/offsite-research-members/${userId}/resend-invite-link/research/${researchId}`,
		);
	} catch (error) {
		throw error as AxiosError;
	}
};
