import { AxiosResponse } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';

export const updateResearch = async (
	researchId: number,
	name?: string,
	forecastParticipantsCount?: number,
) => {
	try {
		const { data } = await privateAxios.put(
			`/researches/${researchId}/update-research`,
			{
				name: name,
				forecastParticipantsCount: forecastParticipantsCount,
			},
		);
		return data;
	} catch (error) {
		throw error as AxiosResponse;
	}
};
