import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ReactNode, createContext, useContext } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { CustomLoader } from '../../../components/ui/loader';
import { paths } from '../../../routes/paths';
import { ParticipantProfile } from '../../profile/api/types/participants.types';
import { useCurrentResearch } from '../../researches/contexts/researches.context';
import { getParticipantDetails } from '../api/queries/participants.queries';
import { ParticipantOnResearch } from '../api/types/participants.types';
import { UserOnResearch } from '../api/types/users-on-research.types';
import { usersOnResearchQueryKeys } from '../api/users-on-research.query-keys';

export type ParticipantContextData = {
	participant: UserOnResearch & {
		participantOnResearch: ParticipantOnResearch;
		participantProfile: ParticipantProfile;
	};
};
export const ParticipantContext = createContext({} as ParticipantContextData);

type ParticipantProviderProps = {
	children: ReactNode;
};

export function ParticipantProvider(props: ParticipantProviderProps) {
	const { participantId } = useParams();
	const { research } = useCurrentResearch();
	const { children } = props;

	const {
		data: participant,
		isLoading,
		error,
	} = useQuery<UserOnResearch, AxiosError>({
		queryKey: usersOnResearchQueryKeys.detail(
			research.id,
			Number(participantId),
		),
		queryFn: () => getParticipantDetails(research.id, Number(participantId)),
		enabled: !!research.id && !!participantId,
	});

	if (isLoading) return <CustomLoader />;
	if (error) return <Navigate to={`/${paths.ERROR_PATH}`} />;

	return (
		<ParticipantContext.Provider
			value={{
				participant: participant as UserOnResearch & {
					participantOnResearch: ParticipantOnResearch;
					participantProfile: ParticipantProfile;
				},
			}}
		>
			{children}
		</ParticipantContext.Provider>
	);
}
// eslint-disable-next-line react-refresh/only-export-components
export function useCurrentParticipant() {
	return useContext(ParticipantContext);
}
