import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { handleExportData } from '../../../../shared/utils/files';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { formatResearchMemberData } from '../../utils/formatResearchMemberData';
import { CreateResearchMemberDto } from '../dto/research-members.dto.ts';
import {
	addInvestigatorOnResearch,
	addOffsiteResearchMemberOnResearch,
	addOnsiteResearchMemberOnResearch,
} from '../mutations/research-members.mutations';
import { getOffsiteResearchMembersByParticipant } from '../queries/research-members.queries.ts';
import {
	UserOnResearch,
	UserOnResearchStatusType,
} from '../types/users-on-research.types.ts';
import { usersOnResearchQueryKeys } from '../users-on-research.query-keys.ts';
import { useGetUsersOnResearchDetails } from './users-on-research.hooks';

export const useGetOffsiteResearchMembersByParticipant = (
	participantId: number,
	statuses: UserOnResearchStatusType[],
) => {
	const { research } = useCurrentResearch();

	const {
		data: offsiteResearchMembers,
		isLoading,
		error,
	} = useQuery<UserOnResearch[]>({
		queryKey: usersOnResearchQueryKeys.listOffsiteResearchMembersByParticipant(
			research.id,
			participantId,
			statuses,
		),
		queryFn: () =>
			getOffsiteResearchMembersByParticipant(
				research.id,
				participantId,
				statuses,
			),
	});

	return { offsiteResearchMembers, isLoading, error };
};

export const useAddResearchMembers = (onSettled?: () => void) => {
	const { research } = useCurrentResearch();
	const addedResearchMembers = useRef(0);
	const queryClient = useQueryClient();
	const [isLoading, setIsLoading] = useState(false);

	const handleSuccess = () => {
		queryClient.invalidateQueries({
			queryKey: usersOnResearchQueryKeys.lists(research.id),
		});
		const message =
			addedResearchMembers.current > 1
				? `Ajout de ${addedResearchMembers.current} membres à l'équipe de recherche`
				: "Ajout d'un membre à l'équipe de recherche";
		notifications.show({
			message: message,
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const addResearchMembersMutation = async (
		researchMembers: CreateResearchMemberDto[],
	) => {
		const promises = researchMembers.map(
			async (researchMember) =>
				await addResearchMemberMutation.mutateAsync(researchMember),
		);
		setIsLoading(true);
		await Promise.all(promises);
		handleSuccess();
		onSettled?.();
	};

	const addResearchMemberMutation = useMutation({
		mutationFn: async (payload: CreateResearchMemberDto) => {
			const { role, ...body } = payload;
			switch (role) {
				case 'INVESTIGATOR':
					return await addInvestigatorOnResearch(body);
				case 'ONSITE_RESEARCH_MEMBER':
					return await addOnsiteResearchMemberOnResearch(body);
				case 'OFFSITE_RESEARCH_MEMBER':
					return await addOffsiteResearchMemberOnResearch(body);
			}
			throw new Error('Role not found');
		},
		onSuccess: () => {
			addedResearchMembers.current += 1;
		},
		onError: (_, payload) => {
			notifications.show({
				message: `Une erreur est survenue lors de l'ajout de ${payload.email}`,
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		},
		onSettled: () => setIsLoading(false),
	});

	return { addResearchMembersMutation, isLoading };
};

export const useExportResearchMemberData = () => {
	const { research } = useCurrentResearch();
	const [isLoading, setIsLoading] = useState(false);

	const { usersOnResearch: researchMembers } = useGetUsersOnResearchDetails([
		'INVESTIGATOR',
		'ONSITE_RESEARCH_MEMBER',
		'OFFSITE_RESEARCH_MEMBER',
	]);

	const exportResearchMemberData = async () => {
		setIsLoading(true);

		try {
			if (!researchMembers) {
				notifications.show({
					message:
						'Les données des membres de recherche ne sont pas disponibles, veuillez réessayer plus tard',
					color: 'red',
					icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
				});
			} else {
				const result = researchMembers.map((researchMember) =>
					formatResearchMemberData(researchMember),
				);
				handleExportData(
					result,
					`Liste des membres de l'équipe de recherche - ${
						research.name
					} - ${new Date().toLocaleDateString()}`,
				);
				notifications.show({
					message: `Export réussi`,
					color: 'teal',
					icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
				});
			}
		} catch (error) {
			notifications.show({
				message: "Une erreur est survenue lors de l'export",
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		} finally {
			setIsLoading(false);
		}
	};

	return {
		exportResearchMemberData,
		isLoadingExport: isLoading,
	};
};
