import { ComboboxItem, OptionsFilter, Select } from '@mantine/core';
import { IconSearch } from '@tabler/icons-react';
import React from 'react';
import { UserOnResearch } from '../../../features/usersOnResearch/api/types/users-on-research.types';
import { getResearchMemberInfo } from '../../../features/usersOnResearch/utils/researchMemberInfo';

type SingleSelectUsersOnResearchProps = {
	selectedUser: UserOnResearch | undefined;
	setSelectedUser: React.Dispatch<
		React.SetStateAction<UserOnResearch | undefined>
	>;
	allUsers: UserOnResearch[];
	label?: string;
	placeholder?: string;
	disabled?: boolean;
};
export function SingleSelectUsersOnResearch({
	selectedUser,
	setSelectedUser,
	allUsers,
	label,
	placeholder,
	disabled = false,
}: SingleSelectUsersOnResearchProps) {
	const handleChange = (email: string) => {
		const user = allUsers.find((v) => v.email === email);
		if (!user) return;
		setSelectedUser(user);
	};

	const filterOptions: OptionsFilter = ({ options, search }) => {
		const filtered = (options as ComboboxItem[]).filter((option) =>
			option.label.toLowerCase().includes(search.toLowerCase()),
		);

		filtered.sort((a, b) => {
			if (a.disabled !== b.disabled) return a.disabled ? 1 : -1;
			return a.label.localeCompare(b.label);
		});
		return filtered;
	};

	const getLabel = (user: UserOnResearch) => {
		const { displayTitle, displayRole } = getResearchMemberInfo(user);
		return displayRole ? `${displayTitle} (${displayRole})` : displayTitle;
	};

	return (
		<Select
			label={label ?? null}
			withCheckIcon={false}
			searchable
			placeholder={placeholder}
			leftSection={<IconSearch />}
			size="md"
			filter={filterOptions}
			maxDropdownHeight={200}
			disabled={disabled}
			data={
				allUsers &&
				allUsers.map((user) => ({
					label: getLabel(user),
					value: user.email,
					disabled: selectedUser?.userOnResearchId === user.userOnResearchId,
				}))
			}
			onChange={(value) => handleChange(value || '')}
			value={selectedUser?.email}
		/>
	);
}
