import { ActionIcon, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
	IconCircleMinus,
	IconCirclePlus,
	IconUsersPlus,
} from '@tabler/icons-react';
import dayjs from 'dayjs';
import {
	MRT_Cell,
	MRT_ColumnDef,
	MantineReactTable,
	useMantineReactTable,
} from 'mantine-react-table';
import { useMemo, useState } from 'react';
import { getDefaultMRTOptions } from '../../../../components/ui/table/table-options';
import {
	customDateFilterFn,
	customLocalisationFilterFn,
	customResearchMemberFilterFn,
	customStatusFilterFn,
	isMatch,
} from '../../../../shared/utils/filters';
import { useSession } from '../../../auth/shared/hooks/useSession';
import { Role } from '../../../profile/api/types/user.types';
import { useGetUsersOnResearch } from '../../api/hooks/users-on-research.hooks';
import {
	UserOnResearch,
	UserOnResearchStatusEnum,
	UserOnResearchStatusType,
} from '../../api/types/users-on-research.types';
import { ResearchMemberOnResearchStatusBadge } from '../badges/ResearchMemberOnResearchStatusBadge';
import { ResearchMemberCard } from '../cards/ResearchMemberCard';
import { LinkParticipantsToOffsiteResearchMemberModal } from '../modals/LinkParticipantsToOffsiteResearchMemberModal';
import { ToggleResearchMemberStatusModal } from '../modals/ToggleResearchMemberStatusModal';

export function ResearchMembersTable() {
	const { user } = useSession();

	const [currentResearchMemberOnResearch, setCurrentResearchMemberOnResearch] =
		useState<UserOnResearch>();

	const canUpdateStatus = user?.role === 'INVESTIGATOR';
	const canLinkParticipants = user?.role === 'INVESTIGATOR';

	const {
		usersOnResearch: researchMembers,
		isLoading,
		error,
	} = useGetUsersOnResearch([
		'INVESTIGATOR',
		'OFFSITE_RESEARCH_MEMBER',
		'ONSITE_RESEARCH_MEMBER',
	]);

	const [
		toggleModalOpened,
		{ open: openToggleModal, close: closeToggleModal },
	] = useDisclosure();
	const handleOnToggleModalClick = (researchMember: UserOnResearch) => {
		setCurrentResearchMemberOnResearch(researchMember);
		openToggleModal();
	};

	const [linkModalOpened, { open: openLinkModal, close: closeLinkModal }] =
		useDisclosure();
	const handleOnLinkModalClick = (user: UserOnResearch) => {
		setCurrentResearchMemberOnResearch(user);
		openLinkModal();
	};

	const columns = useMemo<MRT_ColumnDef<UserOnResearch>[]>(
		() => [
			{
				header: 'Nom',
				accessorFn: (row) => row,
				size: 300,
				Cell: ({ cell }: { cell: MRT_Cell<UserOnResearch> }) => (
					<ResearchMemberCard user={cell.row.original} />
				),
				filterFn: 'customResearchMemberFilterFn',
				enableSorting: false,
			},
			{
				header: 'Localisation',
				accessorKey: 'role',
				size: 20,
				Cell: ({ cell }: { cell: MRT_Cell<UserOnResearch> }) => (
					<p className="text-sm">
						{cell.getValue<Role>() === 'OFFSITE_RESEARCH_MEMBER'
							? 'A distance'
							: 'Sur centre'}
					</p>
				),
				filterFn: (row, _id, filterValue) =>
					customLocalisationFilterFn(row.original.role, filterValue),
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
			},
			{
				header: 'Statut',
				accessorKey: 'status',
				size: 20,
				Cell: ({ cell }: { cell: MRT_Cell<UserOnResearch> }) => (
					<ResearchMemberOnResearchStatusBadge
						status={cell.getValue<UserOnResearchStatusType>()}
						userId={cell.row.original.userId}
						userRole={cell.row.original.role}
					/>
				),
				filterFn: (row, _id, filterValue) =>
					isMatch(UserOnResearchStatusEnum[row.original.status], filterValue),
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
			},
			{
				header: "Date d'ajout",
				accessorKey: 'createdAt',
				size: 20,
				Cell: ({ cell }: { cell: MRT_Cell<UserOnResearch> }) => (
					<p className="text-sm">
						{dayjs(cell.getValue<Date>()).format('DD/MM/YY')}
					</p>
				),
				filterFn: (row, _id, filterValue) =>
					customDateFilterFn(row.original.createdAt, filterValue),
				mantineTableHeadCellProps: {
					align: 'center',
				},
				mantineTableBodyCellProps: {
					align: 'center',
				},
			},
			{
				id: 'actions-link',
				header: '',
				columnDefType: 'display',
				size: 1,
				Cell: ({ cell }: { cell: MRT_Cell<UserOnResearch> }) => (
					<>
						{canLinkParticipants &&
							cell.row.original.status !== 'INACTIVE' &&
							cell.row.original.role === 'OFFSITE_RESEARCH_MEMBER' && (
								<Tooltip label="Associer à des volontaires">
									<ActionIcon
										variant="transparent"
										onClick={() => handleOnLinkModalClick(cell.row.original)}
									>
										<IconUsersPlus size={22} stroke={2} />
									</ActionIcon>
								</Tooltip>
							)}
					</>
				),
			},
			{
				id: 'actions-toggle-status',
				header: '',
				columnDefType: 'display',
				size: 1,
				Cell: ({ cell }: { cell: MRT_Cell<UserOnResearch> }) => (
					<>
						{canUpdateStatus &&
							cell.row.original.status !== 'PENDING' &&
							cell.row.original.role !== 'INVESTIGATOR' && (
								<ActionIcon
									onClick={() => handleOnToggleModalClick(cell.row.original)}
									variant="transparent"
									c={
										cell.row.original.status === 'INACTIVE'
											? 'var(--mantine-primary-color-6)'
											: 'red'
									}
								>
									{cell.row.original.status === 'INACTIVE' ? (
										<IconCirclePlus size={22} stroke={2} />
									) : (
										<IconCircleMinus size={22} stroke={2} />
									)}
								</ActionIcon>
							)}
					</>
				),
			},
		],
		[], // eslint-disable-line react-hooks/exhaustive-deps
	);

	const defaultTableOptions = getDefaultMRTOptions<UserOnResearch>(
		Boolean(error),
	);
	const table = useMantineReactTable({
		data: (researchMembers ?? []) as UserOnResearch[],
		columns,
		...defaultTableOptions,
		state: { isLoading },
		filterFns: {
			customStatusFilterFn: (row, _id, filterValue) =>
				customStatusFilterFn(
					row.original.status,
					UserOnResearchStatusEnum,
					filterValue,
				),
			customResearchMemberFilterFn: (row, _id, filterValue) =>
				customResearchMemberFilterFn(row.original, filterValue),
			customLocalisationFilterFn: (row, _id, filterValue) =>
				customLocalisationFilterFn(row.original.role, filterValue),
			customDateFilterFn: (row, _id, filterValue) =>
				customDateFilterFn(row.original.createdAt, filterValue),
		},
		globalFilterFn: 'customResearchMemberFilterFn',
	});

	return (
		<>
			<MantineReactTable table={table} />

			{toggleModalOpened && currentResearchMemberOnResearch && (
				<ToggleResearchMemberStatusModal
					researchMember={currentResearchMemberOnResearch}
					handleClose={closeToggleModal}
				/>
			)}

			{linkModalOpened && currentResearchMemberOnResearch && (
				<LinkParticipantsToOffsiteResearchMemberModal
					offsiteResearchMember={currentResearchMemberOnResearch}
					handleClose={closeLinkModal}
				/>
			)}
		</>
	);
}
