import { useInfiniteScroll } from '@/components/hooks/useInfiniteScroll';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { CustomLoader } from '../../../../components/ui/loader';
import { paths } from '../../../../routes/paths';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { getThreadsLatestMessages } from '../../api/messages.queries';
import { messagesQueryKeys } from '../../api/messages.query-keys';
import { Thread } from '../../api/messages.types';
import { filterBySearchValue } from '../../utils/messages';
import { NewThreadItem } from '../atoms/NewThreadItem';
import { ThreadItem } from '../atoms/ThreadItem';
import { ThreadListHeader } from '../atoms/ThreadListHeader';

type ThreadListProps = {
	currentThread?: number;
	setCurrentThread: (threadId: number) => void;
	newMessage: boolean;
	setNewMessage: (newMessage: boolean) => void;
};

export const ThreadList = ({
	currentThread,
	setCurrentThread,
	newMessage,
	setNewMessage,
}: ThreadListProps) => {
	const { research, userOnResearch: user } = useCurrentResearch();

	const { data, fetchNextPage, hasNextPage, isFetching, isLoading, error } =
		useInfiniteQuery({
			queryKey: messagesQueryKeys.threads(research.id),
			queryFn: ({ pageParam }) =>
				getThreadsLatestMessages(research.id, pageParam),
			initialPageParam: 1,
			getNextPageParam: (lastPage, allPages) =>
				lastPage.length ? allPages.length + 1 : undefined,
			refetchInterval: 1000 * 60 * 3,
		});

	const [threads, setThreads] = useState<Thread[]>([]);
	const [searchValue, setSearchValue] = useState('');
	const [debouncedSearchText] = useDebounce(searchValue, 500);
	const infiniteScrollRef = useInfiniteScroll({
		isLoading,
		hasNextPage,
		isFetching,
		fetchNextPage,
	});

	useEffect(() => {
		if (!data) return;
		const allThreads = data?.pages.reduce((acc, page) => [...acc, ...page], []);
		const filteredThreads = debouncedSearchText
			? filterBySearchValue(allThreads, user.role, debouncedSearchText)
			: allThreads;
		setThreads(filteredThreads);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, debouncedSearchText]);

	useEffect(() => {
		if (threads && threads.length > 0) setCurrentThread(threads[0].id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [threads]);

	const handleThreadClick = (threadId: number) => {
		setCurrentThread(threadId);
	};

	if (isLoading) return <CustomLoader additionalClasses="bg-white" />;
	if (error) return <Navigate to={`/${paths.ERROR_PATH}`} />;

	return (
		<div className="flex h-full w-full flex-col gap-4 border border-gray-50 bg-white px-4 py-2">
			<ThreadListHeader
				setSearchValue={setSearchValue}
				setNewMessage={setNewMessage}
			/>
			<div className="flex flex-col divide-y divide-gray-50 overflow-y-auto">
				{newMessage && <NewThreadItem />}
				{threads.map((thread, index) => (
					<div
						key={thread.id}
						ref={index === threads.length - 1 ? infiniteScrollRef : undefined}
					>
						<ThreadItem
							thread={thread}
							onThreadClick={handleThreadClick}
							isActive={currentThread === thread.id}
						/>
					</div>
				))}
			</div>
		</div>
	);
};
