import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';

export const markNotificationAsRead = async (notificationId: number) => {
	try {
		await privateAxios.patch(`/notifications/${notificationId}/mark-as-read`);
	} catch (error) {
		throw error as AxiosError;
	}
};

export const markNotificationAsPerformed = async (notificationId: number) => {
	try {
		await privateAxios.patch(
			`/notifications/${notificationId}/mark-as-performed`,
		);
	} catch (error) {
		throw error as AxiosError;
	}
};
