import {
	ActionIcon,
	Card,
	CardSection,
	Group,
	Stack,
	Text,
} from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import { formatDate } from '../../../../shared/utils/dates';
import { useDownloadDocument } from '../../../documents/api/hooks/useDownloadDocument';
import { HelpDocument } from '../../api/help.types';

type Props = {
	document: HelpDocument;
};

export function HelpDocumentPreviewCard({ document }: Props) {
	const { mutation: downloadDocumentMutation } = useDownloadDocument(true);

	return (
		<Card shadow="sm" padding="lg" radius="md" withBorder h="100%">
			<CardSection bg="var(--mantine-primary-color-1)" h="50" />

			<Group
				justify="space-between"
				mt="md"
				mb="xs"
				wrap="nowrap"
				align="start"
			>
				<Stack>
					<Text fw={500} size="md">
						{document.fileName}
					</Text>
					<Text size="sm" c="dimmed">
						{formatDate(document.createdAt, 'D MMM YYYY')}
					</Text>
				</Stack>

				<ActionIcon
					size="md"
					variant="transparent"
					onClick={async () =>
						await downloadDocumentMutation.mutateAsync(document.id)
					}
				>
					<IconDownload />
				</ActionIcon>
			</Group>
		</Card>
	);
}
