import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { ScheduleVisitDto } from './visits.dto';

export const scheduleVisit = async (
	participantId: number,
	visitId: number,
	payload: ScheduleVisitDto,
) => {
	try {
		const { data } = await privateAxios.post(
			`visits/visit/${visitId}/participant/${participantId}/schedule`,
			payload,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
