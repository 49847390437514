import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useInvalidateQueries } from '../../../../shared/hooks/useInvalidateQueries';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { updateDocumentRecipients } from '../documents.mutation';
import { documentsQueryKeys } from '../documents.query-keys';

export const useUpdateDocumentRecipients = (
	documentId: number,
	successCallback: () => void,
) => {
	const invalidateQueries = useInvalidateQueries();
	const { research } = useCurrentResearch();
	const updateDocumentMutation = useMutation({
		mutationFn: async (recipientIds: number[]) =>
			updateDocumentRecipients(documentId, { recipientIds }),
		onSuccess: async () => {
			invalidateQueries([
				documentsQueryKeys.list(research.id),
				documentsQueryKeys.details(documentId),
			]);
			successCallback();
			notifications.show({
				message: 'Destinataires mis à jour avec succès',
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
		},
		onError: () => {
			notifications.show({
				message: 'Une erreur est survenue',
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		},
	});

	return { updateDocumentMutation };
};
