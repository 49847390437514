import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { UploadFileDto } from '../../../../shared/api/files.dto';
import { uploadFileQuery } from '../../../../shared/api/files.queries';
import { PreSignedPutUrl } from '../../../../shared/types/PreSignedPutUrl';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { useCurrentParticipant } from '../../../usersOnResearch/contexts/participants.context';
import {
	CreateDocumentDto,
	CreateDocumentWithoutKeyDto,
} from '../documents.dto';
import { createDocument } from '../documents.mutation';
import { getUploadUrl } from '../documents.queries';
import { documentsQueryKeys } from '../documents.query-keys';

export const useUploadDocuments = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { research } = useCurrentResearch();
	const { participant } = useCurrentParticipant();
	const queryClient = useQueryClient();

	const showSuccessNotification = (uploadedDocumentCount: number) => {
		notifications.show({
			message: `Ajout ${
				uploadedDocumentCount > 1
					? `de ${uploadedDocumentCount} fichiers`
					: "d'un fichier"
			} à la recherche`,
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const getDocumentUploadUrl = useMutation({
		mutationFn: async (fileName: string): Promise<PreSignedPutUrl> =>
			await getUploadUrl(fileName),
	});
	const createDocumentMutation = useMutation({
		mutationFn: async (data: CreateDocumentDto) =>
			createDocument(data, research.id),
	});
	const uploadDocumentMutation = useMutation({
		mutationFn: async (data: UploadFileDto) => await uploadFileQuery(data),
	});

	const uploadDocuments = async (
		documents: (CreateDocumentWithoutKeyDto & { file: File })[],
	) => {
		setIsLoading(true);
		try {
			const uploadedDocuments: { key: string; name: string }[] = [];
			for (const document of documents) {
				const { file, ...documentInfo } = document;
				const { signedUrl, key } = await getDocumentUploadUrl.mutateAsync(
					file.name,
				);
				await uploadDocumentMutation.mutateAsync({ signedUrl, file });
				await createDocumentMutation.mutateAsync({
					...documentInfo,
					key,
					participantId: participant?.userOnResearchId ?? undefined,
				});
				uploadedDocuments.push({ key, name: document.file.name });
			}
			showSuccessNotification(uploadedDocuments.length);
			queryClient.invalidateQueries({
				queryKey: documentsQueryKeys.list(research.id),
			});
		} catch (error) {
			showErrorNotification();
		} finally {
			setIsLoading(false);
		}
	};

	return { uploadDocuments, isLoading };
};
