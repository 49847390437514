import classes from '@/components/ui/form/mantine-radio-group.module.scss';
import { Radio } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { useCurrentResearch } from '../../../researches/contexts/researches.context';
import { DocumentAccessLevelType } from '../../api/documents.types';

type SimpleDocumentRecipientsFormProps = {
	accessLevel: DocumentAccessLevelType;
	setAccessLevel: React.Dispatch<React.SetStateAction<DocumentAccessLevelType>>;
};

export function SimpleDocumentRecipientsForm({
	accessLevel,
	setAccessLevel,
}: SimpleDocumentRecipientsFormProps) {
	const { userOnResearch } = useCurrentResearch();

	const getAccessLevels = () => {
		if (userOnResearch?.role === 'OFFSITE_RESEARCH_MEMBER') {
			return [
				{
					value: 'ALL',
					label: 'Participant et membres de l’équipe de recherche',
				},
				{
					value: 'OFFSITE_RESEARCH_MEMBERS',
					label:
						"Uniquement les membres de l'équipe de recherche ayant accès à cet espace participant",
				},
			];
		}

		return [
			{
				value: 'ALL',
				label:
					"Participant et membres de l'équipe de recherche ayant accès à cet espace participant",
			},
			{
				value: 'OFFSITE_RESEARCH_MEMBERS',
				label:
					'Uniquement les membres de l’équipe de recherche ayant accès à cet espace participant',
			},
			{ value: 'PARTICIPANTS', label: 'Participant seulement' },
		];
	};

	return (
		<div className="flex flex-col gap-8">
			<div className="flex flex-col gap-2">
				<h5 className="text-center">Sélectionnez les destinataires</h5>
				<div className="flex items-center justify-center gap-2 bg-gray-100 px-4 py-3 text-sm italic">
					<IconInfoCircle size={16} />
					<p className="text-center">
						Il s’agit des personnes uniquement de cette recherche
					</p>
				</div>
			</div>
			<Radio.Group
				value={accessLevel}
				onChange={(value) => setAccessLevel(value as DocumentAccessLevelType)}
				name="accessLevel"
				classNames={classes}
				label="À qui souhaitez-vous accorder l'accès à ce document ?"
				description="Vous pouvez choisir un groupe de destinataires parmi ceux qui ont déjà accès à cet espace participant."
				withAsterisk
				size="md"
			>
				<div className="flex flex-col gap-4">
					{getAccessLevels().map((level) => (
						<Radio value={level.value} label={level.label} key={level.value} />
					))}
				</div>
			</Radio.Group>
		</div>
	);
}
