import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import classes from '@/components/ui/modal/modal.module.scss';
import { Modal } from '@mantine/core';
import { useDeleteConsent } from '../../api/consents.hooks';

type DeleteConsentModalProps = {
	consentId: number;
	handleClose: () => void;
};

export function DeleteConsentModal({
	consentId,
	handleClose,
}: DeleteConsentModalProps) {
	const isMobile = useIsMobile();
	const mutation = useDeleteConsent(consentId, handleClose);

	return (
		<Modal
			opened={true}
			onClose={handleClose}
			centered
			classNames={classes}
			title="Supprimer le consentement"
			size="md"
			fullScreen={isMobile}
		>
			<div className="flex flex-col gap-6">
				<p className="text-center">
					Êtes-vous sûr(e) de vouloir supprimer ce consentement ? La demande de
					signature électronique sera ainsi que le document seront
					définitivement supprimés.
				</p>
				<div className="flex gap-4">
					{!isMobile && (
						<Button onClick={handleClose} variant="outline" className="w-full">
							Annuler
						</Button>
					)}
					<Button
						disabled={mutation.isPending}
						className="w-full"
						variant="destructive"
						onClick={() => mutation.mutate()}
					>
						Retirer
					</Button>
				</div>
			</div>
		</Modal>
	);
}
