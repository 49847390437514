import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
	getHelpDocuments,
	getHelpDownloadUrl,
	getHelpQuestions,
} from './help.queries';
import { helpQueryKeys } from './help.query-keys';
import { HelpDocument, HelpQuestion } from './help.types';

export const useGetQuestions = () => {
	const {
		data: questions,
		isLoading,
		error,
	} = useQuery<HelpQuestion[]>({
		queryKey: helpQueryKeys.questionsLists(),
		queryFn: () => getHelpQuestions(),
	});

	return { questions, isLoading, error };
};

export const useGetDocuments = () => {
	const {
		data: documents,
		isLoading,
		error,
	} = useQuery<HelpDocument[]>({
		queryKey: helpQueryKeys.documentsLists(),
		queryFn: () => getHelpDocuments(),
	});

	return { documents, isLoading, error };
};

export const useDownloadHelpDocument = () => {
	const handleDownload = (signedUrl: string) => {
		const downloadLink = document.createElement('a');
		downloadLink.href = signedUrl;
		downloadLink.click();
	};
	const showSuccessNotification = () => {
		notifications.show({
			message: 'Document téléchargé',
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const mutation = useMutation({
		mutationFn: async (id: number) => {
			return await getHelpDownloadUrl(id);
		},
		onSuccess: ({ signedUrl }) => {
			handleDownload(signedUrl);
			showSuccessNotification();
		},
		onError: showErrorNotification,
	});

	return { mutation };
};
