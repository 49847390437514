import classes from '@/components/ui/tabs/tabs.module.scss';
import { Space, Tabs } from '@mantine/core';
import { HelpDocuments } from './HelpDocuments';
import { HelpQuestions } from './HelpQuestions';
export function HelpTabs() {
	const tabItems = [
		{
			label: 'Foire aux questions',
			value: 'questions',
			component: <HelpQuestions />,
		},
		{
			label: 'Documents',
			value: 'documents',
			component: <HelpDocuments />,
		},
	];

	return (
		<Tabs classNames={classes} defaultValue={tabItems[0].value}>
			<Tabs.List>
				{tabItems.map((item, index) => (
					<Tabs.Tab key={index} value={item.value} className={classes.tab}>
						{item.label}
					</Tabs.Tab>
				))}
			</Tabs.List>

			<Space h="xl" />

			{tabItems.map((item, index) => (
				<Tabs.Panel key={index} value={item.value}>
					{item.component}
				</Tabs.Panel>
			))}
		</Tabs>
	);
}
