import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

type Props = {
	startDate: Date;
	setIsTimeElapsed: (value: boolean) => void;
};

const initialState = (startDate: Date) => {
	const time = dayjs(startDate).diff(dayjs(new Date()), 'millisecond');
	return time > 0 ? time : 0;
};

export function Countdown({ startDate, setIsTimeElapsed }: Props) {
	const [time, setTime] = useState(() => initialState(startDate));

	useEffect(() => {
		const interval = setInterval(() => {
			if (time > 1000) {
				setTime(Math.max(time - 1000, 0));
			} else {
				setTime(0);
				clearInterval(interval);
			}
		}, 1000);

		if (time === 0) setIsTimeElapsed(true);

		return () => clearInterval(interval);
	}, [time, setIsTimeElapsed]);

	return (
		<h2 className="text-primary">
			{dayjs
				.duration(dayjs(startDate).diff(dayjs(new Date())))
				.format('H[h] m[m] s[s]')}
		</h2>
	);
}
