import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { Role } from '../../../profile/api/types/user.types';
import { resendOffsiteResearchMemberInviteLink } from '../../offsite-research-member/api/offsite-research-member.queries';
import { resendOnsiteResearchMemberInviteLink } from '../../onsite-research-member/api/onsite-research-member.queries';
import { resendParticipantInviteLink } from '../../participant/api/participants.mutations';
import { resendInvestigatorInviteLink } from '../../investigator/api/investigator.queries';

export const useResendInviteLink = (
	researchId: number,
	userId: number,
	userRole: Role,
) => {
	return useMutation({
		mutationFn: async () => {
			switch (userRole) {
				case 'PARTICIPANT':
					return resendParticipantInviteLink(userId, researchId);
				case 'OFFSITE_RESEARCH_MEMBER':
					return resendOffsiteResearchMemberInviteLink(userId, researchId);
				case 'ONSITE_RESEARCH_MEMBER':
					return resendOnsiteResearchMemberInviteLink(userId, researchId);
				case 'INVESTIGATOR':
					return resendInvestigatorInviteLink(userId, researchId);
				default:
					throw new Error('Invalid user role');
			}
		},
		onSuccess: () => {
			notifications.show({
				message: "Lien d'invitation renvoyé",
				color: 'teal',
				icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
			});
		},
		onError: () => {
			notifications.show({
				message: 'Une erreur est survenue',
				color: 'red',
				icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
			});
		},
	});
};
