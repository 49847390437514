import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import MyField from '@/components/ui/form/form-field';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/components/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { Fieldset } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { FormProvider, useForm } from 'react-hook-form';
import { isUnder18 } from '../../../../shared/utils/dates';
import { FieldInfo } from '../../../contacts/components/atoms/FieldInfo';
import {
	useDeleteLegalRepresentative,
	useGetUserProfile,
	useUpdateLegalRepresentative,
} from '../../api/profile.hooks';
import { LegalRepresentativeRoleEnum } from '../../api/types/participants.types';
import { ConfirmDeleteModal } from '../modals/ConfirmDeleteModal';
import {
	UpdateLegalRepresentativeZodSchema,
	UpdateLegalRepresentativeZodType,
} from './validators/UpdateLegalRepresentativeZodSchema';

type LegalRepresentativeFormProps = {
	legalRepresentativeId: number;
};
export function LegalRepresentativeForm({
	legalRepresentativeId,
}: LegalRepresentativeFormProps) {
	const isMobile = useIsMobile();
	const { profile } = useGetUserProfile();
	const { updateLegalRepresentativeMutation } = useUpdateLegalRepresentative(
		legalRepresentativeId,
	);
	const { deleteLegalRepresentativeMutation } = useDeleteLegalRepresentative(
		legalRepresentativeId,
	);
	const legalRepresentative =
		profile?.participantProfile?.legalRepresentatives?.find(
			(legalRep) => legalRep.legalRepresentativeId === legalRepresentativeId,
		);

	const [opened, { open, close }] = useDisclosure();

	const methods = useForm<UpdateLegalRepresentativeZodType>({
		resolver: zodResolver(UpdateLegalRepresentativeZodSchema),
		defaultValues: legalRepresentative,
	});
	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = methods;

	if (!legalRepresentative) return null;

	const canDeleteLegalRepresentative =
		(legalRepresentative.role === 'PARENT1' ||
			legalRepresentative.role === 'PARENT2') &&
		profile?.participantProfile?.birthDate &&
		!isUnder18(profile.participantProfile.birthDate);

	const submitForm = (data: UpdateLegalRepresentativeZodType) => {
		updateLegalRepresentativeMutation.mutate(data);
	};

	return (
		<div className="flex gap-4 max-md:flex-col">
			<h5 className={cn(isMobile ? 'w-full' : 'w-1/4')}>
				{LegalRepresentativeRoleEnum[legalRepresentative.role]}
			</h5>
			<div className="flex flex-1">
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(submitForm)} className="w-full">
						<Fieldset
							p="lg"
							styles={{
								root: {
									backgroundColor: 'white',
								},
							}}
						>
							<div className="flex flex-col gap-12">
								<div className="flex flex-col gap-4">
									<div className="flex gap-4 max-md:flex-col">
										<MyField
											type="text"
											name="firstName"
											label="Prénom"
											required={true}
											placeholder="Prénom"
											error={errors.firstName?.message}
											labelClass="text-black"
											inputClass="text-black"
										/>

										<MyField
											type="text"
											name="lastName"
											label="Nom"
											required={true}
											placeholder="Nom"
											error={errors.lastName?.message}
											labelClass="text-black"
											inputClass="text-black"
										/>
									</div>

									{legalRepresentative.role === 'FAMILY_MEMBER' &&
										legalRepresentative.additionalInfo && (
											<FieldInfo
												label="Relation de parenté"
												value={legalRepresentative.additionalInfo}
											/>
										)}

									<MyField
										type="email"
										name="email"
										label="Email"
										required={true}
										placeholder="Addresse email"
										disabled={true}
										error={errors.address?.message}
										labelClass="text-black"
										inputClass="text-black"
									/>

									<MyField
										type="tel"
										name="phoneNumber"
										required={true}
										label="Téléphone"
										placeholder="Numéro de téléphone"
										error={errors.phoneNumber?.message}
										labelClass="text-black"
										inputClass="text-black"
									/>

									<MyField
										type="text"
										name="address"
										label="Adresse"
										placeholder="Adresse"
										required={true}
										error={errors.address?.message}
										labelClass="text-black"
										inputClass="text-black"
									/>

									<div className="flex flex-col justify-between gap-4 md:flex-row">
										<MyField
											type="text"
											name="city"
											label="Ville"
											placeholder="Ville"
											required={true}
											error={errors.city?.message}
											labelClass="text-black"
											inputClass="text-black"
											additionalClass="md:w-1/2"
										/>
										<MyField
											type="number"
											name="zipCode"
											label="Code postal"
											placeholder="Code postal"
											required={true}
											error={errors.zipCode?.message}
											labelClass="text-black"
											inputClass="text-black"
											additionalClass="md:w-1/2"
										/>
									</div>
								</div>
								<Separator />

								<div className="flex gap-4 max-md:flex-col">
									<Button
										onClick={() => reset(legalRepresentative)}
										type="button"
										variant="outline"
										className="w-full"
									>
										Annuler
									</Button>

									{canDeleteLegalRepresentative && (
										<>
											<Button
												variant={'destructive'}
												onClick={open}
												type="button"
												className="w-full"
											>
												Supprimer
											</Button>
											{opened && (
												<ConfirmDeleteModal
													handleClose={close}
													handleConfirm={
														deleteLegalRepresentativeMutation.mutate
													}
													isLoading={
														deleteLegalRepresentativeMutation.isPending
													}
												/>
											)}
										</>
									)}

									<Button
										type="submit"
										disabled={updateLegalRepresentativeMutation.isPending}
										className="w-full"
									>
										Enregistrer
									</Button>
								</div>
							</div>
						</Fieldset>
					</form>
				</FormProvider>
			</div>
		</div>
	);
}
