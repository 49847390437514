import { AxiosError } from 'axios';
import { privateAxios } from '../../../../shared/services/privateAxios';
import {
	UserOnResearch,
	UserOnResearchStatusType,
} from '../types/users-on-research.types';

export const getOffsiteResearchMembersByParticipant = async (
	researchId: number,
	participantId: number,
	statuses?: UserOnResearchStatusType[],
): Promise<UserOnResearch[]> => {
	try {
		const { data } = await privateAxios.get<UserOnResearch[]>(
			`/researches/${researchId}/get-offsite-research-members/participant/${participantId}`,
			{ params: { status: statuses } },
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
