import classes from '@/components/ui/modal/modal.module.scss';
import { UserOnResearch } from '@/features/usersOnResearch/api/types/users-on-research.types';
import { ActionIcon, Modal } from '@mantine/core';
import { IconArrowLeft, IconMessageCircle2Filled } from '@tabler/icons-react';
import { useState } from 'react';
import { ContactsPicker } from '../../forms/ContactsPicker';
import { MobileSendNewMessageForm } from '../forms/MobileSendNewMessageForm';

type MobileSendNewMessageProps = {
	handleClose: () => void;
};

export const MobileSendNewMessage = (props: MobileSendNewMessageProps) => {
	const [recipients, setRecipients] = useState<UserOnResearch[]>([]);

	return (
		<Modal
			fullScreen
			opened
			classNames={classes}
			onClose={props.handleClose}
			withCloseButton={false}
		>
			<div className="flex h-full flex-col justify-between">
				<div className="flex flex-col">
					<div className="border-b-0.5 flex min-h-[70px] items-center gap-4 border-gray-50 px-4 py-3">
						<IconArrowLeft
							size={24}
							className="text-primary"
							onClick={props.handleClose}
						/>
						<ActionIcon size="50" radius="xl">
							<IconMessageCircle2Filled />
						</ActionIcon>
						<p className="text-lg font-semibold">Nouveau message</p>
					</div>
					<div className="min-h-[30px] w-full">
						<ContactsPicker
							recipients={recipients}
							setRecipients={setRecipients}
						/>
					</div>
				</div>
				<MobileSendNewMessageForm
					recipients={recipients}
					onMessageSent={props.handleClose}
				/>
			</div>
		</Modal>
	);
};
