import { AxiosError } from 'axios';
import { privateAxios } from '../../../shared/services/privateAxios';
import { CreateNoteDto } from './notes.dto';

export const createNote = async (researchId: number, body: CreateNoteDto) => {
	try {
		const { data } = await privateAxios.post(
			`/notes/researches/${researchId}/create-note`,
			body,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const deleteNote = async (noteId: number) => {
	try {
		const { data } = await privateAxios.delete(`/notes/${noteId}/delete-note`);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};

export const updateNote = async (noteId: number, body: CreateNoteDto) => {
	try {
		const { data } = await privateAxios.patch(
			`/notes/${noteId}/update-note`,
			body,
		);
		return data;
	} catch (error) {
		throw error as AxiosError;
	}
};
