import {
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from '@/components/ui/form';
import clsx from 'clsx';

export type MyFieldProps = {
	name: string;
	label: string;
	placeholder: string;
	type: React.HTMLInputTypeAttribute;
	error?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
	required?: boolean;
	disabled?: boolean;
	accept?: string;
	additionalClass?: string;
	fieldAdditionalClass?: string;
	labelClass?: string;
	inputClass?: string;
	direction?: string;
	defaultValue?: string;
	inputMode?: React.HTMLAttributes<HTMLInputElement>['inputMode'];
	pattern?: string;
	autoComplete?: string;
	maxLength?: number;
	step?: string;
	min?: string;
};

function MyField({
	name,
	label,
	placeholder,
	type,
	error,
	onChange,
	onPaste,
	required,
	disabled,
	accept,
	additionalClass,
	fieldAdditionalClass,
	labelClass,
	inputClass,
	direction,
	defaultValue,
	inputMode,
	pattern,
	autoComplete,
	maxLength,
	step,
	min,
}: MyFieldProps) {
	const formItemClasses = clsx('flex w-full flex-col', additionalClass);
	const formFieldClasses = clsx(
		'flex w-full rounded-md border border-input bg-background px-6 py-4 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50',
		fieldAdditionalClass,
	);

	return (
		<FormItem className={formItemClasses}>
			<FormLabel
				className={clsx(labelClass, {
					'text-base font-semibold text-white': !labelClass,
				})}
			>
				<div className="flex space-x-1">
					<p>{label}</p>
					{required && <span className="text-sm text-error-500">*</span>}
				</div>
			</FormLabel>
			<div className={direction ? '' : 'flex w-full flex-col'}>
				<FormField
					name={name}
					render={({ field }) => (
						<FormControl className={formFieldClasses}>
							<input
								type={type}
								{...field}
								placeholder={placeholder}
								className={clsx(inputClass, {
									'text-white': !inputClass,
								})}
								inputMode={inputMode}
								pattern={pattern}
								autoComplete={autoComplete}
								maxLength={maxLength}
								onChange={(e) => {
									field.onChange(e);
									onChange && onChange(e);
								}}
								onPaste={(e) => {
									onPaste && onPaste(e);
								}}
								accept={accept}
								defaultValue={defaultValue}
								step={step}
								disabled={disabled}
								min={min}
							/>
						</FormControl>
					)}
				/>
				{error && (
					<FormMessage className="mt-[6px] text-sm font-medium text-error-500">
						{error}
					</FormMessage>
				)}
			</div>
		</FormItem>
	);
}

MyField.defaultProps = {
	error: '',
	onChange: () => {},
	accept: '',
	additionalClass: '',
	fieldAdditionalClass: '',
	labelClass: '',
	inputClass: '',
};

export default MyField;
