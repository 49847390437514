import React from 'react';
import { FileDropzone } from '../../../../shared/components/files/FileDropzone';
import FilesControlPanel from '../../../../shared/components/files/FilesControlPanel';
import { S3File } from '../../../../shared/types/S3File';

type UploadDocumentsFormProps = {
	document: S3File | undefined;
	setDocument: React.Dispatch<React.SetStateAction<S3File | undefined>>;
};

export const UploadConsentForm = ({
	document,
	setDocument,
}: UploadDocumentsFormProps) => {
	const handleFilesUpload = (files: File[]) => {
		const selectedFile = files[0];
		setDocument({
			fileName: selectedFile.name,
			file: selectedFile,
		});
	};

	const handleRemoveFile = () => {
		setDocument(undefined);
	};

	return (
		<div className="flex flex-col gap-8">
			<div className="flex flex-col gap-1">
				<h5 className="text-center">Importer le document à faire signer</h5>
				<p className="text-center">
					Les destinataires sélectionnés précédemment recevront un email de la
					part de Yousign pour signer électroniquement ce document.
				</p>
			</div>
			<div className="flex flex-col gap-4">
				<FileDropzone
					onFilesUpload={handleFilesUpload}
					mimeTypes={['application/pdf']}
				/>
				<FilesControlPanel
					files={document ? [document] : []}
					onRemoveFile={handleRemoveFile}
				/>
			</div>
		</div>
	);
};
