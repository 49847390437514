import { cn } from '@/components/utils';
import clsx from 'clsx';
import { useState } from 'react';
import { FormField, FormItem, FormMessage } from '../form';

type BasicComponentProps = {
	name: string;
	label: string;
	placeholder: string;
};

type TextAreaProps = {
	description?: string;
	minChar?: number;
	additionalClass?: string;
	labelAdditionalClass?: string;
	textareaAdditionalClass?: string;
	maxChar?: number;
};

export const MyTextArea = (props: BasicComponentProps & TextAreaProps) => {
	const {
		name,
		label,
		placeholder,
		description,
		additionalClass,
		labelAdditionalClass,
		textareaAdditionalClass,
		maxChar,
		minChar,
	} = props;

	const [charCount, setCharCount] = useState(0);

	const formItemClasses = clsx('flex w-full flex-col', additionalClass);
	const textareaClasses = clsx(
		'border-second_gray h-[200px] w-full rounded border bg-transparent px-[0.88rem] py-2 text-sm font-normal leading-6',
		textareaAdditionalClass,
	);

	return (
		<div className="flex flex-col">
			<FormField
				name={name}
				render={({ field }) => (
					<div className="flex w-full flex-col">
						<FormItem className={formItemClasses}>
							<div>
								<p className={cn('text-sm text-white', labelAdditionalClass)}>
									{label}
								</p>
								{description && (
									<span className="bg-red-500 text-sm font-normal leading-5">
										<h3 className="text-gray-600">{description}</h3>
										<p className="italic text-gray-400">(minimum {minChar})</p>
									</span>
								)}
							</div>
							<div className={description ? '' : 'w-full'}>
								<textarea
									{...field}
									placeholder={placeholder}
									className={textareaClasses}
									onChange={(e) => {
										field.onChange(e);
										setCharCount(e.target.value.length);
									}}
								/>
								<FormMessage className="leading-150 text-sm font-medium text-red-500" />
							</div>
						</FormItem>
					</div>
				)}
			/>
			{maxChar && (
				<div
					className={clsx(
						'text-right text-[.875rem] font-normal text-gray-600',
						{
							'text-red-600': charCount > maxChar,
							'text-gray-600': charCount <= maxChar,
						},
					)}
				>
					{charCount} / {maxChar}
				</div>
			)}
		</div>
	);
};

MyTextArea.defaultProps = {
	description: '',
	additionalClass: '',
	labelAdditionalClass: 'text-white text-base font-semibold leading-150',
	textareaAdditionalClass: 'text-white w-full',
};
