import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import MyField from '@/components/ui/form/form-field';
import { Separator } from '@/components/ui/separator';
import { cn } from '@/components/utils';
import { zodResolver } from '@hookform/resolvers/zod';
import { Fieldset } from '@mantine/core';
import { FormProvider, useForm } from 'react-hook-form';
import { useSession } from '../../../auth/shared/hooks/useSession';
import {
	useGetUserProfile,
	useUpdateUserProfile,
} from '../../api/profile.hooks';
import {
	ParticipantProfileZodSchema,
	ParticipantProfileZodType,
} from './validators/ParticipantProfileZodSchema';

export function ParticipantProfileForm() {
	const isMobile = useIsMobile();
	const { user } = useSession();
	const { profile } = useGetUserProfile();
	const { updateUserInfoMutation } = useUpdateUserProfile();

	const methods = useForm<ParticipantProfileZodType>({
		resolver: zodResolver(ParticipantProfileZodSchema),
		defaultValues: profile,
	});
	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = methods;

	const submitForm = ({
		participantProfile,
		...rest
	}: ParticipantProfileZodType) => {
		updateUserInfoMutation.mutate({
			...rest,
			participantProfile: { ...participantProfile, legalRepresentatives: [] },
		});
	};

	return (
		<div className="flex gap-4 max-md:flex-col">
			<h5 className={cn(isMobile ? 'w-full' : 'w-1/4')}>
				Informations personnelles
			</h5>
			<div className="flex flex-1">
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit(submitForm)} className="w-full">
						<Fieldset
							p="lg"
							styles={{
								root: {
									backgroundColor: 'white',
								},
							}}
						>
							<div className="flex flex-col gap-12">
								<div className="flex flex-col gap-4">
									<div className="flex gap-4 max-md:flex-col">
										<MyField
											type="text"
											name="firstName"
											label="Prénom"
											required={true}
											placeholder="Prénom"
											error={errors.firstName?.message}
											labelClass="text-black"
											inputClass="text-black"
										/>

										<MyField
											type="text"
											name="lastName"
											label="Nom"
											required={true}
											placeholder="Nom"
											error={errors.lastName?.message}
											labelClass="text-black"
											inputClass="text-black"
										/>
									</div>

									<MyField
										type="email"
										name="email"
										label="Email"
										required={true}
										placeholder="Addresse email"
										disabled={true}
										defaultValue={user?.email}
										labelClass="text-black"
										inputClass="text-black"
									/>

									<MyField
										type="tel"
										name="phoneNumber"
										required={true}
										label="Téléphone"
										placeholder="Numéro de téléphone"
										error={errors.phoneNumber?.message}
										labelClass="text-black"
										inputClass="text-black"
									/>

									<MyField
										type="text"
										name="participantProfile.address"
										label="Adresse"
										placeholder="Adresse"
										required={true}
										error={errors.participantProfile?.address?.message}
										labelClass="text-black"
										inputClass="text-black"
									/>

									<div className="flex flex-col justify-between gap-4 md:flex-row">
										<MyField
											type="text"
											name="participantProfile.city"
											label="Ville"
											placeholder="Ville"
											required={true}
											error={errors.participantProfile?.city?.message?.toString()}
											labelClass="text-black"
											inputClass="text-black"
											additionalClass="md:w-1/2"
										/>
										<MyField
											type="number"
											name="participantProfile.zipCode"
											label="Code postal"
											placeholder="Code postal"
											required={true}
											error={errors.participantProfile?.zipCode?.message?.toString()}
											labelClass="text-black"
											inputClass="text-black"
											additionalClass="md:w-1/2"
										/>
									</div>
								</div>
								<Separator />

								<div className="flex gap-4 max-md:flex-col">
									<Button
										onClick={() => reset(profile)}
										variant="outline"
										className="w-full"
										type="button"
									>
										Annuler
									</Button>

									<Button
										type="submit"
										disabled={updateUserInfoMutation.isPending}
										className="w-full"
									>
										Enregistrer
									</Button>
								</div>
							</div>
						</Fieldset>
					</form>
				</FormProvider>
			</div>
		</div>
	);
}
