import parsePhoneNumber from 'libphonenumber-js';
import { z } from 'zod';

export const emailValidator = z
	.string()
	.min(1, { message: 'Email requis' })
	.email({
		message: 'Email invalide',
	})
	.trim();

export const passwordValidator = z
	.string()
	.trim()
	.min(12, { message: 'Le mot de passe doit avoir au moins 12 caractères' })
	.refine((password) => /[A-Z]/.test(password), {
		message: 'Le mot de passe doit contenir au moins une lettre majuscule',
	})
	.refine((password) => /\d/.test(password), {
		message: 'Le mot de passe doit contenir au moins un nombre',
	})
	.refine((password) => /\W|_/.test(password), {
		message: 'Le mot de passe doit contenir au moins un symbole',
	});

export const phoneNumberValidator = z
	.string()
	.min(1, { message: 'Numéro de téléphone requis' })
	.transform((value, ctx) => {
		const parsedValue = parsePhoneNumber(value, 'FR');
		if (!parsedValue || !parsedValue.isValid()) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: 'Numéro de téléphone invalide',
			});
			return z.NEVER;
		}

		return parsedValue.formatNational();
	});

export const otpValidator = z
	.string()
	.trim()
	.optional()
	.refine((value) => /^\d{6}$/.test(value ?? ''), {
		message: 'Le code requis est composé de 6 chiffres',
	});

export const birthDateValidator = z.date({
	required_error: 'Date requise',
});

export const firstNameValidator = z
	.string()
	.trim()
	.min(1, { message: 'Prénom requis' });

export const lastNameValidator = z
	.string()
	.trim()
	.min(1, { message: 'Nom requis' });

export const workplaceValidator = z
	.string()
	.trim()
	.min(1, { message: 'Établissement requis' });

export const addressValidator = z
	.string()
	.trim()
	.min(1, { message: 'Adresse requise' });

export const zipCodeValidator = z
	.string()
	.trim()
	.min(1, { message: 'Code postal requis' })
	.refine((value) => /^\d{5}$/.test(value), {
		message: 'Le code postal doit être composé de 5 chiffres.',
	});

export const cityValidator = z
	.string()
	.trim()
	.min(1, { message: 'Ville requise' });

export const medicalServiceValidator = z.string().trim().optional();

export const rppsValidator = z
	.string()
	.trim()
	.nullish()
	.refine((value) => (!value ? true : /^\d{11}$/.test(value)), {
		message: "L'identifiant RPPS doit être composé de 11 chiffres",
	})
	.transform((value) => (!value ? undefined : value));

export const UserSignUpSchema = z.object({
	firstName: firstNameValidator,
	lastName: lastNameValidator,
	password: passwordValidator,
});
