import { cn } from '@/components/utils';
import { Avatar } from '@mantine/core';
import dayjs from 'dayjs';
import { useSession } from '../../../auth/shared/hooks/useSession';
import { UserOnResearch } from '../../../usersOnResearch/api/types/users-on-research.types';
import { getResearchMemberInfo } from '../../../usersOnResearch/utils/researchMemberInfo';
import { Thread } from '../../api/messages.types';
import { getThreadContact } from '../../utils/messages';

type ThreadItemProps = {
	thread: Thread;
	isActive: boolean;
	onThreadClick: (threadId: number) => void;
};

export const ThreadItem = ({
	thread,
	isActive = false,
	onThreadClick: handleThreadClick,
}: ThreadItemProps) => {
	const { user } = useSession();
	const { latestMessage } = thread;
	if (!latestMessage || !user) return null;

	const contact = getThreadContact(thread, user.role);
	const isForResearchCenter = contact === 'RESEARCH_CENTER';
	const {
		avatar: avatarSrc,
		displayTitle,
		displayRole,
	} = getResearchMemberInfo(contact as UserOnResearch);

	return (
		<div
			className={cn(
				isActive && 'bg-muted',
				'flex min-h-[70px] flex-col gap-2 px-2 py-3',
			)}
			onClick={() => handleThreadClick(thread.id)}
		>
			<div className="flex items-center justify-between max-md:flex-col max-md:items-start max-md:gap-1">
				<div className="flex items-center gap-2">
					<Avatar size={40} src={avatarSrc} />
					<div className="flex flex-col">
						<p className="font-semibold">{displayTitle}</p>
						{!isForResearchCenter && (
							<p className="text-sm text-muted-foreground">{displayRole}</p>
						)}
					</div>
				</div>
				<p className="text-right text-xs text-muted-foreground">
					{dayjs(latestMessage.createdAt).fromNow()}
				</p>
			</div>
			<p className="line-clamp-2 text-sm">{latestMessage.content}</p>
		</div>
	);
};
