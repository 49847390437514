import { useIsMobile } from '@/components/hooks/useIsMobile';
import { Button } from '@/components/ui/button';
import { zodResolver } from '@hookform/resolvers/zod';
import { PinInput } from '@mantine/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
	SignInOtpZodSchema,
	SignInOtpZodType,
} from './validators/SignInOtpZodSchema';

type Props = {
	onSubmit: (otp: string) => void;
	isLoading: boolean;
};

export function SignInOtpVerificationForm({ onSubmit, isLoading }: Props) {
	const isMobile = useIsMobile();
	const methods = useForm<SignInOtpZodType>({
		resolver: zodResolver(SignInOtpZodSchema),
	});

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = methods;

	const submitForm = async (data: SignInOtpZodType) => {
		const { otp } = data;
		if (!otp || otp.length !== 6) return;
		onSubmit(otp);
	};

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={handleSubmit(submitForm)}
				noValidate
				className="flex flex-col gap-12"
			>
				<div className="flex flex-col gap-4 overflow-hidden">
					<Controller
						control={control}
						name="otp"
						render={({ field: { onChange, value }, fieldState: { error } }) => (
							<PinInput
								size={isMobile ? 'sm' : 'xl'}
								length={6}
								onChange={(newValue) => onChange(newValue)}
								value={value}
								radius={isMobile ? 'md' : 'lg'}
								placeholder="_"
								type="number"
								gap={isMobile ? 'xs' : 'xl'}
								error={Boolean(error?.message)}
							/>
						)}
					/>
					{errors.otp && (
						<p className="text-center text-sm text-error-500">
							{errors.otp.message}
						</p>
					)}
				</div>
				<Button
					disabled={isLoading}
					type="submit"
					className="flex justify-center"
				>
					Vérifier l'email
				</Button>
			</form>
		</FormProvider>
	);
}
