import { Space, Stack, Text, Title } from '@mantine/core';
import { CustomLoader } from '../../../../../../components/ui/loader';
import { useGetQuestionnairePreview } from '../../api/questionnaires.hooks';
import { QuestionPreview } from './QuestionPreview';

type Props = {
	actionId: number;
};

export function QuestionnairePreview({ actionId }: Props) {
	const { questionnaire, isLoading, error } =
		useGetQuestionnairePreview(actionId);

	if (error)
		return (
			<Text fs="italic" c="red" ta="center">
				Une erreur est survenue lors du chargement des données
			</Text>
		);
	if (isLoading || !questionnaire) return <CustomLoader />;

	return (
		<Stack gap="lg" w="100%">
			<Title order={3} ta="center">
				{questionnaire.title}
			</Title>
			<Text
				ta="center"
				fw={500}
				truncate
				style={{
					whiteSpace: 'pre-line',
				}}
			>
				{questionnaire.description}
			</Text>
			<Space h="xl" />
			<Stack gap="xl" w="100%" pb="sm" align="center">
				{questionnaire.questions?.map((question) => (
					<QuestionPreview key={question.id} question={question} />
				))}
			</Stack>
		</Stack>
	);
}
