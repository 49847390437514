import { rem } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { useCurrentResearch } from '../../researches/contexts/researches.context';
import { getConsentDownloadUrl, getConsentsZip } from '../api/consents.queries';
import dayjs from 'dayjs';

export const useDownloadConsent = () => {
	const handleDownload = (signedUrl: string) => {
		const downloadLink = document.createElement('a');
		downloadLink.href = signedUrl;
		downloadLink.click();
	};
	const showSuccessNotification = () => {
		notifications.show({
			message: 'Document téléchargé',
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const downloadConsentMutation = useMutation({
		mutationFn: async (id: number) => getConsentDownloadUrl(id),
		onSuccess: ({ signedUrl }) => {
			handleDownload(signedUrl);
			showSuccessNotification();
		},
		onError: showErrorNotification,
	});

	return downloadConsentMutation;
};

export const useDownloadConsentsAsZip = () => {
	const { research } = useCurrentResearch();
	const handleDownload = (blobData: Blob, fileName: string) => {
		const blobUrl = URL.createObjectURL(blobData);
		const downloadLink = document.createElement('a');
		downloadLink.href = blobUrl;
		downloadLink.download = fileName;
		downloadLink.click();
		URL.revokeObjectURL(blobUrl);
	};
	const showSuccessNotification = () => {
		notifications.show({
			message: 'Documents téléchargés',
			color: 'teal',
			icon: <IconCheck style={{ width: rem(20), height: rem(20) }} />,
		});
	};
	const showErrorNotification = () => {
		notifications.show({
			message: `Une erreur est survenue`,
			color: 'red',
			icon: <IconX style={{ width: rem(20), height: rem(20) }} />,
		});
	};

	const downloadConsentsMutation = useMutation({
		mutationFn: async (ids: number[]) => getConsentsZip(ids),
		onSuccess: (blobData: Blob) => {
			handleDownload(
				blobData,
				`${research.name}-${dayjs().format('DD/MM/YYYY')}-documents.zip`,
			);
			showSuccessNotification();
		},
		onError: showErrorNotification,
	});

	return downloadConsentsMutation;
};
